import React, { useContext, useEffect, useState, useRef } from "react";
import { Web3Context } from "../contexts/Web3Context";
import { MainContext } from "../contexts/MainContext";

import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";

import CryptoJS from "crypto-js";
import changeNetworkToPolygon from "../func/changeNetwork";

const CreateProject = () => {
  const { web3, accounts, contract } = useContext(Web3Context);
  const { updateCurrentPage } = useContext(MainContext);

  const [projectName, setProjectName] = useState();
  const [projectID, setProjectID] = useState();
  const [actionName, setActionName] = useState();
  const [actionPoints, setActionPoints] = useState();
  const [actionDirection, setActionDirection] = useState(0);
  const [scoreType, setScoreType] = useState();
  const [owner, setOwner] = useState();
  const [updater, setUpdater] = useState();

  const [modalText, setModalText] = useState("");

  const { isOpen, onOpen, onClose } = useDisclosure();

  const ownerRef = useRef();
  const updaterRef = useRef();

  const handleClick = async () => {
    let action = {
      name: actionName,
      points: actionPoints,
      direction: actionDirection,
    };
    console.log("direciton:" + actionDirection);
    console.log(
      projectID,
      projectName,
      [action],
      [scoreType],
      [owner],
      [updater]
    );

    await changeNetworkToPolygon();

    await contract.methods
      .createProject(
        projectID,
        projectName,
        [action],
        [scoreType],
        [owner],
        [updater]
      )
      .send({
        from: accounts[0],
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .on("transactionHash", (hash) => {
        setModalText("sent!");
        console.log("Transaction hash: " + hash);
        onOpen();
      })
      .on("error", (error) => {
        setModalText("error!");
        console.log(error);
        onOpen();
      });
  };

  const generateProjectID = () => {
    let projectString = "xp_project_" + Date.now();
    let hash = CryptoJS.SHA256(projectString).toString();
    setProjectID("0x" + hash);
  };

  const checkAddress = (value, ref) => {
    let isAddress = web3.utils.isAddress(value);

    if (!isAddress) ref.current.style.borderColor = "#EB0028";
    else ref.current.style.borderColor = "#0E0E0E";
  };

  useEffect(() => {
    updateCurrentPage("createProject");
  }, [updateCurrentPage]);

  return (
    <Flex flexDir="column" mt="10vh" ml="10vw" mb={10} w={500}>
      <Heading mb={12} color="white" fontSize="40px" fontWeight={900}>
        Create Your Project
      </Heading>

      <Heading mb={4} color="white" fontSize="24px" fontWeight={700}>
        Project Name
      </Heading>
      <Input
        borderColor="#0E0E0E"
        placeholder="BAYC"
        textColor="white"
        mb={6}
        onChange={(e) => setProjectName(e.target.value)}
      />
      <Heading color="white" fontSize="24px" fontWeight={700}>
        Project ID
      </Heading>
      <Text color="#A7A7A7" fontSize="15px" mb={2}>
        Enter your unique hashed ID (or click to generate)
      </Text>
      <Flex>
        <Input
          borderColor="#0E0E0E"
          placeholder="0x495f94...7b5e"
          textColor="white"
          mb={6}
          mr={5}
          w="65%"
          value={projectID}
          onChange={(e) => setProjectID(e.target.value)}
        />
        <Button
          bg={"#A654F4"}
         color={"white"}
          w="30%"
          borderRadius={10}
          onClick={() => generateProjectID()}
        >
          Generate
        </Button>
      </Flex>
      <Heading color="white" fontSize="24px" fontWeight={700}>
        Action
      </Heading>
      <Text color="#A7A7A7" fontSize="15px" mb={2}>
        Enter an action name, amount of points and whether it adds or subtracts
        points
      </Text>
      <Flex>
        <Flex w="65%">
          <Input
            borderColor="#0E0E0E"
            placeholder="Action name"
            onChange={(e) => setActionName(e.target.value)}
            textColor="white"
            mb={3}
            mr={5}
          />
        </Flex>
        <Flex w="30%">
          <Input
            borderColor="#0E0E0E"
            placeholder="Action points"
            onChange={(e) => setActionPoints(e.target.value)}
            textColor="white"
            mb={3}
          />
        </Flex>
      </Flex>
      <RadioGroup
        onChange={setActionDirection}
        value={actionDirection}
        mb={5}
        defaultValue="0"
      >
        <Stack direction="row">
          <Radio value="0" borderColor="#545454">
            <Text color="#A7A7A7" fontSize="18px">
              Increase
            </Text>
          </Radio>
          <Radio value="1" borderColor="#545454">
            <Text color="#A7A7A7" fontSize="18px">
              Decrease
            </Text>
          </Radio>
        </Stack>
      </RadioGroup>
      <Heading color="white" fontSize="24px" fontWeight={700}>
        Score Type
      </Heading>
      <Text color="#A7A7A7" fontSize="15px" mb={2}>
        Enter the name of the score type (e.g. "Creator")
      </Text>
      <Input
        borderColor="#0E0E0E"
        placeholder="Creator"
        onChange={(e) => setScoreType(e.target.value)}
        textColor="white"
        mb={6}
      />
      <Heading color="white" fontSize="24px" fontWeight={700}>
        Project Owner
      </Heading>
      <Text color="#A7A7A7" fontSize="15px" mb={2}>
        Enter an address for the project owner
      </Text>
      <Input
        ref={ownerRef}
        borderColor="#0E0E0E"
        placeholder="0xf93d9b30...4gAe0"
        onChange={(e) => setOwner(e.target.value)}
        onBlur={() => checkAddress(owner, ownerRef)}
        textColor="white"
        mb={6}
      />
      <Heading color="white" fontSize="24px" fontWeight={700}>
        Project Updater
      </Heading>
      <Text color="#A7A7A7" fontSize="15px" mb={2}>
        Enter an address for the project updater
      </Text>
      <Input
        ref={updaterRef}
        borderColor="#0E0E0E"
        placeholder="0xf93d9b30...4gAe0"
        onChange={(e) => setUpdater(e.target.value)}
        onBlur={() => checkAddress(updater, updaterRef)}
        textColor="white"
        mb={12}
      />
      <Button
        onClick={handleClick}
       bg={"#A654F4"}
        minH="60px"
        color="white"
        fontSize="20px"
        fontWeight={700}
        borderRadius={45}
        mb={5}
      
      >
        Create project
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor="#383838" borderColor="white" borderWidth={1}>
          <ModalHeader color="white">Transaction {modalText}</ModalHeader>
          <ModalCloseButton color="whiteAlpha.900" />

          <ModalFooter>
            <Button
              bgGradient="linear(to-r, #B5E5FF, #F9ABE7)"
              _active={{
                bgGradient: "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
              }}
              _hover={{
                bgGradient: "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
              }}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default CreateProject;
