import React, { useState } from "react";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import {
  Icon,
  Input,
  Button,
  Flex,
  Text,
  Switch,
  Stack,
  Radio,
  RadioGroup,
  Image,
  Collapse,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";
import { GoCheck, GoX, GoChevronUp } from "react-icons/go";
import { RiCloseCircleFill } from "react-icons/ri";

const PADDING = "24px";
const BOARDER = "2px solid #383838";
const BG = "#212121";

const UserRow = ({ address, removeHandler, type, locked, isLargerThan800 }) => {
  return (
    <Flex
      alignItems="center"
      justifyContent="space-between"
      borderBottom="2px solid #414141"
      pt="6px"
      pb="6px"
    >
      <Text
        w={isLargerThan800 ? "80%" : "140px"}
        // flex={isLargerThan800 ? "7" : "1"}
        color={locked ? "#a7a7a7" : "white"}
        mr="20px"
        isTruncated
        // noOfLines={1}
      >
        {address}
      </Text>
      <Flex alignItems="center">
        <Text color={locked ? "#a7a7a7" : "white"} w="70px">
          {type}
        </Text>
        <Flex w="40px" justifyContent="center">
          {!locked && (
            <Icon
              onClick={() => removeHandler(address, type)}
              as={RiCloseCircleFill}
              color="#a7a7a7"
              cursor="pointer"
              _hover={{ color: "#E24F51" }}
              fontSize="14px"
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default function DisplayAdmins({
  existingUpdaters,
  existingOwners,
  removeHandler,
}) {
  const [collapsed, setCollapsed] = useState(false);
  const [locked, setLocked] = useState(true);
  const [isLargerThan800] = useMediaQuery("(min-width: 1000px)");

  return (
    <Flex
      w="100%"
      bg={BG}
      border={BOARDER}
      flexDirection="column"
      borderRadius="15px"
      mb="20px"
      {...(collapsed && {
        onClick: () => setCollapsed(false),
      })}
      _hover={{
        transform: collapsed ? "scale(1.05)" : "scale(1.00)",
      }}
      cursor={collapsed ? "pointer" : "auto"}
      transition="all 0.2s"
    >
      <Flex flexDirection="column" transition="all .2s ease" overflow="hidden">
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding={PADDING}
        >
          <Heading color="white" fontSize="18px" mb="5px">
            Existing
          </Heading>
          <Flex flexDirection="row" alignItems="center">
            {!collapsed && <Locks state={locked} setState={setLocked} />}
            <Icon
              as={GoChevronUp}
              color="#ddd"
              fontWeight={600}
              fontSize="23px"
              cursor="pointer"
              onClick={
                collapsed
                  ? () => {}
                  : () => {
                      setCollapsed(true);
                    }
              }
              style={{
                transform: `rotate(${!collapsed ? "180" : "0"}deg)`,
              }}
            />
          </Flex>
        </Flex>
        <Collapse in={!collapsed} animateOpacity>
          <Flex padding={PADDING} pt={0}>
            <Flex
              flexDirection="column"
              maxH="300px"
              w="100%"
              overflowY="auto"
              position="relative"
              pr={PADDING}
              pl={PADDING}
            >
              <Flex alignItems="center" justifyContent="space-between" w="100%">
                <Text fontSize="10px" color="#a1a1a1" mr="20px">
                  Address
                </Text>
                <Flex alignItems="center">
                  <Text fontSize="10px" color="#a1a1a1" w="70px">
                    Type
                  </Text>
                  <Flex w="40px" justifyContent="center">
                    {!locked && (
                      <Text fontSize="10px" color="#a1a1a1">
                        Remove
                      </Text>
                    )}
                  </Flex>
                </Flex>
              </Flex>
              {existingOwners.map((item, index) => (
                <UserRow
                  key={item + index + "Owner"}
                  address={item}
                  removeHandler={removeHandler}
                  type="Owner"
                  locked={locked}
                  isLargerThan800={isLargerThan800}
                />
              ))}
              {existingUpdaters.map((item, index) => (
                <UserRow
                  key={item + index + "Updater"}
                  address={item}
                  removeHandler={removeHandler}
                  type="Updater"
                  locked={locked}
                  isLargerThan800={isLargerThan800}
                />
              ))}
              {locked && (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                />
              )}
            </Flex>
          </Flex>
        </Collapse>
      </Flex>
    </Flex>
  );
}
