export const erc721TransferHandler = "TransferHandler";
export const erc721TransferHandlerShared =
  "Transfer_SharedContract_wTokenfilter_Handler";
export const erc1155TransferSingleHandler = "TransferSingleHandler";
export const erc1155TransferBatchHandler = "TransferBatchHandler";

export const erc721TransferEvent = "Transfer";
export const erc1155TransferSingleEvent = "TransferSingle";
export const erc1155TransferBatchEvent = "TransferBatch";

export const YAML_TEMPLATE = {
  version: "1.0.0",
  description: "description",
  network: null,
  startBlock: 0,
  blockEvents: [
    {
      event: "confirmed",
      handler: "blocksConfirmed",
    },
  ],
  options: {
    pollInterval: 500,
    confirmations: 12,
    chunkSize: 5000,
    concurrency: 10,
    backoff: 1500,
  },
  contracts: [],
};

export const CONTRACT_TEMPLATE = {
  name: "",
  file: "./index.js",
  address: "",
  abiFile: "./abis/ERC721.json",
  eventHandlers: [
    {
      event: "Transfer",
      handler: "TransferHandler",
      arguments: [[], []],
    },
  ],
};

export const getContractType = (contract) => {
  const abiFile = contract?.abiFile;
  let text = "Invalid ABI file format";
  if (abiFile) {
    const fileName = abiFile.split("/").pop();
    const parts = fileName.split(".");
    if (parts?.length >= 2) {
      text = parts[0];
    }
  }

  return text;
};

export const checkIfContractShared = (contract) => {
  const eventHandlers = contract?.eventHandlers;
  let bool = false;
  if (eventHandlers?.length > 0) {
    const result = (
      eventHandlers?.[0]?.handler === erc721TransferHandlerShared
    ).toString();
    bool = result.charAt(0).toUpperCase() + result.slice(1);
  }
  return bool;
};

export const displayWatchList = (contract) => {
  const eventHandlers = contract?.eventHandlers;

  let list = [];
  if (eventHandlers?.length > 0) {
    const argumentsArray = eventHandlers?.[0]?.arguments;
    if (argumentsArray.length > 0) {
      list = argumentsArray[0].join(",");
    }
  }

  return list;
};

export const absoluteCompareYamls = (a, b) => {
  for (let i = 0; i < a.length; i++) {
    const objA = a[i];
    const objB = b[i];

    if (
      objA.network === objB.network &&
      JSON.stringify(objA) === JSON.stringify(objB)
    ) {
      throw new Error(`Object at index ${i} is the same in both arrays.`);
    }

    if (
      objA.network === objB.network &&
      objA.contracts.length === objB.contracts.length
    ) {
      const isEqual = objA.contracts.every((contractA, index) => {
        const contractB = objB.contracts[index];
        return (
          contractA.name === contractB.name &&
          contractA.address === contractB.address
        );
      });

      if (isEqual) {
        throw new Error(
          `Object at index ${i} has the same contracts in both arrays.`
        );
      }
    }
  }
};

export const filterUntouchedYamlsHandler = (a, b) => {
  const networksInA = new Set(a.map((item) => item.network));
  const filteredItems = b.filter(
    (item) => networksInA.has(item.network) || item.contracts.length > 0
  );
  return filteredItems;
};

export const checkIfYamlFieldsFilled = (a) => {
  a.forEach((item, index) => {
    const startBlock = item.startBlock;
    if (startBlock === null || isNaN(startBlock)) {
      throw new Error(`${item.network}: Please enter a valid Start Block`);
    }

    item.contracts.forEach((contract, contractIndex) => {
      if (!contract.name) {
        throw new Error(
          `${item.network}: Please enter a valid name for contracts`
        );
      }

      if (!contract.address) {
        throw new Error(
          `${item.network}: Please enter a valid address for contracts`
        );
      }
    });
  });
};

export const deepCompareByNetwork = (a, b) => {
  const differentItems = [];

  for (const objB of b) {
    let found = false;

    for (const objA of a) {
      if (objA.network === objB.network && deepCompare(objA, objB)) {
        found = true;
        break;
      }
    }

    if (!found) {
      differentItems.push(objB);
    }
  }

  return differentItems;
};

function deepCompare(objA, objB) {
  // Compare properties excluding the "contracts" array
  const { contracts: contractsA, ...restA } = objA;
  const { contracts: contractsB, ...restB } = objB;
  const arePropertiesEqual = JSON.stringify(restA) === JSON.stringify(restB);

  if (!arePropertiesEqual) {
    return false;
  }

  // Compare the "contracts" array
  return deepArrayCompare(contractsA, contractsB);
}

function deepArrayCompare(arrA, arrB) {
  if (arrA.length !== arrB.length) {
    return false;
  }

  const sortedArrA = arrA.sort();
  const sortedArrB = arrB.sort();

  for (let i = 0; i < sortedArrA.length; i++) {
    if (JSON.stringify(sortedArrA[i]) !== JSON.stringify(sortedArrB[i])) {
      return false;
    }
  }

  return true;
}

export const checkIfHex = (text) => {
  const hexRegex = /^0[xX][0-9a-fA-F]+$/;

  return hexRegex.test(text);
};
