import React, { useEffect, useState, useContext } from "react";
import { Web3Context } from "../../contexts/Web3Context";
import {
  Button,
  Flex,
  Heading,
  Text,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  Collapse,
  PopoverHeader,
  PopoverFooter,
  useMediaQuery,
} from "@chakra-ui/react";
import { BsFillShareFill } from "react-icons/bs";
import { FiDownload, FiTrash2 } from "react-icons/fi";
import { GoChevronUp } from "react-icons/go";

const PADDING = "24px";
const BOARDER = "2px solid #383838";
const BG = "#212121";

const ClaimRow = ({ claim, toast, deleteClaimHandler }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(true);
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const deleteClaim = async () => {
    deleteClaimHandler(claim?.xpClaimId);
    setDeleteOpen(false);
  };

  const downloadImage = async () => {
    const image = await fetch(claim.qrCodeURL);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "qr_code";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formatDate = (timestamp, inSeconds) => {
    if (typeof timestamp === "string") {
      timestamp = parseInt(timestamp);
    }

    if (isNaN(timestamp)) {
      return "Error";
    }

    if (inSeconds) {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      const maxTimestamp = currentTimestamp + 70 * 365 * 24 * 60 * 60;

      if (timestamp > maxTimestamp) {
        return "Never";
      }

      return new Date(timestamp * 1000).toLocaleDateString();
    }

    return new Date(timestamp).toLocaleDateString();
  };

  const copyUrl = () => {
    navigator.clipboard.writeText(claim.url);
    toast({
      title: "Url Copied",
      // description: "We've created your account for you.",
      position: "top",
      status: "success",
      variant: "subtle",
      duration: 2000,
      isClosable: true,
    });
  };

  return (
    <Flex
      w="100%"
      bg={BG}
      border={BOARDER}
      flexDirection="column"
      borderRadius="15px"
      mb="20px"
      {...(collapsed && {
        onClick: () => setCollapsed(false),
      })}
      _hover={{
        transform: collapsed ? "scale(1.05)" : "scale(1.00)",
      }}
      cursor={collapsed ? "pointer" : "auto"}
      transition="all 0.2s"
    >
      <Flex flexDirection="column" transition="all .2s ease" overflow="hidden">
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          padding={PADDING}
        >
          <Flex w="100%" justifyContent="space-between">
            <Flex flexDirection="column" maxW="50%" overflow="hidden">
              <Text color="#aaa" fontSize="14px" fontWeight={500}>
                Claim Title
              </Text>
              <Heading
                color="white"
                fontSize="14px"
                fontWeight={500}
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                maxW={isLargerThan800 ? "300px" : "160px"}
              >
                {claim?.name}
              </Heading>
            </Flex>
            <Flex
              minW="150px"
              justifyContent="space-between"
              mr={isLargerThan800 ? "20px" : "10px"}
            >
              <Flex flexDirection="column" flex={1} mr="20px">
                <Text color="#aaa" fontSize="14px" fontWeight={500}>
                  Created
                </Text>
                <Heading color="white" fontSize="14px" fontWeight={500}>
                  {formatDate(claim?.creationDate)}
                </Heading>
              </Flex>
              <Flex flexDirection="column" flex={1}>
                <Text color="#aaa" fontSize="14px" fontWeight={500}>
                  Expiration
                </Text>
                <Heading color="white" fontSize="14px" fontWeight={500}>
                  {formatDate(claim?.xpExpire, true)}
                </Heading>
              </Flex>
            </Flex>
          </Flex>
          <Icon
            as={GoChevronUp}
            color="#ddd"
            fontWeight={600}
            fontSize="23px"
            cursor="pointer"
            onClick={
              collapsed
                ? () => {}
                : () => {
                    setCollapsed(true);
                  }
            }
            style={{
              transform: `rotate(${!collapsed ? "180" : "0"}deg)`,
            }}
          />
        </Flex>
        <Collapse in={!collapsed} animateOpacity>
          <Flex
            flexDir="column"
            position="relative"
            mb="10px"
            padding={PADDING}
            pt={0}
          >
            <Flex flexDir="column" mb={PADDING}>
              <Text color="#aaa" fontSize="14px" fontWeight={500}>
                Description
              </Text>
              <Heading color="white" fontSize="14px" fontWeight={500}>
                {claim.description}
              </Heading>
            </Flex>
            <Flex flexDir={isLargerThan800 ? "row" : "column"}>
              <Flex flex="1" mb={isLargerThan800 ? 0 : "10px"}>
                <Flex flexDir="column" w="160px" mr="10px">
                  <Text color="#aaa" fontSize="14px" fontWeight={500}>
                    Action
                  </Text>
                  <Heading color="white" fontSize="14px" fontWeight={500}>
                    {claim?.xpAction}
                  </Heading>
                </Flex>
                <Flex flexDir="column" w="100px" mr="10px">
                  <Text color="#aaa" fontSize="14px" fontWeight={500}>
                    Score Type
                  </Text>
                  <Heading color="white" fontSize="14px" fontWeight={500}>
                    {claim?.xpScoreType}
                  </Heading>
                </Flex>
                <Flex flexDir="column">
                  <Text color="#aaa" fontSize="14px" fontWeight={500}>
                    Points
                  </Text>
                  <Heading color="white" fontSize="14px" fontWeight={500}>
                    {claim?.points}
                  </Heading>
                </Flex>
              </Flex>
              <Flex>
                <Flex flexDir="column" alignItems="center" mr="20px">
                  <Text color="#aaa" fontSize="14px" fontWeight={500}>
                    Share Link
                  </Text>
                  <Heading color="white" fontSize="14px" fontWeight={500}>
                    <Icon
                      onClick={copyUrl}
                      as={BsFillShareFill}
                      color="#C6C7FF"
                      fontSize="12px"
                      cursor="pointer"
                    />
                  </Heading>
                </Flex>
                <Flex flexDir="column" alignItems="center" mr="20px">
                  <Text color="#aaa" fontSize="14px" fontWeight={500}>
                    Download QR
                  </Text>
                  <Heading color="white" fontSize="14px" fontWeight={500}>
                    <Icon
                      as={FiDownload}
                      color="#C6C7FF"
                      fontSize="14px"
                      cursor="pointer"
                      onClick={downloadImage}
                    />
                  </Heading>
                </Flex>
                <Flex flexDir="column" alignItems="center">
                  <Text color="#aaa" fontSize="14px" fontWeight={500}>
                    Delete
                  </Text>
                  <Popover placement="top-start" isOpen={deleteOpen}>
                    <PopoverTrigger>
                      <Icon
                        as={FiTrash2}
                        color="#C6C7FF"
                        fontSize="14"
                        cursor="pointer"
                        onClick={() => setDeleteOpen(true)}
                      />
                    </PopoverTrigger>
                    <PopoverContent
                      color="white"
                      bg="#121212"
                      borderColor="#000"
                      _focus={{ boxShadow: "none" }}
                    >
                      <PopoverHeader pt={4} fontWeight="bold" border="0">
                        Do You want to delete this claim?
                      </PopoverHeader>
                      <PopoverCloseButton
                        _focus={{ boxShadow: "none" }}
                        onClick={() => setDeleteOpen(false)}
                      />
                      <PopoverFooter
                        border="0"
                        d="flex"
                        alignItems="center"
                        justifyContent="space-evenly"
                        pb={4}
                      >
                        <Button
                          h="30px"
                          w="90px"
                          borderRadius="15px"
                          fontSize="14px"
                          fontWeight={700}
                          variant="blank"
                          onClick={() => setDeleteOpen(false)}
                        >
                          No
                        </Button>
                        <Button
                          h="30px"
                          w="90px"
                          borderRadius="15px"
                          fontSize="14px"
                          fontWeight={700}
                          variant="error"
                          onClick={deleteClaim}
                        >
                          Yes
                        </Button>
                      </PopoverFooter>
                    </PopoverContent>
                  </Popover>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        </Collapse>
      </Flex>
    </Flex>
  );
};

export default ClaimRow;
