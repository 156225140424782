import React, { useEffect } from "react";
import {
  IconButton,
  Box,
  CloseButton,
  Flex,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  FlexProps,
  useMediaQuery,
  Image,
  Heading,
  Button,
  Divider
} from "@chakra-ui/react";
import {
  FiMenu,
} from "react-icons/fi";
import { useNavigate, Outlet, useLocation, useParams } from "react-router-dom";
import logoxp from '../../assets/imgs/xp_proto.png'
import refresh from "../../assets/imgs/refresh.png";
import qr from "../../assets/imgs/qr.png";
import reward from "../../assets/imgs/reward.png";
import plug from "../../assets/imgs/plug.png";
import setting from "../../assets/imgs/setting.png";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";
import { useQuery, gql } from "@apollo/client";

const BAR_COLOR = '#262626'
const COLOR = "white";
const LINK_HOVER = {
  backgroundColor: "rgba(30, 30, 30, 0.8)",
}
interface LinkItemProps {
  name: string;
  isTitle?: boolean;
  icon?: string;
  to?: string
}
const LinkItems: Array<LinkItemProps> = [
  // { name: "Summary", to: 'scores' },
  { name: "Points", icon: refresh, isTitle: true },
  { name: "Update", to: 'update-score' },
  { name: "Actions", to: 'add-actions' },
  { name: "Scores", to: 'score-types' },
  { name: "Levels", to: 'levels' },
  { name: "Claims", icon: qr, isTitle: true },
  { name: "XP Claims", to: 'create-qr' },
  // { name: "claim activity", icon: FiHome, to: 'claim-activity' },
  { name: "Rewards", icon: reward, isTitle: true },
  { name: "NFT Redemptions", to: 'redemptions' },
  { name: "ERC-20 Redemptions", to: 'erc20' },
  // { name: "ERC-20 Test", to: 'erc20-test' },
  { name: "Integration", icon: plug, isTitle: true },
  { name: "Discord", to: 'discord' },
 // { name: "Twitter", to: 'twitter' },
  { name: "Snapshot", to: 'snapshot' },
  { name: "NFT Watcher", to: 'watcher' },
  { name: "Setting", icon: setting, isTitle: true },
  { name: "Edit Admin", to: 'admin' },
  // { name: "Projects", to: '/' },
];

export default function SimpleSidebar({ children }: { children: React.ReactNode[] }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { projectId } = useParams();
  const [isLargerThan800] = useMediaQuery("(min-width: 768px)");
  const location = useLocation();
  const navigate = useNavigate()

  const { data: projectData } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    if (isLargerThan800) onClose()
  }, [isLargerThan800])

  const navTo = (link) => {
    if (link === '/' || link === '/create-project') {
      navigate(link);
    } else if (projectId) {
      navigate(
        `/project/${projectId}/${link}`
      );
    } else {
      navigate(
        `/${link}}`
      );
    }
    onClose()
  }

  const toDiscover = () => {
    if (window) {
      window.open("https://xp.huddln.io/community/" + projectId, "_blank")?.focus();
    }
  }

  return (
    <Flex
      flexDir={isLargerThan800 ? "row" : "column"}
      flex='1'
      position="relative">
      <Flex>
        <SidebarContent
          onClose={onClose}
          pathname={location.pathname}
          navTo={navTo}
          title={projectData?.projects[0]?.name}
          toDiscover={toDiscover}
          display={{ base: "none", md: "block" }}
        />
        <Drawer
          autoFocus={false}
          isOpen={isOpen}
          placement="left"
          onClose={onClose}
          returnFocusOnClose={false}
          onOverlayClick={onClose}
          size="300px"
        >
          <DrawerContent bg='rgba(0,0,0,.75)' onClick={onClose}>
            <SidebarContent
              onClose={onClose}
              pathname={location.pathname}
              navTo={navTo}
              title={projectData?.projects[0]?.name}
              toDiscover={toDiscover} />
          </DrawerContent>
        </Drawer>
        {/* mobilenav */}
        <MobileNav onOpen={onOpen} />
        {/* <Flex ml={{ base: 0, md: 0 }}> */}
      </Flex>
      {children ? children : <Outlet />}
    </Flex >
  );
}

interface SidebarProps extends FlexProps {
  pathname: string;
  onClose: () => void;
  navTo: (link: any) => void
  title: any;
  toDiscover: () => void
  display?: any
}

const SidebarContent = ({ pathname, onClose, navTo, title, toDiscover, display = {}, ...rest }: SidebarProps) => {
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <Flex
      w='300px'
      overflowX='auto'
      // minHeight='100%'
      bg={BAR_COLOR}
      display={display}
      onClick={stopPropagation}
    >
      <Flex
        flex='1'
        flexDir='column'
        alignItems="center"
        justifyContent="space-between"
        // minHeight="100vh"
        minHeight="100vh"
        w='100%'
        pt='10px'
      >
        <Flex flexDir='column' w='260px'>
          <Flex alignItems="center" justifyContent="center" mb="20px" pos='relative'>
            < Image src={logoxp} boxSize="130px" objectFit="contain" />
            <CloseButton
              display={{ base: 'flex', md: 'none' }}
              pos='absolute'
              top='20px'
              right='20px'
              onClick={onClose} color='white' />
          </Flex >
          <Heading color="white" fontSize="18px" m='20px'>
            {title}
          </Heading>
          <Flex
            onClick={toDiscover}
            align="center"
            p="4px"
            mx="1px"
            my="2px"
            pl='30px'
            borderRadius="lg"
            role="group"
            cursor="pointer"
            _hover={LINK_HOVER}
          >
            <Flex height='20px' width='30px' />
            <Text color='#A7A7A7' fontSize='16px'>
              Community Page
            </Text>
          </Flex >
          <Flex
            onClick={() => navTo('scores')}
            align="center"
            p="4px"
            mx="1px"
            my="2px"
            pl='30px'
            borderRadius="lg"
            role="group"
            cursor="pointer"
            _hover={LINK_HOVER}
          >
            <Flex height='20px' width='30px' />
            <Text color={pathname.includes('scores') ? 'white' : '#A7A7A7'} fontSize='16px'>
              Summary
            </Text>
          </Flex >
          <Divider
            orientation="horizontal"
            borderWidth="2px"
            borderRadius="1px"
            borderColor="#474747"
            mt='10px'
            mb='10PX'
          />
          {
            LinkItems.map((link) => (
              <NavItem key={link.name} link={link} pathname={pathname} navTo={navTo} />
            ))
          }
          <Divider
            orientation="horizontal"
            borderWidth="2px"
            borderRadius="1px"
            borderColor="#474747"
            mt='20px'
          />
        </Flex>
        <Flex
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          mt='20px'
          mb='40px' >
          <Button
            onClick={() => navTo('/create-project')}
            borderRadius={25}
            w="200px"
            variant="blank"
            mb='20px'
          >
            Create Project
          </Button>
          <Button
            onClick={() => navTo('/')}
            borderRadius={25}
            w="200px"
            variant="purple"
          >
            All Projects
          </Button>
        </Flex>
      </Flex>
    </Flex >
  );
};

interface NavItemProps {
  link: LinkItemProps;
  pathname: string;
  navTo: (link: any) => void
}

const NavItem = ({ link, navTo, pathname, ...rest }: NavItemProps) => {
  let isActive = link?.to && pathname.includes(link.to) && link.to !== '/'

  if (link?.isTitle) {
    return (
      <Flex
        align="center"
        p="4px"
        mx="1px"
        my="2px"
        pl='30px'
        borderRadius="lg"
        role="group"
        {...rest}
      >
        <Image src={link.icon} boxSize="20px" objectFit="contain" mr='10px' />
        <Text color={COLOR} fontWeight={600} fontSize='16px'>
          {link.name}
        </Text>
      </Flex >
    );

  } else {
    return (
      <Flex
        onClick={() => navTo(link?.to)}
        align="center"
        p="4px"
        mx="1px"
        my="2px"
        pl='30px'
        borderRadius="lg"
        role="group"
        cursor="pointer"
        _hover={LINK_HOVER}
        {...rest}
      >
        <Flex height='20px' width='30px' />
        <Text color={isActive ? 'white' : '#A7A7A7'} fontSize='16px'>
          {link.name}
        </Text>
      </Flex >
    );
  }
};

interface MobileProps extends FlexProps {
  onOpen: () => void;
}
const MobileNav = ({ onOpen }: MobileProps) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      w='100%'
      bgColor={BAR_COLOR}
      height="20"
      borderBottomWidth="1px"
      borderBottomColor='black'
      alignItems='center'
      justifyContent='space-between'
      display={{ base: "flex", md: "none" }}
    >
      <Image src={logoxp} boxSize="60px" objectFit="contain" />
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu color='white' />}
      />
    </Flex>
  );
};
