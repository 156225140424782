import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import CryptoJS from "crypto-js";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  TabPanel,
  Tabs,
  Tab,
  TabPanels,
  TabList,
  useToast,
  TabIndicator,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  Divider,
} from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import NFTWatcherTab from "./NFTWatcherTab";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import NFTStatus from "./NFTStatus";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";
import { hexToDecimal } from "../../func/myFunc.ts";
import {
  YAML_TEMPLATE,
  CONTRACT_TEMPLATE,
  //handlers
  erc721TransferHandler,
  erc721TransferHandlerShared,
  erc1155TransferSingleHandler,
  erc1155TransferBatchHandler,
  //events
  erc721TransferEvent,
  erc1155TransferSingleEvent,
  erc1155TransferBatchEvent,
  //funcs
  getContractType,
  checkIfContractShared,
  displayWatchList,
  filterUntouchedYamlsHandler,
  checkIfYamlFieldsFilled,
  deepCompareByNetwork,
} from "./NFTWatcher.config";
import { SUPPORTED_WATCHER_NETWORKS } from "../../config/config.ts";
import { CgClose } from "react-icons/cg";

const NFTWatcher = () => {
  const { projectId } = useParams();
  const { web3, accounts, contract } = useContext(Web3Context);
  const toast = useToast();

  const [isLoading, setIsLoading] = useState(true);
  const [btnStatus, setBtnStatus] = useState("");
  const [pointsLocked, setPointsLocked] = useState(true);

  const [supportedNetworks, setSupportedNetworks] = useState(
    SUPPORTED_WATCHER_NETWORKS
  );
  const [oldXpConfig, setXpConfig] = useState({
    xpId: projectId,
    mintAction: null,
    collectAction: null,
    transferAction: null,
    mintScoreType: null,
    collectScoreType: null,
    transferScoreType: null,
  });
  const [yamlsArrayOriginal, setYamlsArrayOriginal] = useState([]);
  const [yamlsArray, setYamlsArray] = useState([]);
  const [ignoreWalletList, setIgnoreWalletList] = useState([]);
  const [selectedMint, setSelectedMint] = useState();
  const [selectedTransfer, setSelectedTransfer] = useState();
  const [selectedCollect, setSelectedCollect] = useState();
  const [selectedScoreType, setSelectedScoreType] = useState();

  const [latestBlockLoading, setLatestBlockLoading] = useState(true);
  const [blockLocked, setBlockLocked] = useState(false);
  const [modalContract, setModalContract] = useState({});
  const [modalOpen, setModalOpen] = useState(false);

  const { data: projectData, loading } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    const getWatcherConfig = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://us-central1-huddl-in.cloudfunctions.net/getNFTWatcherConfig",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ xpId: projectId }),
          }
        );
        const data = await response.json();
        const xpConfig = data?.configData?.xpConfig;
        const yamlsData = data?.configData?.yamls;

        const foundIgnoreList = yamlsData.find(
          (item) =>
            item.contracts[0]?.eventHandlers[0]?.arguments[1]?.length > 0
        );

        if (xpConfig) {
          setSelectedMint({ name: xpConfig?.mintAction });
          setSelectedCollect({ name: xpConfig?.collectAction });
          setSelectedTransfer({ name: xpConfig?.transferAction });
          setSelectedScoreType(xpConfig?.transferScoreType);

          const obj = {
            xpId: projectId,
            mintAction: xpConfig?.mintAction,
            collectAction: xpConfig?.collectAction,
            transferAction: xpConfig?.transferAction,
            mintScoreType: xpConfig?.transferScoreType,
            collectScoreType: xpConfig?.transferScoreType,
            transferScoreType: xpConfig?.transferScoreType,
          };

          setXpConfig(obj);
        }
        if (yamlsData.length > 0) {
          const deepCopyArray = JSON.parse(JSON.stringify(yamlsData));
          const networkYamlNames = yamlsData.map((item) => item.network);

          supportedNetworks.forEach((item) => {
            if (!networkYamlNames.includes(item.name)) {
              deepCopyArray.push({
                ...YAML_TEMPLATE,
                network: item.name,
              });
            }
          });

          setYamlsArrayOriginal(yamlsData);
          setYamlsArray(deepCopyArray);
        } else {
          const data = supportedNetworks.map((element) => ({
            ...YAML_TEMPLATE,
            network: element.name,
          }));

          setYamlsArray(data);
        }
        if (foundIgnoreList) {
          const filledArgument =
            foundIgnoreList.contracts[0]?.eventHandlers[0]?.arguments[1];
          setIgnoreWalletList(filledArgument);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    const getLatestBlock = async () => {
      setLatestBlockLoading(true);
      try {
        const requestOptions = {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            method: "eth_blockNumber",
            params: [],
            id: 1,
            jsonrpc: "2.0",
          }),
          redirect: "follow",
        };

        let array = [];

        for (let index = 0; index < supportedNetworks.length; index++) {
          const element = supportedNetworks[index];
          let latestBlockNumber = 0;
          if (element.rpcUri) {
            const response = await fetch(element.rpcUri, requestOptions);
            const result = await response.text();
            const data = JSON.parse(result);
            latestBlockNumber = hexToDecimal(data.result);
          }

          element.latestBlockNumber = latestBlockNumber;
          array.push(element);
        }
        setSupportedNetworks(array);
      } catch (error) {
        console.log("error", error);
        setLatestBlockLoading(false);
      } finally {
        setLatestBlockLoading(false);
      }
    };

    getWatcherConfig();
    getLatestBlock();

    const interval = setInterval(() => {
      getLatestBlock();
    }, 60 * 1000);

    return () => clearInterval(interval);
  }, [projectId]);

  const updateObjectByNetwork = (network, key, value) => {
    const updatedArray = yamlsArray.map((obj) => {
      if (obj.network === network) {
        // Update the specific key-value pair in the matching object
        return { ...obj, [key]: value };
      }
      return obj;
    });

    setYamlsArray(updatedArray);
  };

  const updateContractByIndex = (network, index, key, value) => {
    setYamlsArray((prevYamlsArray) => {
      const updatedArray = prevYamlsArray.map((obj) => {
        if (obj.network === network && obj.contracts && obj.contracts[index]) {
          const updatedContracts = [...obj.contracts];
          const updatedContract = { ...updatedContracts[index] };

          updatedContract[key] = value;

          updatedContracts[index] = updatedContract;
          return { ...obj, contracts: updatedContracts };
        }
        return obj;
      });

      return updatedArray;
    });
  };

  const updateContractEventHandlerByIndex = (
    network,
    index,
    watchList,
    type,
    shared
  ) => {
    let abiFile = "./abis/ERC721.json";
    let eventHandlers = [];

    if (type === "ERC1155") {
      abiFile = "./abis/ERC1155.json";
      eventHandlers = [
        {
          event: erc1155TransferSingleEvent,
          handler: erc1155TransferSingleHandler,
          arguments: [[], []],
        },
        {
          event: erc1155TransferBatchEvent,
          handler: erc1155TransferBatchHandler,
          arguments: [[], []],
        },
      ];
    } else if (shared === true) {
      eventHandlers = [
        {
          event: erc721TransferEvent,
          handler: erc721TransferHandlerShared,
          arguments: [watchList, []],
        },
      ];
    } else {
      eventHandlers = [
        {
          event: erc721TransferEvent,
          handler: erc721TransferHandler,
          arguments: [[], []],
        },
      ];
    }

    updateContractByIndex(network, index, "abiFile", abiFile);
    updateContractByIndex(network, index, "eventHandlers", eventHandlers);
  };

  const updateContractByNetwork = (network, action, index) => {
    setYamlsArray((prevYamlsArray) => {
      const updatedArray = prevYamlsArray.map((item) => {
        if (item.network === network) {
          const updatedContracts = [...item.contracts];

          if (
            action === "remove" &&
            index >= 0 &&
            index < updatedContracts.length
          ) {
            updatedContracts.splice(index, 1);
            setModalOpen(false);
          }

          if (action === "add") {
            updatedContracts.push(CONTRACT_TEMPLATE);
          }

          return { ...item, contracts: updatedContracts };
        }

        return item;
      });

      return updatedArray;
    });
  };

  const openRemoveContractHandler = (network, index) => {
    const foundNetwork = yamlsArray.find((obj) => obj.network === network);
    const foundContract = foundNetwork?.contracts[index];

    setModalContract({ ...foundContract, network, index });
    setModalOpen(true);
  };

  const addIgnoreListToContracts = (arr) => {
    const updatedArray = [];
    const hexRegex = /0[xX][0-9a-fA-F]+/g;

    for (let i = 0; i < arr.length; i++) {
      const yamlFile = arr[i];
      const network = yamlFile.network;
      const updatedContracts = [];

      for (let j = 0; j < yamlFile.contracts.length; j++) {
        const contract = yamlFile.contracts[j];
        const updatedEventHandlers = [];
        console.log(contract.name);
        for (let k = 0; k < contract.eventHandlers.length; k++) {
          const handler = contract.eventHandlers[k];
          const isShared = handler?.handler === erc721TransferHandlerShared;

          const updatedArguments = handler.arguments.map((argArray, m) => {
            if (m === 0 && isShared) {
              argArray.forEach((e) => {
                const element = e.toString();
                if (
                  parseInt(element) ||
                  element === "" ||
                  !isNaN(parseFloat(element)) ||
                  element.match(hexRegex)
                ) {
                  return;
                } else {
                  throw new Error(
                    `${network}: Watch list must be a hex or number`
                  );
                }
              });
            } else if (m === 1) {
              ignoreWalletList.forEach((e) => {
                const element = e.toString();
                if (
                  parseInt(element) ||
                  element === "" ||
                  !isNaN(parseFloat(element)) ||
                  element.match(hexRegex)
                ) {
                  return;
                } else {
                  throw new Error("Ignore list must be a hex or number");
                }
              });

              return ignoreWalletList;
            }
            return [...argArray];
          });

          const updatedHandler = { ...handler, arguments: updatedArguments };
          updatedEventHandlers.push(updatedHandler);
        }

        const updatedContract = {
          ...contract,
          eventHandlers: updatedEventHandlers,
        };
        updatedContracts.push(updatedContract);
      }

      const updatedItem = { ...yamlFile, contracts: updatedContracts };
      updatedArray.push(updatedItem);
    }

    return updatedArray;
  };

  const handleSave = async () => {
    if (btnStatus === "loading") return;
    setBtnStatus("loading");
    let title = "";
    let status = "error";
    try {
      if (
        !projectId ||
        !selectedMint?.name ||
        !selectedCollect?.name ||
        !selectedTransfer?.name ||
        !selectedScoreType
      ) {
        throw new Error("Please fill Point Configuration");
      }
      //check if config changed
      const xpConfig = {
        xpId: projectId,
        mintAction: selectedMint?.name,
        collectAction: selectedCollect?.name,
        transferAction: selectedTransfer?.name,
        mintScoreType: selectedScoreType,
        collectScoreType: selectedScoreType,
        transferScoreType: selectedScoreType,
      };

      const isConfigTheSame =
        JSON.stringify(oldXpConfig) === JSON.stringify(xpConfig);
      //remove yamls that have not been touched

      let filteredYamls = filterUntouchedYamlsHandler(
        yamlsArrayOriginal,
        yamlsArray
      );

      let onlyActiveYamls = addIgnoreListToContracts(filteredYamls);

      //check touched yamls filled
      checkIfYamlFieldsFilled(onlyActiveYamls);
      if (!(onlyActiveYamls.length > 0)) {
        throw new Error("Must contain at least 1 valid contract to submit");
      }
      //if config hasn't changed,compare yamls to init yamls
      if (isConfigTheSame) {
        onlyActiveYamls = deepCompareByNetwork(
          yamlsArrayOriginal,
          onlyActiveYamls
        );
        //compare these yamls to init yamls
        if (!(onlyActiveYamls.length > 0)) {
          throw new Error("Please make changes to watchers page");
        }
      }

      let requestData = {
        configData: {
          version: "1.0.0",
          yamls: onlyActiveYamls,
          xpConfig: {
            xpId: projectId,
            mintAction: selectedMint.name,
            collectAction: selectedCollect.name,
            transferAction: selectedTransfer.name,
            mintScoreType: selectedScoreType,
            collectScoreType: selectedScoreType,
            transferScoreType: selectedScoreType,
          },
        },
      };

      const toSign =
        "0x" + CryptoJS.SHA256(JSON.stringify(requestData)).toString();

      const signature = await web3.eth.personal.sign(
        toSign,
        window.ethereum.selectedAddress
      );

      let requestBody = {
        configData: requestData.configData,
        verification: {
          signature,
          message: toSign,
          address: accounts[0],
        },
      };

      const configResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/createNFTWatcherConfig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const response = await configResponse.json();

      title = "Configuration Updated";
      status = "success";
    } catch (error) {
      title = error.message;
      if (title.includes("User denied")) {
        title = "Signature Rejected by User";
      }
      status = "error";
    } finally {
      setBtnStatus("");
      toast({
        title,
        position: "top",
        status,
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getObjectByNetwork = (n) => {
    return supportedNetworks.find((obj) => obj.name === n);
  };

  if (loading || isLoading) {
    return <LoadComponent />;
  } else {
    return (
      <Flex flex="1">
        <PageWrapper width="800px">
          <Flex w="100%" flexDirection="column" mb="30px">
            <Heading color="white" fontSize="18px" mb="5px">
              NFT Watcher
            </Heading>
            <Text color="#A7A7A7" fontSize="14px">
              Customize how to reward XP based on your contract actions
            </Text>
          </Flex>
          {yamlsArrayOriginal.length > 0 && (
            <NFTStatus
              projectId={projectId}
              yamlsArray={yamlsArray}
              supportedNetworks={supportedNetworks}
              latestBlockLoading={latestBlockLoading}
            />
          )}
          <CustomCard>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
              mb="5px"
            >
              <Flex flexDirection="row" alignItems="center">
                <Heading
                  color={pointsLocked ? "#aaa" : "white"}
                  fontWeight={600}
                  fontSize="18px"
                >
                  Point Configuration
                </Heading>
              </Flex>
              <Locks state={pointsLocked} setState={setPointsLocked} />
            </Flex>
            <Text color="#A7A7A7" fontSize="14px" mb="15px">
              Configure what on-chain events trigger which actions, this is
              applied to all contracts below
            </Text>
            <Flex flexDir="column" w="100%" position="relative">
              <Flex
                flexDir="column"
                w="100%"
                position="relative"
                maxW="340px"
                mb="20px"
              >
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="10px"
                >
                  <Flex flex="1">
                    <Text color="#A7A7A7" fontWeight={600} fontSize="14px">
                      Transactions
                    </Text>
                    <CustomTooltip
                      txt="On-chain calls made to the contract that should receive XP."
                      w={390}
                      t={-28}
                    />
                    {/* <Icon
                        mt="2px"
                        as={BsFillInfoCircleFill}
                        color="#a7a7a7"
                        fontSize="10px"
                      /> */}
                  </Flex>
                  <Flex flex="1">
                    <Text color="#A7A7A7" fontWeight={600} fontSize="14px">
                      Actions
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="10px"
                >
                  <Flex flex="1">
                    <Text
                      color={pointsLocked ? "#aaa" : "white"}
                      fontWeight={600}
                      fontSize="14px"
                    >
                      Mint
                    </Text>
                  </Flex>
                  <Flex flex="1">
                    {projectData?.projects[0]?.actions?.length > 0 && (
                      <CustomDropdown
                        type="action"
                        locked={pointsLocked}
                        placeholder="mint"
                        array={projectData?.projects[0]?.actions}
                        state={selectedMint}
                        setState={setSelectedMint}
                      />
                    )}
                  </Flex>
                </Flex>
                <Flex
                  alignItems="center"
                  justifyContent="space-between"
                  mb="10px"
                >
                  <Flex flex="1">
                    <Text
                      color={pointsLocked ? "#aaa" : "white"}
                      fontWeight={600}
                      fontSize="14px"
                    >
                      Transfer
                    </Text>
                  </Flex>
                  <Flex flex="1">
                    {projectData?.projects[0]?.actions?.length > 0 && (
                      <CustomDropdown
                        type="action"
                        locked={pointsLocked}
                        placeholder="transfer"
                        array={projectData?.projects[0]?.actions}
                        state={selectedTransfer}
                        setState={setSelectedTransfer}
                      />
                    )}
                  </Flex>
                </Flex>
                <Flex alignItems="center" justifyContent="space-between">
                  <Flex flex="1">
                    <Text
                      color={pointsLocked ? "#aaa" : "white"}
                      fontWeight={600}
                      fontSize="14px"
                    >
                      Collect
                    </Text>
                  </Flex>
                  <Flex flex="1">
                    {projectData?.projects[0]?.actions?.length > 0 && (
                      <CustomDropdown
                        type="action"
                        locked={pointsLocked}
                        placeholder="collect"
                        array={projectData?.projects[0]?.actions}
                        state={selectedCollect}
                        setState={setSelectedCollect}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDir="column" mb="15px">
                <Text color="#A7A7A7" opacity="1" fontSize="12px" mb="5px">
                  Score Type
                </Text>
                {projectData?.projects[0]?.scoreTypes?.length > 0 && (
                  <CustomDropdown
                    locked={pointsLocked}
                    placeholder="Scores"
                    array={projectData?.projects[0]?.scoreTypes}
                    state={selectedScoreType}
                    setState={setSelectedScoreType}
                  />
                )}
              </Flex>
              <Flex flexDir="column" mb="15px">
                <Flex>
                  <Text
                    color={pointsLocked ? "#aaa" : "white"}
                    fontWeight={600}
                    fontSize="18px"
                  >
                    Ignore Wallet List
                  </Text>
                  <CustomTooltip
                    txt="Wallet addresses that shouldn't receive XP. (ie. the creator of the contract)"
                    t={-48}
                    w={360}
                  />
                </Flex>
                <Text color="#A7A7A7" fontSize="14px" mb="15px">
                  Provide 0x wallet addresses in a comma separated list
                </Text>
                <Input
                  borderRadius="10px"
                  textColor={pointsLocked ? "#aaa" : "white"}
                  maxLength="250"
                  width="100%"
                  max={100}
                  bg="#292929"
                  border="2px solid #383838"
                  p={2}
                  pt={0}
                  pb={0}
                  onChange={(e) => {
                    setIgnoreWalletList(
                      e.target.value
                        .replace(/\s/g, "")
                        .replace(/\./g, "")
                        .split(",")
                    );
                  }}
                  value={ignoreWalletList}
                />
              </Flex>
              {pointsLocked && (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                />
              )}
            </Flex>
            <Flex
              flexDirection="row"
              alignItems="center"
              w="100%"
              justifyContent="center"
            >
              <Text color="#A7A7A7" fontSize="14px">
                Changes only apply to future transactions.
              </Text>
            </Flex>
          </CustomCard>
          <CustomCard>
            <Flex
              flexDirection="row"
              alignItems="center"
              justifyContent="flex-end"
              mb="5px"
            >
              <Locks state={blockLocked} setState={setBlockLocked} />
            </Flex>
            <Tabs w="100%" position="relative" isLazy>
              <Flex overflowX="auto" position="relative" pb="10px">
                <TabList
                  position="relative"
                  variant="unstyled"
                  whiteSpace="nowrap"
                >
                  {yamlsArray?.length > 0 &&
                    yamlsArray.map((item, index) => {
                      const data = getObjectByNetwork(item?.network);
                      return (
                        <Tab
                          key={index}
                          alignItems="center"
                          fontWeight={600}
                          fontSize="14px"
                          _selected={{ color: "#A654F4" }}
                          ml="2px"
                          mr="2px"
                        >
                          <Image src={data?.logoUri} boxSize="20px" mr="5px" />
                          <Text>{data?.displayName}</Text>
                        </Tab>
                      );
                    })}
                </TabList>
                <TabIndicator
                  // mt="-1.5px"
                  bottom="10px"
                  height="2px"
                  bg="#A654F4"
                  borderRadius="1px"
                  zIndex={100}
                />
              </Flex>
              {/* <Text color="#A7A7A7" fontSize="14px" mb="15px">
                Provide the block number of the earliest deployed contract from
                each chain
              </Text> */}
              <TabPanels position="relative" mb="-10px">
                {yamlsArray?.length > 0 &&
                  yamlsArray.map((item, index) => (
                    <TabPanel key={item.network}>
                      <NFTWatcherTab
                        networkData={item}
                        updateObjectByNetwork={updateObjectByNetwork}
                        updateContractByIndex={updateContractByIndex}
                        updateContractEventHandlerByIndex={
                          updateContractEventHandlerByIndex
                        }
                        updateContractByNetwork={updateContractByNetwork}
                        openRemoveContractHandler={openRemoveContractHandler}
                        blockLocked={blockLocked}
                      />
                    </TabPanel>
                  ))}
                {blockLocked && (
                  <Flex
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  />
                )}
              </TabPanels>
            </Tabs>
          </CustomCard>
          <Button
            onClick={handleSave}
            variant="purple"
            alignSelf="center"
            disabled={contract.length === 0}
          >
            {btnStatus === "loading" ? (
              <Spinner size="xs" color="gray.500" />
            ) : (
              "Save"
            )}
          </Button>
        </PageWrapper>
        <Modal isOpen={modalOpen} onClose={setModalOpen} isCentered>
          <ModalOverlay bg="rgba(0,0,0,.75)" />
          <ModalContent
            bg="#121212"
            w="95%"
            maxWidth="520px"
            borderRadius="25px"
            overflow="hidden"
          >
            <Flex flex="1" w="500px" h="500px" justifyContent="center">
              <Box flex="1" alignItems="center" flexDirection="column">
                <ModalHeader>
                  <Flex
                    width="100%"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Text
                      color="white"
                      fontSize="24px"
                      mt={2}
                      fontWeight="extrabold"
                    >
                      Remove Contract
                    </Text>
                    <Text color="#A7A7A7" fontSize="14px">
                      Deleting this NFt will remove it from the watch-list
                    </Text>
                  </Flex>
                </ModalHeader>
                <Flex
                  onClick={() => {
                    setModalOpen(false);
                  }}
                  position="absolute"
                  boxSize="30px"
                  borderRadius="50px"
                  top="15px"
                  right="20px"
                  justifyContent="center"
                  alignItems="center"
                  _hover={{
                    cursor: "pointer",
                    shadow: "dark-lg",
                    transform: "scale(1.1)",
                    borderColor: "black",
                    backgroundColor: "rgba(50,50,50,.5)",
                    transitionDuration: "0.2s",
                    transitionTimingFunction: "ease-in-out",
                  }}
                >
                  <CgClose color="white" size="20px" />
                </Flex>
                <ModalBody
                  flex="1"
                  h="290px"
                  mt="10px"
                  mb="10px"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Flex
                    bg="#2C2C2C"
                    borderRadius="10px"
                    flexDirection="column"
                    p="20px"
                    pt="20px"
                    pb="20px"
                    height="100%"
                  >
                    <Flex flexDirection="column">
                      <Flex flexDirection="column">
                        <Text color="#A7A7A7" fontWeight={600} fontSize="12px">
                          Name
                        </Text>
                        <Heading
                          fontSize="16px"
                          fontWeight={700}
                          color="white"
                          pl="2px"
                          maxW="320px"
                          isTruncated
                        >
                          {modalContract?.name}
                        </Heading>
                      </Flex>
                      <Divider
                        orientation="horizontal"
                        borderWidth="2px"
                        borderRadius="1px"
                        borderColor="#474747"
                        mt={1}
                        mb={1}
                      />
                      <Flex flexDirection="column">
                        <Text color="#A7A7A7" fontWeight={500} fontSize="12px">
                          Address
                        </Text>
                        <Text
                          fontSize="16px"
                          fontWeight={700}
                          color="white"
                          pl="2px"
                          maxW="320px"
                          isTruncated
                        >
                          {modalContract?.address}
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex flex="1" />
                    <Flex flexDirection="column">
                      <Flex flexDirection="column">
                        <Flex flexDirection="row">
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Network
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {modalContract?.network}
                            </Heading>
                          </Flex>
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              NFT Type
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {getContractType(modalContract)}
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider
                        orientation="horizontal"
                        borderWidth="2px"
                        borderRadius="1px"
                        borderColor="#474747"
                        mt={1}
                        mb={1}
                      />
                      <Flex flexDirection="column">
                        <Flex flexDirection="row">
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Shared Contract
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {checkIfContractShared(
                                modalContract?.eventHandlers
                              )}
                            </Heading>
                          </Flex>
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Watch NFTs
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {displayWatchList(modalContract?.eventHandlers)}
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </ModalBody>
                <ModalFooter alignItems="center" justifyContent="center">
                  <Flex
                    width="240px"
                    pt="20px"
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Flex
                      // variant="error"
                      variant="blank"
                      bg="transparent"
                      _hover={{
                        cursor: "pointer",
                        bg: "#212121",
                      }}
                      onClick={() => setModalOpen(false)}
                      fontWeight={700}
                      borderRadius={45}
                      justifyContent="center"
                      alignItems="center"
                      // pl="10px"
                      // pr="10px"
                      width="100px"
                      height="30px"
                      fontSize="14px"
                    >
                      <Heading fontSize="14px" fontWeight={700} color="white">
                        Cancel
                      </Heading>
                    </Flex>
                    <Button
                      // variant="error"
                      variant="error"
                      // bg="transparent"
                      // _hover={{
                      //   cursor: "pointer",
                      //   bg: "#E24E51",
                      //   color: "white",
                      // }}
                      onClick={() =>
                        updateContractByNetwork(
                          modalContract?.network,
                          "remove",
                          modalContract?.index
                        )
                      }
                      borderRadius={45}
                      justifyContent="center"
                      alignItems="center"
                      // pl="10px"
                      // pr="10px"
                      w="100px"
                      height="30px"
                      fontSize="14px"
                      fontWeight={700}
                    >
                      Remove
                    </Button>
                  </Flex>
                </ModalFooter>
              </Box>
            </Flex>
          </ModalContent>
        </Modal>
      </Flex>
    );
  }
};

export default NFTWatcher;
