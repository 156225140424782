import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";

import { Web3ContextProvider } from "./contexts/Web3Context";
import { MainContextProvider } from "./contexts/MainContext";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "./theme/theme";
import App from "./App";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";

import { ColorModeScript } from "@chakra-ui/react";

const client = new ApolloClient({
  uri: "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-xp-mainnet",
  cache: new InMemoryCache(),
});

createRoot(document.getElementById("root")).render(
  <ChakraProvider theme={theme}>
    <ApolloProvider client={client}>
      <Web3ContextProvider>
        <MainContextProvider>
          <App />
          <ColorModeScript />
        </MainContextProvider>
      </Web3ContextProvider>
    </ApolloProvider>
  </ChakraProvider>
);
