import { Flex } from "@chakra-ui/react";

interface Props {
  children: any;
  width?: string
}

export default function PageWrapper({ children, width = "1000px" }: Props) {
  return (
    <Flex
      flex={1}
      pb={10}
      width="100%"
      bg="#121212"
      justifyContent="flex-start"
      alignItems="center"
      flexDir="column"
    >
      <Flex
        flex={1}
        flexDir="column"
        // justifyContent="center"
        justifyContent="flex-start"
        alignItems="center"
        // overflow="hidden"
        width="90%"
        maxWidth={width}
        mt="40px"
      >
        {children}
      </Flex>
    </Flex>
  );
}
