import React, { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import CryptoJS from "crypto-js";
import { Web3Context } from "../../contexts/Web3Context";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import { GoCheck, GoX } from "react-icons/go";
import { addressCheckHandler } from "../../func/myFunc.ts";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";
import changeNetworkToPolygon from "../../func/changeNetwork";

const UpdateScore = () => {
  const { web3, accounts, contract } = useContext(Web3Context);
  const { projectId } = useParams();

  const toast = useToast();

  const [updateIdLocked, setUpdateIdLocked] = useState(false);
  const [updateID, setUpdateID] = useState("");
  const [actionName, setActionName] = useState();
  const [scoreType, setScoreType] = useState();
  const [targetWallet, setTargetWallet] = useState("");
  const [projectActions, setProjectActions] = useState([]);
  const [projectScoreTypes, setProjectScoreTypes] = useState([]);
  const [snapAddressPass, setSnapAddressPass] = useState(false);
  const [snapAddressChecked, setSnapAddressChecked] = useState(false);
  const [btnStatus, setBtnStatus] = useState("");

  const { data: projectData, loading } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    if (projectData) {
      let actions = projectData.projects[0].actions.map((action) => {
        return {
          name: action.name,
          points: action.points,
        };
      });
      console.log(projectData.projects[0].actions);
      setProjectActions(actions);
      setProjectScoreTypes(projectData.projects[0].scoreTypes);
    }
  }, [projectData]);

  const generateUpdateID = () => {
    let updateString = projectId + "_" + Date.now();
    let hash = CryptoJS.SHA256(updateString).toString();
    setUpdateID("0x" + hash);
  };

  const handleClick = async () => {
    if (btnStatus === "loading") {
      return;
    }

    setBtnStatus("loading");
    setSnapAddressChecked(false);
    const pass = await addressCheckHandler(targetWallet);
    setSnapAddressChecked(true);
    setSnapAddressPass(pass);
    if (!pass) {
      setBtnStatus("");
      return;
    }

    await changeNetworkToPolygon();

    await contract.methods
      .updateScore(
        updateID,
        projectId,
        actionName?.name,
        scoreType,
        targetWallet
      )
      .send({
        from: accounts[0],
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .on("transactionHash", (hash) => {
        console.log("hash: " + hash);
      })
      .on("confirmation", (confirmationNumber, receipt) => {
        if (confirmationNumber === 0) {
          setBtnStatus("");
          toast({
            title: "Score Added",
            // description: "We've created your account for you.",
            position: "top",
            status: "success",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .on("error", (error) => {
        let title = error.message;
        setBtnStatus("");
        toast({
          title,
          // description: "We've created your account for you.",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      });
  };
  if (loading) {
    return <LoadComponent />;
  } else {
    return (
      <PageWrapper width="800px">
        <Flex w="100%" flexDirection="column" mb="30px">
          <Heading color="white" fontSize="18px" mb="5px">
            Score Updates
          </Heading>
          <Text color="#A7A7A7" fontSize="14px">
            Manually update a wallet address XP score
          </Text>
        </Flex>
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-start"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              <Text
                color={updateIdLocked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
                mb="5px"
              >
                Update Wallet Score
              </Text>
            </Flex>
            {/* <Locks state={updateIdLocked} setState={setUpdateIdLocked} /> */}
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="5px">
            Update ID
          </Text>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            position="relative"
            mb="15px"
          >
            <Input
              borderRadius="10px"
              textColor={updateIdLocked ? "#aaa" : "white"}
              maxLength="250"
              width="80%"
              max={100}
              p={2}
              bg="#292929"
              border="2px solid #383838"
              value={updateID}
              mr="10px"
              onChange={(e) => setUpdateID(e.target.value)}
            />
            <Button
              onClick={generateUpdateID}
              h="30px"
              w="90px"
              fontSize="14px"
              fontWeight={700}
              variant="purple"
              disabled={updateIdLocked}
            >
              Generate
            </Button>
            {updateIdLocked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="5px">
            Enter the address of the user whose score will be updated
          </Text>
          <Flex
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb="15px"
          >
            <Flex
              flexDirection="row"
              position="relative"
              justifyContent="center"
              alignItems="center"
              bg="#292929"
              border="2px solid #383838"
              // borderColor={!royaltyAddressPass ? "red" : "#505050"}
              borderRadius="10px"
              width="70%"
              p={2}
              pt={0}
              pb={0}
            >
              <Input
                borderRadius="10px"
                textColor={updateIdLocked ? "#aaa" : "white"}
                maxLength="250"
                width="100%"
                max={100}
                p={0}
                bg="#292929"
                pr={2}
                value={targetWallet}
                onChange={(e) => setTargetWallet(e.target.value)}
              />
              {snapAddressPass && snapAddressChecked ? (
                <GoCheck color="green" size="18px" />
              ) : !snapAddressPass && snapAddressChecked ? (
                <GoX color="red" size="18px" />
              ) : (
                <Flex boxSize="16px" />
              )}
              {updateIdLocked && (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                />
              )}
            </Flex>
            <Flex />
          </Flex>
          <Flex flexDirection="row" alignItems="center" position="relative">
            <Flex flexDir="column" mr="15px">
              <Text
                color={updateIdLocked ? "#aaa" : "white"}
                opacity="1"
                fontSize="12px"
                mb="6px"
              >
                Action
              </Text>
              <Flex
                flex="1"
                justifyContent="flex-end"
                alignItems="flex-end"
                id={updateIdLocked ? "snap-select-locked" : "snap-select"}
              >
                {projectActions.length > 0 && (
                  <CustomDropdown
                    type="action"
                    placeholder="Action"
                    array={projectActions}
                    state={actionName}
                    setState={setActionName}
                  />
                )}
              </Flex>
            </Flex>
            <Flex flexDir="column">
              <Text
                color={updateIdLocked ? "#aaa" : "white"}
                opacity="1"
                fontSize="12px"
                mb="6px"
              >
                Score Type
              </Text>
              <Flex
                flex="1"
                justifyContent="flex-end"
                alignItems="flex-end"
                id={updateIdLocked ? "snap-select-locked" : "snap-select"}
              >
                {projectScoreTypes.length > 0 && (
                  <CustomDropdown
                    placeholder="Scores"
                    array={projectScoreTypes}
                    state={scoreType}
                    setState={setScoreType}
                  />
                )}
              </Flex>
            </Flex>
            {updateIdLocked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
        </CustomCard>

        <Button
          onClick={handleClick}
          variant="purple"
          alignSelf="center"
          mt={10}
          disabled={
            targetWallet === "" ||
            updateID === "" ||
            !actionName?.name ||
            !scoreType
          }
        >
          {btnStatus === "loading" ? (
            <Spinner size="xs" color="gray.500" />
          ) : (
            "Update"
          )}
        </Button>
      </PageWrapper>
    );
  }
};

export default UpdateScore;
