import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import CryptoJS from "crypto-js";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Tabs,
  Tab,
  TabPanel,
  TabList,
  TabIndicator,
  TabPanels,
  Spinner,
  Image,
  Icon,
  useToast,
} from "@chakra-ui/react";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import { useQuery, gql } from "@apollo/client";
import LevelsScoreTypeForm from "./LevelsScoreTypeForm";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import OutsideAlerter from "../../components/OutsideAlerter/OutsideAlerter";
import { HiMinusSm } from "react-icons/hi";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";

const Levels = () => {
  const { web3, accounts, contract } = useContext(Web3Context);
  const toast = useToast();
  const { projectId } = useParams();
  const [btnStatus, setBtnStatus] = useState("");
  const [isLoading, setIsLoading] = useState();
  const [existingConfig, setExistingConfig] = useState();
  const [projectScoreTypes, setProjectScoreTypes] = useState();
  const [globalLevelsFields, setGlobalLevelsFields] = useState([]);
  const [scoreTypeLevelsFields, setScoreTypeLevelsFields] = useState({});
  const [marginBottom, setMarginBottom] = useState(0);
  const [totalScoreLocked, setTotalScoreLocked] = useState(true);
  const [scoreTypesLocked, setScoreTypesScoreLocked] = useState(true);
  const [emojiVisibilities, setEmojiVisibilities] = React.useState(() =>
    globalLevelsFields.map((x) => false)
  );

  const { data: projectData } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  const addField = () => {
    let newField = {
      icon: "",
      levelName: "",
      min: 0,
      max: 0,
    };

    setGlobalLevelsFields([...globalLevelsFields, newField]);
  };

  const removeField = (index) => {
    let formData = [...globalLevelsFields];
    formData.splice(index, 1);
    setGlobalLevelsFields(formData);
  };

  const handleChange = (e, index, fieldName) => {
    let val = e.target.value;
    let formData = [...globalLevelsFields];
    let isNum = /^\d+$/.test(val);

    if (fieldName === "min" || fieldName === "max") {
      if (isNum) {
        formData[index][`${fieldName}`] = parseInt(val);
        setGlobalLevelsFields(formData);
      } else if (val === "") {
        formData[index][`${fieldName}`] = val;
        setGlobalLevelsFields(formData);
      }
    } else {
      formData[index][`${fieldName}`] = val;
      setGlobalLevelsFields(formData);
    }
  };

  const handelEmoji = (emojiData, index) => {
    const val = emojiData.unified;
    let formData = [...globalLevelsFields];
    formData[index].icon = val;
    setGlobalLevelsFields(formData);
  };

  const updateScoreTypeFields = (scoreType, fields) => {
    let fieldsCurr = scoreTypeLevelsFields;

    fieldsCurr[`${scoreType}`] = {
      scoreType,
      fields,
    };

    setScoreTypeLevelsFields(fieldsCurr);
  };

  const sortByInterval = (a, b) => {
    if (a.min < b.min) {
      return -1;
    }
    if (a.min > b.min) {
      return 1;
    }
    return 0;
  };

  const checkIfOverlap = (intervals, newInterval) => {
    const newMin = parseInt(newInterval.min);
    const newMax = parseInt(newInterval.max);

    if (intervals.length === 0) {
    } else {
      for (const interval of intervals) {
        const oldMin = parseInt(interval.min);
        const oldMax = parseInt(interval.max);

        if (newMin > oldMin && newMin > oldMax) {
          return true;
        }
      }
      throw new Error("Level Ranges must not overlap");
    }
  };

  const handleSaveConfig = async () => {
    try {
      setBtnStatus("loading");
      let levelMap = {};
      let globalLevelsObject = {};
      let scoreTypeLevelsObject = {};

      // globalLevelsFields check
      for (let i = 0; i < globalLevelsFields.length; i++) {
        let scoreType = "total";
        let item = globalLevelsFields[i];
        if (
          item.levelName &&
          item.levelName.length > 0 &&
          parseInt(item.min) >= 0 &&
          parseInt(item.max) > parseInt(item.min)
        ) {
          globalLevelsObject[item.levelName] = {
            icon: item.icon,
            levelName: item.levelName,
            min: parseInt(item.min),
            max: parseInt(item.max),
          };
        } else {
          if (!item.levelName || item.levelName.length === 0) {
            throw new Error(`${scoreType}: Level name missing`);
          } else if (!(parseInt(item.min) >= 0)) {
            throw new Error(`${scoreType}: Minimum may not be empty`);
          } else if (
            !(parseInt(item.max) >= 0) ||
            parseInt(item.min) >= parseInt(item.max)
          ) {
            throw new Error(
              `${scoreType}: Maximum must be greater than Minimum`
            );
          } else {
            throw new Error(`${scoreType}: Something went wrong`);
          }
        }
      }

      const sortedGlobalLevel = [...globalLevelsFields].sort(sortByInterval);
      let sortedGlobalLevelEmpty = [];

      sortedGlobalLevel.forEach((element) => {
        checkIfOverlap(sortedGlobalLevelEmpty, element);
        sortedGlobalLevelEmpty = [element];
      });

      // projectScoreTypes check
      for (let i = 0; i < projectScoreTypes.length; i++) {
        let obj = {};
        let scoreType = projectScoreTypes[i];

        for (
          let j = 0;
          j < scoreTypeLevelsFields[`${scoreType}`].fields.length;
          j++
        ) {
          let scoreItem = scoreTypeLevelsFields[`${scoreType}`].fields[j];
          if (
            scoreItem.levelName &&
            scoreItem.levelName.length > 0 &&
            parseInt(scoreItem.min) >= 0 &&
            parseInt(scoreItem.max) > parseInt(scoreItem.min)
          ) {
            obj[`${scoreItem.levelName}`] = {
              icon: scoreItem.icon,
              levelName: scoreItem.levelName,
              min: parseInt(scoreItem.min),
              max: parseInt(scoreItem.max),
            };
          } else {
            if (!scoreItem.levelName || scoreItem.levelName.length === 0) {
              throw new Error(`${scoreType}: Level name missing`);
            } else if (!(parseInt(scoreItem.min) >= 0)) {
              throw new Error(`${scoreType}: Minimum may not be empty`);
            } else if (
              !(parseInt(scoreItem.max) >= 0) ||
              parseInt(scoreItem.min) >= parseInt(scoreItem.max)
            ) {
              throw new Error(
                `${scoreType}: Maximum must be greater than Minimum`
              );
            } else {
              throw new Error(`${scoreType}: Something went wrong`);
            }
          }
        }

        scoreTypeLevelsObject[`${scoreType}`] = obj;
      }

      for (const score in scoreTypeLevelsFields) {
        let sortedScoreTypeLevelsFields = [
          ...scoreTypeLevelsFields[score].fields,
        ].sort(sortByInterval);
        let sortedScoreTypeLevelsFieldsEmpty = [];

        sortedScoreTypeLevelsFields.forEach((element) => {
          checkIfOverlap(sortedScoreTypeLevelsFieldsEmpty, element);
          sortedScoreTypeLevelsFieldsEmpty = [element];
        });
      }

      levelMap["scoreTypeLevelMap"] = scoreTypeLevelsObject;

      levelMap["globalLevelMap"] = globalLevelsObject;

      const requestData = {
        configData: {
          xpProjectId: projectId,
          version: "1.0.0",
          levelMap,
        },
      };

      const toSign =
        "0x" + CryptoJS.SHA256(JSON.stringify(requestData)).toString();

      const signature = await web3.eth.personal.sign(
        toSign,
        window.ethereum.selectedAddress
      );

      let requestBody = {
        configData: {
          xpProjectId: projectId,
          version: "1.0.0",
          levelMap,
        },
        verification: {
          signature,
          message: toSign,
          address: accounts[0],
        },
      };

      const configResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/createLevelsConfig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const response = await configResponse.json();

      toast({
        title: "Configuration Updated",
        // description: "We've created your account for you.",
        position: "top",
        status: "success",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
      setBtnStatus("");
    } catch (error) {
      setBtnStatus("error");
      let title = error.message;
      if (title.includes("User denied")) {
        title = "Signature Rejected by User";
      }
      toast({
        title,
        // description: "We've created your account for you.",
        position: "top",
        status: "error",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (projectData) {
      let scoreTypes = projectData.projects[0].scoreTypes;

      setProjectScoreTypes(scoreTypes);
    }
  }, [projectData]);

  useEffect(() => {
    const getLevelsConfig = async () => {
      setIsLoading(true);
      await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getLevelsConfig",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ xpProjectId: projectId }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data?.configData) {
            if (data?.configData?.levelMap?.globalLevelMap) {
              let fields = [];
              const globalLevelMap = data.configData.levelMap.globalLevelMap;
              const keys = Object.keys(globalLevelMap);
              for (let i = 0; i < keys.length; i++) {
                let obj = globalLevelMap[`${keys[i]}`];
                fields.push(obj);
              }
              setGlobalLevelsFields(fields);
            }
            if (data?.configData?.levelMap?.scoreTypeLevelMap) {
              setExistingConfig(data.configData.levelMap.scoreTypeLevelMap);
            }

            setIsLoading(false);
          }
        });

      setIsLoading(false);
    };

    getLevelsConfig();
  }, []);

  const toggleVisibilityHandler = (index, boolean) => {
    const newVisibilities = [...emojiVisibilities];
    newVisibilities[index] = boolean;
    setEmojiVisibilities(newVisibilities);
  };

  if (isLoading) {
    return <LoadComponent />;
  } else {
    return (
      <PageWrapper width="800px">
        <Flex w="100%" alignItems="center" mb="30px">
          <Heading color="white" fontSize="18px">
            Level Configuration
          </Heading>
        </Flex>
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              {/* <Icon
                as={FiPlusCircle}
                color="white"
                fontSize="25px"
                fontWeight={600}
                mr="10px"
              /> */}
              <Text
                color={totalScoreLocked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
              >
                Total Score
              </Text>
            </Flex>
            <Locks state={totalScoreLocked} setState={setTotalScoreLocked} />
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="15px">
            Award levels based on cumulative XP scores
          </Text>
          <Flex position="relative" flexDirection="column">
            {globalLevelsFields &&
              globalLevelsFields.map((level, index) => (
                <Flex
                  key={index}
                  width="100%"
                  mb="10px"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                >
                  <Flex flexDir="column" mr="15px" position="relative">
                    <Text color="#fff" fontSize="12px" mb="6px">
                      Icon
                    </Text>
                    <Flex
                      onClick={(e) => toggleVisibilityHandler(index, true)}
                      bg="#292929"
                      boxSize="40px"
                      border="2px solid #383838"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="10px"
                      cursor="pointer"
                      position="relative"
                      overflow="hidden"
                    >
                      {globalLevelsFields[index].icon ? (
                        <Emoji
                          unified={globalLevelsFields[index].icon}
                          emojiStyle={EmojiStyle.APPLE}
                          size={22}
                          lazyLoad={true}
                        />
                      ) : (
                        <></>
                      )}
                      {totalScoreLocked && (
                        <Flex
                          position="absolute"
                          boxSize="40px"
                          // border="2px solid #383838"
                          borderRadius="10px"
                          // top={0}
                          left={0}
                          // right={0}
                          bottom={0}
                          bg="rgba(33,33,33,.5)"
                        />
                      )}
                    </Flex>
                    {globalLevelsFields[index].icon && (
                      <Flex
                        cursor="pointer"
                        position="absolute"
                        borderRadius="50%"
                        boxSize="14px"
                        right="-3px"
                        bottom="28px"
                        bg={totalScoreLocked ? "#aaa" : "white"}
                        justifyContent="center"
                        alignItems="center"
                        onClick={() => handelEmoji("", index)}
                      >
                        <Icon as={HiMinusSm} fontSize="14px" color="black" />
                      </Flex>
                    )}
                    {emojiVisibilities[index] === true && (
                      <OutsideAlerter
                        onBlur={() => toggleVisibilityHandler(index, false)}
                        // onBlur={() => toggleVisibilityHandler(index, false)}
                      >
                        <Flex position="absolute" top="64px" zIndex={100}>
                          <EmojiPicker
                            onEmojiClick={(e) => handelEmoji(e, index)}
                            theme={Theme.DARK}
                            searchDisabled={true}
                            autoFocusSearch={false}
                            height={350}
                            previewConfig={{
                              showPreview: false,
                            }}
                            categories={[
                              {
                                name: "Smiles & Emotions",
                                category: Categories.SMILEYS_PEOPLE,
                              },
                              {
                                name: "Animals",
                                category: Categories.ANIMALS_NATURE,
                              },
                              {
                                name: "Food & Drinks",
                                category: Categories.FOOD_DRINK,
                              },
                              {
                                name: "Travel",
                                category: Categories.TRAVEL_PLACES,
                              },
                              {
                                name: "Activities",
                                category: Categories.ACTIVITIES,
                              },
                              {
                                name: "Objects",
                                category: Categories.OBJECTS,
                              },
                              {
                                name: "Symbols",
                                category: Categories.SYMBOLS,
                              },
                              {
                                name: "Flags",
                                category: Categories.FLAGS,
                              },
                            ]}
                          />
                        </Flex>
                      </OutsideAlerter>
                    )}
                    {/* </ChildrenBlur> */}
                  </Flex>
                  <Flex flexDir="column" width="100%" maxW="300px" mr="15px">
                    <Text color="#fff" fontSize="12px" mb="6px">
                      Level Name
                    </Text>
                    <Input
                      borderRadius="10px"
                      textColor={totalScoreLocked ? "#aaa" : "white"}
                      // maxLength="250"
                      width="100%"
                      maxW="300px"
                      max={100}
                      pl={2}
                      pr={2}
                      bg="#292929"
                      border={`2px solid ${
                        globalLevelsFields[index]?.levelNameError
                          ? "#E24E51"
                          : "#383838"
                      }`}
                      onChange={(e) => handleChange(e, index, "levelName")}
                      value={globalLevelsFields[index].levelName}
                    />
                  </Flex>
                  <Flex flexDir="column" mr="15px">
                    <Text color="#fff" fontSize="12px" mb="6px">
                      Minimum
                    </Text>
                    <Input
                      borderRadius="10px"
                      textColor={totalScoreLocked ? "#aaa" : "white"}
                      width="100%"
                      maxW="100px"
                      max={100}
                      pl={2}
                      pr={2}
                      bg="#292929"
                      border={`2px solid ${
                        globalLevelsFields[index]?.minError
                          ? "#E24E51"
                          : "#383838"
                      }`}
                      onChange={(e) => handleChange(e, index, "min")}
                      value={globalLevelsFields[index].min}
                    />
                  </Flex>
                  <Flex flexDir="column" mr="15px">
                    <Text color="#fff" fontSize="12px" mb="6px">
                      Maximum
                    </Text>
                    <Input
                      borderRadius="10px"
                      textColor={totalScoreLocked ? "#aaa" : "white"}
                      width="100%"
                      maxW="100px"
                      max={100}
                      pl={2}
                      pr={2}
                      bg="#292929"
                      border={`2px solid ${
                        globalLevelsFields[index]?.maxError
                          ? "#E24E51"
                          : "#383838"
                      }`}
                      onChange={(e) => handleChange(e, index, "max")}
                      value={globalLevelsFields[index].max}
                    />
                  </Flex>
                  {index > 0 || true ? (
                    <Button
                      onClick={() => removeField(index)}
                      h="30px"
                      w="90px"
                      borderRadius="15px"
                      mb="5px"
                      fontSize="14px"
                      fontWeight={700}
                      variant="error"
                      disabled={totalScoreLocked}
                    >
                      Delete
                    </Button>
                  ) : (
                    <Flex h="30px" w="90px" />
                  )}
                </Flex>
              ))}
            <Button
              h="30px"
              w="90px"
              mt="10px"
              fontSize="14px"
              fontWeight={700}
              variant="purple"
              onClick={addField}
              disabled={totalScoreLocked}
            >
              Add field
            </Button>
            {/* <Button variant="purple" onClick={addField}>
              Add field
            </Button> */}
            {totalScoreLocked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
        </CustomCard>
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              {/* <Icon
                as={FiPlusCircle}
                color="white"
                fontSize="25px"
                fontWeight={600}
                mr="10px"
              /> */}
              <Text
                color={scoreTypesLocked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
              >
                Score Types
              </Text>
            </Flex>
            <Locks
              state={scoreTypesLocked}
              setState={setScoreTypesScoreLocked}
            />
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="15px">
            Reward levels based on XP scores in specific score types
          </Text>
          <Flex position="relative" flexDirection="column">
            <Tabs position="relative" variant="unstyled">
              <TabList>
                {projectScoreTypes &&
                  projectScoreTypes.map((type, typeIndex) => (
                    <Tab
                      key={typeIndex}
                      // _focus={{
                      //   boxShadow: "none",
                      // }}
                      // _selected={{ color: "#A654F4" }}
                      // _hover={{ bg: "#414141" }}
                      // color="#a7a7a7"
                    >
                      {type}
                    </Tab>
                  ))}
              </TabList>
              <TabIndicator
                mt="-1.5px"
                height="2px"
                bg="#A654F4"
                borderRadius="1px"
                zIndex={100}
              />
              <TabPanels position="relative">
                {projectScoreTypes &&
                  projectScoreTypes.map((type, typeIndex) => (
                    <TabPanel key={typeIndex}>
                      <LevelsScoreTypeForm
                        typeIndex={typeIndex}
                        scoreType={type}
                        updateScoreTypeFields={updateScoreTypeFields}
                        existingConfig={existingConfig}
                        scoreTypesLocked={scoreTypesLocked}
                        setMarginBottom={setMarginBottom}
                      />
                    </TabPanel>
                  ))}
                {scoreTypesLocked && (
                  <Flex
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                  />
                )}
              </TabPanels>
            </Tabs>
          </Flex>
        </CustomCard>
        <Button onClick={handleSaveConfig} variant="purple" mb={marginBottom}>
          {btnStatus === "loading" ? (
            <Spinner size="xs" color="gray.500" />
          ) : (
            "Save"
          )}
        </Button>
      </PageWrapper>
    );
  }
};

export default Levels;
