import filetype from 'magic-bytes.js'
import { useCallback } from 'react';
import Web3 from "web3";
import CryptoJS from "crypto-js";

export const getMimeType = async (url: any) => {
  const options = {
    uri: url,
    gzip: true,
    encoding: null,
    method: 'GET',
    headers: {
      'Range': 'bytes=0-256' // Maximum number of bytes to download // usually 100 byte is enough
    }
  };
  let response = await fetch(url, options).then((a) => a.arrayBuffer());

  let arr = new Uint8Array(response)
  let resp;
  try {
    resp = filetype(arr)[0].mime?.split('/')[1]


  } catch (e) {
    resp = 'dne'
  }

  return resp;
};
export const isValidURL = async (url) => {
  let valid = false;
  try {
    await fetch(url).then(async(response) => {
      
      valid = response.status == 200;
      
    });
    return valid;
  } catch (e) {
    return false;
  }
}

export const capitalizeFirstLetter = (string) => {

  if (typeof string === 'string') {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  else return string;

}

export const addressCheckHandler = async (val) => {
  try {
    const provider = new Web3.providers.HttpProvider(
      "https://chaotic-solitary-wave.quiknode.pro/f4c7767687e3654059ef21fc64229adb515d6fc5/"
    );
    const _web3 = new Web3(provider);

    if (val.endsWith('.eth')) {
      val = await _web3.eth.ens.getAddress(val);
    }

    let isAddress = await _web3.utils.isAddress(val);

    if (isAddress) {
      return true;
    } else {
      // throw new Error('Invalid address')
      return false;
    }
  } catch (error) {
    // throw new Error('Invalid address')
    return false;
  }
};

export const useNoArgs = (fn) => {
  return useCallback(() => fn(), [fn]);
}

export function generateUniqueId() {
  const timestamp = new Date().getTime();
  const random = Math.floor(Math.random() * 10000);
  return `${timestamp}-${random}`;
}

export const hexToDecimal = (hex) => parseInt(hex, 16);

export const redeemableIdHandler = (projectId) => {
  let updateString = projectId + "_" + Date.now();
  let hash = CryptoJS.SHA256(updateString).toString();
  return "0x" + hash;
};