import React, { useState, useEffect, createContext, useCallback } from "react";
import getWeb3 from "../getWeb3";
import XP from "../abi/XP.json";
import RedeemablesDeploy1155 from "../abi/RedemptionsFunctionsDeploy1155.json";
import RedeemablesExisting1155 from "../abi/RedemptionsFunctionsExisting1155.json";
import RedeemablesExisting721 from "../abi/RedemptionsFunctionsExisting721.json";
import RedeemablesStorage from "../abi/RedemptionsStorage.json";
import ERC20Redemption from "../abi/ERC20Redemption.json";

import ERC721 from "../abi/ERC721.json";
import ERC1155 from "../abi/ERC1155.json";

import { Flex, Heading } from "@chakra-ui/react";

import "../loader.css";
import NotConnected from "../components/NotConnected";

export const Web3Context = createContext();

export const Web3ContextProvider = ({ children }) => {
  const [web3, setWeb3] = useState();
  const [contract, setContract] = useState();
  const [contractRedeemablesDeploy1155, setContractRedeemablesDeploy1155] =
    useState();
  const [contractRedeemablesExisting1155, setContractRedeemablesExisting1155] =
    useState();
  const [contractRedeemablesExisting721, setContractRedeemablesExisting721] =
    useState();
  const [reedemablesStorage, setRedeemablesStorage] = useState();
  const [contractERC20RedemptionSepolia, setContractERC20RedemptionSepolia] =
    useState();
  const [
    contractERC20RedemptionAvalanche,
    setContractERC20RedemptionAvalanche,
  ] = useState();
  const [contractERC20RedemptionPolygon, setContractERC20RedemptionPolygon] =
    useState();

  const [accounts, setAccounts] = useState();
  const [network, setNetwork] = useState(0);
  const [notConnected, setNotConnected] = useState(false);
  const [connectAttempts, setConnectAttempts] = useState(0);
  const [loaded, setLoaded] = useState(false);

  const connectWallet = () => {
    console.log("attempting connect...");
    setConnectAttempts((prev) => prev + 1);
  };

  useEffect(() => {
    const getWeb3Data = async () => {
      try {
        // Get network provider and web3 instance
        const web3 = await getWeb3();

        // Use web3 to get the user's accounts
        const accounts = await web3.eth.getAccounts();

        // Get the contract instance
        const networkId = await web3.eth.net.getId();
        console.log(networkId);

        const deployedNetwork = XP.networks[networkId];
        const instance = new web3.eth.Contract(
          XP.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceRedeemablesDeploy1155 = new web3.eth.Contract(
          RedeemablesDeploy1155.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceRedeemablesExisting1155 = new web3.eth.Contract(
          RedeemablesExisting1155.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceRedeemablesExisting721 = new web3.eth.Contract(
          RedeemablesExisting721.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceRedeemablesStorage = new web3.eth.Contract(
          RedeemablesStorage.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceERC20RedemptionSepolia = new web3.eth.Contract(
          ERC20Redemption.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceERC20RedemptionAvalanche = new web3.eth.Contract(
          ERC20Redemption.abi,
          deployedNetwork && deployedNetwork.address
        );

        const instanceERC20RedemptionPolygon = new web3.eth.Contract(
          ERC20Redemption.abi,
          deployedNetwork && deployedNetwork.address
        );

        // Set address of Polygon mainnet contract
        instance.options.address = "0xf1b5af2bd94ddfb696b54dabb06118a79c7460bc";

        // Set address of Polygon Redeemables Deploy 1155 contract
        instanceRedeemablesDeploy1155.options.address =
          "0x0A76be7ec3511E861A177AC3d3bEc25B4c6f7cb6";
        //
        // Set address of Polygon Redeemables Existing 1155 contract
        instanceRedeemablesExisting1155.options.address =
          "0x1682ceb9C22a16E52B5B06be139796B5595b7cE1";

        // Set address of Polygon Redeemables Existing 721 contract
        instanceRedeemablesExisting721.options.address =
          "0x10dc5d77097F109677a8aE8cbDCEA63F7934CA9a";

        // redeemables storage
        instanceRedeemablesStorage.options.address =
          "0x3dCD7b85C634Aa8741Ddd75EaB899B108c10E3fc";

        // Set addresses of ERC-20 Redemption contracts
        instanceERC20RedemptionSepolia.options.address =
          "0x6b984d09979F1dc8f2BDe8E470944696316Eb318";

        instanceERC20RedemptionAvalanche.options.address =
          "0xA68D24005d4Dc631774C8f965dE58E0f014f5977";

        instanceERC20RedemptionPolygon.options.address =
          "0x0981EA28F0D8f3aFa7b2b8eD5BD7DE92c2A894a6";

        /* Used when frontend broke to manually insert contract address
        instance.options.address = "0xb656973BAcD8Bd164A4DA5617ad55349DEb4927C";

        console.log("ADDRESS:");
        console.log(instance);
        deployedNetwork && console.log(deployedNetwork);
        */

        // let gasPrice = await web3.eth.getGasPrice();
        // let gasPriceInteger = parseInt(gasPrice, 10);

        // Set web3, accounts, and contract to the state
        setContract(instance);
        setContractRedeemablesDeploy1155(instanceRedeemablesDeploy1155);
        setContractRedeemablesExisting1155(instanceRedeemablesExisting1155);
        setContractRedeemablesExisting721(instanceRedeemablesExisting721);
        setRedeemablesStorage(instanceRedeemablesStorage);
        setContractERC20RedemptionSepolia(instanceERC20RedemptionSepolia);
        setContractERC20RedemptionAvalanche(instanceERC20RedemptionAvalanche);
        setContractERC20RedemptionPolygon(instanceERC20RedemptionPolygon);
        setAccounts(accounts);
        setWeb3(web3);
        setNetwork(networkId);
        setLoaded(true);
      } catch (error) {
        // alert("Web3 wallet not connected..");
        setNotConnected(true);
        console.error(error);
      }
    };
    getWeb3Data();
  }, [connectAttempts]);

  if (notConnected) {
    return <NotConnected connectWallet={connectWallet} loaded={loaded} />;
  }

  if (!web3) {
    return (
      <Flex
        flexDir="column"
        bgColor="black"
        minH="100vh"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="white" fontSize="xl" mb="2vh">
          Loading web3 connection...
        </Heading>

        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Flex>
    );
  }

  return (
    <Web3Context.Provider
      value={{
        web3,
        accounts,
        contract,
        contractRedeemablesDeploy1155,
        contractRedeemablesExisting1155,
        contractRedeemablesExisting721,
        reedemablesStorage,
        contractERC20RedemptionSepolia,
        contractERC20RedemptionAvalanche,
        contractERC20RedemptionPolygon,
        network,
        connectWallet,
      }}
    >
      {children}
    </Web3Context.Provider>
  );
};
