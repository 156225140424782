import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  initialColorMode: "dark",
  useSystemColorMode: false,
  colors: {
    toggle: {
      100: "#A654F4",
      200: "#A654F4",
      300: "#A654F4",
      400: "#A654F4",
      500: "#A654F4",
      600: "#A654F4",
      700: "#A654F4",
      800: "#A654F4",
    },
    gray: {
      100: "#242424",
      200: "#545454",
    },
  },
  components: {
    Heading: {
      baseStyle: {
        color: "#E1E8FD",
      },
    },
    Radio: {
      parts: ["label"],
      baseStyle: {
        label: {
          color: "#A7A7A7",
        },
      },
    },
    Input: {
      baseStyle: {
        field: {
          color: "white",
          bg: "#212121;",
        },
      },
      defaultProps: {
        variant: null, // null here
      },
    },
    Select: {
      baseStyle: {
        field: {
          color: "white",
          bg: "#212121",
        },
      },
      defaultProps: {
        variant: null, // null here
      },
    },
    Tabs: {
      baseStyle: {
        tab: {
          _selected: {
            color: "#A654F4",
          },
          color: "#a7a7a7",
          _hover: {
            bg: "#414141",
          },
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
    Button: {
      fontSize: "16px",
      fontWeight: 800,
      baseStyle: {
        _focus: { boxShadow: "none" },
        field: {},
      },
      variants: {
        blank: {
          // bgColor: "#121212",
          bgColor: "transparent",
          color: "white",
          _active: {},
          _hover: {
            shadow: "dark-lg",
            transform: "scale(1.05)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          },
        },
        gray: {
          h: "40px",
          w: "95%",
          maxW: "150px",
          fontSize: "18px",
          fontWeight: 700,
          borderRadius: "45px",
          bgColor: "#2A2A2A",
          color: "white",
          _active: {},
          _hover: {
            shadow: "dark-lg",
            transform: "scale(1.05)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          },
        },
        error: {
          h: "40px",
          w: "100%",
          maxW: "150px",
          bgColor: "#E24E51",
          color: "black",
          _active: {},
          _hover: {
            shadow: "dark-lg",
            transform: "scale(1.05)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          },
        },
        gradient: {
          bgGradient: "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
          color: "#121212",
          _disabled: {
            bg: "transparent",
            color: "white",
          },
          _active: {
            shadow: "dark-lg",
            transform: "scale(1.05)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          },
          _hover: {
            shadow: "dark-lg",
            transform: "scale(1.05)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          },
        },
        purple: {
          h: "40px",
          w: "100%",
          maxW: "150px",
          bgColor: "#A654F4",
          color: "white",
          borderRadius: "45px",
          _disabled: {
            bg: "transparent",
            color: "white",
          },
          _active: {
            // bgColor: "#E24E51",
          },
          _hover: {
            shadow: "dark-lg",
            transform: "scale(1.05)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          },
        },
      },
      defaultProps: {
        variant: "gradient",
      },
    },
  },
});

theme.fonts = {
  heading: "SFProHeavy",
  body: "SFProBold",
};

export default theme;
