import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Heading,
  Flex,
  Spinner,
  Icon,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { HiTrash } from "react-icons/hi";

const truncateMiddle = (str, maxLength) => {
  if (str.length <= maxLength) {
    return str;
  }

  const separator = "...";

  const sepLen = separator.length;
  const charsToShow = maxLength - sepLen;
  const frontChars = Math.ceil(charsToShow / 2);
  const backChars = Math.floor(charsToShow / 2);

  return (
    str.substr(0, frontChars) + separator + str.substr(str.length - backChars)
  );
};

export default function ClickRemoveModal({
  isOpen,
  onOpen,
  onClose,
  removeHandler,
  item,
  btnStatus,
}) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      closeOnOverlayClick={!btnStatus === "loading"}
    >
      <ModalOverlay />
      <ModalContent bg="#121212" textAlign="center">
        {/* <ModalCloseButton color="white" /> */}
        <ModalBody alignItems="center" p="40px">
          <Flex alignItems="flex-start" w="100%">
            <Icon
              _hover={{
                transform: "scale(1.05)",
              }}
              transition="all 0.2s"
              as={HiTrash}
              color="white"
              fontWeight={600}
              fontSize="18px"
              mr="5px"
              mt="2px"
            />
            {/* <Heading fontSize="18px" color="white" mr="5px">
                  Remove
                </Heading> */}
            <Heading
              fontSize="18px"
              color="white"
              wordBreak="break-word"
              textAlign="start"
            >
              Remove{" "}
              {item?.clickedAddress
                ? truncateMiddle(item?.clickedAddress, 19)
                : item}
              ?
            </Heading>
          </Flex>
        </ModalBody>

        <ModalFooter alignItems="center" justifyContent="center">
          <Flex w="230px" alignItems="center" justifyContent="space-between">
            <Button
              variant="blank"
              onClick={onClose}
              w="110px"
              h="32px"
              borderRadius="16px"
            >
              Cancel
            </Button>
            <Button
              variant="error"
              onClick={() => removeHandler(item)}
              w="110px"
              h="32px"
              borderRadius="16px"
            >
              {btnStatus === "loading" ? (
                <Spinner size="xs" color="white" />
              ) : (
                "Remove"
              )}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
