import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import ConnectedServers from "./ConnectedServers";
import DiscordSelectEngagement from "./DiscordSelectEngagement";
import DiscordLogin from "./DiscordLogin";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  Image,
  Avatar,
  Divider,
} from "@chakra-ui/react";
import { MdArrowBackIos } from "react-icons/md";
import { FaDiscord } from "react-icons/fa";
import { getExistingConfig } from './Discord.config'

const TEST_TOKEN = "jmmZt3WOnM8JBneAjPLMrI1TH4c5Pg";

const Discord = () => {
  const location = useLocation();
  const { projectId } = useParams();
  const [code, setCode] = useState();
  const [guildTest, setGuildTest] = useState();
  const [currentPage, setCurrentPage] = useState("");
  const [currentServer, setCurrentServer] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [serversWhereAdmin, setServersWhereAdmin] = useState();
  const [display, setDisplay] = useState(true); // PREVENTS USERS FROM SEEING DURING TESTING
  const [isLoading, setIsLoading] = useState(true);
  const [mounted, setMounted] = useState(false);

  //const authUrl = "https://discord.com/api/oauth2/authorize?client_id=1002632424469057587&redirect_uri=https%3A%2F%2Fdashboard.huddln.io%2Fdiscord-auth%2F&response_type=code&scope=identify%20guilds%20connections%20guilds.join";
  // const authRedirect = "https://dashboard.huddln.io/discord-auth/"
  const authUrl = "https://discord.com/api/oauth2/authorize?client_id=1002632424469057587&redirect_uri=http%3A%2F%2Flocalhost:3000%2Fdiscord-auth%2F&response_type=code&scope=identify%20guilds%20connections%20guilds.join";
  const authRedirect = "http://localhost:3000/discord-auth/"


  useEffect(() => {
    const getExistingConfigHandler = async () => {
      setIsLoading(true);
      const data = await getExistingConfig(projectId)

      if (data.configData) {
        setCurrentServer(data);
        setCurrentPage("config");
      }
      setIsLoading(false);
    }

    getExistingConfigHandler();
  }, []);

  const handleConnect = () => {
    const authPopup = window.open(authUrl, "", "width=320,height=800");

    const handleMessage = (event) => {
      if (event.data.target === "discord") {
        authPopup.close();

        getUserToken(event.data.code);
        setCurrentPage("server");
      }
    };

    window.addEventListener("message", handleMessage);
  };

  const getUserToken = async (authCode) => {
    if (mounted) {
      return;
    }

    const urlencoded = new URLSearchParams();
    urlencoded.append("client_id", "1002632424469057587");
    urlencoded.append("client_secret", "DXswlfedhZnRV0QfR-Ikkbh5sZQWD9Gm");
    urlencoded.append("grant_type", "authorization_code");
    urlencoded.append("code", authCode);
    urlencoded.append("redirect_uri", authRedirect);

    try {
      const response = await fetch("https://discord.com/api/oauth2/token", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: urlencoded,
      });

      if (response.ok) {
        const data = await response.json();
        const token = data.access_token
        const userResult = await fetch("https://discord.com/api/users/@me", {
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        let user = await userResult.json();
        const guildResult = await fetch(
          "https://discord.com/api/users/@me/guilds",
          {
            headers: {
              authorization: `Bearer ${token}`,
            },
          }
        );

        let guilds = await guildResult.json();
        setCurrentUser(user);
        setGuildTest(guilds);

        let adminServers = [];

        for (let i = 0; i < guilds.length; i++) {
          if ((parseInt(guilds[i].permissions_new) & 0x20) == 0x20) {
            adminServers.push(guilds[i]);
          }
        }

        setServersWhereAdmin(adminServers);

        setMounted(true);
      } else {
        console.log("Failed to get user token");
      }
    } catch (error) {
      console.log(error);
    }
  };


  const getUser = async (token) => {
    const userResult = await fetch("https://discord.com/api/users/@me", {
      headers: {
        authorization: `Bearer ${token}`,
      },
    });

    let user = await userResult.json();
    setCurrentUser(user);
    console.log(user);
  };

  const getGuilds = async (token) => {
    await new Promise(r => setTimeout(r, 3000));

    const guildResult = await fetch(
      "https://discord.com/api/users/@me/guilds",
      {
        headers: {
          authorization: `Bearer ${token}`,
        },
      }
    );

    let guilds = await guildResult.json();

    setGuildTest(guilds);

    let adminServers = [];

    for (let i = 0; i < guilds.length; i++) {
      if ((parseInt(guilds[i].permissions_new) & 0x20) == 0x20) {
        adminServers.push(guilds[i]);
      }
    }

    setServersWhereAdmin(adminServers);
  };

  const goBackHandler = () => {
    setCurrentPage("initial");
  };
  if (isLoading) {
    return <LoadComponent />;
  } else if (currentPage === "server" || currentPage === "config") {
    return (
      <PageWrapper>
        <Flex flexDirection="column" alignItems="center" w="100%" h="100%">
          <Flex width="100%" alignItems="center">
            <Flex alignItems="center">
              <Icon
                as={MdArrowBackIos}
                color="white"
                onClick={goBackHandler}
                cursor="pointer"
              />
              <Icon
                as={FaDiscord}
                color="white"
                fontSize="30px"
                mr="10px"
                ml="10px"
              />
              <Text color="white" fontSize="18px">
                Discord
              </Text>
            </Flex>
          </Flex>
          <Divider
            orientation="horizontal"
            size="xl"
            mt="15px"
            mb="15px"
            w="100%"
          />
          {currentPage === "server" && (
            <ConnectedServers
              serversWhereAdmin={serversWhereAdmin}
              setCurrentServer={setCurrentServer}
              setCurrentPage={setCurrentPage}
            />
          )}
          {currentPage === "config" && (
            <DiscordSelectEngagement
              currentServer={currentServer}
            // currentUser={currentUser}
            // setCurrentPage={setCurrentPage}
            />
          )}
        </Flex>
      </PageWrapper>
    );
  } else {
    return (
      <DiscordLogin
        handleConnect={handleConnect}
        setCurrentPage={setCurrentPage}
      />
    );
  }
};

export default Discord;
