import React, { useState } from "react";
import { Input, Flex, Text, Icon, Switch } from "@chakra-ui/react";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { BsChatDots } from "react-icons/bs";
import Locks from "../../components/Locks/Locks";

export default function TwitterCard({
  item,
  actionArray,
  scoreArray,
  twitterBotObj,
  setStateHandler,
}) {
  const [locked, setLocked] = useState(false);

  const { name, displayName } = item;
  const state = twitterBotObj[name];

  return (
    <CustomCard>
      <Flex
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        mb="10px"
      >
        <Flex flexDirection="row" alignItems="center">
          <Icon
            as={BsChatDots}
            color="white"
            fontSize="25px"
            fontWeight={600}
            mr="10px"
          />
          <Text
            color={locked ? "#aaa" : "white"}
            fontWeight={600}
            fontSize="18px"
          >
            {displayName}
          </Text>
        </Flex>
        <Locks state={locked} setState={setLocked} />
      </Flex>
      <Flex flexDirection="column" pos="relative">
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="#A7A7A7" fontSize="14px">
            Reward XP anytime a user adds a message in this server:
          </Text>
          <Switch
            colorScheme="toggle"
            size="md"
            disabled={locked}
            onChange={(e) => setStateHandler(name, "shared", e.target.checked)}
            isChecked={state.shared}
          />
        </Flex>
        {state.shared && (
          <Flex
            alignItems="center"
            justifyContent="space-between"
            w="100%"
            maxW="420px"
          >
            <Flex flexDir="column">
              {actionArray.length > 0 && (
                <Flex flexDirection="column" mt="20px">
                  <Text color="#a7a7a7" fontSize="12px" mb="6px">
                    Action
                  </Text>
                  <CustomDropdown
                    placeholder="Action"
                    type="action"
                    array={actionArray}
                    state={state.action}
                    setState={(action) =>
                      setStateHandler(name, "action", action)
                    }
                    locked={locked}
                  />
                </Flex>
              )}
            </Flex>
            <Flex flexDir="column">
              {scoreArray.length > 0 && (
                <Flex flexDirection="column" mt="20px">
                  <Text color="#a7a7a7" fontSize="12px" mb="6px">
                    Score Type
                  </Text>
                  <CustomDropdown
                    placeholder="Score"
                    array={scoreArray}
                    state={state.scoreType}
                    setState={(action) =>
                      setStateHandler(name, "scoreType", action)
                    }
                    locked={locked}
                  />
                </Flex>
              )}
            </Flex>
            <Flex flexDir="column">
              <Flex flexDirection="column" mt="20px">
                <Text color="#a7a7a7" fontSize="12px" mb="6px">
                  Daily Limit
                </Text>
                <Input
                  borderRadius="10px"
                  textColor={locked ? "#aaa" : "white"}
                  maxLength="250"
                  width="80px"
                  max={100}
                  bg="#292929"
                  border="2px solid #383838"
                  p={2}
                  pt={0}
                  pb={0}
                  type="number"
                  onChange={(e) =>
                    setStateHandler(name, "dailyLimit", e.target.value, name)
                  }
                  value={state.dailyLimit}
                />
              </Flex>
            </Flex>
          </Flex>
        )}
        {locked && (
          <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
        )}
      </Flex>
    </CustomCard>
  );
}
