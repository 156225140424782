import React, { useState } from "react";
import "./CustomTooltip.css";
import {
  Icon,
  Select,
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Switch,
  TabPanel,
  Tabs,
  Tab,
  TabPanels,
  TabList,
  TabsList,
  useToast,
  TabIndicator,
  Spinner,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Box,
  ModalCloseButton,
  Divider,
  Tooltip,
  Center,
} from "@chakra-ui/react";
import { BsFillInfoCircleFill } from "react-icons/bs";

const CustomTooltip = ({ txt, w, t }) => {
  const [show, setShow] = useState(false);

  return (
    <Flex mt="2px" position="relative" ml="4px">
      <Icon
        as={BsFillInfoCircleFill}
        color="#a7a7a7"
        fontSize="10px"
        cursor="pointer"
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      />
      {show && (
        <Center
          position="absolute"
          top={`${t}px`}
          left={-w / 3}
          bg="rgba(0,0,0,.95)"
          w={`${w}px`}
          p="4px 8px"
          borderRadius="10px"
        >
          <Text color="#A7A7A7" fontWeight={600} fontSize="12px">
            {txt}
          </Text>
        </Center>
      )}
    </Flex>
  );
};

// const CustomTooltip = (props) => {
//   let timeout;
//   const [active, setActive] = useState(false);

//   const showTip = () => {
//     timeout = setTimeout(() => {
//       setActive(true);
//     }, props.delay || 400);
//   };

//   const hideTip = () => {
//     clearInterval(timeout);
//     setActive(false);
//   };

//   return (
//     <div
//       className="Tooltip-Wrapper"
//       // When to show the tooltip
//       onMouseEnter={showTip}
//       onMouseLeave={hideTip}
//     >
//       {/* Wrapping */}
//       {props.children}
//       {active && (
//         <div className={`Tooltip-Tip ${props.direction || "top"}`}>
//           {/* Content */}
//           {props.content}
//         </div>
//       )}
//     </div>
//   );
// };

export default CustomTooltip;
