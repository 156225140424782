import React, { useContext, useEffect, useState } from "react";

import { Web3Context } from "../contexts/Web3Context";

import { Flex, Heading, Text, Stack, Button, Image } from "@chakra-ui/react";

import connectbg from "../connectbg.png";
import logoxp from "../assets/imgs/xp_proto.png";

const NotConnected = ({ connectWallet, loaded }) => {
  // const { connectWallet } = useContext(Web3Context);

  useEffect(() => {
    if (loaded) {
      window.location.reload(false);
    }
  }, [loaded]);

  return (
    <Flex
      flexDir="column"
      bgColor="#0E0E0E"
      bgImage={connectbg}
      bgSize="cover"
      h="100vh"
      alignItems="center"
      justifyContent="center"
    >
      <Image src={logoxp} w="18vw" objectFit="contain" mb="1vh"></Image>
  
      {/*
      <Heading
        size="2xl"
        bgGradient="linear-gradient(90deg, #B5E5FF 2.35%, #C2CEFF 31.77%, #E1A9F6 68.99%, #F9ABE7 102.02%)"
        bgClip="text"
        mb={20}
      >
        To use Huddln XP protocol
      </Heading>
      */}

      <Flex mb={20}>
        <Flex flexDir="column" alignItems="center">
          <Text color="rgba(255, 255, 255, 0.7)" fontSize="xl" fontWeight={600}>
            XP Earned
          </Text>
          <Text color="white" fontSize="xl" fontWeight={800}>
            1M+
          </Text>
        </Flex>
        <Flex flexDir="column" ml={20} alignItems="center">
          <Text color="rgba(255, 255, 255, 0.7)" fontSize="xl" fontWeight={600}>
            Transactions
          </Text>
          <Text color="white" fontSize="xl" fontWeight={800}>
            500,000+
          </Text>
        </Flex>
      </Flex>

      <Button
        onClick={() => {
          connectWallet();
        }}
        variant="purple"
        minH="60px"
        color="#121212"
        alignSelf="center"
        fontSize="20px"
        fontWeight={800}
        borderRadius={45}
        px={120}
        py={1}
        mb={5}
        _hover={{
          color: "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
        }}
      >
        Connect Wallet
      </Button>
    </Flex>
  );
};

export default NotConnected;
