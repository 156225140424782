import { Flex } from "@chakra-ui/react";

export default function LoadComponent() {
  return (
    <Flex
      flexDir="column"
      h="100%"
      w="100%"
      justifyContent="center"
      alignItems="center"
    >
      {/* <Heading color="white" fontSize="xl" mb="2vh">
      Loading claims...
    </Heading> */}
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Flex>
  );
}
