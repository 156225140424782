import { Flex, Text } from "@chakra-ui/react";
import React from "react";

const MARGIN = "20px";
const BOARDER = "2px solid #575757";
const BG = "#363636";
const BOARDER_RADIUS = "10px";
const WIDTH = "60px";
const HEIGHT = "24px";

export default function Pagination({
  total,
  perPage,
  setPage,
  currentPage,
  enableEnd,
  onEndReach,
}) {
  let pages = [];
  const pageAmount = Math.ceil(total / perPage);

  for (let index = 0; index < pageAmount; index++) {
    pages.push(index + 1);
  }

  const paginate = (d) => {
    if (d === "more" && currentPage < pageAmount) {
      setPage(currentPage + 1);
    } else if (d === "less" && currentPage > 1) {
      setPage(currentPage - 1);
    }
  };

  const setPageHandler = (page) => {
    if (page > pageAmount - 3 && enableEnd === true) {
      setPage(page);
      onEndReach();
    } else {
      setPage(page);
    }
  };

  let min = 0;
  let max = 5;

  if (currentPage > 2) {
    min = currentPage - 3;
    max = currentPage + 2;
  }

  let showPages = pages.slice(min, max);

  if (showPages.length > 0) {
    return (
      <Flex
        flexDirection="row"
        width="90%"
        justifyContent="center"
        alignItems="center"
        mb="10px"
      >
        <Flex
          justifyContent="center"
          alignItems="center"
          bg={BG}
          border={BOARDER}
          width={WIDTH}
          height={HEIGHT}
          borderRadius={BOARDER_RADIUS}
        >
          <Text
            fontWeight={600}
            color="#fff"
            fontSize="10px"
            onClick={() => paginate("less")}
            _hover={{
              cursor: "pointer",
            }}
          >
            {"Previous"}
          </Text>
        </Flex>
        <Flex
          bg="#2E2E2E"
          borderRadius={showPages.length > 1 ? BOARDER_RADIUS : "5px"}
          height={HEIGHT}
          ml={MARGIN}
          mr={MARGIN}
        >
          {showPages.map((page) => (
            <Flex
              justifyContent="center"
              alignItems="center"
              height={HEIGHT}
              width={HEIGHT}
              key={page}
            >
              <Text
                key={page}
                fontWeight={currentPage === page ? 800 : 600}
                fontSize={currentPage === page ? "14px" : "10px"}
                onClick={() => setPageHandler(page)}
                color={currentPage === page ? "white" : "#727272"}
                _hover={{
                  cursor: "pointer",
                }}
              >
                {page}
              </Text>
            </Flex>
          ))}
        </Flex>
        <Flex
          justifyContent="center"
          alignItems="center"
          bg={BG}
          border={BOARDER}
          width={WIDTH}
          height={HEIGHT}
          borderRadius={BOARDER_RADIUS}
        >
          <Text
            fontWeight={600}
            color="#fff"
            fontSize="10px"
            onClick={() => paginate("more")}
            _hover={{
              cursor: "pointer",
            }}
          >
            {"Next"}
          </Text>
        </Flex>
      </Flex>
    );
  } else {
    return <></>;
  }
}
