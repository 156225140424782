import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Web3Context } from "../contexts/Web3Context";
import { MainContext } from "../contexts/MainContext";

import { Flex, Heading, Text, Stack, Button } from "@chakra-ui/react";

import { useQuery, gql, useApolloClient } from "@apollo/client";

const ProjectList = () => {
  const { web3, accounts, contract } = useContext(Web3Context);
  const { updateCurrentPage } = useContext(MainContext);

  const client = useApolloClient();

  const navigate = useNavigate();

  const [user, setUser] = useState();

  const GET_PROJECTS = gql`
    query getProjects($user: String!) {
      projects(where: { owners_contains: [$user] }) {
        id
        name
        owners
        totalUsers
        projectTotalScore
      }
    }
  `;

  const { data: projectData } = useQuery(GET_PROJECTS, {
    skip: !user,
    variables: { user },
  });

  useEffect(() => {
    setUser(accounts[0].toLowerCase());
  }, []);

  useEffect(() => {
    updateCurrentPage("dashboard");
  }, [updateCurrentPage]);

  return (
    <Flex h="100%" w="100%" pt="10vh" justifyContent="center">
      <Flex w="80%" flexDir="column">
        <Heading size="xl" color="white" mt={15} mb={10}>
          Your Projects
        </Heading>
        <Flex flexDir="column" mt={10} mb={20} w="100%">
          {projectData &&
            projectData.projects.map((project, index) => (
              <Flex
                key={project.name + index}
                bgColor="#1F1F1F"
                borderRadius={30}
                h="fit-content"
                w="90%"
                minW="600px"
                justifyContent="space-between"
                px={10}
                pt={5}
                pb={3}
                mb={5}
                onClick={() => navigate("/project/" + project.id)}
                cursor="pointer"
                _hover={{
                  background: "#1E1E1E",
                }}
              >
                <Flex flexDir="column" maxW="70%">
                  <Text color="white" mb="2vh" fontWeight={600} fontSize="3xl">
                    {project.name}
                  </Text>
                  <Text color="#C4C4C4">Project ID</Text>
                  <Text
                    color="#C4C4C4"
                    mb="3vh"
                    fontSize="md"
                    fontWeight={600}
                    noOfLines={1}
                    textOverflow="ellipsis"
                  >
                    {project.id}
                  </Text>
                </Flex>
                <Flex alignItems="center" h="100%">
                  <Flex flexDir="column" mr="2vh">
                    <Text color="#C4C4C4">Total XP</Text>
                    <Text
                      color="white"
                      mb="2vh"
                      fontWeight={600}
                      fontSize="3xl"
                    >
                      {parseInt(project.projectTotalScore).toLocaleString(
                        "en-US"
                      )}
                    </Text>
                  </Flex>
                  <Flex flexDir="column">
                    <Text color="#C4C4C4">Total Users</Text>
                    <Text
                      color="white"
                      mb="2vh"
                      fontWeight={600}
                      fontSize="3xl"
                    >
                      {parseInt(project.totalUsers).toLocaleString("en-US")}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            ))}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ProjectList;
