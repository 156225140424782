import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import { projectRealtimeDb } from "../firebase/config";

import Lottie from "lottie-react";
import bonfire from "../assets/lottie/bonfire.json";

import {
  Button,
  Flex,
  Heading,
  Text,
  Avatar,
  Image,
  Spacer,
  Icon,
} from "@chakra-ui/react";

import { BiRefresh } from "react-icons/bi";

import ClaimRow from "../components/ClaimRow";

const ClaimActivity = () => {
  const { projectId } = useParams();

  const [projectClaims, setProjectClaims] = useState();
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState(0);
  const [noClaims, setNoClaims] = useState();

  const rerender = () => {
    setUpdate(Math.random());
  };

  useEffect(() => {
    const getProjectClaims = async () => {
      setLoading(true);

      fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getXPClaimsByProject",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ xpProjectId: projectId }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((res) => {
          let claims = Object.entries(res.claimsData).map(([name, obj]) => ({
            name,
            ...obj,
          }));

          setProjectClaims(claims);

          setLoading(false);
        })
        .catch((err) => {
          console.log("No claims!");
          setLoading(false);
          setNoClaims(true);
          setProjectClaims([]);
        });
    };

    getProjectClaims();
  }, [update]);

  if (loading) {
    return (
      <Flex
        flexDir="column"
        h="100%"
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="white" fontSize="xl" mb="2vh">
          Loading claims...
        </Heading>

        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Flex>
    );
  }

  if (noClaims) {
    return (
      <Flex
        flexDir="column"
        h="100%"
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Lottie
          animationData={bonfire}
          loop={true}
          style={{ height: 300, position: "absolute" }}
        />
        <Heading color="#A7A7A7" fontSize="xl" mt="220px">
          No claims found for this project.
        </Heading>
      </Flex>
    );
  }

  return (
    <Flex flexDir="column" ml="4vw" w="100%">
      <Flex mt="8vh" mb="8vh">
        <Heading color="white" fontSize="2xl">
          Claim Activity
        </Heading>
        <Spacer />
        <Icon
          as={BiRefresh}
          onClick={() => rerender()}
          cursor="pointer"
          color="white"
          fontSize="2xl"
          mr="8vw"
        />
      </Flex>
      {projectClaims &&
        projectClaims.map((claim, index) => (
          <ClaimRow claim={claim} key={index} />
        ))}
    </Flex>
  );
};

export default ClaimActivity;
