import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  Box,
  Link,
  Divider,
  useMediaQuery,
  Input,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import loading from "../../assets/lottie/loader.json";
import complete from "../../assets/lottie/complete.json";
import tryAgain from "../../assets/lottie/warning.json";
import error from "../../assets/imgs/error.png";
import { CgClose } from "react-icons/cg";
import { capitalizeFirstLetter } from "../../func/myFunc.ts";
import missingImg from "../../assets/imgs/missingToken.png";
import { changeNetwork, getErc20, getWalletToken, web3Divide } from "./Erc20.config";
import { MdCircle } from "react-icons/md";
import { Web3Context } from "../../contexts/Web3Context";
import {ethers} from 'ethers';
export default function TokenRedemptionModal({
  modalOpen,
  setModalOpen,
  handleRedemption,
  image,
  modalStatus,
  txHash,
  modalMode,
  modalTitle,
  modalDescription,
  nftTitle,
  nftAddress,
  nftDescription,
  nftTokenType,
  actionPoints,
  actionName,
  btnText,
  onCloseExtra,
  network,
  xpAmount,
  tokenAmount,
  redemptionItem = {},
  completeText = false,
}) {
  const {
    web3,
    accounts,
  } = useContext(Web3Context);

  const [isLargerThan800] = useMediaQuery("(min-width: 900px)");
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [dynamicHref, setDynamicHref] = useState("/example");
  const [walletBalance, setWalletBalance] = useState(0);

  useEffect(() => {
    const getWalletTokenHandler = async () => {
      try {
        const data = await getWalletToken(
          accounts[0].toLowerCase(),
          network.chainId
        );
        const item = data.find(
          (item) => item.contract_address === redemptionItem?.contractAddress
        );
        const { balance, contract_decimals } = item
      const wB =   ethers.formatUnits(balance.toString(), parseInt(contract_decimals)).toString();
      //  const wB = web3Divide(web3, balance, contract_decimals);
        setWalletBalance(wB)
      } catch (error) {
        console.log(error)
      }
    }

    getWalletTokenHandler()
    setDynamicHref(`${network?.explorerUri}/tx/${txHash}`);
  }, [txHash, network]);

  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
      <ModalOverlay bg="rgba(0,0,0,.75)" />
      <ModalContent
        bg="#121212"
        w="95%"
        maxWidth={isLargerThan800 ? "1120px" : "520px"}
        borderRadius="25px"
        overflow="hidden"
      >
        <Flex
          flexDirection={isLargerThan800 ? "row" : "column"}
          alignItems="center"
        >
          <Flex
            flex="1"
            justifyContent="center"
            position="relative"
            w="500px"
            h="500px"
            bg="#040404"
          >
            <Flex
              height="500px"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                boxSize="250px"
                objectFit="contain"
                src={image}
                fallbackSrc={missingImg}
                alt=""
              />
            </Flex>
            {modalStatus === "start" ? (
              <></>
            ) : (
              <Flex
                flex="1"
                zIndex={100}
                position="absolute"
                width="100%"
                height="100%"
                bg="rgba(0,0,0,.5)"
                top="0"
                left="0"
                alignItems="center"
                justifyContent="center"
              >
                {modalStatus === "loading" ? (
                  <Lottie
                    animationData={loading}
                    loop={true}
                    style={{ height: 200 }}
                  />
                ) : modalStatus === "complete" ? (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Lottie
                      animationData={complete}
                      loop={false}
                      style={{ height: 200 }}
                    />
                    {txHash && (
                      <Link
                        // href={`https://polygonscan.com/tx/${txHash}`}
                        href={dynamicHref}
                        isExternal
                        bg=" #C6C7FF"
                        color="#121212"
                        _hover={{
                          cursor: "pointer",
                          transform: "scale(1.05)",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "ease-in-out",
                        }}
                        shadow="lg"
                        fontWeight={700}
                        borderRadius={45}
                        padding="5px"
                        pl="20px"
                        pr="20px"
                        maxWidth="360px"
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Text
                            fontSize="16px"
                            // mt={3}
                            width="90px"
                            fontWeight="700"
                            isTruncated
                          >
                            {`TxHash:`}
                          </Text>
                          <Text
                            fontSize="16px"
                            // mt={3}
                            width="100%"
                            fontWeight="500"
                            isTruncated
                          >
                            {txHash}
                          </Text>
                        </Flex>
                      </Link>
                    )}
                  </Flex>
                ) : (
                  <Lottie
                    animationData={tryAgain}
                    loop={false}
                    style={{ height: 200 }}
                  />
                )}
              </Flex>
            )}
          </Flex>
          <Flex flex="1" w="500px" h="500px" justifyContent="center">
            <Box
              // p="10px"
              // pl="20px"
              // pr="20px"
              flex="1"
              alignItems="center"
              flexDirection="column"
            >
              <ModalHeader>
                <Flex
                  width="100%"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    color="white"
                    fontSize="24px"
                    mt={2}
                    fontWeight="extrabold"
                  >
                    {modalTitle}
                  </Text>
                  <Text color="#A7A7A7" fontSize="14px">
                    {modalDescription}
                  </Text>
                </Flex>
              </ModalHeader>
              <Flex
                onClick={() => {
                  setModalOpen(false);
                  onCloseExtra && onCloseExtra();
                }}
                position="absolute"
                boxSize="30px"
                borderRadius="50px"
                top="15px"
                right="20px"
                justifyContent="center"
                alignItems="center"
                _hover={{
                  cursor: "pointer",
                  shadow: "dark-lg",
                  transform: "scale(1.1)",
                  borderColor: "black",
                  backgroundColor: "rgba(50,50,50,.5)",
                  transitionDuration: "0.2s",
                  transitionTimingFunction: "ease-in-out",
                }}
              >
                <CgClose color="white" size="20px" />
              </Flex>
              <ModalBody
                flex="1"
                h="290px"
                mt="10px"
                mb="10px"
                justifyContent="center"
                alignItems="center"
              >
                {completeText ? (
                  <Flex
                    bg="#2C2C2C"
                    borderRadius="10px"
                    flexDirection="column"
                    p="20px"
                    pt="20px"
                    pb="20px"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Heading color="white" textAlign="center" fontSize="20px">
                      Don't forget to deposit!
                    </Heading>
                  </Flex>
                ) : (
                  <Flex
                    bg="#2C2C2C"
                    borderRadius="10px"
                    flexDirection="column"
                    p="20px"
                    pt="20px"
                    pb="20px"
                    height="100%"
                  >
                    <Flex flexDirection="column">
                      <Flex flexDirection="column">
                        <Flex flexDirection="row">
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Token Name
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              pl="2px"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              color={nftTitle ? "white" : "#ccc"}
                            >
                              {nftTitle ? nftTitle : "Not Available"}
                            </Heading>
                          </Flex>
                          <Flex
                            flexDirection="column"
                            flex="1"
                            overflow="hidden"
                            maxW="60%"
                          >
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Token Address
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              maxW="200px"
                            >
                              {nftAddress}
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider
                        orientation="horizontal"
                        borderWidth="2px"
                        borderRadius="1px"
                        borderColor="#474747"
                        mt={1}
                        mb={1}
                      />
                      <Flex flexDirection="column">
                        <Flex flexDirection="row">
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Symbol
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              pl="2px"
                              color={nftTitle ? "white" : "#ccc"}
                            >
                              {nftDescription
                                ? nftDescription
                                : "Not Available"}
                            </Heading>
                          </Flex>
                          <Flex flex="1" pl="5px">
                            {modalMode !== "create" && (
                              <Flex flexDirection="column" flex="1">
                                <Text
                                  color="#A7A7A7"
                                  fontWeight={500}
                                  fontSize="12px"
                                >
                                  State
                                </Text>
                                <Heading
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="white"
                                  pl="2px"
                                >
                                  <Flex alignItems="center">
                                    <MdCircle
                                      color={
                                        redemptionItem?.redemptionStatus ===
                                          "Active"
                                          ? "#20E070"
                                          : "#E24E51"
                                      }
                                      size="16px"
                                    />
                                    <Text
                                      ml="4px"
                                      fontSize="16px"
                                      fontWeight={700}
                                      color="white"
                                      maxW={isLargerThan800 ? "380px" : "320px"}
                                      isTruncated
                                    >
                                      {redemptionItem?.redemptionStatus}
                                    </Text>
                                  </Flex>
                                </Heading>
                              </Flex>
                            )}
                            <Flex flexDirection="column" flex="1">
                              <Text
                                color="#A7A7A7"
                                fontWeight={500}
                                fontSize="12px"
                              >
                                Network
                              </Text>
                              <Heading
                                fontSize="16px"
                                fontWeight={700}
                                color="white"
                                pl="2px"
                              >
                                <Flex alignItems="center">
                                  {network?.img && (
                                    <Image
                                      // key={src}
                                      // bg="green"
                                      mr="10px"
                                      boxSize="15px"
                                      objectFit="contain"
                                      src={network?.img}
                                      // fallbackSrc={missingImg}
                                      alt=""
                                    />
                                  )}
                                  <Text
                                    fontSize="16px"
                                    fontWeight={700}
                                    color="white"
                                    maxW={isLargerThan800 ? "380px" : "320px"}
                                    isTruncated
                                  >
                                    {network?.name}
                                  </Text>
                                </Flex>
                              </Heading>
                            </Flex>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex flex="1" />
                    <Flex flexDirection="column">
                      <Flex flexDirection="column">
                        <Flex flexDirection="row">
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              {modalMode === "withdraw"
                                ? "Pool Balance"
                                : "Your Token Balance"}
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {actionPoints ? actionPoints : 0}
                            </Heading>
                          </Flex>
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Token Type
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {nftTokenType}
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Divider
                        orientation="horizontal"
                        borderWidth="2px"
                        borderRadius="1px"
                        borderColor="#474747"
                        mt={1}
                        mb={1}
                      />
                      <Flex flexDirection="column">
                        <Flex flexDirection="row">
                          {modalMode === "deposit" ||
                            modalMode === "withdraw" ? (
                            <Flex flexDirection="column" flex="1">
                              <Text
                                color="#A7A7A7"
                                fontWeight={500}
                                fontSize="12px"
                              >
                                {capitalizeFirstLetter(modalMode)} Amount
                              </Text>
                              <Input
                                type="number"
                                bg="#292929"
                                border="2px solid #383838"
                                textColor="white"
                                width="140px"
                                borderRadius="10px"
                                value={redeemAmount}
                                onChange={(e) =>
                                  setRedeemAmount(e.target.value)
                                }
                                onWheel={(e) => e.target.blur()}
                              />
                              {modalMode === "deposit" && (
                                <Text color="#ccc" fontSize="10px" mt="2px">
                                  Balance {walletBalance}
                                </Text>
                              )}
                            </Flex>
                          ) : (
                            <Flex flexDirection="column" flex="1">
                              <Text
                                color="#A7A7A7"
                                fontWeight={500}
                                fontSize="12px"
                              >
                                Deduct from action
                              </Text>
                              <Heading
                                fontSize="16px"
                                fontWeight={700}
                                color="white"
                                pl="2px"
                              >
                                {actionName}
                              </Heading>
                            </Flex>
                          )}

                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Exchange Rate
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {`${xpAmount} XP : ${tokenAmount} ${nftDescription ? nftDescription : ""
                                }`}
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                )}
                {/* {modalMode === "remove" && ( */}
                <Flex
                  flexDirection="row"
                  justifyContent="center"
                  mt="15px"
                  w="100%"
                >
                  <Text color="white" fontWeight={500} fontSize="12px">
                    Transfer remaining tokens from pool before deleting
                    redemption
                  </Text>
                </Flex>
                {/* )} */}
              </ModalBody>
              <ModalFooter alignItems="center" justifyContent="center">
                <Flex
                  width="240px"
                  pt="20px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={
                    modalStatus !== "complete" ? "space-between" : "center"
                  }
                >
                  <Flex
                    // variant="error"
                    variant="blank"
                    bg="transparent"
                    _hover={{
                      cursor: "pointer",
                      bg: "#212121",
                    }}
                    onClick={() => setModalOpen(false)}
                    fontWeight={700}
                    borderRadius={45}
                    justifyContent="center"
                    alignItems="center"
                    // pl="10px"
                    // pr="10px"
                    width="100px"
                    height="30px"
                    fontSize="14px"
                    disabled={
                      modalStatus === "loading" || modalStatus === "complete"
                    }
                  >
                    <Heading fontSize="14px" fontWeight={700} color="white">
                      {modalStatus === "complete" ? "Close" : "Cancel"}
                    </Heading>
                  </Flex>
                  {modalStatus !== "complete" && (
                    <Button
                      // variant="error"
                      variant="blank"
                      bg="transparent"
                      _hover={{
                        cursor: "pointer",
                        bg: modalMode === "remove" ? "#E24E51" : "#A654F4",
                        color: modalMode === "remove" ? "white" : "white",
                      }}
                      onClick={() => handleRedemption(modalMode, redeemAmount)}
                      borderRadius={45}
                      justifyContent="center"
                      alignItems="center"
                      // pl="10px"
                      // pr="10px"
                      width="100px"
                      height="30px"
                      fontSize="14px"
                      fontWeight={700}
                      disabled={modalStatus === "loading"}
                    >
                      {btnText}
                    </Button>
                  )}
                </Flex>
              </ModalFooter>
            </Box>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
