import React from "react";
import { Input, Flex, Text, Button } from "@chakra-ui/react";
import NFTWatcherTabContract from "./NFTWatcherTabContract";

export default function NFTWatcherTab({
  networkData,
  updateObjectByNetwork,
  updateContractByIndex,
  updateContractEventHandlerByIndex,
  blockLocked,
  updateContractByNetwork,
  openRemoveContractHandler,
}) {
  return (
    <Flex flexDirection="column">
      <Text color="#A7A7A7" fontSize="14px" mb="15px">
        Provide the block number of the earliest deployed contract from each
        chain
      </Text>
      <Input
        borderRadius="10px"
        textColor={blockLocked ? "#aaa" : "white"}
        maxLength="250"
        width="100%"
        max={100}
        bg="#292929"
        border="2px solid #383838"
        mb="20px"
        p={2}
        pt={0}
        pb={0}
        type="number"
        onChange={(e) => {
          updateObjectByNetwork(
            networkData.network,
            "startBlock",
            parseInt(e.target.value.replace(/[^0-9]/g, ""))
          );
        }}
        value={networkData.startBlock}
      />
      {networkData?.contracts.length > 0 &&
        networkData?.contracts.map((item, index) => (
          <NFTWatcherTabContract
            key={index}
            index={index}
            network={networkData.network}
            contractItem={item}
            updateObjectByNetwork={updateObjectByNetwork}
            updateContractByIndex={updateContractByIndex}
            updateContractEventHandlerByIndex={
              updateContractEventHandlerByIndex
            }
            openRemoveContractHandler={openRemoveContractHandler}
            blockLocked={blockLocked}
          />
        ))}
      <Button
        onClick={() => updateContractByNetwork(networkData.network, "add")}
        h="30px"
        w="110px"
        fontSize="14px"
        fontWeight={700}
        variant="purple"
        disabled={blockLocked}
      >
        Add Contract
      </Button>
    </Flex>
  );
}
