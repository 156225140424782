import React, { useState, useEffect, useCallback } from "react";
import {
  Flex,
  Text,
  Tabs,
  TabList,
  Tab,
  Image,
  TabIndicator,
  TabPanel,
  TabPanels,
  Spinner,
} from "@chakra-ui/react";
import CustomCard from "../../components/CustomCard/CustomCard";
import ProgressBar from "@ramonak/react-progress-bar";
import { projectFirestore, projectRealtimeDb } from "../../firebase/config";

const NftTab = ({ item, latestBlockLoading }) => {
  let { blockNumber, mode, completed, contractLength, latestBlockNumber } =
    item;

  return (
    <Flex flexDirection="column" w="100%">
      <Flex justifyContent="space-between" mb="10px">
        <Flex flexDirection="row" alignItems="center">
          <Text fontWeight={600} fontSize="18px" color="white" mr="10px">
            {contractLength}
          </Text>
          <Text color="#A7A7A7" fontWeight={600} fontSize="18px">
            Contracts
          </Text>
        </Flex>
        <Flex flexDirection="row" alignItems="center">
          <Flex
            flexDirection="row"
            alignItems="center"
            p="15px"
            pb="5px"
            pt="5px"
            bg="#363636"
            borderRadius="60px"
          >
            {mode === 1 && <div className="pulse yellow" />}
            {mode === 2 && <div className="pulse green" />}
            {mode === 3 && <div className="pulse-anim" />}
            {/* {mode === 4 && <div className="pulse yellow" />} */}
            {(mode === 5 || mode === -1) && <div className="pulse red" />}
            <Text
              fontWeight={600}
              fontSize="18px"
              m={0}
              p={0}
              ml="10px"
              color="white"
              style={{ userSelect: "none" }}
            >
              {mode === 1 && "PENDING"}
              {mode === 2 && "ACTIVE"}
              {mode === 3 && "SCANNING"}
              {mode === 4 && "PAUSED"}
              {mode === 4 && "FORCE SCAN"}
              {mode === 5 && "ERROR"}
              {mode === -1 && "STALLED"}
            </Text>
          </Flex>
        </Flex>
      </Flex>
      <Flex flexDir="column">
        {mode !== 1 && (
          <Flex justifyContent="flex-end" mb={2} alignItems="center">
            <Text color="#A7A7A7" fontWeight={600} fontSize="16px" mr="6px">
              at block {blockNumber} of
            </Text>
            {latestBlockLoading === true ? (
              <Spinner size="xs" color="gray.500" />
            ) : (
              <Text color="#A7A7A7" fontWeight={600}>
                {latestBlockNumber}
              </Text>
            )}
          </Flex>
        )}
        <ProgressBar
          // animateOnRender={true}
          completed={completed}
          maxCompleted={100}
          bgColor="#A654F4"
          baseBgColor="#393939"
          labelColor="#171718"
          labelSize="14px"
          customLabelStyles={{
            marginRight: 5,
            userSelect: "none",
          }}
        />
      </Flex>
    </Flex>
  );
};

const NFTStatus = ({
  projectId,
  yamlsArray,
  supportedNetworks,
  latestBlockLoading,
}) => {
  const [configArray, setConfigArray] = useState([]);

  useEffect(() => {
    const onDataChange = (snapshot) => {
      const data = snapshot.val();

      if (data) {
        const result = Object.entries(data).map(([name, value]) => {
          const networkIsSupported = supportedNetworks.find(
            (item) => item.name === name
          );
          if (!networkIsSupported) {
            return {};
          }

          const { blockNumber, heartbeat, mode } = value;
          const { startBlock, contracts } = yamlsArray?.find(
            (item) => item?.network === name
          );

          const { latestBlockNumber, logoUri, displayName } =
            networkIsSupported;

          const percent = Math.floor(
            ((blockNumber - startBlock) / (latestBlockNumber - startBlock)) *
              100
          );

          const completed = percent >= 0 && mode === 2 ? percent : 0;

          return {
            name,
            blockNumber,
            heartbeat,
            mode,
            completed,
            contractLength: contracts.length,
            logoUri,
            displayName,
            latestBlockNumber,
          };
        });

        setConfigArray(result);
      }
    };

    const databaseRef = projectRealtimeDb.ref(
      `xp/nft_watcher_configs/${projectId}/status`
    );
    databaseRef.on("value", onDataChange);

    return () => {
      databaseRef.off("value", onDataChange);
    };
  }, [projectId, yamlsArray, supportedNetworks]);

  return (
    <>
      {configArray.length > 0 && (
        <CustomCard>
          <Tabs w="100%" position="relative">
            <Flex overflowX="auto" position="relative" pb="10px">
              <TabList
                position="relative"
                variant="unstyled"
                whiteSpace="nowrap"
              >
                {configArray?.length > 0 &&
                  configArray.map((item, index) => (
                    <Tab
                      key={index}
                      alignItems="center"
                      fontWeight={600}
                      fontSize="14px"
                      _selected={{ color: "#A654F4" }}
                    >
                      <Image src={item.logoUri} boxSize="14px" mr="5px" />
                      <Text>{item.displayName}</Text>
                    </Tab>
                  ))}
              </TabList>
              <TabIndicator
                // mt="-1.5px"
                bottom="10px"
                height="2px"
                bg="#A654F4"
                borderRadius="1px"
                zIndex={100}
              />
            </Flex>
            <TabPanels position="relative" mb="-10px">
              {configArray?.length > 0 &&
                configArray.map((item, index) => (
                  <TabPanel key={item.name + index}>
                    <NftTab
                      item={item}
                      latestBlockLoading={latestBlockLoading}
                    />
                  </TabPanel>
                ))}
            </TabPanels>
          </Tabs>
        </CustomCard>
      )}
    </>
  );
};

export default NFTStatus;
