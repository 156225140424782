import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Icon,
  Select,
  Input,
  Button,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import EmojiPicker, {
  EmojiStyle,
  SkinTones,
  Theme,
  Categories,
  EmojiClickData,
  Emoji,
  SuggestionMode,
  SkinTonePickerLocation,
} from "emoji-picker-react";
import OutsideAlerter from "../../components/OutsideAlerter/OutsideAlerter";
import { HiMinusSm } from "react-icons/hi";

const LevelsScoreTypeForm = ({
  typeIndex,
  scoreType,
  updateScoreTypeFields,
  existingConfig,
  scoreTypesLocked,
  setMarginBottom,
}) => {
  const [fields, setFields] = useState([]);

  const [emojiVisibilities, setEmojiVisibilities] = React.useState(() =>
    fields.map((x) => false)
  );

  const addField = () => {
    let newField = {
      icon: "",
      levelName: "",
      min: 0,
      max: 0,
    };

    setFields([...fields, newField]);
  };

  const removeField = (index) => {
    let formData = [...fields];
    formData.splice(index, 1);
    setFields(formData);
  };

  const handleChange = (e, index, fieldName) => {
    let val = e.target.value;
    let formData = [...fields];
    let isNum = /^\d+$/.test(val);

    if (fieldName === "min" || fieldName === "max") {
      if (isNum) {
        formData[index][`${fieldName}`] = parseInt(val);
        setFields(formData);
      } else if (val === "") {
        formData[index][`${fieldName}`] = val;
        setFields(formData);
      }
    } else {
      formData[index][`${fieldName}`] = val;
      setFields(formData);
    }
  };

  const handelEmoji = (emojiData, index) => {
    const val = emojiData.unified;
    let formData = [...fields];
    formData[index].icon = val;
    setFields(formData);
  };

  useEffect(() => {
    if (fields) {
      updateScoreTypeFields(scoreType, fields);
    }
  }, [fields]);

  useEffect(() => {
    if (existingConfig) {
      const keys = Object.keys(existingConfig);
      let existingFields = [];

      for (let i = 0; i < keys.length; i++) {
        if (keys[i] === scoreType) {
          let scoreTypeObj = existingConfig[`${keys[i]}`];
          const fieldKeys = Object.keys(scoreTypeObj);

          for (let j = 0; j < fieldKeys.length; j++) {
            let fieldObj = scoreTypeObj[`${fieldKeys[j]}`];
            if (fieldObj) existingFields.push(fieldObj);
          }

          setFields(existingFields);
          updateScoreTypeFields(scoreType, existingFields);

          break;
        }
      }
    }
  }, [existingConfig]);

  const toggleVisibilityInner = (index, boolean) => {
    const newVisibilities = [...emojiVisibilities];
    newVisibilities[index] = boolean;
    setEmojiVisibilities(newVisibilities);
  };

  const toggleVisibilityHandler = (index, boolean) => {
    const time = 150;
    if (index + 1 === fields.length && boolean) {
      setMarginBottom("200px");
      setTimeout(() => {
        toggleVisibilityInner(index, boolean);
      }, time);
    } else if (index + 2 === fields.length && boolean) {
      setMarginBottom("140px");
      setTimeout(() => {
        toggleVisibilityInner(index, boolean);
      }, time);
    } else if (index + 3 === fields.length && boolean) {
      setMarginBottom("80px");
      setTimeout(() => {
        toggleVisibilityInner(index, boolean);
      }, time);
    } else {
      setMarginBottom(0);
      toggleVisibilityInner(index, boolean);
    }
  };

  return (
    <Flex flexDir="column">
      {fields &&
        fields.map((level, index) => (
          <Flex width="100%" mb="10px" alignItems="flex-end" key={index}>
            <Flex flexDir="column" mr="15px" position="relative">
              {/* <ChildrenBlur
                onBlur={() => toggleVisibilityHandler(index, false)}
              > */}
              <Text color="#fff" fontSize="12px" mb="6px">
                Icon
              </Text>
              {/* <Input
                  borderRadius="10px"
                  textColor={scoreTypesLocked ? "#aaa" : "white"}
                  maxLength="250"
                  width="40px"
                  max={100}
                  pl={2}
                  pr={2}
                  bg="#292929"
                  border="2px solid #383838"
                  // onChange={(e) => handleChange(e, index, "icon")}
                  value={fields[index].icon}
                  onFocus={(e) => toggleVisibilityHandler(index, true)}
                  // onBlur={(e) => toggleVisibilityHandler(index, false)}
                /> */}
              <Flex
                onClick={(e) => toggleVisibilityHandler(index, true)}
                bg="#292929"
                boxSize="40px"
                border="2px solid #383838"
                justifyContent="center"
                alignItems="center"
                borderRadius="10px"
                cursor="pointer"
                position="relative"
                overflow="hidden"
              >
                {fields[index].icon ? (
                  <Emoji
                    unified={fields[index].icon}
                    emojiStyle={EmojiStyle.APPLE}
                    size={22}
                    lazyLoad={true}
                  />
                ) : (
                  <></>
                )}
                {scoreTypesLocked && (
                  <Flex
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bg="rgba(33,33,33,.5)"
                  />
                )}
              </Flex>
              {fields[index].icon && (
                <Flex
                  cursor="pointer"
                  position="absolute"
                  borderRadius="50%"
                  boxSize="14px"
                  right="-3px"
                  bottom="28px"
                  bg={scoreTypesLocked ? "#aaa" : "white"}
                  justifyContent="center"
                  alignItems="center"
                  onClick={() => handelEmoji("", index)}
                >
                  <Icon as={HiMinusSm} fontSize="14px" color="black" />
                </Flex>
              )}
              {emojiVisibilities[index] === true && (
                <OutsideAlerter
                  onBlur={() => toggleVisibilityHandler(index, false)}
                  // onBlur={() => toggleVisibilityHandler(index, false)}
                >
                  <Flex position="absolute" top="64px" zIndex={100}>
                    <EmojiPicker
                      onEmojiClick={(e) => handelEmoji(e, index)}
                      theme={Theme.AUTO}
                      searchDisabled={true}
                      autoFocusSearch={false}
                      height={350}
                      previewConfig={{
                        showPreview: false,
                      }}
                      categories={[
                        {
                          name: "Smiles & Emotions",
                          category: Categories.SMILEYS_PEOPLE,
                        },
                        {
                          name: "Animals",
                          category: Categories.ANIMALS_NATURE,
                        },
                        {
                          name: "Food & Drinks",
                          category: Categories.FOOD_DRINK,
                        },
                        {
                          name: "Travel",
                          category: Categories.TRAVEL_PLACES,
                        },
                        {
                          name: "Activities",
                          category: Categories.ACTIVITIES,
                        },
                        {
                          name: "Objects",
                          category: Categories.OBJECTS,
                        },
                        {
                          name: "Symbols",
                          category: Categories.SYMBOLS,
                        },
                        {
                          name: "Flags",
                          category: Categories.FLAGS,
                        },
                      ]}
                    />
                  </Flex>
                </OutsideAlerter>
              )}
              {/* </ChildrenBlur> */}
            </Flex>
            <Flex flexDir="column" width="100%" maxW="300px" mr="15px">
              <Text color="#fff" fontSize="12px" mb="6px">
                Level Name
              </Text>
              <Input
                borderRadius="10px"
                textColor={scoreTypesLocked ? "#aaa" : "white"}
                maxLength="250"
                width="100%"
                max={100}
                pl={2}
                pr={2}
                bg="#292929"
                border="2px solid #383838"
                onChange={(e) => handleChange(e, index, "levelName")}
                value={fields[index].levelName}
              />
            </Flex>
            <Flex flexDir="column" mr="15px">
              <Text color="#fff" fontSize="12px" mb="6px">
                Minimum
              </Text>
              <Input
                borderRadius="10px"
                textColor={scoreTypesLocked ? "#aaa" : "white"}
                width="100%"
                maxW="100px"
                max={100}
                pl={2}
                pr={2}
                bg="#292929"
                border="2px solid #383838"
                onChange={(e) => handleChange(e, index, "min")}
                value={fields[index].min}
              />
            </Flex>
            <Flex flexDir="column" mr="15px">
              <Text color="#fff" fontSize="12px" mb="6px">
                Maximum
              </Text>
              <Input
                borderRadius="10px"
                textColor={scoreTypesLocked ? "#aaa" : "white"}
                width="100%"
                maxW="100px"
                max={100}
                pl={2}
                pr={2}
                bg="#292929"
                border="2px solid #383838"
                onChange={(e) => handleChange(e, index, "max")}
                value={fields[index].max}
              />
            </Flex>
            {index > 0 || true ? (
              <Button
                onClick={() => removeField(index)}
                h="30px"
                w="90px"
                borderRadius="15px"
                mb="5px"
                fontSize="14px"
                fontWeight={700}
                variant="error"
                disabled={scoreTypesLocked}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
          </Flex>
        ))}
      <Button
        h="30px"
        w="90px"
        mt="10px"
        fontSize="14px"
        fontWeight={700}
        variant="purple"
        onClick={addField}
        disabled={scoreTypesLocked}
      >
        Add field
      </Button>
    </Flex>
  );
};

export default LevelsScoreTypeForm;
