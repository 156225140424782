import React, { useState, useEffect } from "react";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  Image,
  Avatar,
  Divider,
} from "@chakra-ui/react";
import { MdArrowBackIos } from "react-icons/md";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import { FaDiscord } from "react-icons/fa";
import CustomCard from "../../components/CustomCard/CustomCard";
import Lottie from "lottie-react";
import bonfire from "../../assets/lottie/bonfire.json";
import { getGuildInfo } from './Discord.config.js'

let exampleServers = [
  {
    id: "1002651136949502054",
    icon: "73da9aa85b6dede27b8831a48a1e3282",
    name: "Best Server",
  },
  {
    id: "1002651136949502054",
    icon: "73da9aa85b6dede27b8831a48a1e3282",
    name: "Best Server",
  },
  {
    id: "1002651136949502054",
    icon: "73da9aa85b6dede27b8831a48a1e3282",
    name: "Best Server",
  },
  {
    id: "1002651136949502054",
    icon: "73da9aa85b6dede27b8831a48a1e3282",
    name: "Best Server",
  },
  {
    id: "1002651136949502054",
    icon: "73da9aa85b6dede27b8831a48a1e3282",
    name: "Best Server",
  },
];

const GuildItem = ({ server, setCurrentServer, setCurrentPage }) => {
  useEffect(() => {
    const getGuildInfoHandler = async () => {
      const data = await getGuildInfo(server.id);
      // setGuildInfo(data);
      console.log('data')
      console.log(data)
    };
    getGuildInfoHandler()
  }, [])

  console.log(server)
  return (
    <Flex
      flexDir="column"
      alignItems="center"
      cursor="pointer"
      mx={8}
      onClick={() => {
        console.log('current server: ', server)
        setCurrentServer({
          configData: {
            "actionMap": {
            },
            guildId: server.id,
            version: "1.0.0"
          }
        });
        setCurrentPage("config");
      }}
      _hover={{
        transform: "scale(1.05)",
        filter: "brightness(1.1)",
      }}
    >
      <Avatar
        src={`https://cdn.discordapp.com/icons/${server.id}/${server.icon}.png`}
        borderColor="white"
        borderWidth={6}
        width="120px"
        height="120px"
      />
      <Text color="white" fontWeight={700} fontSize="18px" mt={5}>
        {server.name}
      </Text>
    </Flex>
  )
}

const ConnectedServers = ({
  serversWhereAdmin,
  setCurrentServer,
  setCurrentPage,
}) => {
  return (
    <CustomCard>
      <Text color="white" fontWeight={700} fontSize="18px" mb="20px">
        Add Server
      </Text>
      <Flex justifyContent="center" flexWrap="wrap" rowGap="40px">
        {serversWhereAdmin?.length > 0 ? (
          serversWhereAdmin.map((server, index) => (
            <GuildItem key={server.id} server={server} setCurrentServer={setCurrentServer} setCurrentPage={setCurrentPage} />
          ))
        ) : (
          <Flex
            flexDirection="column"
            width="90%"
            maxW="400px"
            alignItems="center"
            textAlign="center"
            mt="-20px"
          >
            <Lottie
              animationData={bonfire}
              loop={true}
              style={{ height: 300, width: 300 }}
            />
            <Text
              color="white"
              fontWeight={600}
              fontSize="20px"
              mt="-20px"
              mb="10px"
            >
              Not Available
            </Text>
            <Text color="#a7a7a7" fontSize="16px" fontWeight={500}>
              Your account must have permission to add a Bot to a Discord server
            </Text>
          </Flex>
        )}
      </Flex>
    </CustomCard>
  );
};

export default ConnectedServers;
