import React, { useState, useContext, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import {
  Button,
  Flex,
  Heading,
  Icon,
  Text,
  Image,
  Select,
  Input,
  Switch,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Avatar,
} from "@chakra-ui/react";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import CryptoJS from "crypto-js";
import { useQuery, gql } from "@apollo/client";
import { BsChatDots } from "react-icons/bs";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import { GoChevronDown } from "react-icons/go";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";
import { projectRealtimeDb } from "../../firebase/config";
import { getExistingConfig, getGuildInfo, isUnixTimeLessThan20Minutes } from './Discord.config'

const DiscordSelectEngagement = ({ currentServer }) => {
  // currentServer = { id: "1002651136949502054", icon: "2" };
  // currentUser = { id: "1", avatar: "2" };
  const { web3, accounts, contract } = useContext(Web3Context);

  const { projectId } = useParams();

  const { isOpen, onClose, onOpen } = useDisclosure();
  const [messageLocked, setMessageLocked] = useState(false);
  const [anyMessage, setAnyMessage] = useState(false);
  const [specificMessage, setSpecificMessage] = useState();
  const [reaction, setReaction] = useState();

  const [anyMessageScoreType, setAnyMessageScoreType] = useState();
  const [anyMessageAction, setAnyMessageAction] = useState();
  const [anyMessageLimit, setAnyMessageLimit] = useState();
  const [reactionScoreType, setReactionScoreType] = useState();
  const [reactionAction, setReactionAction] = useState();
  const [reactionLimit, setReactionLimit] = useState();
  const [guildId, setGuildId] = useState(null);
  const [guildInfo, setGuildInfo] = useState({});
  const [spefMessageFields, setSpefMessageFields] = useState([
    {
      scoreType: "",
      action: "",
      dailyLimit: 0,
      message: "",
    },
  ]);

  const [projectActions, setProjectActions] = useState();
  const [projectScoreTypes, setProjectScoreTypes] = useState();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [modalText, setModalText] = useState("");
  const [mode, setMode] = useState(-1);

  const anyMessageLimitRef = useRef();
  const reactionLimitRef = useRef();
  const spefMessageLimitRef = useRef([]);
  const spefMessageTextRef = useRef([]);

  const { data: projectData } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });
  useEffect(() => {
    const getGuildInfoHandler = async () => {
      const data = await getGuildInfo(guildId);

      setGuildInfo(data);
    };
    if (guildId)
      getGuildInfoHandler();

  }, [guildId])
  useEffect(() => {
    const getExistingConfigHandler = async () => {
      setIsLoading(true);
      let actionMap

      if (!currentServer?.configData?.actionMap || Object.keys(currentServer.configData.actionMap).length === 0) {

        const data = await getExistingConfig(projectId)

        actionMap = data.configData.actionMap;
      } else {


        actionMap = currentServer.configData.actionMap;
      }
      //Guild info
      if (currentServer?.configData?.guildId) {
        setGuildId(currentServer?.configData?.guildId);
      }
      if (currentServer?.configData?.status?.heartBeat) {
        const heartBeat = isUnixTimeLessThan20Minutes(currentServer?.configData?.status?.heartBeat)
        heartBeat && setMode(2)
      }


      if (actionMap.messageCreate) {
        setAnyMessage(true);
        setAnyMessageAction(actionMap.messageCreate.action);
        setAnyMessageScoreType(actionMap.messageCreate.scoreType);
        setAnyMessageLimit(actionMap.messageCreate.dailyLimit);
      }
      if (actionMap.messageMatch) {
        setSpecificMessage(true);
        const keys = Object.keys(actionMap.messageMatch);
        let fields = [];
        for (let i = 0; i < keys.length; i++) {
          let obj = actionMap.messageMatch[`${keys[i]}`];
          obj["message"] = keys[i];
          fields.push(obj);
        }
        setSpefMessageFields(fields);
      }
      if (actionMap.messageReactionAdd) {
        setReaction(true);
        setReactionAction(actionMap.messageReactionAdd.action);
        setReactionScoreType(actionMap.messageReactionAdd.scoreType);
        setReactionLimit(actionMap.messageReactionAdd.dailyLimit);
      }

      setIsLoading(false);
    };

    getExistingConfigHandler();
  }, []);

  useEffect(() => {
    if (projectData) {
      let actions = projectData.projects[0].actions.map(
        (action) => action.name
      );

      setProjectActions(actions);
      setProjectScoreTypes(projectData.projects[0].scoreTypes);
    }
  }, [projectData]);

  const addField = () => {
    let newField = {
      scoreType: "",
      action: "",
      dailyLimit: 0,
      message: "",
    };

    setSpefMessageFields([...spefMessageFields, newField]);
  };

  const removeField = (index) => {
    let formData = [...spefMessageFields];
    formData.splice(index, 1);
    setSpefMessageFields(formData);
  };

  const handleChange = (e, index, fieldName) => {
    let formData = [...spefMessageFields];
    formData[index][`${fieldName}`] = e.target.value;
    setSpefMessageFields(formData);
  };

  const handleSelectChange = (e, index, fieldName) => {
    let formData = [...spefMessageFields];
    formData[index][fieldName] = e;
    setSpefMessageFields(formData);
  };

  useEffect(() => {
    spefMessageLimitRef.current = spefMessageLimitRef.current.slice(
      0,
      spefMessageFields.length
    );
    spefMessageTextRef.current = spefMessageTextRef.current.slice(
      0,
      spefMessageFields.length
    );
  }, [spefMessageFields.length]);

  const checkDailyLimit = (value, ref) => {
    if (
      !(typeof value === "string") ||
      isNaN(value) ||
      isNaN(parseFloat(value)) ||
      value < 0 ||
      value > 50
    ) {
      ref.current.style.borderWidth = "2px";
      ref.current.style.borderColor = "#EB0028";
    } else {
      ref.current.style.borderWidth = 0;
    }
  };

  const checkMessage = (value, ref) => {
    if (
      !(typeof value === "string") ||
      value.length <= 2 ||
      value.length > 50
    ) {
      ref.current.style.borderWidth = "2px";
      ref.current.style.borderColor = "#EB0028";
    } else {
      ref.current.style.borderWidth = 0;
    }
  };

  const compareConfigs = (a, b) => {
    if (Object.keys(a).length !== Object.keys(b).length) {
      return false;
    }

    for (let key in a) {
      if (!b.hasOwnProperty(key)) {
        return false;
      }

      if (JSON.stringify(a[key]) !== JSON.stringify(b[key])) {
        return false;
      }
    }
    //no difference
    return true;
  };

  const handleClick = async () => {
    try {
      let actionMapObj = {};

      if (anyMessage && anyMessageAction && anyMessageScoreType) {
        let limit = 0;
        if (anyMessageLimit) limit = anyMessageLimit;
        actionMapObj["messageCreate"] = {
          action: anyMessageAction,
          dailyLimit: limit,
          scoreType: anyMessageScoreType,
        };
      }

      if (specificMessage) {
        let spefMessageCases = [];

        for (let i = 0; i < spefMessageFields.length; i++) {
          if (
            spefMessageFields[i].action &&
            spefMessageFields[i].scoreType &&
            spefMessageFields[i].message
          ) {
            if (!spefMessageFields[i].dailyLimit)
              spefMessageFields[i].dailyLimit = 0;
            spefMessageCases.push(spefMessageFields[i]);
          }
        }

        let messageObj = {};

        for (let i = 0; i < spefMessageCases.length; i++) {
          messageObj[`${spefMessageCases[i].message}`] = {
            action: spefMessageCases[i].action,
            dailyLimit: spefMessageCases[i].dailyLimit,
            scoreType: spefMessageCases[i].scoreType,
          };
        }

        actionMapObj["messageMatch"] = messageObj;
      }

      if (reaction && reactionAction && reactionScoreType) {
        let limit = 0;
        if (reactionLimit) limit = reactionLimit;
        actionMapObj["messageReactionAdd"] = {
          action: reactionAction,
          dailyLimit: limit,
          scoreType: reactionScoreType,
        };
      }

      const noChanges = compareConfigs(
        currentServer.configData.actionMap,
        actionMapObj
      );

      if (noChanges) {
        setModalText("Error while updating configuration");
        throw new Error("Please makes changes to submit");
      }

      const requestData = {
        configData: {
          xpProjectId: projectId,
          guildId: currentServer.configData.guildId,
          actionMap: actionMapObj,
          version: "1.0.0",
        },
      };

      const toSign =
        "0x" + CryptoJS.SHA256(JSON.stringify(requestData)).toString();

      const signature = await web3.eth.personal.sign(
        toSign,
        window.ethereum.selectedAddress
      );

      setIsSubmitting(true);

      let requestBody = {
        configData: {
          xpProjectId: projectId,
          guildId: currentServer.configData.guildId,
          actionMap: actionMapObj,
          version: "1.0.0",
        },
        verification: {
          signature,
          message: toSign,
          address: accounts[0],
        },
      };

      const configResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/createDiscordConfig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const response = await configResponse.json();

      if (response.status === "created") {
        setModalText("Configuration updated");
      } else {
        setModalText("Error while updating configuration");
      }
    } catch (error) {
      console.log(error);
      setModalText(error.message);
    }

    onOpen();

    setIsSubmitting(false);
  };

  if (isLoading) {
    return (
      <Flex
        flexDir="column"
        h="100%"
        w="100%"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="white" fontSize="xl" mb="2vh">
          Loading configuration...
        </Heading>

        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Flex>
    );
  }

  return (
    <Flex
      // justifyContent="center"
      w="100%"
      h="100%"
      flexDirection="column"
    >
      <CustomCard>
        <Flex
          flexDirection="row"
        >
          <Flex flexDirection="row" >
            {guildInfo &&
              <Avatar
                size="md"
                borderWidth="4px"
                borderColor="white"
                src={`https://cdn.discordapp.com/icons/${guildId}/${guildInfo.icon}.png`}
              />
            }
          </Flex>
          <Flex flex='1' flexDir='column' ml='20px'>
            {guildInfo &&
              <Flex>
                <Heading color="white" fontWeight={700} fontSize='20px' mb='20px'>
                  {guildInfo.name}
                </Heading>
                <CustomTooltip
                  txt="Saving Config will override previous server config"
                  w={300}
                  t={-30}
                />
              </Flex>
            }
            {guildInfo &&
              <Flex >
                <Text color="white" fontWeight={600} paddingRight={'10px'} fontSize='18px'>
                  {guildInfo.approximateMemberCount}
                </Text>
                <Text color="#a7a7a7" fontWeight={600} fontSize='18px'>
                  Members
                </Text>
              </Flex>
            }
          </Flex>
          <Flex alignItems='flex-start'>
            <Flex height={'50%'} justifyContent={'center'} alignItems={'center'}>
              <Text
                fontWeight={600}
                fontSize="18px"
                m={0}
                p={0}
                mr="10px"
                color="white"
              >
                Status
              </Text>
            </Flex>

            <Flex
              flexDirection="row"
              alignItems="center"
              p="15px"
              pb="5px"
              pt="5px"
              bg="#363636"
              borderRadius="60px"
            >

              {mode === 1 && <div className="pulse yellow" />}
              {mode === 2 && <div className="pulse green" />}
              {mode === 3 && <div className="pulse-anim" />}
              {/* {mode === 4 && <div className="pulse yellow" />} */}
              {(mode === 5 || mode === -1) && <div className="pulse red" />}
              <Text
                fontWeight={600}
                fontSize="18px"
                m={0}
                p={0}
                ml="10px"
                color="white"
                style={{ userSelect: "none" }}
              >
                {mode === 1 && "PENDING"}
                {mode === 2 && "ACTIVE"}
                {mode === 3 && "SCANNING"}
                {mode === 4 && "PAUSED"}
                {mode === 4 && "FORCE SCAN"}
                {mode === 5 && "ERROR"}
                {mode === -1 && "STALLED"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </CustomCard>
      <Flex mb="20px" width="100%" justifyContent="center" alignItems="center">
        <Text color="white" fontWeight={600} fontSize="18px">
          Configuration
        </Text>
      </Flex>
      <CustomCard>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb="10px"
        >
          <Flex flexDirection="row" alignItems="center">
            <Icon
              as={BsChatDots}
              color="white"
              fontSize="25px"
              fontWeight={600}
              mr="10px"
            />
            <Text color="white" fontWeight={600} fontSize="18px">
              Messages
            </Text>
          </Flex>
          <Locks state={messageLocked} setState={setMessageLocked} />
        </Flex>
        <Text color="#A7A7A7" fontSize="14px" mb="10px">
          Reward XP anytime a user adds a message in this server:
        </Text>
        <Flex flexDir="column" pl="20px" position="relative">
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            mb="10px"
          >
            <Text color="white" fontSize="14px">
              Any message
            </Text>
            <Switch
              colorScheme="toggle"
              size="md"
              onChange={(e) => setAnyMessage(e.target.checked)}
              isChecked={anyMessage}
            />
          </Flex>
          {anyMessage && (
            <Flex mb="20px">
              <Flex flexDir="column">
                <Text color="white" opacity="0.4" fontSize="xs">
                  Score Type
                </Text>
                <Flex
                  flex="1"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  id={messageLocked ? "snap-select-locked" : "snap-select"}
                >
                  {projectScoreTypes && (
                    <CustomDropdown
                      locked={messageLocked}
                      placeholder="Score Type"
                      array={projectScoreTypes}
                      state={anyMessageScoreType}
                      setState={setAnyMessageScoreType}
                    />
                    // <Select
                    //   textColor="white"
                    //   w={180}
                    //   background="#363636"
                    //   border="none"
                    //   borderRadius="16px"
                    //   iconColor="white"
                    //   onChange={(e) => setAnyMessageScoreType(e.target.value)}
                    //   value={anyMessageScoreType}
                    //   placeholder="Score Type"
                    // >
                    //   {projectScoreTypes.map((type, index) => (
                    //     <option key={index} value={type}>
                    //       {type}
                    //     </option>
                    //   ))}
                    // </Select>
                  )}
                </Flex>
              </Flex>
              <Flex flexDir="column" ml={15}>
                <Text ml={1} mb={1} color="white" opacity="0.4" fontSize="xs">
                  Action
                </Text>
                <Flex
                  flex="1"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  id={messageLocked ? "snap-select-locked" : "snap-select"}
                >
                  {projectActions && (
                    <CustomDropdown
                      locked={messageLocked}
                      placeholder="Action"
                      array={projectActions}
                      state={anyMessageAction}
                      setState={setAnyMessageAction}
                    />
                    // <Select
                    //   textColor="white"
                    //   w={180}
                    //   background="#363636"
                    //   border="none"
                    //   borderRadius="16px"
                    //   iconColor="white"
                    //   onChange={(e) => setAnyMessageAction(e.target.value)}
                    //   value={anyMessageAction}
                    //   placeholder="Action"
                    // >
                    //   {projectActions.map((action, index) => (
                    //     <option key={index} value={action}>
                    //       {action}
                    //     </option>
                    //   ))}
                    // </Select>
                  )}
                </Flex>
              </Flex>
              <Flex flexDir="column" ml={15}>
                <Text ml={1} mb={1} color="white" opacity="0.4" fontSize="xs">
                  Daily Limit
                </Text>
                <Input
                  bg="#292929"
                  border="2px solid #383838"
                  w="90px"
                  ref={anyMessageLimitRef}
                  onChange={(e) => setAnyMessageLimit(e.target.value)}
                  onBlur={() => {
                    checkDailyLimit(anyMessageLimit, anyMessageLimitRef);
                  }}
                  value={anyMessageLimit}
                  _placeholder={{
                    color: "white",
                  }}
                  _hover={{
                    background: "#4d4c4c",
                  }}
                  color={messageLocked ? "#555555" : "white"}
                />
              </Flex>
            </Flex>
          )}
          <Flex w="100%" justifyContent="space-between" alignItems="center">
            <Text color="white" fontSize="14px">
              A specific message
            </Text>
            <Switch
              colorScheme="toggle"
              size="md"
              onChange={(e) => setSpecificMessage(e.target.checked)}
              isChecked={specificMessage}
            />
          </Flex>
          {specificMessage && (
            <>
              {spefMessageFields.map((form, index) => (
                <Flex key={index} flexDir="column" mt="10px">
                  <Flex alignItems="center">
                    <Text color="white" fontWeight={500} fontSize="md">
                      Case #{index + 1}
                    </Text>
                    {index > 0 && (
                      <Button
                        onClick={() => removeField(index)}
                        h="30px"
                        w="90px"
                        ml="15px"
                        variant="gray"
                        fontSize="14px"
                        fontWeight={500}
                        borderRadius={50}
                      >
                        Remove
                      </Button>
                    )}
                  </Flex>
                  <Flex pt="1vh" pl="1vw">
                    <Flex flexDir="column">
                      <Text
                        ml={1}
                        mb={1}
                        color="white"
                        opacity="0.4"
                        fontSize="xs"
                      >
                        Score Type
                      </Text>
                      <Flex
                        flex="1"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        id={
                          messageLocked ? "snap-select-locked" : "snap-select"
                        }
                      >
                        {projectScoreTypes && (
                          <Menu>
                            <MenuButton
                              px={4}
                              py={2}
                              minW="150px"
                              flexDirection="row"
                              transition="all 0.2s"
                              borderRadius="10px"
                              height="40px"
                              bg="#292929"
                              border="2px solid #383838"
                              // borderRadius="md"
                              // borderWidth="1px"
                              // _hover={{ bg: "gray.400" }}
                              // _expanded={{ bg: "blue.400" }}
                              _focus={{ boxShadow: "none" }}
                            >
                              <Flex
                                alignItems="center"
                                color={
                                  messageLocked ||
                                    !spefMessageFields[index].scoreType
                                    ? "#aaa"
                                    : "white"
                                }
                                width="100%"
                                justifyContent="space-between"
                              >
                                {spefMessageFields[index].scoreType
                                  ? spefMessageFields[index].scoreType
                                  : "Score"}
                                <GoChevronDown color="white" size="22px" />
                              </Flex>
                            </MenuButton>
                            <MenuList bg="#212121" color="white" mr="4px">
                              {projectScoreTypes.map((item, i) => {
                                return (
                                  <MenuItem
                                    onClick={() =>
                                      handleSelectChange(
                                        item,
                                        index,
                                        "scoreType"
                                      )
                                    }
                                    _hover={{ bg: "#010101" }}
                                    key={`${item}:${i}`}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                              {/* <MenuItem _hover={{ bg: "#010101" }}>Download</MenuItem>
                      <MenuItem onClick={() => {}} _hover={{ bg: "#010101" }}>
                        Create a Copy
                      </MenuItem> */}
                            </MenuList>
                          </Menu>
                          // <Select
                          //   minW="150px"
                          //   iconColor="white"
                          //   onChange={(e) =>
                          //     handleSelectChange(e, index, "scoreType")
                          //   }
                          //   value={spefMessageFields[index].scoreType}
                          //   required
                          //   _hover={{
                          //     cursor: "pointer",
                          //   }}
                          // >
                          //   {projectScoreTypes.map((type, index) => (
                          //     <option key={index} value={type}>
                          //       {type}
                          //     </option>
                          //   ))}
                          // </Select>
                        )}
                      </Flex>
                    </Flex>
                    <Flex flexDir="column" ml={15}>
                      <Text
                        ml={1}
                        mb={1}
                        color="white"
                        opacity="0.4"
                        fontSize="xs"
                      >
                        Action
                      </Text>
                      <Flex
                        flex="1"
                        justifyContent="flex-end"
                        alignItems="flex-end"
                        id={
                          messageLocked ? "snap-select-locked" : "snap-select"
                        }
                      >
                        {projectActions && (
                          <Menu>
                            <MenuButton
                              px={4}
                              py={2}
                              minW="150px"
                              flexDirection="row"
                              transition="all 0.2s"
                              borderRadius="10px"
                              height="40px"
                              bg="#292929"
                              border="2px solid #383838"
                              // borderRadius="md"
                              // borderWidth="1px"
                              // _hover={{ bg: "gray.400" }}
                              // _expanded={{ bg: "blue.400" }}
                              _focus={{ boxShadow: "none" }}
                            >
                              <Flex
                                alignItems="center"
                                color={
                                  messageLocked ||
                                    !spefMessageFields[index].action
                                    ? "#aaa"
                                    : "white"
                                }
                                width="100%"
                                justifyContent="space-between"
                                mr="4px"
                              >
                                {spefMessageFields[index].action
                                  ? spefMessageFields[index].action
                                  : "Action"}
                                <GoChevronDown color="white" size="22px" />
                              </Flex>
                            </MenuButton>
                            <MenuList bg="#212121" color="white">
                              {projectActions.map((item, i) => {
                                return (
                                  <MenuItem
                                    onClick={() =>
                                      handleSelectChange(item, index, "action")
                                    }
                                    _hover={{ bg: "#010101" }}
                                    key={`${item}:${i}`}
                                  >
                                    {item}
                                  </MenuItem>
                                );
                              })}
                              {/* <MenuItem _hover={{ bg: "#010101" }}>Download</MenuItem>
                               <MenuItem onClick={() => {}} _hover={{ bg: "#010101" }}>
                                 Create a Copy
                               </MenuItem> */}
                            </MenuList>
                          </Menu>
                          // <Select
                          //   minW="150px"
                          //   background="#292929"
                          //   border="2px solid #383838"
                          //   iconColor="white"
                          //   onChange={(e) =>
                          //     handleSelectChange(e, index, "action")
                          //   }
                          //   value={spefMessageFields[index].action}
                          //   required
                          //   _hover={{
                          //     cursor: "pointer",
                          //   }}
                          // >
                          //   {projectActions.map((action, index) => (
                          //     <option key={index} value={action}>
                          //       {action}
                          //     </option>
                          //   ))}
                          // </Select>
                        )}
                      </Flex>
                    </Flex>
                    <Flex flexDir="column" ml={15}>
                      <Text
                        ml={1}
                        mb={1}
                        color="white"
                        opacity="0.4"
                        fontSize="xs"
                      >
                        Daily Limit
                      </Text>
                      <Input
                        bg="#292929"
                        border="2px solid #383838"
                        w="90px"
                        ref={(el) =>
                          (spefMessageLimitRef.current[index] = { current: el })
                        }
                        onChange={(e) => handleChange(e, index, "dailyLimit")}
                        onBlur={() =>
                          checkDailyLimit(
                            spefMessageFields[index].dailyLimit,
                            spefMessageLimitRef.current[index]
                          )
                        }
                        value={spefMessageFields[index].dailyLimit}
                        color={messageLocked ? "#555555" : "white"}
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDir="column" ml={15} mt="1vh">
                    <Text
                      ml={1}
                      mb={1}
                      color="white"
                      opacity="0.4"
                      fontSize="xs"
                    >
                      Message
                    </Text>
                    <Input
                      bg="#292929"
                      border="2px solid #383838"
                      w="280px"
                      ref={(el) =>
                        (spefMessageTextRef.current[index] = { current: el })
                      }
                      onChange={(e) => handleChange(e, index, "message")}
                      onBlur={() =>
                        checkMessage(
                          spefMessageFields[index].message,
                          spefMessageTextRef.current[index]
                        )
                      }
                      value={spefMessageFields[index].message}
                      _placeholder={{
                        color: "white",
                      }}
                      color={messageLocked ? "#555555" : "white"}
                    />
                  </Flex>
                </Flex>
              ))}
              <Button
                h="30px"
                w="90px"
                mt="20px"
                fontSize="14px"
                fontWeight={700}
                variant="purple"
                onClick={addField}
              >
                Add case
              </Button>
            </>
          )}
          {messageLocked && (
            <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
          )}
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb="10px"
        >
          <Flex flexDirection="row" alignItems="center">
            <Icon
              as={BsChatDots}
              color="white"
              fontSize="25px"
              fontWeight={600}
              mr="10px"
            />
            <Text color="white" fontWeight={600} fontSize="18px">
              Reactions
            </Text>
          </Flex>
          <Flex h="25px" w="24px" justifyContent="flex-start"></Flex>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="#A7A7A7" fontSize="14px">
            Reward XP anytime a user adds a reaction to a message in this server:
          </Text>
          <Switch
            colorScheme="toggle"
            size="md"
            onChange={(e) => setReaction(e.target.checked)}
            isChecked={reaction}
          />
        </Flex>
        <Flex flexDir="column" pl="20px" pt="10px">
          {reaction && (
            <Flex>
              <Flex flexDir="column">
                <Text ml={1} mb={1} color="white" opacity="0.4" fontSize="xs">
                  Score Type
                </Text>
                <Flex
                  flex="1"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  id="snap-select"
                >
                  {projectScoreTypes && (
                    <CustomDropdown
                      placeholder="Score Type"
                      array={projectScoreTypes}
                      state={reactionScoreType}
                      setState={setReactionScoreType}
                    />
                    // <Select
                    //   textColor="white"
                    //   w={180}
                    //   background="#363636"
                    //   border="none"
                    //   borderRadius="16px"
                    //   iconColor="white"
                    //   onChange={(e) => setReactionScoreType(e.target.value)}
                    //   value={reactionScoreType}
                    //   placeholder="Score Type"
                    // >
                    //   {projectScoreTypes.map((type, index) => (
                    //     <option key={index} value={type}>
                    //       {type}
                    //     </option>
                    //   ))}
                    // </Select>
                  )}
                </Flex>
              </Flex>
              <Flex flexDir="column" ml={15}>
                <Text ml={1} mb={1} color="white" opacity="0.4" fontSize="xs">
                  Action
                </Text>
                <Flex
                  flex="1"
                  justifyContent="flex-end"
                  alignItems="flex-end"
                  id="snap-select"
                >
                  {projectActions && (
                    <CustomDropdown
                      placeholder="Action"
                      array={projectActions}
                      state={reactionAction}
                      setState={setReactionAction}
                    />
                    // <Select
                    //   textColor="white"
                    //   w={180}
                    //   background="#363636"
                    //   border="none"
                    //   borderRadius="16px"
                    //   iconColor="white"
                    //   onChange={(e) => setReactionAction(e.target.value)}
                    //   value={reactionAction}
                    //   placeholder="Action"
                    // >
                    //   {projectActions.map((action, index) => (
                    //     <option key={index} value={action}>
                    //       {action}
                    //     </option>
                    //   ))}
                    // </Select>
                  )}
                </Flex>
              </Flex>
              <Flex flexDir="column" ml={15}>
                <Text ml={1} mb={1} color="white" opacity="0.4" fontSize="xs">
                  Daily Limit
                </Text>
                <Input
                  bg="#292929"
                  border="2px solid #383838"
                  ref={reactionLimitRef}
                  w="90px"
                  onChange={(e) => setReactionLimit(e.target.value)}
                  onBlur={() =>
                    checkDailyLimit(reactionLimit, reactionLimitRef)
                  }
                  value={reactionLimit}
                  color="white"
                />
              </Flex>
            </Flex>
          )}
        </Flex>
      </CustomCard>
      <CustomCard>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
          mb="10px"
        >
          <Flex flexDirection="row" alignItems="center">
            <Icon
              as={BsChatDots}
              color="white"
              fontSize="25px"
              fontWeight={600}
              mr="10px"
            />
            <Text color="white" fontWeight={600} fontSize="18px">
              Bot Invite
            </Text>
          </Flex>
          <Flex h="25px" w="24px" justifyContent="flex-start"></Flex>
        </Flex>
        <Flex
          flexDirection="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Text color="#A7A7A7" fontSize="14px">
            Click on the link below to add the bot to your server, this only needs to be done once.

          </Text>


        </Flex>
        <Text color="#A654F4" fontSize="16px" alignItems={'center'} justifyContent={'center'} textAlign={'center'} style={{ width: '100%' }} flexDirection={'row'} marginTop={2} >  <a href="https://discord.com/oauth2/authorize?client_id=1002632424469057587&scope=bot&permissions=70867208256">Invite Link</a></Text>

       
      </CustomCard>
      <Flex justifyContent="center" w="100%" mt="20px">
        {isSubmitting ? (
          <Button
            isLoading
            bgGradient="linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)"
            borderRadius={25}
            w="200px"
            variant="purple"
          >
            Save
          </Button>
        ) : (
          <Button
            onClick={handleClick}
            borderRadius={25}
            w="200px"
            variant="purple"
          >
            Save
          </Button>
        )}
      </Flex>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor="#383838" borderColor="white" borderWidth={1}>
          <ModalHeader color="white">{modalText}</ModalHeader>
          <ModalCloseButton color="whiteAlpha.900" />

          <ModalFooter>
            <Button
              bgGradient="linear(to-r, #B5E5FF, #F9ABE7)"
              _active={{
                bgGradient: "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
              }}
              _hover={{
                bgGradient: "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
              }}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default DiscordSelectEngagement;
