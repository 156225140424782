import React, { useState, useEffect, createContext, useCallback } from "react";

export const MainContext = createContext();

export const MainContextProvider = ({ children }) => {
  const [currentProject, setCurrentProject] = useState();
  const [currentPage, setCurrentPage] = useState();

  const updateCurrentPage = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  const updateCurrentProject = useCallback((project) => {
    setCurrentProject(project);
  }, []);

  return (
    <MainContext.Provider
      value={{
        currentProject,
        updateCurrentProject,
        currentPage,
        updateCurrentPage,
      }}
    >
      {children}
    </MainContext.Provider>
  );
};
