import getWeb3 from "../getWeb3";

const changeNetworkToPolygon = async (network) => {
  const web3 = await getWeb3();

  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [
          {
            chainId: web3.utils.toHex(137),
          },
        ],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: 137,
                chainName: "PolygonMainnet",
                rpcUrls: "https://polygon-rpc.com",
                nativeCurrency: {
                  name: "MATIC",
                  symbol: "MATIC",
                  decimals: 18,
                },
              },
            ],
          });
        } catch (addError) {
          console.log(addError);
        }
      }
    }
  } else {
    console.log("Wallet not connected.");
  }
};

export default changeNetworkToPolygon;
