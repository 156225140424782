import "./CreateRedemptionForm.css";
import React, { useEffect, useState, useContext } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Input,
  Button,
  Flex,
  Heading,
  Select,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
  Image,
  Text,
  Box,
  Wrap,
  Checkbox,
  useMediaQuery,
  Skeleton,
} from "@chakra-ui/react";
import { Web3Context } from "../../../contexts/Web3Context";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";
import axios from "axios";
import ERC721 from "../../../abi/ERC721.json";
import ERC1155 from "../../../abi/ERC1155.json";
import Lottie from "lottie-react";
import missingImg from "../../../assets/imgs/missing_img.png";
import bonfire from "../../../assets/lottie/bonfire.json";
import refresh from "../../../assets/lottie/refresh.json";
import DisplayNftType from "../../../components/DisplayNftType/DisplayNftType";
import Pagination from "../../../components/Pagination/Pagination";
import RedemptionModal from "../../../components/RedemptionModal/RedemptionModal";
import CustomFileUpload from "../../../components/CustomFileUpload/CustomFIleUpload";
import CustomCard from "../../../components/CustomCard/CustomCard";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { CgCheckO, CgImage } from "react-icons/cg";
import { GoCheck, GoX } from "react-icons/go";
import { MdRefresh, MdOutlineArrowBack } from "react-icons/md";
import { getMimeType, redeemableIdHandler } from "../../../func/myFunc.ts";
import { GET_PROJECT_DATA } from "../../../GraphQL/queries";
import changeNetworkToPolygon from "../../../func/changeNetwork";

const CARD_WIDTH = "150px";
const PADDING = "24px";
const BOARDER = "2px solid #383838";
const BG = "#212121";

const NftCard = ({ item, onClick, selected }) => {
  const { id, metadata, balance, contract, media } = item;

  const [uri, setUri] = useState(null);
  const [uriType, setUriType] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const u = metadata?.image?.startsWith("ar://")
        ? `https://arweave.net/${metadata?.image?.slice(5)}`
        : metadata?.imageUri?.startsWith("ipfs://")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
        : metadata?.image?.startsWith("Qm")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image}`
        : metadata?.image;

      setUri(u);
      setUriType(media[0]?.format ? media[0]?.format : await getMimeType(u));
      setIsLoaded(true);
    };
    getData();
  }, []);

  return (
    <Box
      _hover={{
        cursor: "pointer",
        shadow: "dark-lg",
        transform: "scale(1.1)",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-in-out",
      }}
      width="150px"
      pb="4px"
      border="2px solid #303030"
      borderRadius="10px"
      overflow="hidden"
      onClick={() => {
        onClick(item);
      }}
    >
      <Box boxSize="150px" position="relative" bg="#141414">
        <Skeleton
          isLoaded={isLoaded}
          height={CARD_WIDTH}
          width={CARD_WIDTH}
          startColor="gray.100"
          endColor="'gray.200"
        >
          {isLoaded && (
            <DisplayNftType
              selected={selected}
              type={uriType}
              src={uri}
              height={CARD_WIDTH}
              width={CARD_WIDTH}
            />
          )}
        </Skeleton>
        {selected && (
          <Flex
            flex="1"
            pos="absolute"
            width={CARD_WIDTH}
            height={CARD_WIDTH}
            bg="rgba(0,0,0,.5)"
            top="0"
            left="0"
            alignItems="center"
            justifyContent="center"
          >
            <CgCheckO color="white" size="40px" />
          </Flex>
        )}
      </Box>
      <Flex flexDirection="column" p={2}>
        <Text
          bgGradient="linear(to-l,  #B5E5FF, #C6C7FF, #E1A9F6)"
          bgClip="text"
          fontSize="16px"
          // mt={3}
          fontWeight="700"
          maxW="80%"
          noOfLines={1}
        >
          {metadata?.name ? metadata?.name : contract.address}
        </Text>
        <Text color="white" fontSize="14px" fontWeight="500">
          {id?.tokenMetadata?.tokenType}
        </Text>
        <Text color="#A7A7A7" fontSize="12px" fontWeight="500">
          Editions {balance}
        </Text>
      </Flex>
    </Box>
  );
};

export default function CreateRedemptionForm({ type, setType }) {
  const { projectId } = useParams();
  const {
    web3,
    accounts,
    contractRedeemablesDeploy1155,
    contractRedeemablesExisting1155,
    contractRedeemablesExisting721,
  } = useContext(Web3Context);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("publish");
  const [modalStatus, setModalStatus] = useState("start");
  //create
  const [createNftTitle, setCreatedNftTitle] = useState("");
  const [createNftDescription, setCreatedNftDescription] = useState("");
  const [createImage, setCreateImage] = useState(null);
  const [showCreateImage, setShowCreateImage] = useState(null);
  const [createNftAmount, setCreateNftAmount] = useState(0);
  const [imageType, setImageType] = useState("");
  const [royaltyAddress, setRoyaltyAddress] = useState("");
  const [royaltyAddressTouch, setRoyaltyAddressTouch] = useState(false);
  const [royaltyPercent, setRoyaltyPercent] = useState(0);
  const [enableRoyalties, setEnableRoyalties] = useState(false);
  const [royaltyAddressPass, setRoyaltyAddressPass] = useState(false);
  const [royaltyPercentPass, setRoyaltyPercentPass] = useState(false);
  const [nftSymbol, setNftSymbol] = useState("");
  const [nftSymbolPass, setNftSymbolPass] = useState(false);

  const [nftAmount, setNftAmount] = useState(0);
  const [image, setImage] = useState(null);
  const [projectActions, setProjectActions] = useState([]);
  const [projectScoreTypes, setProjectScoreTypes] = useState([]);
  const [nftMode, setNftMode] = useState("create");
  const [actionName, setActionName] = useState("");
  const [actionPoints, setActionPoints] = useState(0);
  const [scoreType, setScoreType] = useState();
  const [balance1155, setBalance1155] = useState(0);
  const [txHash, setTxHash] = useState("");
  const [allNfts, setAllNfts] = useState([]);
  const [allNftsLength, setAllNftsLength] = useState(0);
  const [nftTitle, setNftTitle] = useState("");
  const [nftDescription, setNftDescription] = useState("");
  const [nftAddress, setNftAddress] = useState("");
  const [nftId, setNftId] = useState("");
  const [nftTokenType, setNftTokenType] = useState("");
  const [selectedNft, setSelectedNft] = useState({});
  const [balanceAmountError, setBalanceAmountError] = useState("");
  const [isRedeemed, setIsRedeemed] = useState(false);

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  const [pageKey, setPageKey] = useState(null);

  const [isLargerThan800] = useMediaQuery("(min-width: 1280px)");
  const [loop, setLoop] = useState(false);

  const { data: projectData } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    if (projectData) {
      let actions = projectData.projects[0].actions.map((action) => {
        return {
          name: action.name,
          points: action.points,
        };
      });

      setProjectActions(actions);
      setProjectScoreTypes(projectData.projects[0].scoreTypes);
    }
  }, [projectData]);

  useEffect(() => {
    getNFTInfo();
  }, []);

  const getNFTInfo = async (loadMore = false) => {
    //https://ethereum-azrael.arkane.network/0x0790e36f6ba5348f2b26fd43091f5bb9811e7312/tokens
    // "https://api.nftport.xyz/v0/accounts/0xc7E8ac5cAcCBe263e09638c8069C85513e23FFBb?chain=polygon&page_size=50&include=metadata&include=contract_information",
    // &pageKey=MHhkM2Q0MTdmMDAwNzMyNjY0NzIzMDI0MWQyMjdjNDZkZWI2ZDdjODkzOjB4MDE6ZmFsc2U=
    setLoop(true);
    let more = "";
    const pageSize = postPerPage * 5;

    if (loadMore === true) {
      more = `&pageKey=${pageKey}`;
    } else {
      setCurrentPage(1);
    }
    try {
      const response = await fetch(
        `https://polygon-mainnet.g.alchemy.com/nft/v2/83uAJ90PdMxTEMuwg3zIHj9ZG-CXD_rH/getNFTs?owner=${accounts[0]}&withMetadata=true&pageSize=${pageSize}${more}`
      );

      const data = await response.json();

      if (data?.pageKey) {
        setPageKey(data?.pageKey);
      } else {
        setPageKey(null);
      }

      if (loadMore === true && data?.ownedNfts) {
        setAllNfts([...allNfts, ...data?.ownedNfts]);
      } else if (data?.ownedNfts) {
        setAllNftsLength(data?.totalCount);
        setAllNfts(data?.ownedNfts);
      }
    } catch (error) {
      console.log("error: " + error);
    }
    setLoop(false);
  };

  const onImageChange = async (e) => {
    const acceptedImageTypes = [
      "gif",
      "jpeg",
      "png",
      "mp4",
      "mp3",
      "mpeg",
      "mov",
      "video",
    ];

    if (e?.target?.files && e?.target?.files[0]) {
      var files = e?.target?.files;
      var extension = files[0].type;
      console.log(extension);
      if (acceptedImageTypes.some((v) => extension.includes(v))) {
        // console.log(await fileTypeFromFile(e.target.file));
        setImageType(extension);
        setShowCreateImage(URL.createObjectURL(e.target.files[0]));
        setCreateImage(e.target.files[0]);
      } else {
        console.log("not supported");
      }
    }
  };

  const checkPermissionHandler = async () => {
    setModalStatus("start");
    if (nftMode === "create") {
      // console.log(createNftTitle);
      setNftTitle(createNftTitle);
      setNftDescription(createNftDescription);
      setNftAddress(null);
      setNftId(null);
      setNftTokenType(null);
      // setImage(showCreateImage);
      setNftAmount(createNftAmount);
      setModalMode("publish");
      setModalOpen(true);
    } else {
      const { id, metadata, balance, contract, media } = selectedNft;
      if (!selectedNft) return alert("choose an nft");
      const { name, image, imageUri, description } = metadata;

      const uri = image?.startsWith("ar://")
        ? `https://arweave.net/${image?.slice(5)}`
        : imageUri?.startsWith("ipfs://")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
        : image;

      const nft_address = contract?.address;
      const nft_id = id?.tokenId;
      const nft_type = id?.tokenMetadata?.tokenType;

      setNftTitle(name);
      setNftDescription(description);
      setImage(uri);
      setNftAddress(nft_address);
      setNftId(nft_id);
      setNftTokenType(nft_type);
      setNftAmount(balance);
      setImageType(media[0].format ? media[0].format : await getMimeType(uri));

      const lAddress = accounts[0].toLowerCase();

      if (nft_type === "ERC1155") {
        const instance1155 = new web3.eth.Contract(ERC1155.abi, nft_address);
        let isApproved = await instance1155.methods
          .isApprovedForAll(
            lAddress,
            "0x2f4d727e018e3ae60db57cDA4B78670A8C44f7fc"
          )
          .call();

        if (isApproved) {
          setModalMode("publish");
          setModalOpen(true);
        } else {
          console.log("not approved");
          setModalMode("ask");
          setModalOpen(true);
        }
      } else if (nft_type === "ERC721") {
        const instance721 = new web3.eth.Contract(ERC721.abi, nft_address);
        let isApproved = await instance721.methods.getApproved(nft_id).call();

        if (isApproved === "0x10dc5d77097F109677a8aE8cbDCEA63F7934CA9a") {
          setModalMode("publish");
          setModalOpen(true);
        } else {
          console.log("not approved");
          setModalMode("ask");
          setModalOpen(true);
        }
      }
    }
  };

  const givePermissionHandler = async () => {
    setModalStatus("loading");

    const { id, contract } = selectedNft;
    const nft_address = contract?.address;
    const nft_id = id?.tokenId;

    await changeNetworkToPolygon();

    if (id?.tokenMetadata?.tokenType === "ERC1155") {
      const instance1155 = new web3.eth.Contract(ERC1155.abi, nft_address);

      await instance1155.methods
        .setApprovalForAll("0x2f4d727e018e3ae60db57cDA4B78670A8C44f7fc", true)
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .once("transactionHash", (hash) => {
          console.log("hash: " + hash);
          setModalOpen(true);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 1) {
            setModalStatus("complete");
          } else if (confirmationNumber === 5) {
            setModalStatus("start");
            setModalMode("publish");
          }
        })
        .on("error", (error) => {
          setModalStatus("error");
          console.log("error: " + error);
        });
    } else if (id?.tokenMetadata?.tokenType === "ERC721") {
      const instance721 = new web3.eth.Contract(ERC721.abi, nft_address);

      await instance721.methods
        .approve("0x10dc5d77097F109677a8aE8cbDCEA63F7934CA9a", parseInt(nft_id))
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .once("transactionHash", (hash) => {
          console.log("hash: " + hash);
          setModalOpen(true);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 3) {
            setModalStatus("complete");
          } else if (confirmationNumber === 5) {
            setModalStatus("start");
            setModalMode("publish");
          }
        })
        .on("error", (error) => {
          setModalStatus("error");
          console.log("error: " + error);
        });
    }
  };

  const publishHandler = async () => {
    setModalStatus("loading");
    const redeemableId = redeemableIdHandler(projectId);
    setIsRedeemed(false);
    let typeBoolean = type === "earn" ? false : true;

    await changeNetworkToPolygon();

    if (nftMode === "create") {
      if (!createImage) return;
      let formData = new FormData();
      formData.append("file", createImage);

      let imgRes = await fetch(
        `https://api.pinata.cloud/pinning/pinFileToIPFS`,
        {
          method: "POST",
          headers: {
            pinata_api_key: "fa13b7ac59970eb0a48a",
            pinata_secret_api_key:
              "74c1f5a76bd152fe7405d028089ac3ab76271f0b7505dc5a47ecd4bb4374112b",
          },
          body: formData,
        }
      );
      let imgData = await imgRes.json();

      let imageUrl = "https://huddln.mypinata.cloud/ipfs/" + imgData.IpfsHash;

      var data = {
        name: nftTitle,
        description: nftDescription,
        image: imageUrl,
      };

      var config = {
        method: "post",
        url: "https://api.pinata.cloud/pinning/pinJSONToIPFS",
        headers: {
          pinata_api_key: "fa13b7ac59970eb0a48a",
          pinata_secret_api_key:
            "74c1f5a76bd152fe7405d028089ac3ab76271f0b7505dc5a47ecd4bb4374112b",
        },
        data,
      };

      const res = await axios(config);
      const metadataUrl =
        "https://huddln.mypinata.cloud/ipfs/" + res.data.IpfsHash;

      await contractRedeemablesDeploy1155.methods
        .createRedeemableDeploy1155(
          redeemableId,
          projectId,
          nftAmount,
          nftTitle,
          nftSymbol,
          {
            name: `purchase-${actionPoints}`,
            points: actionPoints,
          },
          scoreType,
          metadataUrl,
          typeBoolean,
          royaltyAddress && enableRoyalties ? royaltyAddress : accounts[0],
          royaltyPercent && enableRoyalties ? parseInt(royaltyPercent) * 100 : 0
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .once("transactionHash", (hash) => {
          setTxHash(hash);
          console.log("hash: " + hash);
          console.log("redeemableId: " + redeemableId);
        })
        .once("confirmation", (confirmationNumber, receipt) => {
          setModalStatus("complete");
          setIsRedeemed(true);
        })
        .on("error", (error) => {
          setModalStatus("error");
          console.log("error: " + error);
        });
    } else {
      const { id, metadata, balance, contract } = selectedNft;
      const { name, image, imageUri, description } = metadata;

      const uri = image?.startsWith("ar://")
        ? `https://arweave.net/${image?.slice(5)}`
        : imageUri?.startsWith("ipfs://")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
        : image
        ? image
        : "";

      setNftTitle(name);
      setNftDescription(description);
      setImage(uri);

      const nft_address = contract?.address;
      const nft_id = id?.tokenId;
      const lAddress = accounts[0].toLowerCase();

      if (id?.tokenMetadata?.tokenType === "ERC1155") {
        const instance1155 = new web3.eth.Contract(ERC1155.abi, nft_address);

        let isApproved = await instance1155.methods
          .isApprovedForAll(
            lAddress,
            "0x2f4d727e018e3ae60db57cDA4B78670A8C44f7fc"
          )
          .call();

        if (isApproved) {
          try {
            await contractRedeemablesExisting1155.methods
              .createRedeemableExisting1155(
                redeemableId,
                projectId,
                balance1155,
                name ? name : nft_id,
                actionPoints,
                actionName,
                scoreType,
                nft_address,
                nft_id,
                typeBoolean
              )
              .send({
                from: lAddress,
                maxPriorityFeePerGas: null,
                maxFeePerGas: null,
              })
              .once("transactionHash", (hash) => {
                setTxHash(hash);
                console.log("hash: " + hash);
                console.log("redeemableId: " + redeemableId);
              })
              .once("confirmation", (confirmationNumber, receipt) => {
                setModalStatus("complete");
                setIsRedeemed(true);
              })
              .on("error", (error) => {
                setModalStatus("error");
                console.log("not published 1155");
                console.log("error: " + error);
              });
          } catch (error) {
            console.log("e: " + error);
          }
        } else {
          setModalMode("ask");
          setModalOpen(true);
        }
      } else if (id?.tokenMetadata?.tokenType === "ERC721") {
        const instance721 = new web3.eth.Contract(ERC721.abi, nft_address);

        let isApproved = await instance721.methods.getApproved(nft_id).call();

        if (isApproved === "0x10dc5d77097F109677a8aE8cbDCEA63F7934CA9a") {
          await contractRedeemablesExisting721.methods
            .createRedeemableExisting721(
              redeemableId,
              projectId,
              name ? name : nft_id,
              actionPoints,
              actionName,
              scoreType,
              nft_address,
              nft_id,
              typeBoolean
            )
            .send({
              from: lAddress,
              maxPriorityFeePerGas: null,
              maxFeePerGas: null,
            })
            // .estimateGas({ from: accounts[0] })
            .once("transactionHash", (hash) => {
              setTxHash(hash);
              console.log("hash: " + hash);
              console.log("redeemableId: " + redeemableId);
            })
            .once("confirmation", (confirmationNumber, receipt) => {
              setModalStatus("complete");
              setIsRedeemed(true);
            })
            .on("error", (error) => {
              setModalStatus("error");
              console.log("not published 721");
              console.log("error: " + error);
            });
        } else {
          console.log("not not supported");
          setModalStatus("error");
        }
      }
    }
  };

  const royaltyAddressHandler = (e) => {
    const val = e.target.value.toLowerCase();
    let isAddress = web3.utils.isAddress(val);
    if (isAddress) {
      setRoyaltyAddressPass(true);
      setRoyaltyAddress(val);
    } else {
      setRoyaltyAddressPass(false);
      setRoyaltyAddress(val);
    }
  };

  const royaltyPercentHandler = (e) => {
    const val = e.target.value;
    if (val.length > 3) {
      return;
    } else if (parseInt(val) >= 0 && parseInt(val) <= 100) {
      setRoyaltyPercentPass(true);
      setRoyaltyPercent(e.target.value);
    } else {
      setRoyaltyPercentPass(false);
      setRoyaltyPercent(e.target.value);
    }
  };

  const sellAmountHandler1155 = (e) => {
    const val = e.target.value;
    if (parseInt(val) <= selectedNft?.balance && parseInt(val) >= 0) {
      setBalanceAmountError("");
      setBalance1155(val);
    } else if (val === "") {
      setBalanceAmountError("");
      setBalance1155("");
    } else {
      setBalanceAmountError(
        "Enter an amount less than or equal to the number of Editions available"
      );
    }
  };

  const setNftSymbolHandler = (e) => {
    const v = e.target.value.toUpperCase();
    var val = v.replace(/[^a-zA-Z]+/g, "");
    if (val.length >= 3 && val.length <= 10) {
      setNftSymbolPass(true);
    } else {
      setNftSymbolPass(false);
    }
    setNftSymbol(val);
  };

  const setModalOpenHandler = (bool) => {
    console.log(bool);
    setModalOpen(bool);
    if (bool === false && modalStatus === "complete") {
      setType("");
    }
  };

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownNfts = allNfts.slice(firstPostIndex, lastPostIndex);

  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      width="90%"
      maxWidth="830px"
    >
      <Flex align="center">
        <Flex
          p={1}
          onClick={setType}
          borderRadius="50px"
          // mt="20px"
          mr="5px"
          _hover={{
            cursor: "pointer",
            // backgroundColor: "rgba(100,100,100,.5)",
            transform: "scale(1.1)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <MdOutlineArrowBack color="white" size="25px" onClick={setType} />
        </Flex>
      </Flex>
      <Flex flexDir="column" width="100%">
        {type === "earn" ? (
          <Flex
            flexDir={isLargerThan800 === true ? "row" : "column"}
            justifyContent="space-between"
            alignItems="center"
            mb={6}
          >
            <Flex
              width="100%"
              maxWidth="380px"
              height="150px"
              flexDir="row"
              bg={BG}
              border={BOARDER}
              flexDirection="column"
              borderRadius="15px"
              padding={PADDING}
              mb={isLargerThan800 === true ? 0 : 6}
            >
              <Flex flex="1" justifyContent="space-between">
                <Flex flex="1" flexDirection="column">
                  <Heading fontSize="20px" fontWeight={700}>
                    Minimum Score
                  </Heading>
                  <Text color="#A7A7A7" fontSize="14px">
                    Amount
                  </Text>
                </Flex>
                <Flex flex="1" justifyContent="flex-end" alignItems="flex-end">
                  <Input
                    bg="#292929"
                    border="2px solid #383838"
                    type="number"
                    textColor="white"
                    width="140px"
                    placeholder="Amount"
                    value={actionPoints}
                    onChange={(e) => setActionPoints(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Flex>
              </Flex>
            </Flex>
            <Flex
              width="100%"
              maxWidth="380px"
              height="150px"
              flexDir="row"
              bg={BG}
              border={BOARDER}
              flexDirection="column"
              borderRadius="15px"
              padding={PADDING}
            >
              <Flex flex="1" justifyContent="space-between">
                <Flex flex="1" flexDirection="column">
                  <Heading fontSize="20px" fontWeight={700}>
                    Score Type
                  </Heading>
                  <Text color="#A7A7A7" fontSize="14px">
                    Choose the score type
                  </Text>
                </Flex>
                <Flex flex="1" justifyContent="flex-end" alignItems="flex-end">
                  {projectScoreTypes.length > 0 && (
                    <CustomDropdown
                      placeholder="Scores"
                      array={projectScoreTypes}
                      state={scoreType}
                      setState={setScoreType}
                    />
                  )}
                  {/* <Select
                    w="150px"
                    iconColor="white"
                    onChange={(e) => setScoreType(e.target.value)}
                    required
                    _hover={{
                      cursor: "pointer",
                    }}
                  >
                    <option selected hidden disabled value="">
                      Scores
                    </option>
                    {projectScoreTypes.map((scoreType, index) => (
                      <option key={index} value={scoreType}>
                        {scoreType}
                      </option>
                    ))}
                  </Select> */}
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        ) : (
          <>
            {projectScoreTypes && (
              <CustomCard>
                <Heading color="white" fontSize="24px" fontWeight={700}>
                  Deduct from Score Type
                </Heading>
                <Text color="#A7A7A7" fontSize="14px">
                  Choose the score type to deduct points from
                </Text>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex />
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    className="redemption-select"
                  >
                    <Text color="#A7A7A7" fontSize="14px" mb={1}>
                      Score Type
                    </Text>
                    {projectScoreTypes.length > 0 && (
                      <CustomDropdown
                        placeholder="Scores"
                        array={projectScoreTypes}
                        state={scoreType}
                        setState={setScoreType}
                      />
                    )}
                    {/* <Select
                      w="150px"
                      iconColor="white"
                      onChange={(e) => setScoreType(e.target.value)}
                      required
                      _hover={{
                        cursor: "pointer",
                      }}
                    >
                      <Text selected hidden disabled value="">
                        Scores
                      </Text>
                      {projectScoreTypes.map((scoreType, index) => (
                        <option key={index} value={scoreType}>
                          {scoreType}
                        </option>
                      ))}
                    </Select> */}
                  </Flex>
                </Flex>
              </CustomCard>
            )}
            {projectActions && (
              <CustomCard>
                <Heading color="white" fontSize="24px" fontWeight={700}>
                  XP Price
                </Heading>
                <Text color="#A7A7A7" fontSize="14px">
                  Assign the amount of XP that will be deducted when purchased
                </Text>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex />
                  <Flex
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Text color="#A7A7A7" fontSize="14px" mb={1}>
                      Amount
                    </Text>
                    <Input
                      type="number"
                      bg="#292929"
                      border="2px solid #383838"
                      textColor="white"
                      width="140px"
                      borderRadius="10px"
                      value={actionPoints}
                      onChange={(e) => setActionPoints(e.target.value)}
                      onWheel={(e) => e.target.blur()}
                    />
                  </Flex>
                </Flex>
              </CustomCard>
            )}
          </>
        )}
        <CustomCard>
          <Heading fontSize="24px" fontWeight={700}>
            NFT
          </Heading>
          <Text color="#A7A7A7" fontSize="14px" mb={5}>
            Choose the type of redemption
          </Text>
          <RadioGroup
            onChange={setNftMode}
            value={nftMode}
            textColor="white"
            w="90%"
            maxWidth={350}
          >
            <Stack direction="column">
              <Radio
                value="create"
                colorScheme="toggle"
                _focus={{
                  boxShadow: "none",
                }}
              >
                Create new NFT
              </Radio>
              <Radio
                value="select"
                colorScheme="toggle"
                _focus={{
                  boxShadow: "none",
                }}
              >
                Select an existing NFT
              </Radio>
            </Stack>
          </RadioGroup>
        </CustomCard>
        {nftMode === "create" ? (
          <Flex flexDirection="column">
            <CustomCard>
              <Heading fontSize="24px" fontWeight={700}>
                NFT Royalties
              </Heading>
              <Text color="#A7A7A7" fontSize="14px" mb={3}>
                Assign royalties for this NFT. Compatible with any marketplace
                that supports ERC-2981
              </Text>
              <Checkbox
                colorScheme="toggle"
                mb={3}
                borderRadius="50%"
                borderColor="#383838"
                // value={enableRoyalties}
                onChange={(e) => {
                  // console.log(JSON.stringify(e));
                  setEnableRoyalties(e.target.checked);
                  // setEnableRoyalties
                }}
              />
              {enableRoyalties && (
                <>
                  <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mt={2}
                  >
                    <Text color="#A7A7A7" fontSize="14px" mb={1} width="70%">
                      Address
                    </Text>
                    <Text color="#A7A7A7" fontSize="14px" mb={1} width="120px">
                      Percentage
                    </Text>
                  </Flex>
                  <Flex
                    flexDirection="row"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={0}
                  >
                    <Flex
                      flexDirection="row"
                      justifyContent="center"
                      alignItems="center"
                      bg="#292929"
                      border="2px solid #383838"
                      // borderColor={!royaltyAddressPass ? "red" : "#505050"}
                      borderRadius="10px"
                      width="70%"
                      p={2}
                      pt={0}
                      pb={0}
                    >
                      <Input
                        bg="#292929"
                        textColor="white"
                        maxLength="250"
                        width="100%"
                        max={100}
                        p={0}
                        pr={2}
                        value={royaltyAddress}
                        onChange={royaltyAddressHandler}
                        onBlur={() => setRoyaltyAddressTouch(true)}
                        // onChange={(e) => setRoyaltyAddress(e.target.value)}
                      />
                      {royaltyAddressPass && royaltyAddressTouch ? (
                        <GoCheck color="green" size="18px" />
                      ) : !royaltyAddressPass && royaltyAddressTouch ? (
                        <GoX color="red" size="18px" />
                      ) : (
                        <Flex boxSize="16px" />
                      )}
                    </Flex>
                    <Flex
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      bg="#292929"
                      border="2px solid #383838"
                      // borderColor={!royaltyPercentPass ? "red" : "#505050"}
                      borderRadius="10px"
                      width="120px"
                      mb={0}
                    >
                      <Input
                        type="number"
                        value={royaltyPercent}
                        onChange={royaltyPercentHandler}
                        bg="#292929"
                        p={1}
                        pl={10}
                        width="80px"
                        onWheel={(e) => e.target.blur()}
                      />
                      <Text color="white" fontSize="14px">
                        %
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
            </CustomCard>
            <CustomCard>
              <Heading fontSize="24px" fontWeight={700}>
                NFT Symbol
              </Heading>
              <Text color="#A7A7A7" fontSize="14px">
                Set the NFT’s symbol, this is a shorthand symbol that can be
                used to reference this collection.
              </Text>
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex />
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Text color="#A7A7A7" fontSize="14px" mb={1}>
                    Symbol
                  </Text>
                  <Input
                    bg="#292929"
                    border="2px solid #383838"
                    textColor="white"
                    width="140px"
                    borderRadius="10px"
                    value={nftSymbol}
                    onChange={setNftSymbolHandler}
                  />
                </Flex>
              </Flex>
            </CustomCard>
            <CustomCard>
              <Heading mb={PADDING} fontSize="24px" fontWeight={700}>
                Upload
              </Heading>
              <Flex flexDirection="row">
                <Flex
                  flexDirection="row"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  {["mp4", "mp3", "mpeg", "video", "mov"].some((v) =>
                    imageType.includes(v)
                  ) ? (
                    <video
                      style={{
                        height: 40,
                        width: 40,
                      }}
                      controls={false}
                      autostart={false}
                      autoPlay={false}
                      src={showCreateImage}
                      type={imageType}
                    />
                  ) : (
                    <Image
                      boxSize="40px"
                      objectFit="contain"
                      borderRadius="5px"
                      src={showCreateImage}
                      fallbackSrc={missingImg}
                      alt=""
                    />
                  )}
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    // alignItems="center"
                    pl="40px"
                    pr="40px"
                    flex="1"
                  >
                    <Text color="#A7A7A7" fontSize="14px" fontWeight={600}>
                      Choose the content that will be minted when members
                      redeem.
                    </Text>
                    <Text color="#A7A7A7" fontSize="12px" fontWeight={400}>
                      PNG, JPG, MOV, MP3, MP4 or GIF
                    </Text>
                  </Flex>
                </Flex>
                <Flex justifyContent="center" alignItems="center">
                  <CustomFileUpload onChange={onImageChange} bg="#212121" />
                </Flex>
              </Flex>
            </CustomCard>
            {showCreateImage && (
              <CustomCard>
                <Heading mb={PADDING} fontSize="24px" fontWeight={700}>
                  Content Preview
                </Heading>
                <Flex
                  flexDirection="row"
                  justifyContent="center"
                  alignItems="center"
                >
                  {["mp4", "mp3", "mpeg", "video", "mov"].some((v) =>
                    imageType.includes(v)
                  ) ? (
                    // <AspectRatio h="380px">
                    <video
                      style={{
                        height: 380,
                      }}
                      controls
                      autostart
                      autoPlay
                      src={showCreateImage}
                      type={imageType}
                    />
                  ) : (
                    // </AspectRatio>
                    <Image
                      height="380px"
                      objectFit="cover"
                      borderRadius="5px"
                      src={showCreateImage}
                      fallbackSrc="https://via.placeholder.com/380"
                      alt=""
                    />
                  )}
                </Flex>
              </CustomCard>
            )}
            <CustomCard>
              <Heading mb={3} mt={1} fontSize="24px" fontWeight={700}>
                NFT Details
              </Heading>
              <Heading mb={2} fontSize="18px" color="white" fontWeight={700}>
                Title
              </Heading>
              {/* <Text color="#A7A7A7" fontSize="14px" mb={1}>
                250 characters max
              </Text> */}
              <Input
                bg="#292929"
                border="2px solid #383838"
                // placeholder="Title"
                textColor="white"
                maxLength="250"
                mb={6}
                value={createNftTitle}
                onChange={(e) => setCreatedNftTitle(e.target.value)}
              />
              <Heading mb={2} fontSize="18px" color="white" fontWeight={700}>
                Description
              </Heading>
              {/* <Text color="#A7A7A7" fontSize="14px" mb={1}>
                500 characters max
              </Text> */}
              <Textarea
                bg="#292929"
                border="2px solid #383838"
                _focus={{ boxShadow: "none" }}
                _hover={{
                  border: "2px solid #383838",
                }}
                // placeholder="Description"
                textColor="white"
                maxLength="500"
                mb={6}
                value={createNftDescription}
                onChange={(e) => setCreatedNftDescription(e.target.value)}
              />
            </CustomCard>
            <CustomCard>
              <Heading fontSize="18px" fontWeight={700} mb={2}>
                NFT Mint Amount
              </Heading>
              <Text color="#A7A7A7" fontSize="14px">
                How many NFT's do you want to mint?
              </Text>
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Flex />
                <Flex
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Input
                    type="number"
                    bg="#292929"
                    border="2px solid #383838"
                    textColor="white"
                    width="140px"
                    placeholder="Amount"
                    value={createNftAmount}
                    onChange={(e) => setCreateNftAmount(e.target.value)}
                    onWheel={(e) => e.target.blur()}
                  />
                </Flex>
              </Flex>
            </CustomCard>
          </Flex>
        ) : (
          <>
            <Flex
              flexDirection="column"
              alignItems="center"
              bg={BG}
              border={BOARDER}
              borderRadius="15px"
              width="100%"
            >
              <Flex flexDirection="column" width="100%" padding="20px">
                <Heading fontSize="24px" fontWeight={700}>
                  Select an existing NFT
                </Heading>
                <Text color="#A7A7A7" fontSize="14px" mb={5}>
                  Choose an existing NFT from your wallet that will be
                  redeemable
                </Text>
              </Flex>
              <Flex
                flexDirection="row"
                pl="20px"
                pr="20px"
                pb="20px"
                width="100%"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color="#A7A7A7" fontSize="18px">
                  Choose 1 NFT
                </Text>
                <Flex flexDirection="row">
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    // border="1px solid transparent"
                    p={2}
                    pl={3}
                    pr={3}
                    borderRadius="25px"
                    _hover={{
                      cursor: "pointer",
                      shadow: "dark-lg",
                      // transform: "translateY(-5px)",
                      backgroundColor: "#383838",
                      borderColor: "black",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    onClick={getNFTInfo}
                  >
                    <Text color="white" fontSize="16px" mr={1} fontWeight={700}>
                      Refresh
                    </Text>
                    <Lottie
                      animationData={refresh}
                      loop={loop}
                      autoPlay={loop}
                      style={{ height: 20 }}
                    />
                  </Flex>
                  {/* <Flex flexDirection="row"></Flex> */}
                </Flex>
              </Flex>
              {shownNfts.length > 0 ? (
                <Wrap
                  key={currentPage}
                  direction={["column", "row"]}
                  spacing="20px"
                  justify="center"
                  width="100%"
                  padding="20px"
                  pb={6}
                  mb={2}
                >
                  {shownNfts.map((item, i) => (
                    <NftCard
                      item={item}
                      key={i}
                      onClick={setSelectedNft}
                      selected={selectedNft === item}
                    />
                  ))}
                </Wrap>
              ) : (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pb="20px"
                >
                  <Lottie
                    animationData={bonfire}
                    loop={true}
                    style={{ height: 300 }}
                  />
                  <Text
                    color="#A7A7A7"
                    fontSize="14px"
                    mt="-50px"
                    fontWeight="700"
                  >
                    No redemptions yet
                  </Text>
                </Flex>
              )}
              <Pagination
                // total={allNftsLength}
                total={allNfts.length}
                perPage={postPerPage}
                setPage={setCurrentPage}
                currentPage={currentPage}
                enableEnd={!(!pageKey || pageKey.length === 0)}
                onEndReach={() => getNFTInfo(true)}
              />
            </Flex>
            {selectedNft?.id?.tokenMetadata?.tokenType === "ERC1155" && (
              <Flex
                bg={BG}
                border={BOARDER}
                borderRadius="15px"
                flexDirection="column"
                padding="20px"
                pb="10px"
                mt={6}
              >
                <Heading color="white" fontSize="24px" fontWeight={700}>
                  Amount to Sell
                </Heading>
                <Text color="#A7A7A7" fontSize="14px">
                  Set how many NFTs do you wish to list
                </Text>
                <Flex
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Flex />
                  <Flex
                    flexDirection="column"
                    alignItems="flex-end"
                    justifyContent="flex-end"
                  >
                    <Flex
                      flexDirection="column"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <Text color="#A7A7A7" fontSize="14px" mb={1}>
                        Amount
                      </Text>
                      <Input
                        type="number"
                        borderColor="#0E0E0E"
                        textColor="white"
                        width="140px"
                        border="2px solid #505050"
                        borderRadius="10px"
                        value={balance1155}
                        onChange={sellAmountHandler1155}
                        onWheel={(e) => e.target.blur()}
                      />
                    </Flex>
                    <Text color="#B03131" fontSize="12" mt="6px" height="14px">
                      {balanceAmountError}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            )}
          </>
        )}
        <Flex mt={10} width="100%" justifyContent="center" alignItems="center">
          <Button
            variant="gray"
            alignSelf="center"
            onClick={setType}
            w="140px"
            mr="10px"
          >
            Cancel
          </Button>
          {nftMode === "create" ? (
            <Button
              ml="10px"
              variant="purple"
              alignSelf="center"
              onClick={checkPermissionHandler}
              disabled={
                !nftSymbolPass ||
                (enableRoyalties &&
                  (!royaltyAddressPass || !royaltyPercentPass))
                  ? true
                  : false || !scoreType || !(parseInt(actionPoints) > 0)
                  ? true
                  : false ||
                    !createNftTitle ||
                    !showCreateImage ||
                    !(parseInt(createNftAmount) > 0)
              }
            >
              Publish
            </Button>
          ) : (
            <Button
              ml="10px"
              variant="purple"
              alignSelf="center"
              onClick={checkPermissionHandler}
              disabled={
                selectedNft?.id?.tokenMetadata?.tokenType === "ERC1155" &&
                !(parseInt(balance1155) > 0)
                  ? true
                  : false || !scoreType || !(parseInt(actionPoints) > 0)
                  ? true
                  : false || Object.keys(selectedNft).length === 0
              }
            >
              Next
            </Button>
          )}
        </Flex>
      </Flex>
      {modalMode === "publish" ? (
        <RedemptionModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpenHandler}
          imageType={imageType}
          image={nftMode === "create" ? showCreateImage : image}
          modalStatus={modalStatus}
          txHash={txHash}
          modalMode=""
          onClick={publishHandler}
          btnText="Publish"
          modalTitle={
            modalStatus === "loading"
              ? "Sending Tx... NFT"
              : modalStatus === "complete"
              ? "Listing Done"
              : "Publish Redemption"
          }
          modalDescription={
            nftMode === "create"
              ? "This NFT will be minted and set up to be redeemed"
              : type === "earn"
              ? "This NFT will be listed and collectable when the minimum XP score is met"
              : "This NFT will be listed for sale and purchaseable using XP"
          }
          nftTitle={nftTitle}
          nftAddress={nftAddress}
          nftDescription={nftDescription}
          nftId={nftId}
          nftAmount={nftAmount}
          nftTokenType={nftMode === "create" ? "ERC-1155" : nftTokenType}
          actionPoints={actionPoints}
          // actionName={actionName}
          actionName={scoreType}
          onCloseExtra={isRedeemed ? () => setType("") : null}
        />
      ) : (
        <RedemptionModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpenHandler}
          imageType={imageType}
          image={nftMode === "create" ? showCreateImage : image}
          modalStatus={modalStatus}
          txHash={txHash}
          modalMode=""
          onClick={givePermissionHandler}
          btnText="Approve"
          modalTitle="Approval"
          modalDescription="In order to allow this NFT to be redeemed, it must be
          transferred to the XP contract."
          nftTitle={nftTitle}
          nftAddress={nftAddress}
          nftDescription={nftDescription}
          nftId={nftId}
          nftAmount={nftAmount}
          nftTokenType={nftMode === "create" ? "ERC-1155" : nftTokenType}
          actionPoints={actionPoints}
          // actionName={actionName}
          actionName={scoreType}
          onCloseExtra={isRedeemed ? () => setType("") : null}
        />
      )}
    </Flex>
  );
}
