import { gql } from "@apollo/client";

export const GET_PROJECT_DATA = gql`
  query getProjetName($id: String!) {
    projects(where: { id: $id }) {
      name
      actions {
        name
        points
      }
      scoreTypes
    }
  }
`;
