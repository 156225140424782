import React, { useState, useEffect, useContext, useCallback } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Text, Icon, Link, Image } from "@chakra-ui/react";
import { FiPieChart } from "react-icons/fi";
import { RiEditBoxLine, RiRefreshLine } from "react-icons/ri";
import { BsPeople, BsCaretRightSquare, BsJournals } from "react-icons/bs";
import logoxp from "../assets/imgs/xp_proto.png";
import { MainContext } from "../contexts/MainContext";

const ProjectSidebar = ({ children }) => {
  const { currentProject, currentPage } = useContext(MainContext);

  return (
    <Flex
      flexDir="row"
      justifyContent="space-between"
      minH="100vh"
      bg="#121212"
    >
      <Flex
        flexDir="column"
        as="nav"
        pt="1.5vh"
        w="240px"
        bg="black"
        alignItems="center"
      >
        <Image src={logoxp} boxSize="130px" objectFit="contain" mb="3vh" />
        <Flex flexDir="column" alignItems="flex-start" justifyContent="center">
          <Flex className="sidebar-items" mt={3} mb={3}>
            <Link>
              <Icon
                as={FiPieChart}
                fontSize="2xl"
                className="active-icon"
                color={currentPage === "dashboard" ? "#D1BBF9" : "#A7A7A7"}
                mr={3}
              />
            </Link>
            <Link _hover={{ textDecor: "none" }} href="/">
              <Text
                fontWeight={600}
                color={currentPage === "dashboard" ? "#D1BBF9" : "#A7A7A7"}
                _hover={{ color: "white" }}
              >
                Projects
              </Text>
            </Link>
          </Flex>
          <Flex className="sidebar-items" mt={3} mb={3}>
            <Link>
              <Icon
                as={RiEditBoxLine}
                fontSize="2xl"
                color={currentPage === "createProject" ? "#D1BBF9" : "#A7A7A7"}
                mr={3}
              />
            </Link>
            <Link _hover={{ textDecor: "none" }} href="/create-project/">
              <Text
                color={currentPage === "createProject" ? "#D1BBF9" : "#A7A7A7"}
                fontWeight={600}
                _hover={{ color: "white" }}
              >
                Create Project
              </Text>
            </Link>
          </Flex>
        </Flex>
      </Flex>
      {children}
    </Flex>
  );
};

export default ProjectSidebar;
