import React, { useEffect } from "react";
import { Flex, Image } from "@chakra-ui/react";
import IframeResizer from "iframe-resizer-react";
import missingImg from "../../assets/imgs/missing_img.png";

export default function DisplayNftType({ type, src, height, width, autoPlay, selected = false }) {
  const vidRef = React.useRef(null)
  useEffect(() => {
    if (selected && vidRef.current) {
     vidRef.current.pause()
    }

  }, [selected])
  if (["gif", "jpeg", "png", "svg", "webp"].some((v) => type?.includes(v))) {
    return (
      <Image
        key={src}
        w={width}
        h={height}
        objectFit="contain"
        src={src}
        fallbackSrc={missingImg}
        alt=""
      />
    );
  } else if (["mp3"].some((v) => type?.includes(v))) {
    return (
      <audio
        key={src}
        style={{
          height,
          width,
        }}
        controls
        autostart={autoPlay ? autoPlay : false}
        autoPlay={autoPlay ? autoPlay : false}
        src={src}
        type={type}
      />
    );
  } else if (["mpeg", "video", "mov", "mp4"].some((v) => type?.includes(v))) {
    return (
      <video
        ref={vidRef}
        key={src}
        style={{
          height,
          width,
        }}
        
        controls={!selected}
        autostart={autoPlay ? autoPlay : false}
        autoPlay={autoPlay ? autoPlay : false}
        src={src}
        type={type}
      />
    );
  } else if (!src) {
    return (
      <Flex

        style={{
          height,
          width,
        }}
        p={0}
        m={0}
        justifyContent="center"
        alignItems="center"
      >
        <Image
          w="50px"
          h="50px"
          p={0}
          m={0}
          objectFit="contain"
          src={missingImg}
          // fallbackSrc={missingImg}
          alt=""
        />
      </Flex>
    );
  } else {
    return (
      <div style={{ position: 'relative' }}>

        <IframeResizer

          heightCalculationMethod="lowestElement"
          alt=""
          sandbox={true}
          src={src}
          style={{
            width: width,
            height: height,
          }}
        />
        <div style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,.0)', width: width, height: height }}></div>
      </div>
    );
  }
}
