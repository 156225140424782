import React, { useState, useEffect } from "react";
import {
  Icon,
  Input,
  Button,
  Flex,
  Text,
  Switch,
  Stack,
  Radio,
  RadioGroup,
  Image,
  Collapse,
} from "@chakra-ui/react";
import { GoCheck, GoX, GoChevronUp } from "react-icons/go";
import {
  getContractType,
  erc721TransferHandlerShared,
} from "./NFTWatcher.config";

const PADDING = "24px";
const BOARDER = "2px solid #383838";
const BG = "#212121";

export default function NFTWatcherTabContract({
  index,
  network,
  contractItem,
  updateContractByIndex,
  updateContractEventHandlerByIndex,
  openRemoveContractHandler,
  blockLocked,
}) {
  const { name, address, abiFile, eventHandlers } = contractItem;

  const [collapsed, setCollapsed] = useState(false);
  const [shared, setShared] = useState();
  const [type, setType] = useState();
  const [watchList, setWatchList] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const eventObj = eventHandlers[0];
    setType(getContractType(contractItem));
    setShared(eventObj?.handler === erc721TransferHandlerShared);
    setWatchList([eventObj?.arguments[0]]);
    name && setCollapsed(true);
    // setLoading(false);
  }, []);

  useEffect(() => {
    updateContractEventHandlerByIndex(network, index, watchList, type, shared);
  }, [network, index, watchList, type, shared]);

  if (loading) {
    return <></>;
  } else {
    return (
      <Flex
        w="100%"
        bg={BG}
        border={BOARDER}
        flexDirection="column"
        borderRadius="15px"
        mb="20px"
        {...(collapsed && {
          onClick: () => setCollapsed(false),
        })}
        _hover={{
          transform: collapsed ? "scale(1.05)" : "scale(1.00)",
        }}
        cursor={collapsed ? "pointer" : "auto"}
        transition="all 0.2s"
      >
        <Flex
          flexDirection="column"
          transition="all .2s ease"
          overflow="hidden"
        >
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            padding={PADDING}
          >
            <Flex flexDirection="column" maxW="50%">
              {collapsed && (
                <>
                  <Text
                    color="white"
                    fontSize="14px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {name}
                  </Text>
                  <Text
                    color="#A7A7A7"
                    fontSize="12px"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                  >
                    {address}
                  </Text>
                </>
              )}
            </Flex>
            <Icon
              as={GoChevronUp}
              color="#ddd"
              fontWeight={600}
              fontSize="23px"
              cursor="pointer"
              // {...(!collapsed && {
              //   onClick: () => updateContracts(index, "collapsed", false),
              // })}
              onClick={collapsed ? () => {} : () => setCollapsed(true)}
              style={{
                transform: `rotate(${!collapsed ? "180" : "0"}deg)`,
              }}
            />
          </Flex>
          <Collapse in={!collapsed} animateOpacity>
            <Flex
              flexDir="column"
              position="relative"
              mb="10px"
              padding={PADDING}
              pt={0}
            >
              <Flex flexDir="column" mb="15px">
                <Text color="#A7A7A7" fontSize="14px" mb={1}>
                  Contract Name
                </Text>
                <Input
                  borderRadius="10px"
                  textColor={blockLocked ? "#aaa" : "white"}
                  maxLength="250"
                  width="100%"
                  max={100}
                  bg="#292929"
                  border="2px solid #383838"
                  p={2}
                  pt={0}
                  pb={0}
                  onChange={(e) => {
                    updateContractByIndex(
                      network,
                      index,
                      "name",
                      e.target.value
                      // e.target.value.replace(/[^0-9]/g, "")
                    );
                  }}
                  value={name}
                />
              </Flex>
              <Flex flexDir="column" mb="15px">
                <Text color="#A7A7A7" fontSize="14px" mb={1} width="70%">
                  Contract Address
                </Text>
                <Flex
                  flexDirection="row"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Flex
                    flexDirection="row"
                    position="relative"
                    justifyContent="center"
                    alignItems="center"
                    bg="#292929"
                    border="2px solid #383838"
                    // borderColor={!royaltyAddressPass ? "red" : "#505050"}
                    borderRadius="10px"
                    width="100%"
                    p={2}
                    pt={0}
                    pb={0}
                  >
                    <Input
                      borderRadius="10px"
                      textColor={blockLocked ? "#aaa" : "white"}
                      maxLength="250"
                      width="100%"
                      max={100}
                      p={0}
                      bg="#292929"
                      pr={2}
                      onChange={(e) => {
                        updateContractByIndex(
                          network,
                          index,
                          "address",
                          e.target.value
                        );
                      }}
                      value={address}
                    />
                    {/* {savedPressed && existingContract.pass ? (
                    <GoCheck color="green" size="18px" />
                  ) : savedPressed && !existingContract.pass ? (
                    <GoX color="red" size="18px" />
                  ) : (
                    <Flex boxSize="16px" />
                  )} */}
                  </Flex>
                  <Flex />
                </Flex>
              </Flex>
              <hr
                style={{
                  border: "2px solid #383838",
                  margin: "10px 0",
                  borderRadius: "7px 7px 7px 7px",
                }}
              />
              {abiFile.includes("ERC721") && (
                <Flex alignItems="center" mb="10px">
                  <Text
                    color={blockLocked ? "#aaa" : "white"}
                    fontWeight={600}
                    fontSize="18px"
                    mr="20px"
                  >
                    Shared Contract
                  </Text>
                  <Switch
                    size="md"
                    colorScheme={blockLocked ? "black" : "toggle"}
                    isChecked={shared}
                    onChange={(e) => setShared(e.target.checked)}
                    // onChange={setShared}
                  />
                </Flex>
              )}
              {type === "ERC721" && shared && (
                <Flex flexDir="column" mb="15px">
                  <Text
                    color={blockLocked ? "#aaa" : "white"}
                    fontWeight={600}
                    fontSize="18px"
                    mb="5px"
                  >
                    Watch NFTs
                  </Text>
                  <Text color="#A7A7A7" fontSize="14px" mb="10px">
                    Provide the Token ID for each token, provide this in a comma
                    separated list
                  </Text>
                  <Input
                    borderRadius="10px"
                    textColor={blockLocked ? "#aaa" : "white"}
                    maxLength="250"
                    width="100%"
                    max={100}
                    bg="#292929"
                    border="2px solid #383838"
                    p={2}
                    pt={0}
                    pb={0}
                    onChange={(e) =>
                      setWatchList(
                        e.target.value
                          .replace(/\s/g, "")
                          .replace(/\./g, "")
                          .split(",")
                      )
                    }
                    value={watchList.toString()}
                  />
                </Flex>
              )}
              <Text
                color={blockLocked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
                mb="10px"
              >
                NFT Type
              </Text>
              <Flex flexDir="column" pl="10px" mb="20px">
                <Text color="#A7A7A7" fontSize="14px" mb="10px">
                  What type of NFT is it?
                </Text>
                <RadioGroup
                  onChange={setType}
                  value={type}
                  textColor="white"
                  w="90%"
                  maxWidth={350}
                >
                  <Stack direction="column">
                    <Radio
                      value="ERC721"
                      colorScheme={blockLocked ? "black" : "toggle"}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      ERC-721
                    </Radio>
                    <Radio
                      value="ERC1155"
                      colorScheme={blockLocked ? "black" : "toggle"}
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      ERC-1155
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
              <Button
                h="30px"
                w="90px"
                borderRadius="15px"
                fontSize="14px"
                fontWeight={700}
                variant="error"
                disabled={blockLocked}
                onClick={() => openRemoveContractHandler(network, index)}
              >
                Remove
              </Button>
            </Flex>
          </Collapse>
        </Flex>
      </Flex>
    );
  }
}
