import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import { addressCheckHandler } from "../../func/myFunc.ts";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Spinner,
  useToast,
  useDisclosure,
} from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import ClickRemoveModal from "../../components/ClickRemoveModal/ClickRemoveModal";
import { useNoArgs } from "../../func/myFunc.ts";
import { GoCheck, GoX } from "react-icons/go";
import DisplayAdmins from "./DisplayAdmins";
import changeNetworkToPolygon from "../../func/changeNetwork";

const GET_OWNERSUPDATERS = gql`
  query getResults($projectId: String!) {
    projects(where: { id: $projectId }) {
      owners
      updaters
    }
  }
`;

const OwnersUpdaters = () => {
  const { web3, accounts, contract } = useContext(Web3Context);
  const toast = useToast();
  const { projectId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [locked, setLocked] = useState(true);
  const [userAddress, setUserAddress] = useState();
  const [isOwner, setIsOwner] = useState("0");
  const [existingOwners, setExistingOwners] = useState([]);
  const [existingUpdaters, setExistingUpdaters] = useState([]);
  const [btnStatus, setBtnStatus] = useState("");
  const [snapAddressPass, setSnapAddressPass] = useState(false);
  const [snapAddressChecked, setSnapAddressChecked] = useState(false);
  const [removeData, setRemoveData] = useState();

  const {
    data: ownerData,
    refetch,
    loading,
  } = useQuery(GET_OWNERSUPDATERS, {
    variables: { projectId },
  });

  const doRefetch = useNoArgs(refetch);

  const handleClick = async () => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");

    setSnapAddressChecked(false);
    const pass = await addressCheckHandler(userAddress);
    setSnapAddressChecked(true);
    setSnapAddressPass(pass);
    if (!pass) {
      setBtnStatus("");
      return;
    }

    await changeNetworkToPolygon();

    if (isOwner === "0") {
      let alreadyIn = existingOwners.includes(userAddress);
      if (alreadyIn) {
        toast({
          title: "User is already an Owner",
          // description: "We've created your account for you.",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
        setBtnStatus("");
        return;
      }

      await contract.methods
        .addProjectOwner(projectId, userAddress)
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash: " + hash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 5) {
            doRefetch();
            setBtnStatus("");
            toast({
              title: "Owner Added",
              // description: "We've created your account for you.",
              position: "top",
              status: "success",
              variant: "subtle",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .on("error", (error) => {
          let title = error.message;
          setBtnStatus("");
          toast({
            title,
            // description: "We've created your account for you.",
            position: "top",
            status: "error",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        });
    } else {
      let alreadyIn = existingUpdaters.includes(userAddress);
      if (alreadyIn) {
        toast({
          title: "User is already a Updater",
          // description: "We've created your account for you.",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
        setBtnStatus("");
        return;
      }

      await contract.methods
        .addProjectUpdater(projectId, userAddress)
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash: " + hash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 5) {
            doRefetch();
            setBtnStatus("");
            toast({
              title: "Updater Added",
              // description: "We've created your account for you.",
              position: "top",
              status: "success",
              variant: "subtle",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .on("error", (error) => {
          let title = error.message;
          setBtnStatus("");
          toast({
            title,
            // description: "We've created your account for you.",
            position: "top",
            status: "error",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  };

  const modalRemoveHandler = (clickedAddress, userType) => {
    setRemoveData({ clickedAddress, userType });
    onOpen();
  };

  const removeHandler = async ({ clickedAddress, userType }) => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");

    await changeNetworkToPolygon();

    //remove
    if (userType === "Owner") {
      await contract.methods
        .removeProjectOwner(projectId, clickedAddress)
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash: " + hash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 5) {
            doRefetch();
            setBtnStatus("");
            onClose();
            toast({
              title: "Owner Removed",
              // description: "We've created your account for you.",
              position: "top",
              status: "success",
              variant: "subtle",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .on("error", (error) => {
          setBtnStatus("");
          let title = error.message;
          toast({
            title,
            // description: "We've created your account for you.",
            position: "top",
            status: "error",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        });
    } else {
      await contract.methods
        .removeProjectUpdater(projectId, clickedAddress)
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("Transaction hash: " + hash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 5) {
            onClose();
            setBtnStatus("");
            doRefetch();
            toast({
              title: "Updater Removed",
              // description: "We've created your account for you.",
              position: "top",
              status: "success",
              variant: "subtle",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .on("error", (error) => {
          setBtnStatus("");
          let title = error.message;
          toast({
            title,
            // description: "We've created your account for you.",
            position: "top",
            status: "error",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        });
    }
  };

  useEffect(() => {
    if (ownerData) {
      let owners = [...ownerData?.projects[0]?.owners];
      // const ownersAll = owners.map((owner) => {
      //   return { address: owner, type: "owner" };
      // });
      let updaters = [...ownerData?.projects[0]?.updaters];
      // const updatersAll = updaters.map((updater) => {
      //   return { address: updater, type: "updater" };
      // });

      setExistingOwners(owners);
      setExistingUpdaters(updaters);
    }
  }, [ownerData]);

  if (loading) {
    return <LoadComponent />;
  } else {
    return (
      <PageWrapper width="800px">
        <Flex w="100%" alignItems="center" mb="30px">
          <Heading color="white" fontSize="18px">
            Owner/Users
          </Heading>
        </Flex>
        {(existingUpdaters.length > 0 || existingOwners.length > 0) && (
          <DisplayAdmins
            existingUpdaters={existingUpdaters}
            existingOwners={existingOwners}
            removeHandler={modalRemoveHandler}
          />
        )}
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              <Text
                color={locked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
              >
                Add New Owner/Updater
              </Text>
            </Flex>
            <Locks state={locked} setState={setLocked} />
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="15px">
            Enter the user's address abd whether this is an owner or updater
          </Text>
          <Flex pos="relative">
            <Flex
              flexDirection="row"
              position="relative"
              justifyContent="center"
              alignItems="center"
              bg="#292929"
              border="2px solid #383838"
              // borderColor={!royaltyAddressPass ? "red" : "#505050"}
              borderRadius="10px"
              width="70%"
              p={2}
              pt={0}
              pb={0}
            >
              <Input
                borderRadius="10px"
                textColor={locked ? "#aaa" : "white"}
                maxLength="250"
                width="100%"
                max={100}
                p={0}
                bg="#292929"
                pr={2}
                onChange={(e) => setUserAddress(e.target.value)}
                value={userAddress}
              />
              {snapAddressPass && snapAddressChecked ? (
                <GoCheck color="green" size="18px" />
              ) : !snapAddressPass && snapAddressChecked ? (
                <GoX color="red" size="18px" />
              ) : (
                <Flex boxSize="16px" />
              )}
              {locked && (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                />
              )}
            </Flex>
            <RadioGroup
              onChange={setIsOwner}
              value={isOwner}
              defaultValue="0"
              ml="15px"
            >
              <Stack direction="column">
                <Radio
                  value="0"
                  colorScheme={locked ? "black" : "toggle"}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Owner
                </Radio>
                <Radio
                  value="1"
                  colorScheme={locked ? "black" : "toggle"}
                  _focus={{
                    boxShadow: "none",
                  }}
                >
                  Updater
                </Radio>
              </Stack>
            </RadioGroup>
            {locked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
        </CustomCard>

        <Button
          onClick={handleClick}
          variant="purple"
          alignSelf="center"
          mt={10}
          // disabled={value === "0" ? scoreType === "" : !scoreTypeRemove}
        >
          {!isOpen && btnStatus === "loading" ? (
            <Spinner size="xs" color="gray.500" />
          ) : (
            "Add"
          )}
        </Button>
        <ClickRemoveModal
          isOpen={isOpen}
          onOpe={onOpen}
          onClose={onClose}
          removeHandler={removeHandler}
          item={removeData}
          btnStatus={btnStatus}
        />
      </PageWrapper>
    );
  }
};

export default OwnersUpdaters;
