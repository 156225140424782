import React, { useEffect, useState, useContext } from "react";
import { projectRealtimeDb } from "../firebase/config";
import { Web3Context } from "../contexts/Web3Context";

import {
  Button,
  Flex,
  Heading,
  Text,
  Avatar,
  Image,
  Spacer,
  Icon,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  SlideFade,
  Collapse,
  Fade,
  useDisclosure,
} from "@chakra-ui/react";

import {
  BsChevronDown,
  BsChevronDoubleUp,
  BsFillShareFill,
} from "react-icons/bs";
import { FiDownload, FiTrash2 } from "react-icons/fi";

const ClaimRow = ({ claim }) => {
  const [createdDate, setCreatedDate] = useState();
  const [expireDate, setExpireDate] = useState();
  const [open, setOpen] = useState(false);

  const { web3, accounts, contract } = useContext(Web3Context);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const deleteClaim = async (claimId) => {
    let timestamp = Date.now().toString();

    const signature = await web3.eth.personal.sign(
      timestamp,
      window.ethereum.selectedAddress
    );

    let reqBody = {
      claimId,
      verification: {
        signature,
        message: timestamp,
        address: accounts[0],
      },
    };

    console.log(reqBody);

    let deleteClaimResponse = await fetch(
      "https://us-central1-huddl-in.cloudfunctions.net/removeXpClaim",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(reqBody),
      }
    );
  };

  async function downloadImage(imageSrc) {
    const image = await fetch(imageSrc);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);

    const link = document.createElement("a");
    link.href = imageURL;
    link.download = "qr_code";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  useEffect(() => {
    const formatDate = (d, inSeconds) => {
      let date;

      if (typeof d === "string") d = parseInt(d);

      if (inSeconds) {
        date = new Date(d * 1000);
      } else {
        date = new Date(d);
      }

      return (
        date.getMonth() + 1 + "/" + date.getDate() + "/" + date.getFullYear()
      );
    };

    setCreatedDate(formatDate(claim.creationDate, false));
    setExpireDate(formatDate(claim.xpExpire, true));
  }, [claim]);

  if (open) {
    return (
      <Flex
        flexDir="column"
        bgColor="#1E1E1E"
        borderRadius={20}
        h={165}
        w="90%"
        px={7}
        pt={5}
        mb={5}
      >
        <Flex>
          <Flex
            cursor="pointer"
            w="20%"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <Icon as={BsChevronDoubleUp} color="white" fontSize="lg" mr={8} />
            <Flex flexDir="column">
              <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
                Title
              </Text>
              <Text color="white" fontWeight={600}>
                {claim.name}
              </Text>
            </Flex>
          </Flex>
          <Spacer />
          <Flex flexDir="column">
            <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
              Created
            </Text>
            <Text color="white" fontWeight={600}>
              {createdDate && createdDate}
            </Text>
          </Flex>
          <Spacer />
          <Flex flexDir="column">
            <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
              Expires
            </Text>
            <Text color="white" fontWeight={600}>
              {expireDate && expireDate}
            </Text>
          </Flex>
          <Spacer />
          <Flex pt={2}>
            <Popover>
              <PopoverTrigger>
                <Flex>
                  <Icon
                    as={BsFillShareFill}
                    color="#C6C7FF"
                    fontSize="xl"
                    mr={8}
                    cursor="pointer"
                  />
                </Flex>
              </PopoverTrigger>
              <PopoverContent border="none">
                <PopoverCloseButton />
                <PopoverBody bgColor="#1E1E1E" color="white">
                  <Flex flexDir="column">
                    {claim.url}
                    <Button
                      bgGradient="linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)"
                      _active={{
                        bgGradient:
                          "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
                      }}
                      _hover={{
                        bgGradient:
                          "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
                      }}
                      onClick={() => navigator.clipboard.writeText(claim.url)}
                      w={20}
                      h={7}
                      mt={2}
                    >
                      Copy
                    </Button>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
            <Icon
              as={FiDownload}
              color="#C6C7FF"
              fontSize="xl"
              cursor="pointer"
              onClick={() => downloadImage(claim.qrCodeURL)}
            />
          </Flex>
        </Flex>

        <Flex mt={8} ml={12}>
          <Flex flexDir="column">
            <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
              Description
            </Text>
            <Text color="white" fontWeight={500} fontSize="sm">
              {claim.description}
            </Text>
          </Flex>
          <Spacer />
          <Flex flexDir="column">
            <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
              Action
            </Text>
            <Text color="white" fontWeight={600}>
              {claim.xpAction}
            </Text>
          </Flex>
          <Spacer />
          <Flex flexDir="column">
            <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
              Points
            </Text>
            <Text color="white" fontWeight={600}>
              {claim.points}
            </Text>
          </Flex>
          <Spacer />
          <Flex flexDir="column">
            <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
              Score Type
            </Text>
            <Text color="white" fontWeight={600}>
              {claim.xpScoreType}
            </Text>
          </Flex>
          <Spacer />
          <Flex pt={2}>
            <Popover isOpen={isOpen} onClose={onClose}>
              <PopoverTrigger>
                <Flex>
                  <Icon
                    as={FiTrash2}
                    color="#C6C7FF"
                    fontSize="xl"
                    cursor="pointer"
                    onClick={onToggle}
                  />
                </Flex>
              </PopoverTrigger>
              <PopoverContent border="none">
                <PopoverCloseButton />
                <PopoverBody bgColor="#1E1E1E" color="white">
                  <Flex flexDir="column">
                    Do you want to delete this claim?
                    <Flex>
                      <Button
                        bgGradient="linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)"
                        _active={{
                          bgGradient:
                            "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
                        }}
                        _hover={{
                          bgGradient:
                            "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
                        }}
                        onClick={() => {
                          deleteClaim(claim.xpClaimId);
                          onClose();
                        }}
                        w={20}
                        h={7}
                        mt={2}
                        mr={2}
                      >
                        Yes
                      </Button>
                      <Button
                        bgGradient="linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)"
                        _active={{
                          bgGradient:
                            "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
                        }}
                        _hover={{
                          bgGradient:
                            "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
                        }}
                        onClick={() => {
                          onClose();
                        }}
                        w={20}
                        h={7}
                        mt={2}
                      >
                        No
                      </Button>
                    </Flex>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Popover>
          </Flex>
        </Flex>
      </Flex>
    );
  } else {
    return (
      <Flex
        bgColor="#1E1E1E"
        borderRadius={20}
        h={75}
        w="90%"
        px={7}
        mb={5}
        alignItems="center"
      >
        <Flex
          cursor="pointer"
          w="20%"
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Icon
            as={BsChevronDown}
            color="white"
            fontSize="lg"
            mr={8}
            alignSelf="center"
          />
          <Text color="white" fontWeight={600}>
            {claim.name}
          </Text>
        </Flex>
        <Spacer />
        <Flex flexDir="column">
          <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
            Created
          </Text>
          <Text color="white" fontWeight={600}>
            {createdDate && createdDate}
          </Text>
        </Flex>
        <Spacer />
        <Flex flexDir="column">
          <Text color="#A7A7A7" fontWeight={400} fontSize="xs">
            Expires
          </Text>
          <Text color="white" fontWeight={600}>
            {expireDate && expireDate}
          </Text>
        </Flex>
        <Spacer />
        <Flex>
          <Popover>
            <PopoverTrigger>
              <Flex>
                <Icon
                  as={BsFillShareFill}
                  color="#C6C7FF"
                  fontSize="xl"
                  mr={8}
                  cursor="pointer"
                />
              </Flex>
            </PopoverTrigger>
            <PopoverContent border="none">
              <PopoverCloseButton />
              <PopoverBody bgColor="#1E1E1E" color="white">
                <Flex flexDir="column">
                  {claim.url}
                  <Button
                    bgGradient="linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)"
                    _active={{
                      bgGradient:
                        "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
                    }}
                    _hover={{
                      bgGradient:
                        "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
                    }}
                    onClick={() => navigator.clipboard.writeText(claim.url)}
                    w={20}
                    h={7}
                    mt={2}
                  >
                    Copy
                  </Button>
                </Flex>
              </PopoverBody>
            </PopoverContent>
          </Popover>
          <Icon
            as={FiDownload}
            color="#C6C7FF"
            fontSize="xl"
            cursor="pointer"
            onClick={() => downloadImage(claim.qrCodeURL)}
          />
        </Flex>
      </Flex>
    );
  }
};

export default ClaimRow;
