import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import {
  Flex,
  Heading,
  Text,
  Stack,
  TableContainer,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Divider,
  Icon,
  Select,
  Link,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";

import { useQuery, gql, useApolloClient } from "@apollo/client";
import CustomDropdown from "../components/CustomDropdown/CustomDropdown";
import { BsGraphUp } from "react-icons/bs";
import { GoChevronDown } from "react-icons/go";
import { Doughnut, Bar } from "react-chartjs-2";
import "chart.js/auto";

const ScoreData = () => {
  const { projectId } = useParams();
  const client = useApolloClient();

  const [scores, setScores] = useState([]);
  const [currentScores, setCurrentScores] = useState();
  const [totalUsers, setTotalUsers] = useState();
  const [totalXP, setTotalXP] = useState();
  const [scoreTypes, setScoreTypes] = useState();
  const [topScoreType, setTopScoreType] = useState();
  const [doughnutData, setDoughnutData] = useState();
  const [barData, setBarData] = useState();
  const [currentScoreType, setCurrentScoreType] = useState(0);
  const [loading, setLoading] = useState(true);
  const [rowsToDisplay, setRowsToDisplay] = useState(5);

  const GET_PROJECT = gql`
    query getResults($projectId: String!) {
      projects(where: { id: $projectId }) {
        scoreTypes
        totalUsers
        projectTotalScore
      }
    }
  `;

  const GET_ROWS = gql`
    query getResults(
      $projectId: String!
      $scoreTypeName: String!
      $first: Int!
      $skip: Int!
    ) {
      scores(
        first: $first
        skip: $skip
        where: { project: $projectId, scoreType: $scoreTypeName }
        orderBy: points
        orderDirection: desc
      ) {
        id
        address
        points
        scoreType
      }
    }
  `;

  const expandScores = () => {
    setRowsToDisplay((prev) => prev + 5);
  };

  const formatDoughnutData = (scoreData) => {
    let data = {
      labels: [],
      datasets: [
        {
          label: "# of Users",
          data: [],
          backgroundColor: [
            "rgba(255, 99, 132, 0.2)",
            "rgba(54, 162, 235, 0.2)",
            "rgba(255, 206, 86, 0.2)",
            "rgba(75, 192, 192, 0.2)",
            "rgba(153, 102, 255, 0.2)",
            "rgba(255, 159, 64, 0.2)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    };

    for (let i = 0; i < scoreData.length; i++) {
      data.labels.push(scoreData[i].scoreType);
      data.datasets[0].data.push(scoreData[i].results.length);
    }

    setDoughnutData(data);
  };

  const formatBarData = (scoreData) => {
    if (currentScores) {
      let data = {
        labels: [],
        datasets: [
          {
            label: "# of Points",
            data: [],
            backgroundColor: [
              "rgba(255, 99, 132, 0.2)",
              "rgba(54, 162, 235, 0.2)",
              "rgba(255, 206, 86, 0.2)",
              "rgba(75, 192, 192, 0.2)",
              "rgba(153, 102, 255, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      };

      let numOfUsersDisplayed = 7;

      if (currentScores.length < 7) {
        numOfUsersDisplayed = currentScores.length;
      }

      for (let i = 0; i < numOfUsersDisplayed; i++) {
        data.labels.push(currentScores[i].address.slice(0, 7) + "...");
        data.datasets[0].data.push(currentScores[i].points);
      }

      setBarData(data);

      setLoading(false);
    }
  };

  const getScoreRows = async (scoreType) => {
    let rows = [];

    const scoreTypeResults = await client.query({
      query: GET_ROWS,
      variables: {
        projectId: projectId,
        scoreTypeName: scoreTypes[currentScoreType],
        first: 5,
        skip: rowsToDisplay - 5,
      },
    });

    if (
      currentScores &&
      currentScores[0] &&
      currentScores[0].scoreType === scoreTypes[currentScoreType]
    ) {
      rows = [...currentScores, ...scoreTypeResults.data.scores];
    } else {
      rows = [...scoreTypeResults.data.scores];
    }

    setCurrentScores(rows);
  };

  useEffect(() => {
    client
      .query({
        query: GET_PROJECT,
        variables: {
          projectId: projectId,
        },
      })
      .then((response) => {
        setScoreTypes(response.data.projects[0].scoreTypes);
        setTotalUsers(response.data.projects[0].totalUsers);
        setTotalXP(response.data.projects[0].projectTotalScore);
        // getScoreRows(currentScoreType);
      })
      .catch((err) => console.error(err));
  }, []);

  // useEffect(() => {
  //   if (scoreTypes) {
  //     console.log("SCORE TYPE LENGTH: " + scoreTypes.length);
  //     if (scores.length >= scoreTypes.length) {
  //       formatDoughnutData(scores);
  //     }
  //   }
  // }, [scores, currentScoreType]);

  useEffect(() => {
    formatBarData();
  }, [currentScores]);
  useEffect(() => {
    setRowsToDisplay(5);
  }, [currentScoreType]);

  useEffect(() => {
    if (scoreTypes && scoreTypes.length > 0) getScoreRows(currentScoreType);
  }, [rowsToDisplay, currentScoreType, scoreTypes]);

  if (loading) {
    return (
      <Flex
        flexDir="column"
        bgColor="#0E0E0E"
        minH="100%"
        minW="80%"
        justifyContent="center"
        alignItems="center"
      >
        <Heading color="white" fontSize="xl" mb="2vh">
          Loading
        </Heading>

        <div className="lds-roller">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Flex>
    );
  }

  return (
    <Flex flex="1" flexDir={'row'} >
      <Flex
        flexDir="column"
        h="100%"
        minW={'350px'}
        //bg="red"
        ml={'50px'}
        mr='50px'
        pl="30px"
        pr="30px"
      >
        <Heading mt="6vh" mb="8vh" fontSize="4xl" color="white">
          Overview
        </Heading>
        <Heading fontSize="2xl" mb="2vh" color="white">
          Totals
        </Heading>

        <Text color="#C4C4C4" fontWeight="semibold" mb="1vh">
          Total Users
        </Text>
        <Flex mb="1.7vh">
          <Heading fontSize="4xl" color="white" w="70%">
            {totalUsers && parseInt(totalUsers).toLocaleString("en-US")}
          </Heading>
          <Flex w="30%"></Flex>
        </Flex>
        <Divider borderColor="#919191" mb="1.7vh" />
        <Text color="#C4C4C4" fontWeight="semibold" mb="1vh">
          Total XP Earned
        </Text>
        <Flex mb="1.7vh">
          <Heading fontSize="4xl" color="white" w="70%">
            {totalXP && parseInt(totalXP).toLocaleString("en-US")}
          </Heading>
          <Flex w="30%">
            {/*
            <Flex
              bgColor="#38EF7D5C"
              pl="0.7vw"
              pr="0.7vw"
              alignItems="center"
              borderRadius={10}
              boxShadow="0px 0px 4px rgba(56, 239, 125, 0.5)"
            >
              <Icon as={BsGraphUp} fontSize="xl" color="#8CED9C" mr={3} />
              <Text color="white" fontWeight="bold">
                28%
              </Text>
            </Flex>
            */}
          </Flex>
        </Flex>
        {/*}
        <Divider borderColor="#919191" mb="1.7vh" />
        <Text color="#C4C4C4" fontWeight="semibold" mb="1vh">
          Top Score Type
        </Text>
        <Flex>
          <Heading fontSize="4xl" color="white" w="70%">
            {topScoreType && topScoreType}
          </Heading>
          <Flex w="30%"></Flex>
        </Flex>
        
        <Flex flexDir="column" mt="8vh" align="flex-end">
          <Text
            alignSelf="flex-start"
            color="white"
            mb="2vh"
            fontSize="lg"
            fontWeight="bold"
          >
            Top Score Types
          </Text>
          {doughnutData && <Doughnut data={doughnutData} />}
        </Flex>
        */}
      </Flex>

      {/* Second Column */}

      <Flex flexDir="column" bgColor="#121212" px="4vw">
        <Flex flexDir="column" w="100%">
          <Heading mt="6vh" mb="4vh" fontSize="4xl" color="white">
            User Activity
          </Heading>
          <Text color="white" mb="2vh" fontSize="lg" fontWeight="bold">
            Top Users
          </Text>
          {barData && <Bar data={barData} />}
          <Flex mt="4vh" mb="2vh">
            {scoreTypes?.length > 0 && (
              <Menu>
                <MenuButton
                  px={4}
                  py={2}
                  minW="150px"
                  flexDirection="row"
                  transition="all 0.2s"
                  borderRadius="10px"
                  height="40px"
                  bg="#292929"
                  border="2px solid #383838"
                  // borderRadius="md"
                  // borderWidth="1px"
                  // _hover={{ bg: "gray.400" }}
                  // _expanded={{ bg: "blue.400" }}
                  _focus={{ boxShadow: "none" }}
                >
                  <Flex
                    alignItems="center"
                    color="white"
                    width="currentScoreType%"
                    justifyContent="space-between"
                    mr="4px"
                  >
                    {scoreTypes[currentScoreType]}
                    <GoChevronDown color="white" size="22px" />
                  </Flex>
                </MenuButton>
                <MenuList bg="#292929" color="white">
                  {scoreTypes.map((item, index) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setCurrentScoreType(index);
                          setRowsToDisplay(5);
                        }}
                        _hover={{ bg: "#212121" }}
                        key={`${item}:${index}`}
                      >
                        {item}
                      </MenuItem>
                    );
                  })}
                  {/* <MenuItem _hover={{ bg: "#010101" }}>Download</MenuItem>
                <MenuItem onClick={() => {}} _hover={{ bg: "#010101" }}>
                  Create a Copy
                </MenuItem> */}
                </MenuList>
              </Menu>
              // <Select
              //   textColor="white"
              //   w="30%"
              //   borderColor="#919191"
              //   iconColor="white"
              //   onChange={(e) => {
              //     setCurrentScoreType(e.target.value);
              //     setRowsToDisplay(5);
              //   }}
              // >
              //   {scoreTypes.map((type, index) => (
              //     <option key={index} value={index}>
              //       {type}
              //     </option>
              //   ))}
              // </Select>
            )}
            {/* <Flex
              bgColor="#363636"
              pl="0.7vw"
              pr="0.7vw"
              ml="2vw"
              alignItems="center"
              borderRadius={20}
            >
              {scores[currentScoreType] && (
                <Text color="#E2D8FD" fontWeight="bold">
                  {scores[currentScoreType].results.length.toLocaleString(
                    "en-US"
                  )}{" "}
                  Users
                </Text>
              )}
              </Flex> */}
          </Flex>

          {currentScores && (
            <TableContainer borderRadius="10">
              <Table
                size="sm"
                variant="simple"
                colorScheme="gray"
                borderRadius={15}
              >
                <Thead bgColor="#D1BBF9">
                  <Tr>
                    <Th
                      p={6}
                      color="#0F1419"
                      borderColor="#E1CFFF"
                      fontSize="15px"
                    >
                      Address
                    </Th>
                    <Th
                      isNumeric
                      p={6}
                      color="#0F1419"
                      borderColor="#E1CFFF"
                      fontSize="15px"
                    >
                      Points
                    </Th>
                  </Tr>
                </Thead>
                <Tbody bgColor="#171818">
                  {currentScores.map((row, index) => (
                    <Tr key={index}>
                      <Td color="white" p={6} borderColor="#E1CFFF">
                        <Link
                          href={`https://xp.huddln.io/community/user/${row.address}?project=${projectId}`}
                          target="_blank"
                        >
                          {row.address}
                        </Link>
                      </Td>
                      <Td color="white" isNumeric p={6} borderColor="#E1CFFF">
                        {row.points}
                      </Td>
                    </Tr>
                  ))}
                  <Tr>
                    <Td
                      color="white"
                      p={6}
                      borderColor="#E1CFFF"
                      cursor="pointer"
                      onClick={() => expandScores()}
                    >
                      Load more..
                    </Td>
                    <Td color="white" p={6} borderColor="#E1CFFF"></Td>
                  </Tr>
                </Tbody>
              </Table>
            </TableContainer>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ScoreData;
