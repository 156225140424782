import React, { useState, useEffect, useContext } from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  Box,
  Link,
  Divider,
  useMediaQuery,
  Input,
  useToast,
} from "@chakra-ui/react";
import Lottie from "lottie-react";
import loading from "../../assets/lottie/loader.json";
import complete from "../../assets/lottie/complete.json";
import tryAgain from "../../assets/lottie/warning.json";
import error from "../../assets/imgs/error.png";
import { CgClose } from "react-icons/cg";
import { capitalizeFirstLetter } from "../../func/myFunc.ts";
import missingImg from "../../assets/imgs/missingToken.png";
import { SUPPORTED_WATCHER_NETWORKS } from "../../config/config.ts";
import { MdOutlineMoreHoriz, MdCircle } from "react-icons/md";
import { FiShare, FiDownload, FiPause, FiPlay, FiUpload } from "react-icons/fi";
import trash from "../../assets/imgs/trash.png";
import { Web3Context } from "../../contexts/Web3Context";
import { changeNetwork, getErc20, getWalletToken, web3Divide } from "./Erc20.config";
import ERC20 from "../../abi/ERC20.json";
import { ethers } from "ethers";
export default function Erc20RedemptionModal({
  modalOpen,
  setModalOpen,
  redemptionItem,
  network,
  modalModeProp,
  showModalOptions
}) {
  const toast = useToast();
  const [logo, setLogo]= useState("missingImg")
  const [isLargerThan800] = useMediaQuery("(min-width: 900px)");
  const [redeemAmount, setRedeemAmount] = useState(0);
  const [walletBalance, setWalletBalance] = useState(0);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [modalStatus, setModalStatus] = useState("start");
  const [txHash, setTxHash] = useState("");
  const [modalMode, setModalMode] = useState(modalModeProp);

  const {
    web3,
    accounts,
  } = useContext(Web3Context);

  useEffect(() => {
  // console.log('redemptionItem',redemptionItem)
    const getWalletTokenHandler = async () => {
      try {
        const data = await getWalletToken(
          accounts[0].toLowerCase(),
          network.chainId
        );
        const item = data.find(
          (item) => item.contract_address === redemptionItem?.contractAddress
        );
        const { balance, contract_decimals } = item
        const wB = web3Divide(web3, balance, contract_decimals);
        setWalletBalance(wB)
      } catch (error) {
        console.log(error)
      }
    }

    getWalletTokenHandler()
  }, [
    network,
  ]);

  useEffect(()=>{
    if(redemptionItem.symbol){
     //check if result logo is a valid address,  https://huddln.mypinata.cloud/ipfs/QmW9tZXY7Y7zqr1ADpMX8p9qwNTfaWXdtjbKeELBuNBMUe contains folder of many logos
     const iconVersion= "QmPwpDzQP2cFLeekaqjb4C5tK3ukaXGKEvq1gvLjaRkyGj";
     let symbolNormalized = redemptionItem.symbol.replace(".e",""); //for DAI.e, USDT.e USDC.e etc
     symbolNormalized = symbolNormalized.replace("WETH","ETH"); //spacial caseses
     symbolNormalized = symbolNormalized.replace("WOP","OP"); //spacial caseses
     symbolNormalized = symbolNormalized.replace("WAVAX","AVAX"); //spacial caseses
     symbolNormalized = symbolNormalized.replace("WARB","ARB"); //spacial caseses
     symbolNormalized = symbolNormalized.replace("WMATIC","MATIC"); //spacial caseses
     const logoCached = `https://huddln.mypinata.cloud/ipfs/${iconVersion}/128/color/${symbolNormalized.toLowerCase()}.png`

   
    
     setLogo(logoCached);
    }
  });
  const copyToClipboard = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        toast({
          title: "Text Copied",
          position: "top",
          status: "success",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      })
      .catch((error) => {
        toast({
          title: "Failed to copy text",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const handleRedemption = async (action) => {
    if (!redemptionItem) {
      return;
    } else if (
      accounts[0].toLowerCase() !== redemptionItem?.creator.toLowerCase()
    ) {
      return toast({
        title: `Only the creator can ${action}`,
        position: "top",
        status: "warning",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }

    setModalStatus("loading");
    const { redemptionId } = redemptionItem;

    try {
      await changeNetwork(network?.chainId, web3);

      let erc20 = await getErc20(network.redemptionContract);
      let transaction;
      if (action === "pause") {
        transaction = erc20.methods.pauseRedemption(redemptionId);
      } else if (action === "resume") {
        transaction = erc20.methods.resumeRedemption(redemptionId);
      } else if (action === "remove") {
        transaction = erc20.methods.deleteRedemption(redemptionId);
      } else if (action === "withdraw") {
        const { contractAddress, dec } = redemptionItem;
        const formattedRedeemAmount = web3.utils.toBN(ethers.parseUnits(redeemAmount.toString(), parseInt(dec)).toString());


        transaction = erc20.methods.withdrawRedemptionPool(
          redemptionId,
          formattedRedeemAmount.toString()
        );
      } else if (action === "deposit") {
        const { contractAddress, dec } = redemptionItem;
        const instanceERC20 = new web3.eth.Contract(ERC20.abi, contractAddress);
        const tokenAllowance = await instanceERC20.methods
          .allowance(accounts[0], network?.redemptionContract)
          .call();

        const formattedRedeemAmount = web3.utils.toBN(ethers.parseUnits(redeemAmount.toString(), parseInt(dec)).toString());
        const formattedAllowence = web3.utils.toBN(tokenAllowance);
        if (formattedAllowence.cmp(formattedRedeemAmount) ==-1) {
          await instanceERC20.methods
            .approve(network?.redemptionContract, formattedRedeemAmount.toString())
            .send({
              from: accounts[0],
              maxPriorityFeePerGas: null,
              maxFeePerGas: null,
            })
            .once("transactionHash", (hash) => {
              console.log("hash: " + hash);
            })
            .on("error", (error) => {
              console.log(error);
            });
        }

        transaction = erc20.methods.depositRedemptionPool(
          redemptionId,
          formattedRedeemAmount.toString()
        );
      } else {
        return;
      }

      await transaction
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
          setTxHash(hash);
          // setModalStatus("complete");
          // setModalMode("complete");
        })
        .once("confirmation", (confirmationNumber, receipt) => {
          setModalStatus("complete");
        })
        .on("error", (error) => {
          console.log(error);
          setModalStatus("error");
        });
    } catch (error) {
      console.log("error: " + error);
      setModalStatus("error");
    }
  };

  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
      <ModalOverlay bg="rgba(0,0,0,.75)" />
      <ModalContent
        bg="#121212"
        w="95%"
        maxWidth={isLargerThan800 ? "1120px" : "520px"}
        borderRadius="25px"
        overflow="hidden"
        onClick={() => setShowMoreOptions(false)}
      >
        <Flex
          flexDirection={isLargerThan800 ? "row" : "column"}
          alignItems="center"
        >
          <Flex
            flex="1"
            justifyContent="center"
            position="relative"
            w="500px"
            h="500px"
            bg="#040404"
          >
            <Flex
              height="500px"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                boxSize="150px"
                objectFit="contain"
                src={logo}
                fallbackSrc={missingImg}
                alt=""
              />
            </Flex>
            {modalStatus === "start" ? (
              <></>
            ) : (
              <Flex
                flex="1"
                zIndex={100}
                position="absolute"
                width="100%"
                height="100%"
                bg="rgba(0,0,0,.5)"
                top="0"
                left="0"
                alignItems="center"
                justifyContent="center"
              >
                {modalStatus === "loading" ? (
                  <Lottie
                    animationData={loading}
                    loop={true}
                    style={{ height: 200 }}
                  />
                ) : modalStatus === "complete" ? (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Lottie
                      animationData={complete}
                      loop={false}
                      style={{ height: 200 }}
                    />
                    {txHash && (
                      <Link
                        href={`${network?.explorerUri}/tx/${txHash}`}
                        // href={dynamicHref}
                        isExternal
                        bg=" #C6C7FF"
                        color="#121212"
                        _hover={{
                          cursor: "pointer",
                          transform: "scale(1.05)",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "ease-in-out",
                        }}
                        shadow="lg"
                        fontWeight={700}
                        borderRadius={45}
                        padding="5px"
                        pl="20px"
                        pr="20px"
                        maxWidth="360px"
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Text
                            fontSize="16px"
                            // mt={3}
                            width="90px"
                            fontWeight="700"
                            isTruncated
                          >
                            {`TxHash:`}
                          </Text>
                          <Text
                            fontSize="16px"
                            // mt={3}
                            width="100%"
                            fontWeight="500"
                            isTruncated
                          >
                            {txHash}
                          </Text>
                        </Flex>
                      </Link>
                    )}
                  </Flex>
                ) : (
                  <Lottie
                    animationData={tryAgain}
                    loop={false}
                    style={{ height: 200 }}
                  />
                )}
              </Flex>
            )}
          </Flex>
          <Flex flex="1" w="500px" h="500px" justifyContent="center">
            <Box
              // p="10px"
              // pl="20px"
              // pr="20px"
              flex="1"
              alignItems="center"
              flexDirection="column"
              position="relative"
            >
              <ModalHeader>
                <Flex
                  width="100%"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    color="white"
                    fontSize="24px"
                    mt={2}
                    fontWeight="extrabold"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    maxW="300px"
                    isTruncated
                  >
                    {redemptionItem?.redemptionName}
                  </Text>
                  <Text
                    color="#A7A7A7"
                    fontSize="14px"
                    whiteSpace="nowrap"
                    textOverflow="ellipsis"
                    maxW="300px"
                    isTruncated
                    cursor="pointer"
                    transition="all 0.2s ease-in-out"
                    _hover={{
                      cursor: "pointer",
                      shadow: "md",
                      transform: "scale(1.05)",
                    }}
                    onClick={() =>
                      copyToClipboard(redemptionItem?.redemptionId)
                    }
                  >
                    Redemption ID: {redemptionItem?.redemptionId}
                  </Text>
                </Flex>
              </ModalHeader>
              {showModalOptions && <Flex
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMoreOptions(true);
                  // onCloseExtra && onCloseExtra();
                }}
                position="absolute"
                boxSize="30px"
                borderRadius="50px"
                top="15px"
                right="20px"
                justifyContent="center"
                alignItems="center"
                _hover={{
                  cursor: "pointer",
                  shadow: "dark-lg",
                  transform: "scale(1.1)",
                  borderColor: "black",
                  backgroundColor: "rgba(50,50,50,.5)",
                  transitionDuration: "0.2s",
                  transitionTimingFunction: "ease-in-out",
                }}
              >
                <MdOutlineMoreHoriz color="white" size="18px" />
                {/* <CgClose color="white" size="20px" /> */}
              </Flex>}
              {showMoreOptions && (
                <Flex
                  flexDirection="column"
                  // alignSelf="center"
                  // justifyContent="center"
                  shadow="lg"
                  position="absolute"
                  width="100px"
                  bg="#212121"
                  right="15px"
                  top="46px"
                  // p="4px"
                  // pl="6px"
                  // pr="6px"
                  cursor="pointer"
                  borderRadius="10px"
                  overflow="hidden"
                  zIndex={100}
                // onClick={(e) => {
                //   e.stopPropagation();
                // }}
                >
                  <Flex
                    p="4px"
                    pl="8px"
                    pr="8px"
                    _hover={{
                      bgColor: "rgba(60,60,60,.5)",
                      transform: "scale(1.05)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="flex-start"
                    onClick={(e) => {
                      setModalMode("deposit");
                    }}
                  >
                    <FiUpload color="white" size="12px" />
                    <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                      Deposit
                    </Text>
                  </Flex>
                  <Flex
                    p="4px"
                    pl="8px"
                    pr="8px"
                    _hover={{
                      bgColor: "rgba(60,60,60,.5)",
                      transform: "scale(1.05)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="flex-start"
                    onClick={(e) => {
                      setModalMode("withdraw");
                    }}
                  >
                    <FiDownload color="white" size="12px" />
                    <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                      Withdraw
                    </Text>
                  </Flex>
                  {redemptionItem?.redemptionStatus === "Active" ? (
                    <Flex
                      p="4px"
                      pl="8px"
                      pr="8px"
                      _hover={{
                        bgColor: "rgba(60,60,60,.5)",
                        transform: "scale(1.05)",
                        transitionDuration: "0.2s",
                        transitionTimingFunction: "ease-in-out",
                      }}
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      justifyContent="flex-start"
                      onClick={(e) => {
                        // e.stopPropagation();
                        handleRedemption("pause");
                      }}
                    >
                      <FiPause color="white" size="12px" />
                      <Text
                        color="white"
                        fontSize="12px"
                        fontWeight="500"
                        ml={2}
                      >
                        Pause
                      </Text>
                    </Flex>
                  ) : (
                    <Flex
                      p="4px"
                      pl="8px"
                      pr="8px"
                      _hover={{
                        bgColor: "rgba(60,60,60,.5)",
                        transform: "scale(1.05)",
                        transitionDuration: "0.2s",
                        transitionTimingFunction: "ease-in-out",
                      }}
                      flexDirection="row"
                      alignItems="center"
                      width="100%"
                      justifyContent="flex-start"
                      onClick={(e) => {
                        // e.stopPropagation();
                        handleRedemption("resume");
                      }}
                    >
                      <FiPlay color="white" size="12px" />
                      <Text
                        color="white"
                        fontSize="12px"
                        fontWeight="500"
                        ml={2}
                      >
                        Resume
                      </Text>
                    </Flex>
                  )}
                  <Flex
                    p="4px"
                    pl="8px"
                    pr="8px"
                    _hover={{
                      bgColor: "rgba(60,60,60,.5)",
                      transform: "scale(1.05)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="flex-start"
                    onClick={(e) => {
                      // e.stopPropagation();
                      handleRedemption("remove");
                    }}
                  >
                    <Image src={trash} boxSize="10px" />
                    <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                      Remove
                    </Text>
                  </Flex>
                </Flex>
              )}
              <ModalBody
                flex="1"
                h="290px"
                mt="10px"
                mb="10px"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  bg="#2C2C2C"
                  borderRadius="10px"
                  flexDirection="column"
                  p="20px"
                  pt="20px"
                  pb="20px"
                  height="100%"
                >
                  <Flex flexDirection="column">
                    <Flex flexDirection="column">
                      <Flex flexDirection="row">
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Token Name
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            pl="2px"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                            color={redemptionItem?.tokenName ? "white" : "#ccc"}
                          >
                            {redemptionItem?.tokenName
                              ? redemptionItem?.tokenName
                              : "Not Available"}
                          </Heading>
                        </Flex>
                        <Flex
                          flexDirection="column"
                          flex="1"
                          overflow="hidden"
                          maxW="60%"
                          pl="5px"
                        >
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Token Address
                          </Text>
                          <a
                            href={`${network?.explorerUri}/address/${redemptionItem?.contractAddress}`}
                            // href={dynamicHref + redemptionItem?.contractAddress}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              maxW="200px"
                              transition="all 0.2s ease-in-out"
                              _hover={{
                                cursor: "pointer",
                                shadow: "md",
                                transform: "scale(1.05)",
                              }}
                            >
                              {redemptionItem?.contractAddress}
                            </Heading>
                          </a>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Divider
                      orientation="horizontal"
                      borderWidth="2px"
                      borderRadius="1px"
                      borderColor="#474747"
                      mt={1}
                      mb={1}
                    />
                    <Flex flexDirection="column">
                      <Flex flexDirection="row">
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Symbol
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            pl="2px"
                            color={redemptionItem?.symbol ? "white" : "#ccc"}
                          >
                            {redemptionItem?.symbol
                              ? redemptionItem?.symbol
                              : "Not Available"}
                          </Heading>
                        </Flex>
                        <Flex flex="1" pl="5px">
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              State
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              <Flex alignItems="center">
                                <MdCircle
                                  color={
                                    redemptionItem?.redemptionStatus ===
                                      "Active"
                                      ? "#20E070"
                                      : "#E24E51"
                                  }
                                  size="16px"
                                />
                                <Text
                                  ml="4px"
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="white"
                                  maxW={isLargerThan800 ? "380px" : "320px"}
                                  isTruncated
                                >
                                  {redemptionItem?.redemptionStatus}
                                </Text>
                              </Flex>
                            </Heading>
                          </Flex>
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Network
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              <Flex alignItems="center">
                                {network?.img && (
                                  <Image
                                    // key={src}
                                    // bg="green"
                                    mr="10px"
                                    boxSize="15px"
                                    objectFit="contain"
                                    src={network?.img}
                                    // fallbackSrc={missingImg}
                                    alt=""
                                  />
                                )}
                                <Text
                                  fontSize="16px"
                                  fontWeight={700}
                                  color="white"
                                  maxW={isLargerThan800 ? "380px" : "320px"}
                                  isTruncated
                                >
                                  {network?.name}
                                </Text>
                              </Flex>
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                  <Flex flex="1" />
                  <Flex flexDirection="column">
                    <Flex flexDirection="column">
                      <Flex flexDirection="row">
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Pool Balance
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            color="white"
                            pl="2px"
                          >
                            {(redemptionItem?.poolBalanceFormatted ?? 0) + ' '+redemptionItem?.symbol?? ""}
                          </Heading>
                        </Flex>
                        <Flex flex="1">
                          <Flex flexDirection="column" flex="1" pl="5px">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Token Type
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              ERC-20
                            </Heading>
                          </Flex>
                          <Flex flexDirection="column" flex="1" pl="5px">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Score Type
                            </Text>
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                            >
                              {redemptionItem?.scoreType}
                            </Heading>
                          </Flex>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Divider
                      orientation="horizontal"
                      borderWidth="2px"
                      borderRadius="1px"
                      borderColor="#474747"
                      mt={1}
                      mb={1}
                    />
                    <Flex flexDirection="column">
                      <Flex flexDirection="row">
                        {modalMode === "deposit" || modalMode === "withdraw" ? (
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              {capitalizeFirstLetter(modalMode)} Amount
                            </Text>
                            <Input
                              type="number"
                              bg="#292929"
                              border="2px solid #383838"
                              textColor="white"
                              width="140px"
                              borderRadius="10px"
                              value={redeemAmount}
                              onChange={(e) => setRedeemAmount(e.target.value)}
                              onWheel={(e) => e.target.blur()}
                            />
                            <Text color="#ccc" fontSize="10px" mt="2px">
                              Wallet Balance {walletBalance}
                            </Text>
                          </Flex>
                        ) : (
                          <Flex flexDirection="column" flex="1">
                            <Text
                              color="#A7A7A7"
                              fontWeight={500}
                              fontSize="12px"
                            >
                              Creator
                            </Text>
                            {/* <a
                              href={`${dynamicHref}/address/${redemptionItem?.contractAddress}`}
                              href={
                                dynamicHref + redemptionItem?.contractAddress
                              }
                              target="_blank"
                              rel="noopener noreferrer"
                            > */}
                            <Heading
                              fontSize="16px"
                              fontWeight={700}
                              color="white"
                              pl="2px"
                              overflow="hidden"
                              whiteSpace="nowrap"
                              textOverflow="ellipsis"
                              maxW="180px"
                              transition="all 0.2s ease-in-out"
                              _hover={{
                                cursor: "pointer",
                                shadow: "md",
                                transform: "scale(1.05)",
                              }}
                            >
                              {redemptionItem?.creator}
                            </Heading>
                            {/* </a> */}
                          </Flex>
                        )}
                        <Flex flexDirection="column" flex="1" pl="5px">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Exchange Rate
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            color="white"
                            pl="2px"
                          >
                            {`${redemptionItem?.points} XP : ${redemptionItem?.tokenAmountFormatted
                              } ${redemptionItem?.symbol ?? ""}`}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                {/* {modalMode === "remove" && ( */}
                {/* <Flex
                  flexDirection="row"
                  justifyContent="center"
                  mt="15px"
                  w="100%"
                >
                  <Text color="white" fontWeight={500} fontSize="12px">
                    Transfer remaining tokens from pool before deleting
                    redemption
                  </Text>
                </Flex> */}
                {/* )} */}
              </ModalBody>
              <ModalFooter alignItems="center" justifyContent="center">
                <Flex
                  width="240px"
                  pt="20px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={
                    (modalStatus === "withdraw" || modalStatus === "deposit" || !showModalOptions)
                      ? "space-between"
                      : "center"
                  }
                >
                  <Flex
                    // variant="error"
                    variant="blank"
                    bg="transparent"
                    _hover={{
                      cursor: "pointer",
                      bg: "#212121",
                    }}
                    onClick={() => setModalOpen(false)}
                    fontWeight={700}
                    borderRadius={45}
                    justifyContent="center"
                    alignItems="center"
                    // pl="10px"
                    // pr="10px"
                    width="100px"
                    height="30px"
                    fontSize="14px"
                    disabled={
                      modalStatus === "loading" || modalStatus === "complete"
                    }
                  >
                    <Heading fontSize="14px" fontWeight={700} color="white">
                      {modalStatus === "complete" ? "Close" : "Cancel"}
                    </Heading>
                  </Flex>
                  {(modalMode === "deposit" || modalMode === "withdraw" || !showModalOptions) && (
                    <Button
                      // variant="error"
                      variant="blank"
                      bg="transparent"
                      _hover={{
                        cursor: "pointer",
                        bg: modalMode === "remove" ? "#E24E51" : "#C6C7FF",
                        color: modalMode === "remove" ? "white" : "black",
                      }}
                      onClick={() => handleRedemption(modalMode)}
                      borderRadius={45}
                      justifyContent="center"
                      alignItems="center"
                      // pl="10px"
                      // pr="10px"
                      width="100px"
                      height="30px"
                      fontSize="14px"
                      fontWeight={700}
                      disabled={modalStatus === "loading"}
                    >
                      {capitalizeFirstLetter(modalMode)}
                    </Button>
                  )}
                </Flex>
              </ModalFooter>
            </Box>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
