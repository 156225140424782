import React from "react";
import { Button, Flex, Heading, Icon, Text } from "@chakra-ui/react";
import { HiLightningBolt } from "react-icons/hi";
import CustomCard from "../../components/CustomCard/CustomCard";

const SnapshotConnect = ({ handleConnect }) => {
  return (
    <Flex alignItems="center" justifyContent="center" w="100%" h="100%">
      <Flex w="100%" maxW="600px">
        <CustomCard>
          <Flex
            maxW="600px"
            flexDirection="column"
            alignItems="center"
            justifyContent="space-evenly"
          >
            <Icon
              as={HiLightningBolt}
              color="#FCAC17"
              fontSize={60}
              mb="30px"
            />
            <Text color="white" fontWeight={600} fontSize="20px" mb="10px">
              Connect Snapshot
            </Text>
            <Text color="#a7a7a7" fontSize="16px" fontWeight={500} mb="40px">
              Reward community members that have voted in your proposals
            </Text>
            <Button
              variant="purple"
              fontSize="18px"
              fontWeight={600}
              borderRadius={45}
              onClick={handleConnect}
            >
              Connect
            </Button>
          </Flex>
        </CustomCard>
      </Flex>
    </Flex>
  );
};

export default SnapshotConnect;
