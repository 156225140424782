import React from "react";
import {
  Button,
  Flex,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Image,
  Text,
  Box,
  Link,
  Divider,
  useMediaQuery,
} from "@chakra-ui/react";
import DisplayNftType from "../DisplayNftType/DisplayNftType";
import Lottie from "lottie-react";
import loading from "../../assets/lottie/loader.json";
import complete from "../../assets/lottie/complete.json";
import tryAgain from "../../assets/lottie/warning.json";
import error from "../../assets/imgs/error.png";
import { CgClose } from "react-icons/cg";

export default function RedemptionModal({
  modalOpen,
  setModalOpen,
  imageType,
  image,
  modalStatus,
  txHash,
  modalMode,
  onClick,
  modalTitle,
  modalDescription,
  nftTitle,
  nftAddress,
  nftDescription,
  nftId,
  nftAmount,
  nftTokenType,
  actionPoints,
  actionName,
  btnText,
  onCloseExtra,
}) {
  const [isLargerThan800] = useMediaQuery("(min-width: 900px)");

  return (
    <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} isCentered>
      <ModalOverlay bg="rgba(0,0,0,.75)" />
      <ModalContent
        bg="#121212"
        w="95%"
        maxWidth={isLargerThan800 ? "1120px" : "520px"}
        borderRadius="25px"
        overflow="hidden"
      >
        <Flex
          flexDirection={isLargerThan800 ? "row" : "column"}
          alignItems="center"
        >
          <Flex
            flex="1"
            justifyContent="center"
            position="relative"
            w="500px"
            h="500px"
            bg="#040404"
          >
            <DisplayNftType
              autoPlay={false}
              type={imageType}
              src={image}
              height="500px"
              width="100%"
            />
            {modalStatus === "start" ? (
              <></>
            ) : (
              <Flex
                flex="1"
                zIndex={100}
                position="absolute"
                width="100%"
                height="100%"
                bg="rgba(0,0,0,.5)"
                top="0"
                left="0"
                alignItems="center"
                justifyContent="center"
              >
                {modalStatus === "loading" ? (
                  <Lottie
                    animationData={loading}
                    loop={true}
                    style={{ height: 200 }}
                  />
                ) : modalStatus === "complete" ? (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Lottie
                      animationData={complete}
                      loop={false}
                      style={{ height: 200 }}
                    />
                    {txHash && (
                      <Link
                        href={`https://polygonscan.com/tx/${txHash}`}
                        isExternal
                        bg=" #C6C7FF"
                        color="#121212"
                        _hover={{
                          cursor: "pointer",
                          transform: "scale(1.05)",
                          transitionDuration: "0.2s",
                          transitionTimingFunction: "ease-in-out",
                        }}
                        shadow="lg"
                        fontWeight={700}
                        borderRadius={45}
                        padding="5px"
                        pl="20px"
                        pr="20px"
                        maxWidth="360px"
                      >
                        <Flex flexDirection="row" alignItems="center">
                          <Text
                            fontSize="16px"
                            // mt={3}
                            width="90px"
                            fontWeight="700"
                            isTruncated
                          >
                            {`TxHash:`}
                          </Text>
                          <Text
                            fontSize="16px"
                            // mt={3}
                            width="100%"
                            fontWeight="500"
                            isTruncated
                          >
                            {txHash}
                          </Text>
                        </Flex>
                      </Link>
                    )}
                  </Flex>
                ) : (
                  <Lottie
                    animationData={tryAgain}
                    loop={false}
                    style={{ height: 200 }}
                  />
                )}
              </Flex>
            )}
          </Flex>
          <Flex flex="1" w="500px" h="500px" justifyContent="center">
            <Box
              // p="10px"
              // pl="20px"
              // pr="20px"
              flex="1"
              alignItems="center"
              flexDirection="column"
            >
              <ModalHeader>
                <Flex
                  width="100%"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text
                    color="white"
                    fontSize="24px"
                    mt={2}
                    fontWeight="extrabold"
                  >
                    {modalTitle}
                  </Text>
                  <Text color="#A7A7A7" fontSize="14px">
                    {modalDescription}
                  </Text>
                </Flex>
              </ModalHeader>
              {/* <ModalCloseButton
                color="white"
                borderRadius="50px"
                _focus={{
                  boxShadow: "none",
                }}
                _hover={{
                  cursor: "pointer",
                  shadow: "dark-lg",
                  transform: "scale(1.1)",
                  borderColor: "black",
                  backgroundColor: "rgba(50,50,50,.5)",
                  transitionDuration: "0.2s",
                  transitionTimingFunction: "ease-in-out",
                }}
              /> */}
              <Flex
                onClick={() => {
                  setModalOpen(false);
                  onCloseExtra && onCloseExtra();
                }}
                position="absolute"
                boxSize="30px"
                borderRadius="50px"
                top="15px"
                right="20px"
                justifyContent="center"
                alignItems="center"
                _hover={{
                  cursor: "pointer",
                  shadow: "dark-lg",
                  transform: "scale(1.1)",
                  borderColor: "black",
                  backgroundColor: "rgba(50,50,50,.5)",
                  transitionDuration: "0.2s",
                  transitionTimingFunction: "ease-in-out",
                }}
              >
                <CgClose color="white" size="20px" />
              </Flex>
              <ModalBody
                flex="1"
                h="290px"
                mt="10px"
                mb="10px"
                justifyContent="center"
                alignItems="center"
              >
                <Flex
                  bg="#2C2C2C"
                  borderRadius="10px"
                  flexDirection="column"
                  p="20px"
                  pt="20px"
                  pb="20px"
                  height="100%"
                >
                  <Flex flexDirection="column">
                    <Flex flexDirection="column">
                      <Text color="#A7A7A7" fontWeight={600} fontSize="12px">
                        Title
                      </Text>
                      <Heading
                        fontSize="16px"
                        fontWeight={700}
                        color="white"
                        pl="2px"
                        maxW={isLargerThan800 ? "380px" : "320px"}
                        isTruncated
                      >
                        {nftTitle ? nftTitle : nftAddress}
                      </Heading>
                    </Flex>
                    <Divider
                      orientation="horizontal"
                      borderWidth="2px"
                      borderRadius="1px"
                      borderColor="#474747"
                      mt={1}
                      mb={1}
                    />
                    <Flex flexDirection="column">
                      <Text color="#A7A7A7" fontWeight={500} fontSize="12px">
                        Description
                      </Text>
                      <Text
                        fontSize="16px"
                        fontWeight={700}
                        color="white"
                        pl="2px"
                        maxW={isLargerThan800 ? "380px" : "320px"}
                        isTruncated
                      >
                        {nftDescription ? nftDescription : nftId}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex flex="1" />
                  <Flex flexDirection="column">
                    <Flex flexDirection="column">
                      <Flex flexDirection="row">
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Editions
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            color="white"
                            pl="2px"
                          >
                            {nftAmount}
                          </Heading>
                        </Flex>
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            NFT Type
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            color="white"
                            pl="2px"
                          >
                            {nftTokenType}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Divider
                      orientation="horizontal"
                      borderWidth="2px"
                      borderRadius="1px"
                      borderColor="#474747"
                      mt={1}
                      mb={1}
                    />
                    <Flex flexDirection="column">
                      <Flex flexDirection="row">
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Deduct from action
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            color="white"
                            pl="2px"
                          >
                            {actionName}
                          </Heading>
                        </Flex>
                        <Flex flexDirection="column" flex="1">
                          <Text
                            color="#A7A7A7"
                            fontWeight={500}
                            fontSize="12px"
                          >
                            Points
                          </Text>
                          <Heading
                            fontSize="16px"
                            fontWeight={700}
                            color="white"
                            pl="2px"
                          >
                            {actionPoints}
                          </Heading>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </Flex>
                {modalMode === "remove" && (
                  <Flex flexDirection="row" alignItems="center" mt="15px">
                    <Image src={error} height="12px" mr={1} />
                    <Text color="white" fontWeight={500} fontSize="12px">
                      Allow about 5 minutes for changes to take effect.
                    </Text>
                  </Flex>
                )}
              </ModalBody>
              <ModalFooter alignItems="center" justifyContent="center">
                <Flex
                  width="240px"
                  pt="20px"
                  flexDirection="row"
                  alignItems="center"
                  justifyContent={
                    modalStatus !== "complete" ? "space-between" : "center"
                  }
                >
                  <Flex
                    // variant="error"
                    variant="blank"
                    bg="transparent"
                    _hover={{
                      cursor: "pointer",
                      bg: "#212121",
                    }}
                    onClick={() => setModalOpen(false)}
                    fontWeight={700}
                    borderRadius={45}
                    justifyContent="center"
                    alignItems="center"
                    // pl="10px"
                    // pr="10px"
                    width="100px"
                    height="30px"
                    fontSize="14px"
                    disabled={
                      modalStatus === "loading" || modalStatus === "complete"
                    }
                  >
                    <Heading fontSize="14px" fontWeight={700} color="white">
                      {modalStatus === "complete" ? "Close" : "Cancel"}
                    </Heading>
                  </Flex>
                  {modalStatus !== "complete" && (
                    <Button
                      // variant="error"
                      variant="blank"
                      bg="transparent"
                      _hover={{
                        cursor: "pointer",
                        bg: modalMode === "remove" ? "#E24E51" : "#C6C7FF",
                        color: modalMode === "remove" ? "white" : "black",
                      }}
                      onClick={onClick}
                      borderRadius={45}
                      justifyContent="center"
                      alignItems="center"
                      // pl="10px"
                      // pr="10px"
                      width="100px"
                      height="30px"
                      fontSize="14px"
                      fontWeight={700}
                      disabled={
                        modalStatus === "loading" || modalStatus === "complete"
                      }
                    >
                      {btnText}
                      {/* <Heading
                        fontSize="14px"
                        fontWeight={700}
                        color="white"
                        _hover={{
                          color: modalMode === "remove" ? "white" : "black",
                        }}
                      >
                        {btnText}
                      </Heading> */}
                    </Button>
                  )}
                </Flex>
              </ModalFooter>
            </Box>
          </Flex>
        </Flex>
      </ModalContent>
    </Modal>
  );
}
