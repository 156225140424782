import React, { useEffect, useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import {
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { Web3Context } from "./contexts/Web3Context";
import CreateProject from "./pages/CreateProject";
import ProjectList from "./pages/ProjectList";
import SimpleSidebar from "./components/SimpleSidebar/SimpleSidebar.tsx";
import NotConnected from "./components/NotConnected";

import UpdateScore from "./pages/UpdatePage/UpdatePage";
import AddActions from "./pages/AddActions/AddActions";
import ScoreTypes from "./pages/ScoreTypes/ScoreTypes";
import OwnersUpdaters from "./pages/AdminPage/AdminPage";
import ScoreData from "./components/ScoreData";
import CreateQRClaim from "./pages/CreatePage/CreatePage";
import ClaimActivity from "./pages/ClaimActivity";
import SnapShotPage from "./pages/SnapShotPage/SnapShotPage";
import Discord from "./pages/DiscordPage/DiscordPage";
import ChooseRedemption from "./pages/ChooseRedemption/ChooseRedemption";
import Erc20 from "./pages/Erc20/Erc20";
import Levels from "./pages/LevelsPage/LevelsPage";
import TwitterBot from "./pages/TwitterBot/TwitterBot";
import NFTWatcher from "./pages/NFTWatcher/NFTWatcher";
import AdminPage from "./pages/AdminPage/AdminPage";
import ProjectSidebar from "./components/Sidebar";
import ERC20Redemption from './pages/ERC20Redemption/ERC20Redemption'

const App = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { web3, network } = useContext(Web3Context);

  useEffect(() => {
    const checkNetwork = async () => {
      const networkId = await web3.eth.net.getId();

      if (networkId != 137) {
        onOpen();
      }
    };

    checkNetwork();
  }, [web3]);

  return (
    <Flex minHeight="100vh" bgColor="#121212">
      <BrowserRouter>
        <Routes>
          <Route path="project/:projectId" element={<SimpleSidebar />}>
            <Route path="" element={<ScoreData />} />
            <Route path="scores" index element={<ScoreData />} />
            <Route path="update-score" element={<UpdateScore />} />
            <Route path="add-actions" element={<AddActions />} />
            <Route path="score-types" element={<ScoreTypes />} />
            <Route path="owners" element={<OwnersUpdaters />} />
            <Route path="create-qr" element={<CreateQRClaim />} />
            <Route path="claim-activity" element={<ClaimActivity />} />
            <Route path="discord" element={<Discord />} />
            <Route path="snapshot" element={<SnapShotPage />} />
            <Route path="redemptions" element={<ChooseRedemption />} />
            <Route path="erc20" element={<Erc20 />} />
            <Route path="erc20-test" element={<ERC20Redemption />} />
            
            <Route path="levels" element={<Levels />} />
            <Route path="twitter" element={<TwitterBot />} />
            <Route path="watcher" element={<NFTWatcher />} />
            <Route path="admin" element={<AdminPage />} />
          </Route>
          <Route path="/connect" element={<NotConnected />} />
          <Route
            path="/create-project"
            element={
              <ProjectSidebar>
                <CreateProject />
              </ProjectSidebar>
            }
          />
          <Route
            path="/"
            element={
              <ProjectSidebar>
                <ProjectList />
              </ProjectSidebar>
            }
          />
        </Routes>
      </BrowserRouter>
      {/* <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bgColor="#383838" borderColor="white" borderWidth={1}>
          <ModalHeader color="white">Please connect to Polygon.</ModalHeader>
          <ModalCloseButton color="whiteAlpha.900" />

          <ModalFooter>
            <Button
              bgGradient="linear(to-r, #B5E5FF, #F9ABE7)"
              _active={{
                bgGradient: "linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)",
              }}
              _hover={{
                bgGradient: "linear(to-r, #93D9FF, #9EA0FE, #DE86FF, #FE7BE0)",
              }}
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal> */}
    </Flex>
  );
};

export default App;
