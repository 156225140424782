import React, { useContext, useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  useToast,
  useDisclosure,
  Spinner,
} from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import { Web3Context } from "../../contexts/Web3Context";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import CustomExist from "../../components/CustomExist/CustomExist";
import { useNoArgs } from "../../func/myFunc.ts";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import ClickRemoveModal from "../../components/ClickRemoveModal/ClickRemoveModal";
import changeNetworkToPolygon from "../../func/changeNetwork";

const GET_SCORETYPES = gql`
  query getResults($projectId: String!) {
    projects(where: { id: $projectId }) {
      scoreTypes
    }
  }
`;

const ScoreTypes = () => {
  const { accounts, contract } = useContext(Web3Context);
  const toast = useToast();
  const { projectId } = useParams();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scoreType, setScoreType] = useState([""]);
  const [existingScoreTypes, setExistingScoreTypes] = useState([""]);
  const [btnStatus, setBtnStatus] = useState("");
  const [locked, setLocked] = useState(false);
  const [removeData, setRemoveData] = useState();

  const {
    loading,
    data: scoreTypeData,
    refetch,
  } = useQuery(GET_SCORETYPES, {
    variables: { projectId },
  });
  const doRefetch = useNoArgs(refetch);

  const handleClick = async () => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");

    await changeNetworkToPolygon();

    const duplicateMap = scoreType.reduce((map, type) => {
      map[type] = (map[type] || 0) + 1;
      return map;
    }, {});

    const hasDuplicate = Object.values(duplicateMap).some((count) => count > 1);
    const isAnyScoreTypeIncluded = scoreType.some((type) =>
      existingScoreTypes.includes(type)
    );
    const isAnyEmptyField = scoreType.some((type) => type.trim() === "");

    let title = "";

    if (hasDuplicate) {
      title = "Please add different Score Types";
    } else if (isAnyScoreTypeIncluded) {
      title = "Please add a new Score Type";
    } else if (isAnyEmptyField) {
      title = "Please fill in all Score Type fields";
    }

    if (title !== "") {
      setBtnStatus("");
      return toast({
        title,
        // description: "We've created your account for you.",
        position: "top",
        status: "error",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }

    await contract.methods
      .addScoreTypes(projectId, scoreType)
      .send({
        from: accounts[0],
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .on("transactionHash", (hash) => {
        console.log("hash: " + hash);
      })
      .on("confirmation", (confirmationNumber, receipt) => {
        if (confirmationNumber === 5) {
          doRefetch();
          setBtnStatus("");
          toast({
            title: "Score Added",
            // description: "We've created your account for you.",
            position: "top",
            status: "success",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .on("error", (error) => {
        let title = error.message;
        setBtnStatus("");
        toast({
          title,
          // description: "We've created your account for you.",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const modalRemoveHandler = (item) => {
    setRemoveData(item);
    onOpen();
  };

  const removeHandler = async (item) => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");

    await changeNetworkToPolygon();

    await contract.methods
      .removeScoreType(projectId, item)
      .send({
        from: accounts[0],
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .on("transactionHash", (hash) => {
        console.log("hash: " + hash);
      })
      .on("confirmation", (confirmationNumber, receipt) => {
        if (confirmationNumber === 5) {
          doRefetch();
          onClose();
          setBtnStatus("");
          toast({
            title: "Score Removed",
            // description: "We've created your account for you.",
            position: "top",
            status: "success",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .on("error", (error) => {
        setBtnStatus("");
        let title = error.message;
        toast({
          title,
          // description: "We've created your account for you.",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    if (scoreTypeData) {
      setExistingScoreTypes(scoreTypeData?.projects[0]?.scoreTypes);
    }
  }, [scoreTypeData]);

  const handleEdit = (e, index) => {
    const val = e.target.value;

    const updatedItems = [...scoreType];
    updatedItems[index] = val;
    setScoreType(updatedItems);
  };

  const handleRemove = (index) => {
    const updatedItems = [...scoreType];
    updatedItems.splice(index, 1);
    setScoreType(updatedItems);
  };

  const handleAddItem = () => {
    const updatedItems = [...scoreType, ""];
    setScoreType(updatedItems);
  };

  if (loading) {
    return <LoadComponent />;
  } else {
    return (
      <PageWrapper width="800px">
        <Flex w="100%" alignItems="center" mb="30px">
          <Heading color="white" fontSize="18px">
            Score Types
          </Heading>
        </Flex>
        {existingScoreTypes.length > 0 && (
          <CustomExist
            array={existingScoreTypes}
            removeHandler={modalRemoveHandler}
            title="Scores"
          />
        )}
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="15px"
          >
            <Flex flexDirection="row" alignItems="center">
              <Text
                color={locked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
              >
                Add New Score Type
              </Text>
            </Flex>
            <Locks state={locked} setState={setLocked} />
          </Flex>
          {/* <Text color="#A7A7A7" fontSize="14px" mb="15px">
            Score Type Name
          </Text> */}
          <Flex flexDirection="column" position="relative">
            {scoreType.map((form, index) => (
              <Flex
                key={index}
                width="100%"
                mb="10px"
                // flexDir="column"
                alignItems="flex-end"
                justifyContent="flex-start"
              >
                <Flex
                  width="100%"
                  alignItems="flex-end"
                  justifyContent="flex-start"
                >
                  <Flex flexDir="column" width="100%" maxW="300px" mr="15px">
                    <Text color="#A7A7A7" fontSize="12px" mb="6px">
                      Score Type Name
                    </Text>
                    <Input
                      borderRadius="10px"
                      textColor={locked ? "#aaa" : "white"}
                      // maxLength="250"
                      width="100%"
                      maxW="300px"
                      max={100}
                      pl={2}
                      pr={2}
                      bg="#292929"
                      border="2px solid #383838"
                      value={scoreType[index]}
                      onChange={(e) => handleEdit(e, index)}
                    />
                  </Flex>
                </Flex>
                <Flex
                  alignItems="center"
                  w="100%"
                  justifyContent="space-between"
                  mb="5px"
                >
                  {index > 0 || true ? (
                    <Button
                      onClick={() => handleRemove(index)}
                      disabled={locked}
                      h="30px"
                      w="90px"
                      borderRadius="15px"
                      fontSize="14px"
                      fontWeight={700}
                      variant="error"
                    >
                      Remove
                    </Button>
                  ) : (
                    <Flex h="30px" w="90px" />
                  )}
                </Flex>
              </Flex>
            ))}
            <Button
              onClick={handleAddItem}
              h="30px"
              w="90px"
              mt="10px"
              fontSize="14px"
              fontWeight={700}
              variant="purple"
              disabled={locked}
            >
              Add field
            </Button>
            {locked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
          {/* <Flex direction="column" pos="relative">
          <Input
            borderRadius="10px"
            textColor={locked ? "#aaa" : "white"}
            maxLength="250"
            width="80%"
            max={100}
            p={2}
            bg="#292929"
            border="2px solid #383838"
            mr="10px"
            onChange={(e) => setScoreType(e.target.value)}
            value={scoreType}
          />
          )
          {locked && (
            <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
          )}
        </Flex> */}
        </CustomCard>

        <Button
          onClick={handleClick}
          variant="purple"
          alignSelf="center"
          mt={10}
          disabled={scoreType.length === 0}
        >
          {!isOpen && btnStatus === "loading" ? (
            <Spinner size="xs" color="gray.500" />
          ) : (
            "Add"
          )}
        </Button>
        <ClickRemoveModal
          isOpen={isOpen}
          onOpe={onOpen}
          onClose={onClose}
          removeHandler={removeHandler}
          item={removeData}
          btnStatus={btnStatus}
        />
      </PageWrapper>
    );
  }
};

export default ScoreTypes;
