// import "./ChooseRedemption.css";
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useReducer,
} from "react";
import { FiShare, FiDownload, FiPause, FiPlay, FiUpload } from "react-icons/fi";
import TokenRedemption from "./TokenRedemption/TokenRedemption";
import {
  Flex,
  Heading,
  Image,
  Text,
  Box,
  Wrap,
  useMediaQuery,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineMoreHoriz } from "react-icons/md";
import Lottie from "lottie-react";
import bonfire from "../../assets/lottie/bonfire.json";
import refresh from "../../assets/lottie/refresh.json";
import trash from "../../assets/imgs/trash.png";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import Pagination from "../../components/Pagination/Pagination";
import TokenRedemptionModal from "./TokenRedemptionModal";
import RedemptionCard from "../../components/RedemptionCard/RedemptionCard";
import ERC20 from "../../abi/ERC20.json";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import { SUPPORTED_WATCHER_NETWORKS } from "../../config/config.ts";
import { changeNetwork, getTokenData, web3Divide, getErc20 } from "./Erc20.config";
import { capitalizeFirstLetter } from "../../func/myFunc.ts";
import missingImg from "../../assets/imgs/missingToken.png";
import Erc20RedemptionModal from "./Erc20RedemptionModal";
import Web3 from "web3";
import { ethers } from 'ethers';
const CARD_WIDTH = "150px";

const RenderItem = React.memo(
  ({ item, openModalHandler, cardOpenModalHandler, network, web3 }) => {
    const {
      name,
      redemptionStatus,
      contractAddress,
      points,
      tokenAmount,
      poolBalance,
      creator,
      limitOnePerWallet,
    } = item;
    const [showOptions, setShowOptions] = useState(false);
    const [showMoreOptions, setShowMoreOptions] = useState(false);
    const [src, setSrc] = useState("");
    const [isLoaded, setIsLoaded] = useState(false);
    const [poolBalanceFormattedDisplay, setPoolBalanceFormattedDisplay] =
      useState(0);
    const [tokenAmountFormattedDisplay, setTokenAmountFormattedDisplay] =
      useState(0);

    const [data, setData] = useState(item);

    useEffect(() => {
      const getTokenMetadata = async () => {
        const payload = {
          id: 1,
          jsonrpc: "2.0",
          method: "alchemy_getTokenMetadata",
          params: [contractAddress],
        };

        try {
          const response = await fetch(network.alchemy, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          });

          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const { result } = await response.json();
         
          
          let [symbol, tokenName, decimals] = await getTokenData(
            network,
            contractAddress
          );
       
          if (symbol == ""  && response.symbol !=="") {
            symbol = result.symbol;
            tokenName = result.name;
            decimals = result.decimals;
          }
         



          const tokenAmountFormatted = ethers.formatUnits(tokenAmount.toString(), parseInt(decimals)).toString();


          const poolBalanceFormatted = ethers.formatUnits(poolBalance.toString(), parseInt(decimals)).toString();
          // const tokenAmountFormatted = web3Divide(web3, tokenAmount, decimals);

          setPoolBalanceFormattedDisplay(poolBalanceFormatted);
          setTokenAmountFormattedDisplay(tokenAmountFormatted);

          setData((prevState) => ({
            ...prevState,
            redemptionName: name,
            ...result,
            poolBalanceFormatted,
            tokenAmountFormatted,
            symbol,
            tokenName,
            dec: decimals,
          }));
          //check if result logo is a valid address,  https://huddln.mypinata.cloud/ipfs/QmW9tZXY7Y7zqr1ADpMX8p9qwNTfaWXdtjbKeELBuNBMUe contains folder of many logos
          const iconVersion = "QmPwpDzQP2cFLeekaqjb4C5tK3ukaXGKEvq1gvLjaRkyGj";
          let symbolNormalized = symbol.replace(".e", ""); //for DAI.e, USDT.e USDC.e etc
          symbolNormalized = symbolNormalized.replace("WETH", "ETH"); //spacial caseses
          symbolNormalized = symbolNormalized.replace("WOP", "OP"); //spacial caseses
          symbolNormalized = symbolNormalized.replace("WAVAX", "AVAX"); //spacial caseses
          symbolNormalized = symbolNormalized.replace("WARB", "ARB"); //spacial caseses
          symbolNormalized = symbolNormalized.replace("WMATIC", "MATIC"); //spacial caseses
          const logoCached = `https://huddln.mypinata.cloud/ipfs/${iconVersion}/128/color/${symbolNormalized.toLowerCase()}.png`
          console.log(logoCached)
          item.logo = logoCached;

          setSrc(logoCached);
          // Process the response data here
        } catch (error) {
          console.error("Error:", error);
        } finally {
          setIsLoaded(true);
        }
      };

      getTokenMetadata();
    }, [item]);

    return (
      <Box
        onMouseEnter={() => {
          setShowOptions(true);
        }}
        onMouseLeave={() => {
          setShowOptions(false);
          setShowMoreOptions(false);
        }}
        onClick={() => cardOpenModalHandler(data)}
        _hover={{
          cursor: "pointer",
          shadow: "dark-lg",
          transform: "scale(1.1)",
          transitionDuration: "0.2s",
          transitionTimingFunction: "ease-in-out",
        }}
        width="150px"
        pb="4px"
        border="2px solid #303030"
        borderRadius="10px"
        overflow="hidden"
      >
        <Skeleton
          isLoaded={isLoaded}
          height={CARD_WIDTH}
          width={CARD_WIDTH}
          startColor="gray.100"
          endColor="'gray.200"
        >
          <Flex
            boxSize="150px"
            position="relative"
            bg="#141414"
            justifyContent="center"
            alignItems="center"
          >
            <Image
              // key={src}
              // bg="green"
              padding="2px"
              borderRadius="40px"
              w="80px"
              h="80px"
              objectFit="contain"
              src={src}
              fallbackSrc={missingImg}
              alt=""
            />

            {showOptions && (
              <Flex
                shadow="lg"
                boxSize="24px"
                position="absolute"
                bg="black"
                top="5px"
                right="5px"
                borderRadius="12px"
                justifyContent="center"
                alignItems="center"
                zIndex={100}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowMoreOptions(true);
                }}
              >
                <MdOutlineMoreHoriz color="white" size="18px" />
              </Flex>
            )}
            {showMoreOptions && (
              <Flex
                flexDirection="column"
                // alignSelf="center"
                // justifyContent="center"
                shadow="lg"
                position="absolute"
                width="100px"
                bg="#212121"
                top="37px"
                right="5px"
                // p="4px"
                // pl="6px"
                // pr="6px"
                borderRadius="10px"
                overflow="hidden"
                zIndex={100}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <Flex
                  p="4px"
                  pl="8px"
                  pr="8px"
                  _hover={{
                    bgColor: "rgba(60,60,60,.5)",
                    transform: "scale(1.05)",
                    transitionDuration: "0.2s",
                    transitionTimingFunction: "ease-in-out",
                  }}
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                  onClick={(e) => {
                    openModalHandler(data, "deposit");
                  }}
                >
                  <FiUpload color="white" size="12px" />
                  <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                    Deposit
                  </Text>
                </Flex>
                <Flex
                  p="4px"
                  pl="8px"
                  pr="8px"
                  _hover={{
                    bgColor: "rgba(60,60,60,.5)",
                    transform: "scale(1.05)",
                    transitionDuration: "0.2s",
                    transitionTimingFunction: "ease-in-out",
                  }}
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                  onClick={(e) => {
                    openModalHandler(data, "withdraw");
                  }}
                >
                  <FiDownload color="white" size="12px" />
                  <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                    Withdraw
                  </Text>
                </Flex>
                {redemptionStatus === "Active" ? (
                  <Flex
                    p="4px"
                    pl="8px"
                    pr="8px"
                    _hover={{
                      bgColor: "rgba(60,60,60,.5)",
                      transform: "scale(1.05)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="flex-start"
                    onClick={(e) => {
                      openModalHandler(data, "pause");
                    }}
                  >
                    <FiPause color="white" size="12px" />
                    <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                      Pause
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    p="4px"
                    pl="8px"
                    pr="8px"
                    _hover={{
                      bgColor: "rgba(60,60,60,.5)",
                      transform: "scale(1.05)",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    flexDirection="row"
                    alignItems="center"
                    width="100%"
                    justifyContent="flex-start"
                    onClick={(e) => {
                      openModalHandler(data, "resume");
                    }}
                  >
                    <FiPlay color="white" size="12px" />
                    <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                      Resume
                    </Text>
                  </Flex>
                )}
                <Flex
                  p="4px"
                  pl="8px"
                  pr="8px"
                  _hover={{
                    bgColor: "rgba(60,60,60,.5)",
                    transform: "scale(1.05)",
                    transitionDuration: "0.2s",
                    transitionTimingFunction: "ease-in-out",
                  }}
                  flexDirection="row"
                  alignItems="center"
                  width="100%"
                  justifyContent="flex-start"
                  onClick={(e) => {
                    openModalHandler(data, "remove");
                  }}
                >
                  <Image src={trash} boxSize="10px" />
                  <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                    Remove
                  </Text>
                </Flex>
              </Flex>
            )}
            <Flex
              shadow="md"
              flexDirection="row"
              position="absolute"
              bg="rgba(0,0,0,.5)"
              bottom="5px"
              right="5px"
              p="2px"
              pr="7px"
              pl="7px"
              borderRadius="10px"
              justifyContent="center"
              alignItems="center"
            >
              <Text color="white" fontSize="10px" fontWeight="700">
                {points} XP : {tokenAmountFormattedDisplay} Tokens
              </Text>
            </Flex>
          </Flex>
        </Skeleton>
        <Flex flexDirection="column" p={2}>
          <Text

            // bgGradient="linear(to-l,  #B5E5FF, #C6C7FF, #E1A9F6)"
            // bgClip="text"
            color="white"
            fontSize="16px"
            // mt={3}
            fontWeight="700"
            maxW="80%"
            noOfLines={1}
          >
            {name}
          </Text>
          <Text
            color="white"
            fontSize="10px"
            fontWeight="500"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {limitOnePerWallet ? "One per wallet" : "Unlimited"}
          </Text>
          <Text
            color="white"
            fontSize="10px"
            fontWeight="500"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            {contractAddress}
          </Text>
          <Text color="white" fontSize="10px" fontWeight="500">
            {redemptionStatus}
          </Text>
          <Text
            color="#A7A7A7"
            fontSize="10px"
            fontWeight="500"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
          >
            Pool Balance: {poolBalanceFormattedDisplay} {data?.symbol ? data?.symbol : ""}
          </Text>
        </Flex>
      </Box>
    );
  }
);

export default function CreateRedemption() {
  const { projectId } = useParams();
  const toast = useToast();
  const lottieRefreshRef = useRef(null);
  const {
    web3,
    accounts,
  } = useContext(Web3Context);

  const [type, setType] = useState("");
  const [projects, setProject] = useState([]);
  const [network, setNetwork] = useState(SUPPORTED_WATCHER_NETWORKS[0]);
  const [btnText, setBtnText] = useState("");
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  //modal
  const [modalOpen, setModalOpen] = useState(false);
  const [erc20ModalOpen, setErc20ModalOpen] = useState(false);
  const [showModalOptions, setShowModalOptions] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [modalStatus, setModalStatus] = useState("start");
  //nft
  const [txHash, setTxHash] = useState("");
  const [redemptionItem, setRedemptionItem] = useState({});
  const [loop, setLoop] = useState(true);

  useEffect(() => {
    getListed();
  }, [network]);

  const getListed = async () => {
    setCurrentPage(1);
    setLoop(true);
    try {
      const response = await fetch(network?.graphUri, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
              query ($projectId: String!, $creator: String!){
                  redemptions(where: { projectId: $projectId }) {
                  id
                  redemptionId
                  projectId
                  creator
                  name
                  tokenAmount
                  points
                  actionName
                  scoreType
                  contractAddress
                  limitOnePerWallet
                  timestamp
                  redemptionStatus
                  poolBalance
                }
              }
            `,
          variables: {
            projectId,
            creator: accounts[0],
          },
        }),
      });

      const { data } = await response.json();

      if (data?.redemptions) {

        setProject(data.redemptions);
      } else {
        setProject([]);
      }
    } catch (error) {
      console.log(error);
      setProject([]);
    }

    setLoop(false);
  };
  const cardOpenModalHandler = async (item, mode) => {

    setShowModalOptions(true)
    setRedemptionItem(item);
    setModalMode('')
    setErc20ModalOpen(true);
  };

  const openModalHandler = async (item, mode) => {
    if (accounts[0].toLowerCase() !== item?.creator.toLowerCase()) {
      return toast({
        title: `Only the creator can ${mode}`,
        position: "top",
        status: "warning",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
    let tokenBalance = 0;

    try {
      const instanceERC20 = new web3.eth.Contract(
        ERC20.abi,
        item.contractAddress
      );

      tokenBalance = await instanceERC20.methods.balanceOf(accounts[0]).call();
    } catch (error) { }

    item = {
      ...item,
      tokenBalance,
    };
    const title = capitalizeFirstLetter(mode);

    setRedemptionItem(item);
    setModalMode(mode);
    setBtnText(title);
    setShowModalOptions(false)
    setErc20ModalOpen(true);
  };
  /*
    const handleRedemption = async (action, redeemAmount) => {
      if (!redemptionItem) {
        return;
      } else if (
        accounts[0].toLowerCase() !== redemptionItem?.creator.toLowerCase()
      ) {
        return toast({
          title: `Only the creator can ${action}`,
          position: "top",
          status: "warning",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      }
  
      setModalStatus("loading");
      const { redemptionId } = redemptionItem;
  
      try {
        await changeNetwork(network?.chainId, web3);
        let erc20 = await getErc20(network.redemptionContract);
  
  
        let transaction;
        if (action === "pause") {
          transaction = erc20.methods.pauseRedemption(redemptionId);
        } else if (action === "resume") {
          transaction = erc20.methods.resumeRedemption(redemptionId);
        } else if (action === "remove") {
          transaction = erc20.methods.deleteRedemption(redemptionId);
        } else if (action === "withdraw") {
          transaction = erc20.methods.withdrawRedemptionPool(
            redemptionId,
            redeemAmount
          );
        } else if (action === "deposit") {
          const { contractAddress, dec } = redemptionItem;
          const instanceERC20 = new web3.eth.Contract(ERC20.abi, contractAddress);
          const formattedRedeemAmount = web3.utils.toBN(
            +redeemAmount * 10 ** dec
          );
  
          const tokenAllowance = await instanceERC20.methods
            .allowance(accounts[0], network?.redemptionContract)
            .call();
  
          if (tokenAllowance < formattedRedeemAmount) {
            await instanceERC20.methods
              .approve(network?.redemptionContract, redeemAmount)
              .send({
                from: accounts[0],
              })
              .once("transactionHash", (hash) => {
                console.log("hash: " + hash);
              })
              .on("error", (error) => {
                console.log(error);
              });
          }
  
          transaction = erc20.methods.depositRedemptionPool(
            redemptionId,
            formattedRedeemAmount
          );
        } else {
          return;
        }
  
        await transaction
          .send({
            from: accounts[0],
          })
          .on("transactionHash", (hash) => {
            console.log("hash: " + hash);
            setTxHash(hash);
            // setModalStatus("complete");
            // setModalMode("complete");
          })
          .once("confirmation", (confirmationNumber, receipt) => {
            setModalStatus("complete");
          })
          .on("error", (error) => {
            console.log(error);
            setModalStatus("error");
          });
      } catch (error) {
        console.log("error: " + error);
        setModalStatus("error");
      }
    };
  */
  const setModalOpenHandler = (bool) => {
    if (bool === false) {
      setModalStatus("start");
    }
    setModalOpen(bool);
  };

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownProjects = projects.slice(firstPostIndex, lastPostIndex);

  return (
    <Flex
      pb={10}
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      flexDir="column"
      bg="#121212"
      pt="40px"
    >
      {type === "tokenRedemption" ? (
        <TokenRedemption setType={setType} />
      ) : (
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          width="90%"
          maxWidth="800px"
        >
          <Flex flexDir="column" width="100%">
            <Heading color="white" fontSize="18px" mb="30px">
              Token Redemptions
            </Heading>
            <Flex
              // flexDir={isLargerThan800 === true ? "row" : "column"}
              width="100%"
              bg="#242424"
              alignItems="center"
              justifyContent="center"
              borderRadius="25px"
              pt="40px"
              pb="40px"
              _hover={{
                cursor: "pointer",
                // shadow: "dark-lg",
                // transitionDuration: "0.2s",
                // transitionTimingFunction: "ease-in-out",
              }}
            >
              <RedemptionCard
                btn="erc 20"
                navTo={() => setType("tokenRedemption")}
                title="Token Redemption"
                description="Members of your community can exchange XP for tokens"
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" mt={6} width="100%" alignItems="center">
            <Heading
              color="white"
              fontSize="18px"
              fontWeight={700}
              alignSelf="flex-start"
            >
              My Redemptions
            </Heading>
            <Flex
              flexDirection="column"
              bg="#212121"
              borderRadius="15px"
              mt={6}
              width="100%"
              alignItems="center"
            >
              <Flex
                flexDirection="row"
                padding="20px"
                pb={2}
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <p></p>
                <Flex flexDirection="row">
                  <CustomDropdown
                    type="action"
                    placeholder="networks"
                    array={SUPPORTED_WATCHER_NETWORKS.filter(network => {
                 
                      if(network.name !== "arbitrum-nova" &&  network.name !== "bsc"){
                        return true;
                      }
                    })}
                    state={network}
                    setState={setNetwork}
                  />
                  {/* <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    // border="1px solid transparent"
                    // p={2}
                    // pl={3}
                    // pr={3}
                    borderRadius="25px"
                    mr="20px"
                  >
                    <Text color="white" fontSize="16px" mr={2} fontWeight={700}>
                      Network
                    </Text>
                    <Image src={polygon} boxSize="26px" alt="" />
                  </Flex> */}
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    // border="1px solid transparent"
                    p={2}
                    pl={3}
                    pr={3}
                    ml="10px"
                    borderRadius="25px"
                    transition="all 0.2s ease-in-out"
                    _hover={{
                      cursor: "pointer",
                      shadow: "dark-lg",
                      backgroundColor: "#383838",
                      borderColor: "black",
                    }}
                    onClick={getListed}
                  >
                    <Text color="white" fontSize="16px" mr={1} fontWeight={700}>
                      Refresh
                    </Text>
                    {/* <MdRefresh color="white" size="18px" /> */}
                    <Lottie
                      animationData={refresh}
                      loop={loop}
                      autoPlay={loop}
                      style={{ height: 20 }}
                    />
                  </Flex>
                  {/* <Flex flexDirection="row"></Flex> */}
                </Flex>
              </Flex>
              {shownProjects.length > 0 ? (
                <Wrap
                  direction={["column", "row"]}
                  spacing="20px"
                  justify="space-evenly"
                  width="100%"
                  padding="10px"
                  spacingY="40px"
                  pt="20px"
                  pb="50px"
                  mb={2}
                >
                  {shownProjects.map((item, i) => (
                    <RenderItem
                      key={item?.id}
                      item={item}
                      openModalHandler={openModalHandler}
                      cardOpenModalHandler={cardOpenModalHandler}
                      network={network}
                      web3={web3}
                    />
                  ))}
                </Wrap>
              ) : (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pb="20px"
                >
                  <Lottie
                    animationData={bonfire}
                    loop={true}
                    style={{ height: 300 }}
                  />
                  <Text
                    color="#A7A7A7"
                    fontSize="14px"
                    mt="-50px"
                    fontWeight="700"
                  >
                    No redemptions yet
                  </Text>
                </Flex>
              )}
              <Pagination
                // total={allNftsLength}
                total={projects.length}
                perPage={postPerPage}
                setPage={setCurrentPage}
                currentPage={currentPage}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      {erc20ModalOpen && (
        <Erc20RedemptionModal
          modalOpen={erc20ModalOpen}
          setModalOpen={setErc20ModalOpen}
          redemptionItem={redemptionItem}
          network={network}
          showModalOptions={showModalOptions}
          modalModeProp={modalMode}
        />
      )}
    </Flex>
  );
}
