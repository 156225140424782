import React, { useEffect, useState, useContext, useMemo } from "react";
import { useQuery, gql } from "@apollo/client";
import {
  Input,
  Button,
  Flex,
  Heading,
  Select,
  Textarea,
  Radio,
  RadioGroup,
  Stack,
  Image,
  Text,
  Box,
  Wrap,
  Checkbox,
  useMediaQuery,
  Skeleton,
} from "@chakra-ui/react";
import { Web3Context } from "../../../contexts/Web3Context";
import { useParams } from "react-router-dom";
import Lottie from "lottie-react";
import missingImg from "../../../assets/imgs/missingToken.png";
import bonfire from "../../../assets/lottie/bonfire.json";
import refresh from "../../../assets/lottie/refresh.json";
import Pagination from "../../../components/Pagination/Pagination";
import TokenRedemptionModal from "../TokenRedemptionModal";
import CustomCard from "../../../components/CustomCard/CustomCard";
import CustomDropdown from "../../../components/CustomDropdown/CustomDropdown";
import { CgCheckO, CgImage } from "react-icons/cg";
import { GoCheck, GoX } from "react-icons/go";
import { ethers } from "ethers";
import { MdRefresh, MdOutlineArrowBack } from "react-icons/md";
import {
  getMimeType,
  redeemableIdHandler,
  addressCheckHandler,
  isValidURL
} from "../../../func/myFunc.ts";
import { GET_PROJECT_DATA } from "../../../GraphQL/queries";
import { getWalletToken, changeNetwork, getTokenData, getErc20 } from "../Erc20.config";
import { SUPPORTED_WATCHER_NETWORKS } from "../../../config/config.ts";
import Web3 from "web3";
import ERC20 from "../../../abi/ERC20.json";

const CARD_WIDTH = "150px";
const PADDING = "24px";
const BOARDER = "2px solid #383838";
const BG = "#212121";

const TokenCard = ({ item, web3, onClick, selected, network }) => {
  const {
    contract_ticker_symbol,
    contract_address,
    logo_url,
    quote,
    contract_decimals,
    balance,
  } = item;
  const [amount, setAmount] = useState(0);
  const [quoteRate, setQuoteRate] = useState(0);
  const [src, setSrc] = useState("");
  const [extraData, setExtraData] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);



  useEffect(() => {

    const getTokenMetadata = async () => {
      const payload = {
        id: 1,
        jsonrpc: "2.0",
        method: "alchemy_getTokenMetadata",
        params: [contract_address],
      };

      try {
        const response = await fetch(network.alchemy, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const { result } = await response.json();

        const [symbol, tokenName, decimals] = await getTokenData(
          network,
          contract_address
        );

        const iconVersion = "QmPwpDzQP2cFLeekaqjb4C5tK3ukaXGKEvq1gvLjaRkyGj";
        let symbolNormalized = symbol ? symbol.replace(".e", "") : result.symbol //for DAI.e, USDT.e USDC.e etc, use symbol from alchemy or from other api
        symbolNormalized = symbolNormalized.replace("WETH", "ETH"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WOP", "OP"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WAVAX", "AVAX"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WARB", "ARB"); //spacial caseses
        symbolNormalized = symbolNormalized.replace("WMATIC", "MATIC"); //spacial caseses
        const logoCached = `https://huddln.mypinata.cloud/ipfs/${iconVersion}/128/color/${symbolNormalized.toLowerCase()}.png`

       
        // check for valid urls that point at images
        if (result && await isValidURL(result.logo)){ setSrc(result.logo);}
        else if (await isValidURL(logo_url)) {setSrc(logo_url);}
        else if (await isValidURL(logoCached)){ setSrc(logoCached);}

        // let num = parseFloat(balance) / 10 ** parseFloat(contract_decimals);

        // if (!num || isNaN(num)) {
        //   num = parseFloat(balance) / 10 ** parseFloat(dec);
        // }

        // const available = web3Divide(web3, balance, contract_decimals);
        const available = ethers.formatUnits(balance.toString(), parseInt(decimals)).toString();
        // console.log(available)
        let q = parseFloat(quote);

       // setSrc(logo_url ? logo_url : result?.logo ? result.logo : logoCached);
        setExtraData(result);
        setQuoteRate(q || 0);
        setAmount(available);
        setExtraData({ ...result, available });
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setIsLoaded(true);
      }
    };

    getTokenMetadata();
  }, [item]);

  return (
    <Box
      _hover={{
        cursor: "pointer",
        shadow: "dark-lg",
        transform: "scale(1.1)",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-in-out",
      }}
      width="150px"
      pb="4px"
      border="2px solid #303030"
      borderRadius="10px"
      overflow="hidden"
      onClick={() => {
        onClick({ ...item, ...extraData });
      }}
    >
      <Skeleton
        isLoaded={isLoaded}
        height={CARD_WIDTH}
        width={CARD_WIDTH}
        startColor="gray.100"
        endColor="'gray.200"
        justifyContent="center"
        alignItems="center"
      >
        <Flex
          boxSize="150px"
          position="relative"
          bg="#141414"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            // key={src}
            // bg="green"
            padding="2px"
            borderRadius="40px"
            w="80px"
            h="80px"
            objectFit="contain"
            src={src}
            fallbackSrc={missingImg}
            alt=""
          />
          {selected && (
            <Flex
              flex="1"
              pos="absolute"
              width={CARD_WIDTH}
              height={CARD_WIDTH}
              bg="rgba(0,0,0,.5)"
              top="0"
              left="0"
              alignItems="center"
              justifyContent="center"
            >
              <CgCheckO color="white" size="40px" />
            </Flex>
          )}
        </Flex>
      </Skeleton>
      <Flex flexDirection="column" p={2}>
        <Text
          // bgGradient="linear(to-l,  #B5E5FF, #C6C7FF, #E1A9F6)"
          // bgClip="text"
          color="white"
          fontSize="16px"
          // mt={3}
          fontWeight="700"
          maxW="80%"
          noOfLines={1}
        >
          {contract_ticker_symbol}
        </Text>
        <Text color="white" fontSize="14px" fontWeight="500">
          ERC-20
        </Text>
        <Text
          color="#A7A7A7"
          fontSize="12px"
          fontWeight="500"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
        >
          Amount: {amount}
        </Text>
        <Text color="#A7A7A7" fontSize="10px" fontWeight="500">
          ${quoteRate}
        </Text>
      </Flex>
    </Box>
  );
};

export default function TokenRedemption({ type, setType }) {
  const { projectId } = useParams();
  const {
    web3,
    accounts,
  } = useContext(Web3Context);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalStatus, setModalStatus] = useState("start");
  const [modalMode, setModalMode] = useState("create");
  //create
  const [scoreType, setScoreType] = useState();
  const [txHash, setTxHash] = useState("");
  const [redeemId, setRedeemId] = useState("");
  const [isRedeemed, setIsRedeemed] = useState(false);
  const [redemptionLimit, setRedemptionLimit] = useState("single");
  const [ercAddressPass, setErcAddressPass] = useState(false);
  const [ercAddressChecked, setErcAddressChecked] = useState(false);
  const [dynamicHref, setDynamicHref] = useState("/");
  const [selectedToken, setSelectedToken] = useState({});
  const [tokenData, setTokenData] = useState([]);
  const [redemptionName, setRedemptionName] = useState("");
  const [xpAmount, setXpAmount] = useState(0);
  const [tokenAmount, setTokenAmount] = useState(0);
  const [network, setNetwork] = useState("");
  const [tokenAddress, setTokenAddress] = useState("");
  const [tokenName, setTokenName] = useState("");

  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  const [pageKey, setPageKey] = useState(null);
  const [loop, setLoop] = useState(false);

  const { data: projectData } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    network && refreshHandler();
    const item = SUPPORTED_WATCHER_NETWORKS.find(
      (item) => item.name === network?.name
    );
    setDynamicHref(`${item?.explorerUri}/address/`);
  }, [network]);

  const refreshHandler = async () => {
    setLoop(true);
    const data = await getWalletToken(
      accounts[0].toLowerCase(),
      network?.chainId
    );

    setTokenData(data);
    setLoop(false);
  };

  const getTokenDecimals = async () => {
    try {
      const newWeb3 = new Web3(network?.publicRpcUri);
      const instanceERC20 = new newWeb3.eth.Contract(ERC20.abi, tokenAddress);

      const decimals = await instanceERC20.methods.decimals().call();

      return decimals;
    } catch (error) {
      return 18;
    }
  };

  const createRedemption = async () => {
    try {

      setModalStatus("loading");

      await changeNetwork(network.chainId, web3);

      setErcAddressChecked(false);
      const pass = await addressCheckHandler(tokenAddress);
      setErcAddressChecked(true);
      setErcAddressPass(pass);
      if (!pass) {
        throw new Error("Invalid Address");
      }

      const signature = await web3.eth.personal.sign(
        "Create ERC20 Redemption",
        window.ethereum.selectedAddress
      );

      const newRedemptionId = web3.utils.randomHex(32);
      const dec = await getTokenDecimals();

      const formattedTokenAmount = web3.utils.toBN(ethers.parseUnits(tokenAmount.toString(), parseInt(dec)).toString());

      // const formattedTokenAmount = web3.utils.toBN(+tokenAmount * 10 ** dec);

      let erc20 = await getErc20(network.redemptionContract);

      await erc20.methods
        .createRedemption(
          newRedemptionId,
          projectId,
          redemptionName,
          formattedTokenAmount, // TOKEN AMOUNT
          +xpAmount, // POINTS
          `${redemptionName
            .trim()
            .replace(/\s+/g, "_")}_${xpAmount}_${tokenAmount}`,
          scoreType, // SCORE TYPE
          tokenAddress, // ERC20 CONTRACT ADDRESS
          redemptionLimit === "single", // LIMIT ONE PER WALLET (BOOL)
          signature
        )
        .send({
          from: accounts[0],
        })
        .once("transactionHash", (hash) => {
          setTxHash(hash);
          setRedeemId(newRedemptionId);
          console.log("hash: " + hash);
          console.log("redeemableId: " + newRedemptionId);
        })
        .once("confirmation", (confirmationNumber, receipt) => {
          setModalStatus("complete");
          setModalMode("complete");
          setIsRedeemed(true);
        })
        .on("error", (error) => {
          setModalStatus("error");
          console.log(error);
        });
    } catch (error) {
      console.log(error);
      setModalStatus("error");
    }
  };

  const setModalOpenHandler = (bool) => {
    if (bool === false && modalStatus === "complete") {
      setType("");
    } else if (bool === false) {
      setModalStatus("start");
    }
    setModalOpen(bool);
  };

  const selectTokenHandler = (item) => {
    const { contract_address, contract_ticker_symbol } = item;
    setSelectedToken(item);
    setTokenAddress(contract_address);
    setTokenName(contract_ticker_symbol);
  };

  const openModalHandler = () => {
    // setModalMode("start");
    setModalOpenHandler(true);
  };

  const handleLinkClick = async (e) => {
    e.preventDefault();
    const pass = await addressCheckHandler(tokenAddress);
    setErcAddressChecked(true);
    setErcAddressPass(pass);
    if (pass) {
      window.open(dynamicHref + tokenAddress, "_blank");
    }
  };

  const btnDisabled = useMemo(() => {
    return (
      redemptionName === "" ||
      isNaN(xpAmount) ||
      !(parseInt(xpAmount) >= 0) ||
      isNaN(parseFloat(tokenAmount)) ||
      network === "" ||
      tokenAddress === "" ||
      !scoreType
    );
  }, [redemptionName, xpAmount, tokenAmount, network, tokenAddress, scoreType]);

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownTokenData = tokenData.slice(firstPostIndex, lastPostIndex);
  return (
    <Flex
      flexDirection="row"
      alignItems="flex-start"
      width="90%"
      maxWidth="830px"
    >
      <Flex align="center">
        <Flex
          p={1}
          onClick={setType}
          borderRadius="50px"
          // mt="20px"
          mr="5px"
          _hover={{
            cursor: "pointer",
            // backgroundColor: "rgba(100,100,100,.5)",
            transform: "scale(1.1)",
            transitionDuration: "0.2s",
            transitionTimingFunction: "ease-in-out",
          }}
        >
          <MdOutlineArrowBack color="white" size="25px" onClick={setType} />
        </Flex>
      </Flex>
      <Flex flexDir="column" width="100%">
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              <Heading fontWeight={600} fontSize="20px">
                Token Redemption Name
              </Heading>
            </Flex>
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="15px">
            Choose the score type to deduct points from
          </Text>
          <Flex
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Flex
              flexDirection="row"
              position="relative"
              justifyContent="center"
              alignItems="center"
              bg="#292929"
              border="2px solid #383838"
              // borderColor={!royaltyAddressPass ? "red" : "#505050"}
              borderRadius="10px"
              width="70%"
              p={2}
              pt={0}
              pb={0}
              de
            >
              <Input
                borderRadius="10px"
                textColor="white"
                maxLength="250"
                width="100%"
                max={100}
                p={0}
                bg="#292929"
                pr={2}
                value={redemptionName}
                onChange={(e) => setRedemptionName(e.target.value)}
              />
            </Flex>
            <Flex />
          </Flex>
        </CustomCard>
        <CustomCard>
          <Heading fontWeight={600} fontSize="20px">
            XP Exchange Rate
          </Heading>
          <Text color="#A7A7A7" fontSize="14px">
            Assign the amount of XP that will be deducted when purchased
          </Text>
          <Flex
            flexDirection="row"
            alignItems="center"
            mt="5px"
          // justifyContent="fle"
          >
            <Flex
              flexDirection="column"
              // alignItems="center"
              justifyContent="center"
            >
              <Text color="#A7A7A7" fontSize="14px" mb={1}>
                XP Amount
              </Text>
              <Input
                type="number"
                bg="#292929"
                border="2px solid #383838"
                textColor="white"
                width="140px"
                borderRadius="10px"
                value={xpAmount}
                onChange={(e) => setXpAmount(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </Flex>
            <Heading color="white" fontSize="20px" mt="24px" mx="10px">
              X
            </Heading>
            <Flex
              flexDirection="column"
              // alignItems="center"
              justifyContent="center"
            >
              <Text color="#A7A7A7" fontSize="14px" mb={1}>
                Tokens
              </Text>
              <Input
                type="number"
                bg="#292929"
                border="2px solid #383838"
                textColor="white"
                width="140px"
                borderRadius="10px"
                value={tokenAmount}
                onChange={(e) => setTokenAmount(e.target.value)}
                onWheel={(e) => e.target.blur()}
              />
            </Flex>
          </Flex>
        </CustomCard>
        <CustomCard>
          <Heading fontWeight={600} fontSize="20px">
            Deduct from Score Type
          </Heading>
          <Text color="#A7A7A7" fontSize="14px">
            Choose the score type to deduct points from
          </Text>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="flex-Start"
            mt="15px"
          >
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              justifyContent="center"
              className="redemption-select"
            >
              <Text color="#A7A7A7" fontSize="14px" mb={1}>
                Score Type
              </Text>
              {projectData?.projects[0]?.scoreTypes?.length > 0 && (
                <CustomDropdown
                  placeholder="Scores"
                  array={projectData.projects[0].scoreTypes}
                  state={scoreType}
                  setState={setScoreType}
                />
              )}
            </Flex>
          </Flex>
        </CustomCard>
        <CustomCard>
          <Heading fontSize="20px" fontWeight={700}>
            Limit 1 Redemption per Wallet
          </Heading>
          <Text color="#A7A7A7" fontSize="14px" mb={5}>
            Choose the type of redemption
          </Text>
          <RadioGroup
            onChange={setRedemptionLimit}
            value={redemptionLimit}
            textColor="white"
            w="100%"
          // maxWidth={350}
          >
            <Stack direction="column">
              <Radio
                value="single"
                colorScheme="toggle"
                _focus={{
                  boxShadow: "none",
                }}
              >
                Yes, only allow 1 redemption per wallet
              </Radio>
              <Radio
                value="multiple"
                colorScheme="toggle"
                _focus={{
                  boxShadow: "none",
                }}
              >
                No, the same wallet can redeem multiple times
              </Radio>
            </Stack>
          </RadioGroup>
        </CustomCard>
        <Flex
          flexDirection="column"
          alignItems="center"
          bg={BG}
          border={BOARDER}
          borderRadius="15px"
          width="100%"
        >
          <Flex flexDirection="column" width="100%" padding="20px">
            <Heading fontWeight={600} fontSize="20px">
              ERC-20 Token
            </Heading>
            <Text color="#A7A7A7" fontSize="14px" mb={5}>
              Insert the address of the ERC-20 token or select it from your
              wallet below.
            </Text>
            <Flex
              flexDirection="column"
              // alignItems="center"
              justifyContent="center"
              mb="5px"
            >
              <Text color="#A7A7A7" fontSize="14px" mb="5px">
                Network
              </Text>
              <CustomDropdown
                type="action"
                placeholder="networks"
                array={SUPPORTED_WATCHER_NETWORKS.filter(network => {

                  if (network.name !== "arbitrum-nova" && network.name !== "bsc") {
                    return true;
                  }
                })}
                state={network}
                setState={setNetwork}
              />
            </Flex>
            <Flex
              flexDirection="column"
              // alignItems="center"
              justifyContent="center"
            >
              <Text color="#A7A7A7" fontSize="14px" mb="5px">
                Address
              </Text>
              <Flex alignItems="center">
                <Flex
                  flexDirection="row"
                  position="relative"
                  justifyContent="center"
                  alignItems="center"
                  bg="#292929"
                  border="2px solid #383838"
                  // borderColor={!royaltyAddressPass ? "red" : "#505050"}
                  borderRadius="10px"
                  width="70%"
                  p={2}
                  pt={0}
                  pb={0}
                >
                  <Input
                    borderRadius="10px"
                    textColor="white"
                    maxLength="250"
                    width="100%"
                    max={100}
                    p={0}
                    bg="#292929"
                    pr={2}
                    value={tokenAddress}
                    onChange={(e) => setTokenAddress(e.target.value)}
                  />
                  {ercAddressPass && ercAddressChecked ? (
                    <GoCheck color="green" size="18px" />
                  ) : !ercAddressPass && ercAddressChecked ? (
                    <GoX color="red" size="18px" />
                  ) : (
                    <Flex boxSize="16px" />
                  )}
                </Flex>
                {network && tokenAddress && (
                  <a
                    href={dynamicHref + tokenAddress}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={handleLinkClick}
                  >
                    <Text
                      ml="15px"
                      cursor="pointer"
                      fontSize="16px"
                      color="white"
                    >
                      Go To Contract
                    </Text>
                  </a>
                )}
              </Flex>
            </Flex>
            <Text color="#A7A7A7" fontSize="14px" mt="5px">
              {tokenName}
            </Text>
          </Flex>
          <Flex
            flexDirection="row"
            pl="20px"
            pr="20px"
            pb="20px"
            width="100%"
            alignItems="center"
            justifyContent="space-between"
          >
            <Text color="#A7A7A7" fontSize="18px">
              Or Choose from below
            </Text>
            <Flex flexDirection="row">
              <Flex
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                // border="1px solid transparent"
                p={2}
                pl={3}
                pr={3}
                borderRadius="25px"
                _hover={{
                  cursor: "pointer",
                  shadow: "dark-lg",
                  // transform: "translateY(-5px)",
                  backgroundColor: "#383838",
                  borderColor: "black",
                  transitionDuration: "0.2s",
                  transitionTimingFunction: "ease-in-out",
                }}
                onClick={refreshHandler}
              >
                <Text color="white" fontSize="16px" mr={1} fontWeight={700}>
                  Refresh
                </Text>
                <Lottie
                  animationData={refresh}
                  loop={loop}
                  autoPlay={loop}
                  style={{ height: 20 }}
                />
              </Flex>
              {/* <Flex flexDirection="row"></Flex> */}
            </Flex>
          </Flex>
          {shownTokenData?.length > 0 ? (
            <Wrap
              key={currentPage}
              direction={["column", "row"]}
              spacing="20px"
              justify="center"
              width="100%"
              padding="20px"
              pb={6}
              mb={2}
            >
              {shownTokenData.map((item, i) => {
                return (
                  <TokenCard
                    item={item}
                    web3={web3}
                    key={item.contract_address}
                    onClick={selectTokenHandler}
                    network={network}
                    selected={
                      item.contract_address ===
                      selectedToken?.contract_address &&
                      item.contract_address === tokenAddress
                    }
                  />
                );
              })}
            </Wrap>
          ) : (
            <Flex
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              pb="20px"
            >
              <Lottie
                animationData={bonfire}
                loop={true}
                style={{ height: 300 }}
              />
              <Text color="#A7A7A7" fontSize="14px" mt="-50px" fontWeight="700">
                No redemptions yet
              </Text>
            </Flex>
          )}
          <Pagination
            // total={allNftsLength}
            total={tokenData.length}
            perPage={postPerPage}
            setPage={setCurrentPage}
            currentPage={currentPage}
            enableEnd={!(!pageKey || pageKey.length === 0)}
          // onEndReach={() => getNFTInfo(true)}
          />
        </Flex>
        <Flex mt={10} width="100%" justifyContent="center" alignItems="center">
          <Button
            variant="gray"
            alignSelf="center"
            onClick={setType}
            w="140px"
            mr="10px"
          >
            Cancel
          </Button>
          <Button
            ml="10px"
            variant="purple"
            alignSelf="center"
            // onClick={createRedemption}
            onClick={() => openModalHandler(true)}
            disabled={btnDisabled}
          >
            Next
          </Button>
        </Flex>
      </Flex>
      {/* {modalMode === "publish" ? ( */}
      <TokenRedemptionModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpenHandler}
        imageType="png"
        image={selectedToken?.logo_url}
        modalStatus={modalStatus}
        txHash={txHash}
        completeText={modalStatus === "complete"}
        modalMode={modalMode}
        handleRedemption={createRedemption}
        btnText="Create"
        modalTitle={
          modalStatus === "loading"
            ? "Creating Redemption"
            : "Create ERC-20 Redemption "
        }
        modalDescription="This redemption will need a deposit to become active after creation"
        nftTitle={redemptionName}
        network={network}
        nftAddress={selectedToken?.contract_address}
        nftDescription={selectedToken?.contract_ticker_symbol}
        nftTokenType="ERC-20"
        actionPoints={selectedToken?.available}
        tokenAmount={tokenAmount}
        xpAmount={xpAmount}
        // {{parseFloat(selectedToken?.quote).toFixed(2)}}
        // actionName={actionName}
        actionName={scoreType}
        onCloseExtra={isRedeemed ? () => setType("") : null}
      />
    </Flex>
  );
}
