import { Flex, Image, Icon } from "@chakra-ui/react";
import { IoMdLock, IoMdUnlock } from "react-icons/io";

export default function Locks({ state, setState }) {
  return (
    <Flex h="25px" w="24px" justifyContent="flex-start">
      {state ? (
        <Icon
          _hover={{
            transform: "scale(1.05)",
          }}
          transition="all 0.2s"
          as={IoMdLock}
          color="#969696"
          fontWeight={600}
          fontSize="23px"
          // style={{ marginLeft: "-3px" }}
          cursor="pointer"
          onClick={() => setState(false)}
        />
      ) : (
        <Icon
          _hover={{
            transform: "scale(1.05)",
          }}
          transition="all 0.2s"
          as={IoMdUnlock}
          color="#ddd"
          fontWeight={600}
          fontSize="23px"
          cursor="pointer"
          onClick={() => setState(true)}
        />
      )}
    </Flex>
  );
}
