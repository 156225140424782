import "./ChooseRedemption.css";

import React, { useState, useEffect, useContext, useRef } from "react";
import { FaArrowRight } from "react-icons/fa";
import { FiShare } from "react-icons/fi";
import CreateRedemptionForm from "./CreateRedemptionForm/CreateRedemptionForm";
import {
  Flex,
  Heading,
  Image,
  Text,
  Box,
  Wrap,
  useMediaQuery,
  Skeleton,
  useToast,
} from "@chakra-ui/react";
import { MdOutlineMoreHoriz } from "react-icons/md";
import Lottie from "lottie-react";
import bonfire from "../../assets/lottie/bonfire.json";
import refresh from "../../assets/lottie/refresh.json";
import polygon from "../../assets/imgs/polygon_color.png";
import trash from "../../assets/imgs/trash.png";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import Pagination from "../../components/Pagination/Pagination";
import DisplayNftType from "../../components/DisplayNftType/DisplayNftType";
import RedemptionModal from "../../components/RedemptionModal/RedemptionModal";
import RedemptionCard from "../../components/RedemptionCard/RedemptionCard";
import { getMimeType } from "../../func/myFunc.ts";
import changeNetworkToPolygon from "../../func/changeNetwork";

const CARD_WIDTH = "150px";

const RenderItem = ({ item, openModalHandler, projectId }) => {
  const [data, setData] = useState({});
  const [name, setName] = useState("");
  const [balance, setBalance] = useState("");
  const [tokenType, setTokenType] = useState("");
  const [address, setAddress] = useState("");
  const [src, setSrc] = useState("");
  const [srcType, setSrcType] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const [showMoreOptions, setShowMoreOptions] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    getNftData();
  }, []);

  const getNftData = async () => {
    // const address = "0x89082ea40fd815631ea6259af33b0af35258fb76";
    const address = item?.contractAddress;
    const response = await fetch(
      `https://polygon-mainnet.g.alchemy.com/nft/v2/83uAJ90PdMxTEMuwg3zIHj9ZG-CXD_rH/getNFTMetadata?contractAddress=${address}&tokenId=${item.token}&refreshCache=false`
    );

    const _d = await response.json();
    const { metadata, contractMetadata, contract, media } = _d;
    setData(_d);
    setName(metadata?.name);
    // setBalance(contractMetadata?.totalSupply);
    setBalance(item?.amount);
    setTokenType(contractMetadata?.tokenType);
    setAddress(contract?.address);

    const uri = metadata?.image?.startsWith("ar://")
      ? `https://arweave.net/${metadata?.image?.slice(5)}`
      : metadata?.imageUri?.startsWith("ipfs://")
      ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
      : metadata?.image?.startsWith("Qm")
      ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image}`
      : metadata?.image;

    setSrc(uri);
    setSrcType(
      metadata?.mediaType
        ? metadata?.mediaType
        : media[0]?.format
        ? media[0]?.format
        : await getMimeType(uri)
    );
    setIsLoaded(true);
  };

  return (
    <Box
      onMouseEnter={() => setShowOptions(true)}
      onMouseLeave={() => {
        setShowOptions(false);
        setShowMoreOptions(false);
      }}
      _hover={{
        cursor: "pointer",
        shadow: "dark-lg",
        transform: "scale(1.1)",
        transitionDuration: "0.2s",
        transitionTimingFunction: "ease-in-out",
      }}
      width="150px"
      pb="4px"
      border="2px solid #303030"
      borderRadius="10px"
      overflow="hidden"
    >
      <Box boxSize="150px" position="relative" bg="#141414">
        <a
          href={`https://xp.huddln.io/community/${projectId}/${item?.id}`}
          target="_blank"
        >
          <Skeleton
            isLoaded={isLoaded}
            height={CARD_WIDTH}
            width={CARD_WIDTH}
            startColor="gray.100"
            endColor="'gray.200"
          >
            {isLoaded && (
              <DisplayNftType
                type={srcType}
                src={src}
                height={CARD_WIDTH}
                width={CARD_WIDTH}
              />
            )}
          </Skeleton>
        </a>
        {showOptions && (
          <Flex
            shadow="lg"
            boxSize="24px"
            position="absolute"
            bg="black"
            top="5px"
            right="5px"
            borderRadius="12px"
            justifyContent="center"
            alignItems="center"
            zIndex={100}
            onClick={() => {
              setShowMoreOptions(true);
            }}
          >
            <MdOutlineMoreHoriz color="white" size="18px" />
          </Flex>
        )}
        {showMoreOptions && (
          <Flex
            flexDirection="column"
            // alignSelf="center"
            // justifyContent="center"
            shadow="lg"
            position="absolute"
            width="100px"
            bg="#212121"
            top="37px"
            right="5px"
            // p="4px"
            // pl="6px"
            // pr="6px"
            borderRadius="10px"
            overflow="hidden"
          >
            <Flex
              p="4px"
              pl="8px"
              pr="8px"
              _hover={{
                bgColor: "rgba(60,60,60,.5)",
                transform: "scale(1.05)",
                transitionDuration: "0.2s",
                transitionTimingFunction: "ease-in-out",
              }}
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="flex-start"
              onClick={() => {
                openModalHandler(item, "remove", data);
              }}
            >
              {/* <FaRegTrashAlt color="white" size="12px" /> */}
              <Image src={trash} boxSize="10px" />
              <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                Remove
              </Text>
            </Flex>
            <Flex
              p="4px"
              pl="8px"
              pr="8px"
              _hover={{
                bgColor: "rgba(60,60,60,.5)",
                transform: "scale(1.05)",
                transitionDuration: "0.2s",
                transitionTimingFunction: "ease-in-out",
              }}
              flexDirection="row"
              alignItems="center"
              width="100%"
              justifyContent="flex-start"
              onClick={() => {
                openModalHandler(item, "share", data);
              }}
            >
              <FiShare color="white" size="12px" />
              {/* <Image src={trash} boxSize="10px" /> */}
              <Text color="white" fontSize="12px" fontWeight="500" ml={2}>
                Share
              </Text>
            </Flex>
          </Flex>
        )}
        <Flex
          shadow="md"
          flexDirection="row"
          position="absolute"
          bg="rgba(0,0,0,.5)"
          bottom="5px"
          right="5px"
          p="2px"
          pr="7px"
          pl="7px"
          borderRadius="10px"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="white" fontSize="10px" fontWeight="700">
            {item?.points} XP
          </Text>
        </Flex>
      </Box>
      <a
        href={`https://xp.huddln.io/community/${projectId}/${item?.id}`}
        target="_blank"
      >
        <Flex flexDirection="column" p={2}>
          <Text
            //bgGradient="linear(to-l,  #B5E5FF, #C6C7FF, #E1A9F6)"
            bg={"white"}
            bgClip="text"
            fontSize="16px"
            // mt={3}
            fontWeight="700"
            maxW="80%"
            noOfLines={1}
          >
            {name ? name : address}
          </Text>
          <Text color="white" fontSize="10px" fontWeight="500">
            {item?.requiresPurchase == true ? "PURCHASE-ABLE" : "EARN-ABLE"}
          </Text>
          <Text color="white" fontSize="14px" fontWeight="500">
            {tokenType}
          </Text>
          <Text color="#A7A7A7" fontSize="12px" fontWeight="500">
            Editions {balance}
          </Text>
        </Flex>
      </a>
    </Box>
  );
};

export default function CreateRedemption() {
  const { projectId } = useParams();
  const toast = useToast();
  const lottieRefreshRef = useRef(null);

  const [mounted, setMounted] = useState(false);
  const [type, setType] = useState("");
  const [projects, setProject] = useState([]);

  const { accounts, reedemablesStorage } = useContext(Web3Context);
  //pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage, setPostPerPage] = useState(12);
  //modal
  const [modalOpen, setModalOpen] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const [modalStatus, setModalStatus] = useState("start");
  //nft
  const [txHash, setTxHash] = useState("");
  const [nftAmount, setNftAmount] = useState(0);
  const [actionPoints, setActionPoints] = useState(0);
  const [nftTitle, setNftTitle] = useState("");
  const [nftDescription, setNftDescription] = useState("");
  const [nftAddress, setNftAddress] = useState("");
  const [nftId, setNftId] = useState("");
  const [actionName, setActionName] = useState("");
  const [image, setImage] = useState(null);
  const [imageType, setImageType] = useState("");
  const [nftTokenType, setNftTokenType] = useState("");
  const [redeemableId, setReedeemableId] = useState("");
  const [isLargerThan800] = useMediaQuery("(min-width: 1280px)");
  const [loop, setLoop] = useState(true);

  useEffect(() => {
    getListed();
  }, []);

  const getListed = async () => {
    setCurrentPage(1);
    setLoop(true);
    let { data } = await fetch(
      "https://api.thegraph.com/subgraphs/name/bcaklovi/huddln-redemptions-matic-v1",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: `
          query ($projectId: String!){
            redeemables(where:{ projectId: $projectId}, orderBy: createdAt, orderDirection: desc) {
              id
              redeemableId
              projectId
              name
              amount
              initialAmount
              points
              actionName
              scoreType
              requiresPurchase
              creator
              contractAddress
              createdAt
              token
            }
  }
        `,
          variables: {
            projectId,
          },
        }),
      }
    ).then((resp) => resp.json());

    if (data?.redeemables.length > 0) {
      setProject(data.redeemables);
    }
    setLoop(false);
  };
  // const navToHandler = (type = "purchase") => {
  //   navigate("/create-redemption/form-page", {
  //     state: { type },
  //   });
  // };

  const removeProject = async () => {
    setModalStatus("loading");
    try {
      await changeNetworkToPolygon();

      await reedemablesStorage.methods
        .removeRedeemable(redeemableId)
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          setTxHash(hash);
          console.log("hash: " + hash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 3) {
            setModalStatus("complete");
          }
        })
        .on("error", (error) => {
          setModalStatus("error");
          console.log(error);
        });
    } catch (error) {
      setModalStatus("error");
      console.log("error: " + error);
    }
  };

  const updateProject = async () => {
    setModalStatus("loading");
    try {
      await changeNetworkToPolygon();

      await reedemablesStorage.methods

        // function updateRedeemableAction(
        //   bytes32 _redeemableId,
        //   uint256 _points,
        //   string memory _actionName,
        //   string memory _scoreType
        // ) public

        .updateRedeemableAction(redeemableId)
        .send({
          from: accounts[0],

          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          setTxHash(hash);
          console.log("hash: " + hash);
        })
        .on("confirmation", (confirmationNumber, receipt) => {
          if (confirmationNumber === 3) {
            setModalStatus("complete");
          }
        })
        .on("error", (error) => {
          setModalStatus("error");
          console.log(error);
        });
    } catch (error) {
      setModalStatus("error");
      console.log("error: " + error);
    }
  };

  const openModalHandler = async (item, mode, data) => {
    if (mode === "share") {
      console.log(item);
      navigator.clipboard.writeText(
        `https://xp.huddln.io/community/${projectId}/${item?.id}`
      );
      toast({
        render: () => (
          <Box color="white" p={3} bg="gray.100">
            Link Copied
          </Box>
        ),
      });
    } else if (mode === "remove") {
      setModalStatus("start");

      const {
        redeemableId,
        amount,
        points,
        contractAddress,
        id: nId,
        scoreType,
      } = item;
      const { id, media, metadata } = data;

      const uri = metadata?.image?.startsWith("ar://")
        ? `https://arweave.net/${metadata?.image?.slice(5)}`
        : metadata?.imageUri?.startsWith("ipfs://")
        ? `https://huddln.mypinata.cloud/ipfs/${metadata?.image?.slice(7)}`
        : metadata?.image;

      setImage(uri);
      setNftTokenType(id?.tokenMetadata?.tokenType);
      setImageType(
        metadata?.mediaType
          ? metadata?.mediaType
          : media[0]?.format
          ? media[0]?.format
          : await getMimeType(uri)
      );

      setNftTitle(metadata?.name);
      setNftDescription(metadata?.description);
      setNftAddress(contractAddress);
      setNftId(nId);
      setActionName(scoreType);
      setReedeemableId(redeemableId);
      setNftAmount(amount);
      setActionPoints(points);

      setModalMode(mode);
      setModalOpen(true);
    }
  };

  const lastPostIndex = currentPage * postPerPage;
  const firstPostIndex = lastPostIndex - postPerPage;
  const shownNfts = projects.slice(firstPostIndex, lastPostIndex);

  return (
    <Flex
      pb={10}
      width="100%"
      justifyContent="flex-start"
      alignItems="center"
      flexDir="column"
      bg="#121212"
      pt="40px"
    >
      {/* <Box
        width="100%"
        height="425px"
        mb={10}
        position="relative"
        overflow="hidden"
        borderBottomRadius="10px"
      >
        <Skeleton
          isLoaded={mounted}
          fadeDuration={0.1}
          width="100%"
          height="425px"
          startColor="gray.100"
          endColor="'gray.200"
        >
          <Image
            width="100%"
            height="425px"
            objectFit="cover"
            src={hero}
            onLoad={() => setMounted(true)}
            alt=""
            fallbackSrc=""
          />
        </Skeleton>
        <Flex
          width="100%"
          height="425px"
          position="absolute"
          // justifyContent="center"
          // alignItems="flex-start"
          top={0}
          left={0}
          // bg="rgba(0,0,0,1)"
          overflow="hidden"
        >
          <Flex
            flex="1"
            flexDir="column"
            p="20px"
            justifyContent="center"
            alignItems="flex-start"
            position="relative"
          >
            <Image
              ml={-8}
              // mt={20}
              width="160px"
              height="90px"
              objectFit="contain"
              src={xp}
              alt=""
              fallbackSrc=""
            />
            <Text
              color="white"
              fontSize="36px"
              fontWeight={800}
              style={{ textShadow: "black 1px 0 10px" }}
            >
              {type === "earn"
                ? "Earn-able redemption"
                : type === "purchase"
                ? "Purchase-able redemption"
                : "Redemptions"}
            </Text>
            <Text
              color="white"
              fontSize="20px"
              fontWeight={800}
              style={{ textShadow: "black 1px 0 10px" }}
            >
              {type === "earn" || type === "purchase"
                ? "Members of your community can claim NFTs based on their XP"
                : "Reward your community with redeemable NFTs"}
            </Text>
          </Flex>
        </Flex>
      </Box> */}
      {type === "purchase" ? (
        <CreateRedemptionForm type="purchase" setType={setType} />
      ) : type === "earn" ? (
        <CreateRedemptionForm type="earn" setType={setType} />
      ) : (
        <Flex
          flexDir="column"
          justifyContent="center"
          alignItems="center"
          overflow="hidden"
          width="90%"
          maxWidth="800px"
        >
          <Flex flexDir="column" width="100%">
            <Heading color="white" fontSize="18px" mb="30px">
              Create New Redemption
            </Heading>
            <Flex
              flexDir={isLargerThan800 === true ? "row" : "column"}
              width="100%"
              bg="#242424"
              alignItems="center"
              justifyContent="space-evenly"
              borderRadius="25px"
              pt="40px"
              pb="40px"
              _hover={{
                cursor: "pointer",
                // shadow: "dark-lg",
                // transitionDuration: "0.2s",
                // transitionTimingFunction: "ease-in-out",
              }}
            >
              <RedemptionCard
                btn="spend xp"
                navTo={() => setType("purchase")}
                title="Purchase-able Redemption"
                description="Members can spend XP for NFTs"
              />
              {isLargerThan800 === false && <Flex width="100%" height="40px" />}
              <RedemptionCard
                btn="minimum score"
                navTo={() => setType("earn")}
                title="Earnable-able Redemption"
                description="Members can redeem NFTs when they reach a minimum XP score"
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" mt={6} width="100%" alignItems="center">
            <Heading
              color="white"
              fontSize="18px"
              fontWeight={700}
              alignSelf="flex-start"
            >
              My Redemptions
            </Heading>
            <Flex
              flexDirection="column"
              bg="#212121"
              borderRadius="15px"
              mt={6}
              width="100%"
              alignItems="center"
            >
              <Flex
                flexDirection="row"
                padding="20px"
                pb={2}
                alignItems="center"
                width="100%"
                justifyContent="space-between"
              >
                <p></p>
                <Flex flexDirection="row">
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    // border="1px solid transparent"
                    // p={2}
                    // pl={3}
                    // pr={3}
                    borderRadius="25px"
                    mr="20px"
                  >
                    <Text color="white" fontSize="16px" mr={2} fontWeight={700}>
                      Network
                    </Text>
                    <Image src={polygon} boxSize="26px" alt="" />
                  </Flex>
                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    justifyContent="center"
                    // border="1px solid transparent"
                    p={2}
                    pl={3}
                    pr={3}
                    borderRadius="25px"
                    _hover={{
                      cursor: "pointer",
                      shadow: "dark-lg",
                      // transform: "translateY(-5px)",
                      backgroundColor: "#383838",
                      borderColor: "black",
                      transitionDuration: "0.2s",
                      transitionTimingFunction: "ease-in-out",
                    }}
                    onClick={getListed}
                  >
                    <Text color="white" fontSize="16px" mr={1} fontWeight={700}>
                      Refresh
                    </Text>
                    {/* <MdRefresh color="white" size="18px" /> */}
                    <Lottie
                      animationData={refresh}
                      loop={loop}
                      autoPlay={loop}
                      style={{ height: 20 }}
                    />
                  </Flex>
                  {/* <Flex flexDirection="row"></Flex> */}
                </Flex>
              </Flex>
              {shownNfts.length > 0 ? (
                <Wrap
                  direction={["column", "row"]}
                  spacing="20px"
                  justify="space-evenly"
                  width="100%"
                  padding="10px"
                  spacingY="40px"
                  pt="20px"
                  pb="50px"
                  mb={2}
                >
                  {shownNfts.map((item, i) => (
                    <RenderItem
                      item={item}
                      key={item?.id}
                      projectId={projectId}
                      openModalHandler={openModalHandler}
                    />
                  ))}
                </Wrap>
              ) : (
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="center"
                  pb="20px"
                >
                  <Lottie
                    animationData={bonfire}
                    loop={true}
                    style={{ height: 300 }}
                  />
                  <Text
                    color="#A7A7A7"
                    fontSize="14px"
                    mt="-50px"
                    fontWeight="700"
                  >
                    No redemptions yet
                  </Text>
                </Flex>
              )}
              <Pagination
                // total={allNftsLength}
                total={projects.length}
                perPage={postPerPage}
                setPage={setCurrentPage}
                currentPage={currentPage}
              />
            </Flex>
          </Flex>
        </Flex>
      )}
      <RedemptionModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        imageType={imageType}
        image={image}
        modalStatus={modalStatus}
        txHash={txHash}
        modalMode="remove"
        btnText={modalMode === "remove" ? "Remove" : "Update"}
        onClick={modalMode === "remove" ? removeProject : updateProject}
        modalTitle={
          modalMode === "remove" ? "Remove Redemption" : "Update Redemption"
        }
        modalDescription="Deleting this redemption will remove this listing"
        nftTitle={nftTitle}
        nftAddress={nftAddress}
        nftDescription={nftDescription}
        nftId={nftId}
        nftAmount={nftAmount}
        nftTokenType={nftTokenType}
        actionPoints={actionPoints}
        actionName={actionName}
      />
    </Flex>
  );
}
