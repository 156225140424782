import "./Snapshot.css";

import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { projectFirestore, projectRealtimeDb } from "../../firebase/config";
import { Web3Context } from "../../contexts/Web3Context";
import CryptoJS from "crypto-js";
import {
  Icon,
  Select,
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Spinner,
  useToast,
} from "@chakra-ui/react";
import Web3 from "web3";
import { HiLightningBolt } from "react-icons/hi";
import { MdArrowBackIos } from "react-icons/md";
import { useQuery, gql } from "@apollo/client";
import SnapshotConnect from "./SnapshotConnect";
import SnapshotVoteConfig from "./SnapshotVoteConfig";
import SnapshotStats from "./SnapshotStats";
import SnapshotSpace from "./SnapshotSpace";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import { addressCheckHandler } from "../../func/myFunc.ts";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";

const Snapshot = () => {
  const { web3, accounts, contract } = useContext(Web3Context);

  const { projectId } = useParams();
  const toast = useToast();
  const [isConnected, setIsConnected] = useState(false);
  const [hasConfigData, setHasConfigData] = useState(false);
  const [configActiveStatus, setConfigActiveStatus] = useState(0);

  const [projectScoreTypes, setProjectScoreTypes] = useState([]);
  const [projectActions, setProjectActions] = useState([]);
  const [selectedScoreType, setSelectedScoreType] = useState();
  const [selectedAction, setSelectedAction] = useState();
  const [snapshotSpace, setSnapshotSpace] = useState("");
  const [proposalCount, setProposalCount] = useState(0);
  const [voteCount, setVoteCount] = useState(0);
  const [totalProposals, setTotalProposals] = useState();
  const [mounted, setMounted] = useState(false);
  const [btnStatus, setBtnStatus] = useState("");

  const [snapAddressPass, setSnapAddressPass] = useState(false);
  const [snapAddressChecked, setSnapAddressChecked] = useState(false);

  const { data: projectData } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    const checkIfConfigExists = async () => {
      fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getSnapshotConfig",
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ xpProjectId: projectId }),
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          if (data?.configData) {
            setIsConnected(true);
            setHasConfigData(true);
            setSelectedAction(data.configData.actionMap.vote.action);
            setSelectedScoreType(data.configData.actionMap.vote.scoreType);
            setSnapshotSpace(data.configData.space);
            //func
            // snapAddressHandler(data.configData.space);
            getTotalProposals(data.configData.space);
            setMounted(true);
          } else {
            setMounted(true);
          }
        })
        .catch((error) => console.log(error));
    };
    const getVoteData = async () => {
      projectFirestore
        .collection("xp_data")
        .doc(projectId)
        .collection("integrations")
        .doc("snapshot")
        .collection("proposals")
        .onSnapshot((snapshot) => {
          setProposalCount(snapshot.docs.length);
        });

      projectFirestore
        .collection("xp_data")
        .doc(projectId)
        .collection("integrations")
        .doc("snapshot")
        .onSnapshot((snapshot) => {
          if (snapshot?.data()?.projectVoteCount)
            setVoteCount(snapshot.data().projectVoteCount);
        });
    };

    const checkProjectStatus = async () => {
      const statusRef = projectRealtimeDb.ref(
        `xp/snapshot_configs/${projectId}/status`
      );
      statusRef.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data?.mode) {
          setConfigActiveStatus(data?.mode);
        } else {
          setConfigActiveStatus(0);
        }
      });
    };

    if (projectId) {
      getVoteData();
      checkIfConfigExists();
      checkProjectStatus();
    }
  }, [projectId]);

  useEffect(() => {
    if (projectData) {
      let actions = projectData.projects[0].actions.map(
        (action) => action.name
      );

      setProjectActions(actions);
      setProjectScoreTypes(projectData.projects[0].scoreTypes);
    }
  }, [projectData]);

  const handleConnect = () => {
    setIsConnected(true);
  };

  const handleSaveConfig = async () => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");
    let title = "";
    let status = "error";
    try {
      setSnapAddressChecked(false);
      const pass = await addressCheckHandler(snapshotSpace);
      setSnapAddressChecked(true);
      setSnapAddressPass(pass);
      if (!pass) {
        throw new Error("Invalid address");
      }
      let actionMapObj = {
        vote: {
          action: selectedAction,
          scoreType: selectedScoreType,
        },
      };

      const requestData = {
        configData: {
          xpProjectId: projectId,
          space: snapshotSpace,
          actionMap: actionMapObj,
          version: "1.0.0",
        },
      };

      const toSign =
        "0x" + CryptoJS.SHA256(JSON.stringify(requestData)).toString();

      const signature = await web3.eth.personal.sign(
        toSign,
        window.ethereum.selectedAddress
      );

      let requestBody = {
        configData: {
          xpProjectId: projectId,
          space: snapshotSpace,
          actionMap: actionMapObj,
          version: "1.0.0",
        },
        verification: {
          signature,
          message: toSign,
          address: accounts[0],
        },
      };

      const configResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/createSnapshotConfig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const response = await configResponse.json();

      title = "Configuration Updated";
      status = "success";
    } catch (error) {
      title = "Something went wrong!";
    } finally {
      setBtnStatus("");
      toast({
        title,
        // description: "We've created your account for you.",
        position: "top",
        status,
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  const getTotalProposals = async (targetId) => {
    let lastCreatedTimestamp = 0;

    let data = [];
    let pageSize = 20;

    while (true) {
      let currProposalsSet = await fetch("https://hub.snapshot.org/graphql", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          query: ` query {
                proposals (first: 1000,orderBy:"created",where:{created_gt:${lastCreatedTimestamp}, space:"${targetId}"},orderDirection:asc) {
                   id
                  ipfs
                  title
                  
                  start
                  end
                  state
                  author
                  created
                  choices
                  space {
                    id
                    name
                    members
                    avatar
                    symbol
                  }
                  scores_state
                  scores_total
                  scores
                  votes
                  quorum
                  symbol
                }
                  
                }
  `,
        }),
      })
        .then((res) => res.json())
        .then((r) => r.data.proposals);

      data = [...data, ...currProposalsSet];

      if (currProposalsSet.length < pageSize) {
        setTotalProposals(data.length);
        break; //exit loop, nothing left.EXIT----
      }
      lastCreatedTimestamp =
        currProposalsSet[currProposalsSet.length - 1].created;
    }
  };

  if (!mounted) {
    return <LoadComponent />;
  } else if (!isConnected) {
    return (
      <PageWrapper>
        <SnapshotConnect handleConnect={handleConnect} />
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper>
        <Flex
          flexDir="column"
          justifyContent="flex-start"
          alignItems="center"
          w="100%"
          h="100%"
        >
          <Flex w="100%" alignItems="center" mb="30px">
            {hasConfigData ? (
              <></>
            ) : (
              <Icon
                as={MdArrowBackIos}
                color="white"
                fontSize="22px"
                onClick={() => setIsConnected(false)}
                cursor="pointer"
              />
            )}
            <Icon
              as={HiLightningBolt}
              fontSize="26px"
              mr="10px"
              color="orange"
            />
            <Heading color="white" fontSize="18px">
              Snapshot
            </Heading>
          </Flex>
          {configActiveStatus > 0 && (
            <SnapshotStats
              proposalCount={proposalCount}
              totalProposals={totalProposals}
              voteCount={voteCount}
              configActiveStatus={configActiveStatus}
            />
          )}
          <Flex
            mb="20px"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Text color="white" fontWeight={600} fontSize="18px">
              Configuration
            </Text>
          </Flex>
          <SnapshotSpace
            snapshotSpace={snapshotSpace}
            setSnapshotSpace={setSnapshotSpace}
            snapAddressPass={snapAddressPass}
            snapAddressChecked={snapAddressChecked}
          />
          <SnapshotVoteConfig
            projectScoreTypes={projectScoreTypes}
            projectActions={projectActions}
            selectedScoreType={selectedScoreType}
            setSelectedScoreType={setSelectedScoreType}
            selectedAction={selectedAction}
            setSelectedAction={setSelectedAction}
            handleSaveConfig={handleSaveConfig}
          />
          <Button
            variant="purple"
            alignSelf="center"
            mt={10}
            onClick={handleSaveConfig}
            disabled={
              // !snapAddressPass ||
              !selectedScoreType || !snapshotSpace || !selectedAction
            }
          >
            {btnStatus === "loading" ? (
              <Spinner size="xs" color="gray.500" />
            ) : (
              "Save"
            )}
          </Button>
        </Flex>
      </PageWrapper>
    );
  }
};

export default Snapshot;
