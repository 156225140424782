import React, { useContext, useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import { projectRealtimeDb } from "../../firebase/config";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  RadioGroup,
  Radio,
  Stack,
  Spinner,
  Textarea,
  useMediaQuery,
  Image,
  useToast,
  AspectRatio,
} from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import avatar from "../../assets/imgs/avatar3.png";
import defaultbg from "../../assets/imgs/defaultbg.png";
import { FiDownload } from "react-icons/fi";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";
import ClaimRow from "./ClaimRow";

const lengthOptions = [1, 7, 14, 30, "Never"];

const CreateQRClaim = () => {
  const { web3, accounts, contract } = useContext(Web3Context);
  const { projectId } = useParams();

  const [isLargerThan1280] = useMediaQuery("(min-width: 1200px)");
  const [isLargerThan1000] = useMediaQuery("(min-width: 1000px)");
  const toast = useToast();

  const [qrLink, setQrLink] = useState();
  const [claimName, setClaimName] = useState("");
  const [claimDescription, setClaimDescription] = useState("");
  const [displayDate, setDisplayDate] = useState("");
  const [action, setAction] = useState();
  const [scoreType, setScoreType] = useState();
  const [claimLasts, setClaimLasts] = useState();
  const [isPublicClaim, setIsPublicClaim] = useState("0");
  const [btnStatus, setBtnStatus] = useState("");
  const [mounted, setMounted] = useState(false);
  const [locked, setLocked] = useState(false);
  const [claimData, setClaimData] = useState({});
  const [claimHistory, setClaimHistory] = useState([]);

  const { data: projectData, loading } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });


  useEffect(() => {
    getProjectDetails();
    getProjectClaims();
  }, [projectId]);

  useEffect(() => {
    if (claimLasts) {
      let date = "";
      if (claimLasts !== "Never") {
        date = new Date(
          new Date().getTime() + claimLasts * 24 * 60 * 60 * 1000
        ).toLocaleDateString();
      }

      setDisplayDate(date);
    }
  }, [claimLasts]);

  const getProjectDetails = async () => {
    if (projectId)
      await projectRealtimeDb
        .ref()
        .child("xp")
        .child("communities")
        .child(projectId)
        .once("value")
        .then((snapshot) => {
          if (snapshot.exists()) {
            setClaimData(snapshot.val());
          }
          setMounted(true);
        })
        .catch((err) => {
          console.log(err);
          setMounted(true);
        });
  };

  const getProjectClaims = async () => {
    try {
      const response = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getXPClaimsByProject",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ xpProjectId: projectId }),
        }
      );

      const data = await response.json();

      let claims = Object.entries(data.claimsData).map(([name, obj]) => ({
        name,
        ...obj,
      }));

      setClaimHistory(claims);
    } catch (error) {
      setClaimHistory([]);
    }
  };

  const handleClick = async () => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");
    try {
      let timestamp = Date.now().toString();

      let date = 0;
      if (claimLasts === "Never") {
        date = new Date(
          (date = new Date(new Date().getTime() + 36500 * 24 * 60 * 60 * 1000))
        );
      } else {
        date = new Date(
          new Date().getTime() + claimLasts * 24 * 60 * 60 * 1000
        );
      }

      let expireDateUnix = Math.floor(date.getTime() / 1000);

      const signature = await web3.eth.personal.sign(
        timestamp,
        window.ethereum.selectedAddress
      );

      let claimData = {
        claimData: {
          xpProjectId: projectId,
          description: claimDescription,
          name: claimName,
          points: action?.points,
          updateIdFormat: "singleUse",
          xpAction: action?.name,
          xpExpire: expireDateUnix.toString(),
          xpScoreType: scoreType,
          discoverable: isPublicClaim === "0" ? true : false,
        },
        verification: {
          signature,
          message: timestamp,
          address: accounts[0],
        },
      };

      let createClaimResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/createXPClaim",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(claimData),
        }
      );
      let claim = await createClaimResponse.json();

      let getClaimResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getXPClaim",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ claimId: claim.claimId }),
        }
      );

      let data = await getClaimResponse.json();

      setQrLink();
      setClaimName("");
      setClaimDescription("");
      setDisplayDate("");
      setAction();
      setScoreType();
      setClaimLasts();
      setIsPublicClaim("0");

      await getProjectClaims();
      setQrLink("https://xp.huddln.io/xpclaim/" + claim.claimId);
      setBtnStatus("");
    } catch (error) {
      setBtnStatus("error");
      let title = error.message;
      if (title.includes("User denied")) {
        title = "Signature Rejected by User";
      }
      toast({
        title,
        // description: "We've created your account for you.",
        position: "top",
        status: "error",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
  };


  const goToClaim = () => {
    window.open(qrLink, "_blank").focus();
  };

  const deleteClaimHandler = async (claimId) => {
    let title = "";
    let status = "error";
    try {
      let timestamp = Date.now().toString();

      const signature = await web3.eth.personal.sign(
        timestamp,
        window.ethereum.selectedAddress
      );

      let reqBody = {
        claimId,
        verification: {
          signature,
          message: timestamp,
          address: accounts[0],
        },
      };

      const deleteData = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/removeXpClaim",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqBody),
        }
      );
      console.log(deleteData);
      await new Promise((resolve) => setTimeout(resolve, 500));

      await getProjectClaims();

      title = "Claim deleted";
      status = "success";
    } catch (error) {
      title = error.message;
      if (title.includes("User denied")) {
        title = "Signature Rejected by User";
      }
    } finally {
      toast({
        title,
        // description: "We've created your account for you.",
        position: "top",
        status,
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  if (!mounted || loading) {
    return <LoadComponent />;
  } else {
    return (
      <PageWrapper width="800px">
        <Flex
          w="100%"
          alignItems="center"
          mb="30px"
          justifyContent="space-between"
        >
          <Heading color="white" fontSize="18px">
            Create QR Claim
          </Heading>
          {qrLink ? (
            <FiDownload color="#C6C7FF" size="16px" onClick={goToClaim} />
          ) : (
            <></>
          )}
        </Flex>
        <Flex
          borderRadius="15px"
          bg="#1e1e1e"
          w="100%"
          maxW="800px"
          overflow="hidden"
          mb="15px"
          flexDirection="column"
          shadow="lg"
        >
          <Flex height="180px" w="100%" position="relative" mb="45px">
            <AspectRatio width="100%" ratio={16 / 9}>
              <Image
                // height="180px"
                // w="100%"
                // width="100%"
                // paddingBottom="56.25%"
                objectFit="cover"
                src={claimData?.backgroundImageURI}
                fallbackSrc={defaultbg}
              />
            </AspectRatio>
            <Flex
              w="100%"
              justifyContent="center"
              alignItems="center"
              bottom="-40px"
              position="absolute"
            >
              <Image
                borderRadius="50%"
                border="5px solid white"
                boxSize="80px"
                src={claimData?.imageURI}
                fallbackSrc={avatar}
              />
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            p={isLargerThan1000 === true ? "40px" : "20px"}
            pt="10px"
            pb="20px"
          >
            <Flex
              flexDirection="column"
              w="100%"
              justifyContent="center"
              alignItems="center"
              mb="15px"
            >
              <Heading color="white" fontWeight={700} fontSize="20px" mb="5px">
            
              </Heading>
              <Heading color="white" fontWeight={700} fontSize="22px">
                {action ? `Claim ${action?.points} XP` : ""}
              </Heading>
            </Flex>
            <Text color="white" fontWeight={700} fontSize="20px">
              {claimName}
            </Text>
            <Text color="#a7a7a7" fontWeight={600} fontSize="16px" mb="5px">
              {claimDescription}
            </Text>
            <Text color="#a7a7a7" fontWeight={500} fontSize="12px">
              {displayDate}
            </Text>
            <Flex
              w="100%"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
            >
              <Button
                onClick={() => {}}
                variant="purple"
                alignSelf="center"
                maxW="120px"
                mb="10px"
              >
                Claim XP
              </Button>
              <Text
                color="#a7a7a7"
                fontWeight={600}
                fontSize="16px"
                as="u"
                cursor="pointer"
              >
                Scan QR to claim with the Huddln app
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              <Text
                color={locked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
                mb="5px"
              >
                Claim Information
              </Text>
            </Flex>
            <Locks state={locked} setState={setLocked} />
          </Flex>
          <Flex flexDirection="column" pos="relative">
            <Text color="#A7A7A7" fontSize="14px" mb="5px">
              Title
            </Text>
            <Input
              bg="#292929"
              border="2px solid #383838"
              textColor={locked ? "#aaa" : "white"}
              maxLength="250"
              mb={6}
              value={claimName}
              onChange={(e) => setClaimName(e.target.value)}
            />
            <Text color="#A7A7A7" fontSize="14px" mb="5px">
              Description
            </Text>
            <Flex
              m={0}
              mb={6}
              p={0}
              border="2px solid #383838"
              borderRadius="10px"
            >
              <Textarea
                bg="#292929"
                borderRadius="10px"
                border="0px solid transparent"
                _focus={{ boxShadow: "none" }}
                _hover={{
                  border: "0px solid transparent",
                }}
                // placeholder="Description"
                textColor={locked ? "#aaa" : "white"}
                maxLength="500"
                value={claimDescription}
                onChange={(e) => setClaimDescription(e.target.value)}
              />
            </Flex>
            <Flex
              flexDir={isLargerThan1280 === true ? "row" : "column"}
              alignItems={isLargerThan1280 === true ? "center" : "flex-start"}
            >
              <Flex
                flexDirection="row"
                alignItems="center"
                mb={isLargerThan1280 === true ? "0" : "15px"}
              >
                <Flex flexDir="column" mr="15px">
                  <Text
                    color={locked ? "#aaa" : "white"}
                    opacity="1"
                    fontSize="12px"
                    mb="6px"
                  >
                    Action
                  </Text>
                  <Flex
                    flex="1"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    {projectData?.projects[0]?.actions?.length > 0 && (
                      <CustomDropdown
                        type="action"
                        placeholder="Action"
                        array={projectData.projects[0].actions}
                        state={action}
                        setState={setAction}
                        locked={locked}
                      />
                    )}
                  </Flex>
                </Flex>
                <Flex flexDir="column" mr="15px">
                  <Text
                    color={locked ? "#aaa" : "white"}
                    opacity="1"
                    fontSize="12px"
                    mb="6px"
                  >
                    Score Type
                  </Text>
                  <Flex justifyContent="flex-end" alignItems="flex-end">
                    {projectData?.projects[0]?.scoreTypes?.length > 0 && (
                      <CustomDropdown
                        placeholder="Scores"
                        array={projectData.projects[0].scoreTypes}
                        state={scoreType}
                        setState={setScoreType}
                        locked={locked}
                      />
                    )}
                  </Flex>
                </Flex>
              </Flex>
              <Flex flexDirection="row" alignItems="flex-end">
                <Flex flexDir="column" mr="15px">
                  <Text
                    color={locked ? "#aaa" : "white"}
                    opacity="1"
                    fontSize="12px"
                    mb="6px"
                  >
                    Expires
                  </Text>
                  <Flex
                    flex="1"
                    justifyContent="flex-end"
                    alignItems="flex-end"
                  >
                    {lengthOptions?.length > 0 && (
                      <CustomDropdown
                        placeholder="Expiration"
                        array={lengthOptions}
                        state={claimLasts}
                        setState={setClaimLasts}
                      />
                    )}
                  </Flex>
                </Flex>
                <RadioGroup
                  onChange={setIsPublicClaim}
                  value={isPublicClaim}
                  textColor="white"
                  size="sm"
                >
                  <Stack direction="column">
                    <Radio
                      value="0"
                      colorScheme="toggle"
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      Public Claim
                    </Radio>
                    <Radio
                      value="1"
                      colorScheme="toggle"
                      _focus={{
                        boxShadow: "none",
                      }}
                    >
                      Claim via link only
                    </Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            </Flex>
            {locked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
        </CustomCard>
        <Button
          onClick={handleClick}
          variant="purple"
          alignSelf="center"
          mt={10}
          disabled={claimName === "" || !action || !scoreType || !claimLasts}
        >
          {btnStatus === "loading" ? (
            <Spinner size="xs" color="white" />
          ) : (
            "Create Claim"
          )}
        </Button>
        <Flex w="100%" mb="30px" mt="60px" flexDirection="column">
          <Heading color="white" fontSize="18px" mb="5px">
            Claim Activity
          </Heading>
          <Text color="#A7A7A7" fontSize="14px">
            Manually update a wallet address XP score
          </Text>
          {/* <FiDownload color="#C6C7FF" size="16px" onClick={goToClaim} /> */}
          <></>
        </Flex>
        {claimHistory.length > 0 &&
          claimHistory.map((claim, index) => (
            <ClaimRow
              claim={claim}
              key={index}
              toast={toast}
              deleteClaimHandler={deleteClaimHandler}
            />
          ))}
      </PageWrapper>
    );
  }
};

export default CreateQRClaim;
