import React, { useState, useEffect, useContext } from "react";
import { Button, Flex, Text } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import ERC20 from "../../abi/ERC20.json";
import { SUPPORTED_WATCHER_NETWORKS } from "../../config/config.ts";

export default function ERC20Redemption() {
  const { projectId } = useParams();

  const {
    web3,
    accounts,
    contractERC20RedemptionSepolia,
    contractERC20RedemptionAvalanche,
  } = useContext(Web3Context);

  const [redemptionId, setRedemptionId] = useState();

  const NETWORK_IDS = {
    sepolia: 11155111,
    avalanche: 43114,
  };

  const REDEMPTION_CONTRACTS = {
    sepolia: "0x6b984d09979F1dc8f2BDe8E470944696316Eb318",
    avalanche: "0xA68D24005d4Dc631774C8f965dE58E0f014f5977",
  };

  const changeNetwork = async (network) => {
    if (window.ethereum) {
      try {
        await window.ethereum.request({
          method: "wallet_switchEthereumChain",
          params: [
            {
              chainId: web3.utils.toHex(SUPPORTED_WATCHER_NETWORKS[6].chainId),
            },
          ],
        });
      } catch (switchError) {
        if (switchError.code === 4902) {
          try {
            await window.ethereum.request({
              method: "wallet_addEthereumChain",
              params: [
                {
                  chainId: SUPPORTED_WATCHER_NETWORKS[6].chainId,
                  chainName: SUPPORTED_WATCHER_NETWORKS[6].displayName,
                  rpcUrls: [SUPPORTED_WATCHER_NETWORKS[6].rpcUri],
                  nativeCurrency: {
                    name: SUPPORTED_WATCHER_NETWORKS[6].nativeCurrencyName,
                    symbol: SUPPORTED_WATCHER_NETWORKS[6].symbol,
                    decimals: 18,
                  },
                },
              ],
            });
          } catch (addError) {
            console.log(addError);
          }
        }
      }
    } else {
      console.log("Wallet not connected.");
    }
  };

  const createRedemption = async () => {
    await changeNetwork("avalanche");

    try {
      const signature = await web3.eth.personal.sign(
        "Create ERC20 Redemption",
        window.ethereum.selectedAddress
      );

      // function createRedemption(
      //     bytes32 _redemptionId,
      //     bytes32 _projectId,
      //     string memory _name,
      //     uint256 _tokenAmount,
      //     uint256 _points,
      //     string memory _actionName,
      //     string memory _scoreType,
      //     address _contract,
      //     bool _limitOnePerWallet,
      //     string memory _verification
      //   )

      const newRedemptionId = web3.utils.randomHex(32);
      console.log("Redemption ID: ", newRedemptionId);

      setRedemptionId(newRedemptionId);

      console.log(
        newRedemptionId,
        projectId,
        "REDEMPTION_NAME_HERE1",
        1, // TOKEN AMOUNT
        1, // POINTS
        "ACTION_NAME_HERE1",
        "Social", // SCORE TYPE
        "0xd547790Ced64B4693b3387D544986E7d388Ef35c", // ERC20 CONTRACT ADDRESS
        false, // LIMIT ONE PER WALLET (BOOL)
        signature
      );

      await contractERC20RedemptionAvalanche.methods
        .createRedemption(
          newRedemptionId,
          projectId,
          "REDEMPTION_NAME_HERE3",
          1, // TOKEN AMOUNT
          1, // POINTS
          "ACTION_NAME_HERE3",
          "Social", // SCORE TYPE
          "0xd547790Ced64B4693b3387D544986E7d388Ef35c", // ERC20 CONTRACT ADDRESS
          false, // LIMIT ONE PER WALLET (BOOL)
          signature
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
        })
        .on("error", (error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const depositToRedemptionPool = async () => {
    await changeNetwork("avalanche");

    const instanceERC20 = new web3.eth.Contract(
      ERC20.abi,
      "0xd547790Ced64B4693b3387D544986E7d388Ef35c"
    ); // SECOND PARAMETER IS TOKEN CONTRACT ADDRESS

    // const tokenBalance = await instanceERC20.methods
    //   .balanceOf(accounts[0])
    //   .call();
    // console.log("BALANCE: ", tokenBalance);

    let tokensToDeposit = 5;

    try {
      const redemption = await contractERC20RedemptionSepolia.methods
        .getRedemption(
          "0x10b8551f8a62d213149ba1e3ecedfbdd3917e65eb0ac71df618955455c954352"
        )
        .call();

      console.log(redemption);

      // Example number, can remove this since it will be replaced by user input

      // Check how many tokens are approved on ERC-20 contract
      const tokenAllowance = await instanceERC20.methods
        .allowance(accounts[0], REDEMPTION_CONTRACTS["sepolia"])
        .call();

      console.log("Token allowance: ", tokenAllowance);

      // If tokens approved is too low, prompt user for token approval first before trying to deposit
      if (tokenAllowance < tokensToDeposit * 10 ** 18) {
        await instanceERC20.methods
          .approve(REDEMPTION_CONTRACTS["sepolia"], tokensToDeposit * 10 ** 18) // SECOND PARAMETER IS NUMBER OF TOKENS TO DEPOSIT
          .send({
            from: accounts[0],
            maxPriorityFeePerGas: null,
            maxFeePerGas: null,
          })
          .once("transactionHash", (hash) => {
            console.log("hash: " + hash);
          })
          .on("error", (error) => {
            console.log("error: " + error);
          });
      }
    } catch (error) {
      console.log("error: " + error);
    }

    // function depositRedemptionPool(
    //     bytes32 _redemptionId,
    //     uint256 _tokenAmount

    try {
      await contractERC20RedemptionSepolia.methods
        .depositRedemptionPool(
          redemptionId, // REPLACE WITH REDEMPTION ID
          tokensToDeposit // AMOUNT OF TOKENS TO DEPOSIT
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
        })
        .on("error", (error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const withdrawFromRedemptionPool = async () => {
    await changeNetwork("avalanche");

    let tokensToWithdraw = 5;

    try {
      await contractERC20RedemptionSepolia.methods
        .withdrawRedemptionPool(
          redemptionId, // REPLACE WITH REDEMPTION ID
          tokensToWithdraw // AMOUNT OF TOKENS TO WITHDRAW
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
        })
        .on("error", (error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const pauseRedemption = async () => {
    await changeNetwork("avalanche");

    try {
      await contractERC20RedemptionAvalanche.methods
        .pauseRedemption(
          redemptionId // REPLACE WITH REDEMPTION ID
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
        })
        .on("error", (error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const resumeRedemption = async () => {
    await changeNetwork("avalanche");

    try {
      await contractERC20RedemptionAvalanche.methods
        .resumeRedemption(
          redemptionId // REPLACE WITH REDEMPTION ID
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
        })
        .on("error", (error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error: " + error);
    }
  };

  const deleteRedemption = async () => {
    await changeNetwork("avalanche");

    try {
      await contractERC20RedemptionSepolia.methods
        .deleteRedemption(
          redemptionId // REPLACE WITH REDEMPTION ID
        )
        .send({
          from: accounts[0],
          maxPriorityFeePerGas: null,
          maxFeePerGas: null,
        })
        .on("transactionHash", (hash) => {
          console.log("hash: " + hash);
        })
        .on("error", (error) => {
          console.log(error);
        });
    } catch (error) {
      console.log("error: " + error);
    }
  };

  return (
    <Flex flexDir="column">
      <Text color="white">ERC-20 Redemption</Text>
      <Button onClick={createRedemption}>Create Redemption</Button>
      <Button onClick={depositToRedemptionPool}>Deposit to Redemption</Button>
      <Button onClick={withdrawFromRedemptionPool}>
        Withdraw from Redemption
      </Button>
      <Button onClick={pauseRedemption}>Pause Redemption</Button>
      <Button onClick={resumeRedemption}>Resume Redemption</Button>
      <Button onClick={deleteRedemption}>Delete Redemption</Button>
    </Flex>
  );
}
