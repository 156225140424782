import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Web3Context } from "../../contexts/Web3Context";
import {
  Input,
  Button,
  Flex,
  Heading,
  Text,
  Radio,
  RadioGroup,
  Stack,
  Spinner,
  useToast,
  useMediaQuery,
} from "@chakra-ui/react";
import { useQuery, gql } from "@apollo/client";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import CustomTooltip from "../../components/CustomTooltip/CustomTooltip";
import DisplayActions from "./DisplayActions";
import { useNoArgs } from "../../func/myFunc.ts";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import changeNetworkToPolygon from "../../func/changeNetwork";

const NEW_FIELD = {
  name: "",
  points: "",
  direction: 0,
};

const GET_ACTIONS = gql`
  query getResults($projectId: String!) {
    projects(where: { id: $projectId }) {
      actions {
        name
        points
        direction
      }
    }
  }
`;

const AddActions = () => {
  const toast = useToast();
  const { web3, accounts, contract } = useContext(Web3Context);
  const { projectId } = useParams();
  const [isLargerThan800] = useMediaQuery("(min-width: 800px)");

  const [btnStatus, setBtnStatus] = useState("");
  const [locked, setLocked] = useState(false);
  const [formFields, setFormFields] = useState([NEW_FIELD]);

  const {
    data: actionData,
    refetch,
    loading,
  } = useQuery(GET_ACTIONS, {
    variables: { projectId },
  });

  const doRefetch = useNoArgs(refetch);

  const handleClick = async () => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");

    let error = false;
    let title = "";
    let foundDuplicate = false;

    for (let index = 0; index < formFields.length; index++) {
      const object = formFields[index];
      for (const key in object) {
        const element = object[key];

        if (element == null || element === "") {
          // if (!element || element === "") { cannot use this because element==0 is considered falsy, even though its valid

          error = true;
          // title = `${key} must be filled`;
          title = "Add New Action information missing";
          break;
        }
      }

      if (
        actionData?.projects[0]?.actions?.some((e) => e.name === object?.name)
      ) {
        error = true;
        title = `Please add a new action`;
        break;
      }

      const valueArr = formFields.map((item) => item.name);
      const isDuplicate = valueArr.some(
        (item, idx) => valueArr.indexOf(item) !== idx
      );

      if (isDuplicate) {
        foundDuplicate = true;
        break;
      }
    }

    if (error || foundDuplicate) {
      setBtnStatus("");
      return toast({
        title: error ? title : "Please enter different Actions",
        position: "top",
        status: "error",
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }

    //formfields clone because, contract call malforms formfield object always.
    let formFields2 = formFields.map((a) => {
      return { ...a };
    });

    await changeNetworkToPolygon();

    await contract.methods
      .addActions(projectId, formFields2)
      .send({
        from: accounts[0],
        maxPriorityFeePerGas: null,
        maxFeePerGas: null,
      })
      .on("transactionHash", (hash) => {
        console.log("Transaction hash: " + hash);
      })
      .on("confirmation", (confirmationNumber, receipt) => {
        if (confirmationNumber === 5) {
          doRefetch();
          setBtnStatus("");
          setFormFields([NEW_FIELD]);
          toast({
            title: "Action Added",
            // description: "We've created your account for you.",
            position: "top",
            status: "success",
            variant: "subtle",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .on("error", (error) => {
        let title = error.message;
        setBtnStatus("");
        toast({
          title,
          // description: "We've created your account for you.",
          position: "top",
          status: "error",
          variant: "subtle",
          duration: 2000,
          isClosable: true,
        });
      });
  };

  const removeHandler = async (item) => {};

  const addField = () => {
    setFormFields([...formFields, NEW_FIELD]);
  };

  const removeField = (index) => {
    setFormFields((prevFormFields) => {
      return prevFormFields.filter((_, i) => i !== index);
    });
  };

  const handlePointsChange = (e, index) => {
    let formData = JSON.parse(JSON.stringify(formFields));
    let val = e?.target?.value;
    let isNum = /^\d+$/.test(val);

    if (isNum || val === "") {
      formData[index]["points"] = val;
      setFormFields(formData);
    }
  };

  const handleRadioChange = (e, index) => {
    let formData = [...formFields];
    formData[index]["direction"] = e;
    setFormFields(formData);
  };

  const handleNameChange = (e, index) => {
    let formData = JSON.parse(JSON.stringify(formFields));
    const val = e?.target?.value;
    const pattern = /^[a-zA-Z0-9_-]*$/;

    if (pattern.test(val)) {
      formData[index]["name"] = val;
      setFormFields(formData);
    }
  };

  if (loading) {
    return <LoadComponent />;
  } else {
    return (
      <PageWrapper width="800px">
        <Flex w="100%" alignItems="center" mb="30px">
          <Heading color="white" fontSize="18px">
            Actions
          </Heading>
        </Flex>
        {actionData?.projects[0]?.actions?.length > 0 && (
          <DisplayActions
            array={actionData?.projects[0]?.actions}
            removeHandler={removeHandler}
            title="Actions"
          />
        )}
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            mb="5px"
          >
            <Flex flexDirection="row" alignItems="center">
              <Text
                color={locked ? "#aaa" : "white"}
                fontWeight={600}
                fontSize="18px"
              >
                Add New Actions
              </Text>
              <Flex mb="12px">
                <CustomTooltip
                  txt="Action Names can only contain letters A-Z, numbers 0-9, underscores “_” and hyphens “-”."
                  w={390}
                  t={-50}
                />
              </Flex>
            </Flex>
            <Locks state={locked} setState={setLocked} />
          </Flex>
          <Text color="#A7A7A7" fontSize="14px" mb="15px">
            Enter the action name, amount of points and whether it adds or
            subtracts points
          </Text>
          <Flex flexDirection="column" position="relative">
            {formFields.map((form, index) => (
              <Flex
                key={index}
                // width="100%"
                mb="10px"
                alignItems={isLargerThan800 ? "row" : "flex-start"}
                flexDirection={isLargerThan800 ? "row" : "column"}
                // justifyContent="space-between"
              >
                <Flex
                  // width="100%"
                  alignItems="flex-start"
                  justifyContent="flex-start"
                >
                  <Flex flexDir="column" width="300px" maxW="300px" mr="15px">
                    <Text color="#A7A7A7" fontSize="12px" mb="6px">
                      Action Name
                    </Text>
                    <Input
                      borderRadius="10px"
                      textColor={locked ? "#aaa" : "white"}
                      // maxLength="250"
                      width="100%"
                      maxW="300px"
                      max={100}
                      pl={2}
                      pr={2}
                      bg="#292929"
                      border="2px solid #383838"
                      value={formFields[index].name}
                      onChange={(e) => handleNameChange(e, index)}
                    />
                  </Flex>
                  <Flex flexDir="column" width="100%" maxW="100px" mr="15px">
                    <Text color="#A7A7A7" fontSize="12px" mb="6px">
                      Action Points
                    </Text>
                    <Input
                      borderRadius="10px"
                      textColor={locked ? "#aaa" : "white"}
                      // maxLength="250"
                      width="100%"
                      maxW="100px"
                      max={100}
                      pl={2}
                      pr={2}
                      bg="#292929"
                      border="2px solid #383838"
                      value={formFields[index].points}
                      onChange={(e) => handlePointsChange(e, index)}
                    />
                  </Flex>
                </Flex>
                <Flex alignItems="center">
                  <Flex flexDir="column" width="100%" maxW="100px" mr="15px">
                    <Text color="#A7A7A7" fontSize="12px" mb="6px">
                      Direction
                    </Text>
                    <RadioGroup
                      onChange={(e) => handleRadioChange(e, index)}
                      // value={formFields[index].direction}
                      defaultValue="0"
                      textColor="white"
                      maxWidth={350}
                      size="sm"
                    >
                      <Stack direction="column">
                        <Radio
                          value="0"
                          colorScheme={locked ? "black" : "toggle"}
                          _focus={{
                            boxShadow: "none",
                          }}
                        >
                          Increase
                        </Radio>
                        <Radio
                          value="1"
                          colorScheme={locked ? "black" : "toggle"}
                          _focus={{
                            boxShadow: "none",
                          }}
                        >
                          Decrease
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                  {index > 0 || true ? (
                    <Button
                      onClick={() => removeField(index)}
                      disabled={locked}
                      h="30px"
                      w="90px"
                      borderRadius="15px"
                      fontSize="14px"
                      fontWeight={700}
                      variant="error"
                    >
                      Remove
                    </Button>
                  ) : (
                    <Flex h="30px" w="90px" />
                  )}
                </Flex>
              </Flex>
            ))}
            <Button
              onClick={addField}
              h="30px"
              w="90px"
              mt="10px"
              fontSize="14px"
              fontWeight={700}
              variant="purple"
              disabled={locked}
            >
              Add field
            </Button>
            {locked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
        </CustomCard>
        <Button
          onClick={handleClick}
          variant="purple"
          alignSelf="center"
          mt={10}
          disabled={formFields.length === 0}
        >
          {btnStatus === "loading" ? (
            <Spinner size="xs" color="gray.500" />
          ) : (
            "Add"
          )}
        </Button>
      </PageWrapper>
    );
  }
};

export default AddActions;
