import React, { useState } from "react";
import { Flex, Icon, Text, Input, Heading } from "@chakra-ui/react";
import { MdGroups } from "react-icons/md";
import { GoCheck, GoX } from "react-icons/go";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";

const SnapshotSpace = ({
  setSnapshotSpace,
  snapshotSpace,
  snapAddressPass,
  snapAddressChecked,
}) => {
  const [spaceLocked, setSpaceLocked] = useState(true);

  return (
    <Flex flexDir="column" w="95%">
      <CustomCard>
        <Flex
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          mb="5px"
        >
          <Flex flexDirection="row" alignItems="center">
            <Icon
              as={MdGroups}
              color={spaceLocked ? "#aaa" : "white"}
              fontSize="25px"
              fontWeight={600}
              mr="10px"
            />
            <Heading
              color={spaceLocked ? "#aaa" : "white"}
              fontWeight={600}
              fontSize="18px"
            >
              Snapshot Space
            </Heading>
          </Flex>
          <Locks state={spaceLocked} setState={setSpaceLocked} />
        </Flex>
        <Text color="#A7A7A7" fontSize="14px" mb="15px">
          Set up the points to reward each time a vote is submitted
        </Text>
        <Flex
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Flex
            flexDirection="row"
            position="relative"
            justifyContent="center"
            alignItems="center"
            bg="#292929"
            border="2px solid #383838"
            // borderColor={!royaltyAddressPass ? "red" : "#505050"}
            borderRadius="10px"
            width="70%"
            p={2}
            pt={0}
            pb={0}
          >
            <Input
              borderRadius="10px"
              textColor={spaceLocked ? "#aaa" : "white"}
              maxLength="250"
              width="100%"
              max={100}
              p={0}
              bg="#292929"
              pr={2}
              value={snapshotSpace}
              onChange={(e) => setSnapshotSpace(e.target.value)}
            />
            {snapAddressPass && snapAddressChecked ? (
              <GoCheck color="green" size="18px" />
            ) : !snapAddressPass && snapAddressChecked ? (
              <GoX color="red" size="18px" />
            ) : (
              <Flex boxSize="16px" />
            )}
            {spaceLocked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
          <Flex />
        </Flex>
      </CustomCard>
    </Flex>
  );
};

export default SnapshotSpace;
