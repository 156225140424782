import Web3 from "web3";
import ERC20 from "../../abi/ERC20.json";
import ERC20Redemption from "../../abi/ERC20Redemption.json";
import XP from "../../abi/XP.json";
import { SUPPORTED_WATCHER_NETWORKS } from "../../config/config.ts";
import getWeb3 from '../../getWeb3'

export const getWalletToken = async (walletAddress, chainId) => {
  try {
    const response = await fetch(
      `https://api.covalenthq.com/v1/${chainId}/address/` +
      walletAddress +
      "/balances_v2/?nft=false&no-logs=true&key=ckey_41582b8966364d718b96c10304a"
    );
    const jsonResponse = await response.json();
    const data = jsonResponse.data.items;
    const filteredItems = data.filter((item) => item.native_token === false);

    return filteredItems;
  } catch (error) {
    console.log(error);
    return [];
  }
};

export const changeNetwork = async (chainId, web3) => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: web3.utils.toHex(chainId) }],
      });
    } catch (switchError) {
      if (switchError.code === 4902) {
        const item = SUPPORTED_WATCHER_NETWORKS.find(
          (obj) => obj.chainId === chainId
        );
        try {
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: item.chainIdOx,
                chainName: item.chainName,
                rpcUrls: [item.publicRpcUri],
                nativeCurrency: {
                  name: item.nativeCurrencyName,
                  symbol: item.symbol,
                  decimals: 18,
                },
              },
            ],
          });
        } catch (error) {
          console.log(error)
          throw new Error("Chain Id Could not be added");
        }
      }
    }
  } else {
    throw new Error("Chain Id not connected to wallet");
  }
};

export const getTokenData = async (network, tokenAddress) => {
  try {
    const newWeb3 = new Web3(network?.publicRpcUri);
    const instanceERC20 = new newWeb3.eth.Contract(ERC20.abi, tokenAddress);

    // const decimals = await instanceERC20.methods.decimals().call();

    const tokenData = await Promise.all([
      instanceERC20.methods.symbol().call(),
      instanceERC20.methods.name().call(),
      instanceERC20.methods.decimals().call(),
    ]);

    return tokenData;
  } catch (error) {
    console.log(error);
    return ["", "", "18"];
  }
};

export const web3Divide = (web3, amount, decimals) => {
  // const a = web3.utils.toBN(amount);
  // const b = web3.utils.toBN(10 ** decimals);
  try {
    const a = amount;
    const b = 10 ** decimals;
    //console.log('a/b', a / b);
    return (a / b).toFixed(4).toString();
  } catch (err) {
    console.log(err);
    return 0;
  }
  //  return a.div(b).toString();
};

export const getErc20 = async (tokenAddress) => {
  try {
    const web3 = await getWeb3();

    const networkId = await web3.eth.net.getId();

    const deployedNetwork = XP.networks[networkId];
    const instance = new web3.eth.Contract(
      ERC20Redemption.abi,
      deployedNetwork && deployedNetwork.address
    );
    instance.options.address = tokenAddress

    return instance

  } catch (error) {
    console.error(error);
  }
};