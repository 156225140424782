import "./RedemptionCard.css";

import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";

const RedemptionCard = ({ btn, title, description, navTo }) => {
  const [isShown, setIsShown] = useState(false);
  return (
    <div
      className="redemption-card"
      onClick={navTo}
      onMouseEnter={() => setIsShown(true)}
      onMouseLeave={() => setIsShown(false)}
    >
      <div className="redemption-card-content">
        <div className="redemption-card-btn">
          <p style={{ opacity: isShown ? 1 : 0 }}>{btn}</p>
        </div>
        <div className="redemption-card-tittle">{title}</div>
        <div className="redemption-card-description">{description}</div>
        <div className="redemption-card-arrow">
          <FaArrowRight color="white" size={20} />
        </div>
      </div>
    </div>
  );
};

export default RedemptionCard;
