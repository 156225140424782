import React, { useState, useEffect } from "react";
import { Flex, Text } from "@chakra-ui/react";
import CustomCard from "../../components/CustomCard/CustomCard";
import ProgressBar from "@ramonak/react-progress-bar";

const SnapshotStats = ({
  proposalCount,
  totalProposals,
  voteCount,
  configActiveStatus,
}) => {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const percent = Math.floor((proposalCount / totalProposals) * 100);
    let timer1 = setTimeout(
      () => setCompleted(percent > 100 ? 100 : percent),
      50
    );
    return () => {
      clearTimeout(timer1);
    };
  }, [proposalCount, totalProposals]);

  return (
    <Flex flexDir="column" w="95%">
      <CustomCard>
        <Flex justifyContent="space-between">
          <Text color="#A7A7A7" fontWeight={600} fontSize="18px">
            Processed
          </Text>
          <Flex flexDirection="row" alignItems="center" >
            <Text
              fontWeight={600}
              fontSize="18px"
              m={0}
              p={0}
              mr="10px"
              color="white"
            >
              Status
            </Text>
            <Flex
              flexDirection="row"
              alignItems="center"
              p="15px"
              pb="5px"
              pt="5px"
              bg="#363636"
              borderRadius="60px"
            >
              {/* <FaCircle
              color={configActiveStatus > 1 ? "#20E070" : "#E0C120"}
              size="14px"
            /> */}
              {configActiveStatus === 1 && <div className="pulse yellow" />}
              {configActiveStatus === 2 && <div className="pulse green" />}
              {configActiveStatus === 3 && <div className="pulse-anim" />}
              <Text
                fontWeight={600}
                fontSize="18px"
                m={0}
                p={0}
                ml="10px"
                color="white"
              >
                {configActiveStatus === 1 && "PENDING"}
                {configActiveStatus === 2 && "ACTIVE"}
                {configActiveStatus === 3 && "SCANNING"}
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Flex mt={5} mb="10px">
          <Flex flexDir="column">
            <Text fontWeight={600} fontSize="18px" color="white">
              {voteCount}
            </Text>
            <Text fontWeight={600} fontSize="18px" color="white">
              {proposalCount}
            </Text>
          </Flex>
          <Flex flexDir="column" ml="1.5vw">
            <Text color="#A7A7A7" fontWeight={600} fontSize="18px">
              Votes
            </Text>
            <Text color="#A7A7A7" fontWeight={600} fontSize="18px">
              Proposals
            </Text>
          </Flex>
        </Flex>
        <Flex flexDir="column">
          <Flex justifyContent="flex-end" mb={2}>
            <Text color="#A7A7A7" fontWeight={600} fontSize="18px">
              {proposalCount} of {totalProposals} Completed
            </Text>
          </Flex>
          <ProgressBar
            completed={completed}
            bgColor="#A654F4"
            baseBgColor="#393939"
            labelColor="white"
            labelSize="12px"
            customLabelStyles={{paddingRight:8}}
          />
        </Flex>
      </CustomCard>
    </Flex>
  );
};

export default SnapshotStats;
