import React from "react";
import { Text, Flex, Button } from "@chakra-ui/react";

require("./CustomFileUpload.css");

const CustomFileUpload = ({ onChange, bg }) => {
  // onChange(e) {
  //   var files = e.target.files;
  //   console.log(files);
  //   var filesArr = Array.prototype.slice.call(files);
  //   console.log(filesArr);
  //   this.setState({ files: [...this.state.files, ...filesArr] });
  // }

  // removeFile(f) {
  //   this.setState({ files: this.state.files.filter((x) => x !== f) });
  // }

  return (
    <Flex
      // bgGradient="linear(to-r, #B5E5FF, #C6C7FF, #E1A9F6, #F9ABE7)"
      borderRadius={25}
      w="110px"
      height="40px"
      justifyContent="center"
      alignItems="center"
    >
      <Button
        m={1}
        onClick={() => {}}
        borderRadius={25}
        variant="blank"
        bg={bg ? bg : ""}
        w="104px"
        height="34px"
        _hover={{
          cursor: "pointer",
          shadow: "dark-lg",
          // transform: "translateY(-5px)",
          borderColor: "black",
          backgroundColor: "#383838",
          transitionDuration: "0.2s",
          transitionTimingFunction: "ease-in-out",
        }}
      >
        <label className="custom-file-upload">
          <input type="file" multiple onChange={onChange} />
          <Text color="white" fontSize="14px" fontWeight="700">
            Browse
          </Text>
        </label>
        {/* {this.state.files.map((x) => (
            <div
              className="file-preview"
              onClick={this.removeFile.bind(this, x)}
            >
              {x.name}
            </div>
          ))} */}
      </Button>
    </Flex>
  );
};

export default CustomFileUpload;
