import React, { useState } from "react";
import { Flex, Icon, Text, Heading } from "@chakra-ui/react";
import { MdHowToVote } from "react-icons/md";
import Locks from "../../components/Locks/Locks";
import CustomCard from "../../components/CustomCard/CustomCard";
import CustomDropdown from "../../components/CustomDropdown/CustomDropdown";

const SnapshotVoteConfig = ({
  projectScoreTypes,
  projectActions,
  selectedScoreType,
  setSelectedScoreType,
  selectedAction,
  setSelectedAction,
}) => {
  const [voteLocked, setVoteLocked] = useState(true);

  return (
    <Flex flexDir="column" w="95%">
      <CustomCard>
        <Flex
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
          mb="5px"
        >
          <Flex flexDirection="row" alignItems="center">
            <Icon
              as={MdHowToVote}
              color={voteLocked ? "#aaa" : "white"}
              fontSize="25px"
              fontWeight={600}
              mr="10px"
              mt="-4px"
            />
            <Heading
              color={voteLocked ? "#aaa" : "white"}
              fontWeight={600}
              fontSize="18px"
            >
              Votes
            </Heading>
          </Flex>
          <Locks state={voteLocked} setState={setVoteLocked} />
        </Flex>
        <Text color="#A7A7A7" fontSize="14px" mb="15px">
          Set up the points to reward each time a vote is submitted
        </Text>
        <Flex
          flexDirection="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Flex flexDirection="row" alignItems="center" position="relative">
            <Flex flexDir="column">
              <Text
                color={voteLocked ? "#aaa" : "white"}
                opacity="1"
                fontSize="12px"
                mb="6px"
              >
                Score Type
              </Text>
              <Flex
                flex="1"
                justifyContent="flex-end"
                alignItems="flex-end"
                id={voteLocked ? "snap-select-locked" : "snap-select"}
              >
                {projectScoreTypes?.length > 0 && (
                  <CustomDropdown
                    placeholder="Scores"
                    array={projectScoreTypes}
                    state={selectedScoreType}
                    setState={setSelectedScoreType}
                  />
                )}
                {/* <Select
                  minW="150px"
                  // border="2px solid #505050"
                  iconColor="white"
                  onChange={(e) => setSelectedScoreType(e.target.value)}
                  required
                  _hover={{
                    cursor: "pointer",
                  }}
                  defaultValue={selectedScoreType}
                >
                  {projectScoreTypes?.length > 0 &&
                    projectScoreTypes?.map((scoreType, index) => (
                      <option key={index} value={scoreType}>
                        {scoreType}
                      </option>
                    ))}
                </Select> */}
              </Flex>
            </Flex>
            <Flex flexDir="column" ml="15px">
              <Text
                color={voteLocked ? "#aaa" : "white"}
                opacity="1"
                fontSize="12px"
                mb="6px"
              >
                Action
              </Text>
              <Flex
                flex="1"
                justifyContent="flex-end"
                alignItems="flex-end"
                id={voteLocked ? "snap-select-locked" : "snap-select"}
              >
                {projectActions?.length > 0 && (
                  <CustomDropdown
                    placeholder="Action"
                    array={projectActions}
                    state={selectedAction}
                    setState={setSelectedAction}
                  />
                )}
                {/* <Select
                  iconColor="white"
                  onChange={(e) => setSelectedAction(e.target.value)}
                  required
                  _hover={{
                    cursor: "pointer",
                  }}
                  defaultValue={selectedAction}
                >
                  {projectActions?.length > 0 &&
                    projectActions?.map((actionType, index) => (
                      <option key={index} value={actionType}>
                        {actionType}
                      </option>
                    ))}
                </Select> */}
              </Flex>
            </Flex>
            {voteLocked && (
              <Flex position="absolute" top={0} left={0} right={0} bottom={0} />
            )}
          </Flex>
          <Flex />
        </Flex>
      </CustomCard>
    </Flex>
  );
};

export default SnapshotVoteConfig;
