import React from "react";
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Image,
} from "@chakra-ui/react";
import { GoChevronDown } from "react-icons/go";
import { capitalizeFirstLetter } from "../../func/myFunc.ts";

export default function CustomDropdown({
  type = "",
  locked = false,
  maxW = "200px",
  placeholder = "",
  array = [],
  state = null,
  setState = () => { },
}) {
  const title = type === "action" ? state?.name : state;
  return (
    <Menu>
      <MenuButton
        px="10px"
        py="0px"
        minW="150px"
        maxW={maxW}
        flexDirection="row"
        transition="all 0.2s"
        borderRadius="10px"
        height="40px"
        bg="#292929"
        border="2px solid #383838"
        _focus={{ boxShadow: "none" }}
      >
        <Flex
          alignItems="center"
          color={locked || !title || title === "" ? "#aaa" : "white"}
          width="100%"
          justifyContent="space-between"
          mr="4px"
          overflow="hidden"
        >
          <Flex alignItems="center">
            {state?.img ||
              (state?.logoUri && (
                <Image
                  // key={src}
                  // bg="green"
                  mr="10px"
                  boxSize="15px"
                  objectFit="contain"
                  src={state?.img ? state?.img : state?.logoUri}
                  // fallbackSrc={missingImg}
                  alt=""
                />
              ))}
            {typeof title === "number" ? title :
              title?.length > 0
                ? capitalizeFirstLetter(title)
                : capitalizeFirstLetter(placeholder)}


          </Flex>
          <GoChevronDown color="white" size="22px" />
        </Flex>
      </MenuButton>
      <MenuList bg="#292929" color="white" maxH="300px" overflowY="auto" zIndex={100}>
        {array.map((item, index) => {
          return (
            <MenuItem
              onClick={() => {
                console.log('item', item)
                setState(item)
              }}
              _hover={{ bg: "#212121" }}
              key={`${item}:${index}`}
            >
              {type === "action"
                ? capitalizeFirstLetter(item.name)
                : capitalizeFirstLetter(item)}
            </MenuItem>
          );
        })}
      </MenuList>
    </Menu>
  );
}
