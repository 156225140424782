import { Flex } from "@chakra-ui/react";

const PADDING = "24px";
const BOARDER = "2px solid #383838";
const BG = "#212121";

export default function CustomCard({ children, mb = "20px" }) {
  return (
    <Flex
      w="100%"
      bg={BG}
      border={BOARDER}
      flexDirection="column"
      borderRadius="15px"
      padding={PADDING}
      mb={mb}
    >
      {children}
    </Flex>
  );
}
