export const getExistingConfig = async (projectId) => {
  try {
    const response = await fetch(
      "https://us-central1-huddl-in.cloudfunctions.net/getDiscordConfig",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ xpProjectId: projectId }),
      }
    );

    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch existing config");
    }
  } catch (error) {
    console.log("Error: " + error);
    return null
  }
};

export const getGuildInfo = async (guildId) => {
  const body = JSON.stringify({
    guildId: guildId
  });

  try {
    const response = await fetch(
      "https://us-central1-huddl-in.cloudfunctions.net/getDiscordGuildInfo",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        mode: "cors",
        body: body,
        redirect: "follow"
      }
    );
    const { data } = await response.json();
    return data;
  } catch (error) {
    console.log("Error: ", error);
    return {};
  }
};

export const isUnixTimeLessThan20Minutes = (unixTimeInSeconds) => {
  const twentyMinutesInMilliseconds = 20 * 60 * 1000;
  const currentUnixTime = Math.floor(Date.now() / 1000);


  return currentUnixTime - unixTimeInSeconds < twentyMinutesInMilliseconds;
};