import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/database";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCMNTZojJA4PIDyePjLy64EXAX7vj99r_U",
  authDomain: "huddl-in.firebaseapp.com",
  databaseURL: "https://huddl-in.firebaseio.com",
  projectId: "huddl-in",
  storageBucket: "huddl-in.appspot.com",
  messagingSenderId: "904258133091",
  appId: "1:904258133091:web:9c96cc3ce1127a8fd2ec56",
  measurementId: "G-QK9H4T1Z9X",
};

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectRealtimeDb = firebase.database();

// timestamp
const timestamp = firebase.firestore.Timestamp;

export { projectFirestore, projectRealtimeDb, timestamp };
