import React, { useState, useContext, useEffect } from "react";
import {
  Flex,
  Text,
  Button,
  Spinner,
  useToast,
  Icon,
  Input,
  Heading,
} from "@chakra-ui/react";
import LoadComponent from "../../components/LoadComponent/LoadComponent";
import PageWrapper from "../../components/PageWrapper/PageWrapper.tsx";
import { GET_PROJECT_DATA } from "../../GraphQL/queries";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router-dom";
import TwitterCard from "./TwitterCard";
import TwitterBotStatus from "./TwitterBotStatus";
import CryptoJS from "crypto-js";
import { Web3Context } from "../../contexts/Web3Context";
import { MdGroups } from "react-icons/md";
import { GoCheck, GoX } from "react-icons/go";
import CustomCard from "../../components/CustomCard/CustomCard";
import Locks from "../../components/Locks/Locks";
import { Client } from "twitter-api-sdk";
import { FaTwitter } from "react-icons/fa";

const client = new Client(
  "AAAAAAAAAAAAAAAAAAAAAFktTgEAAAAAnxf%2FLRDou2ANtJC854h2BxI5R6c%3DVnjmj4lSCHrfyH2fKz80hoMMSK6AEUpZBi8Sli6l6f7NE5rziG"
);

const TWITTER_CONFIG_ARRAY = [
  { name: "followers", displayName: "Followers" },
  { name: "tags", displayName: "Tags" },
  { name: "retweets", displayName: "Retweets" },
  { name: "comments", displayName: "Comments" },
];

export default function TwitterBot() {
  const { projectId } = useParams();
  const toast = useToast();
  const { web3, accounts } = useContext(Web3Context);

  const [twitterBotObj, setTwitterBotObj] = useState({
    followers: {
      action: "",
      scoreType: "",
      dailyLimit: 0,
      shared: false,
    },
    tags: {
      action: "",
      scoreType: "",
      dailyLimit: 0,
      shared: false,
    },
    retweets: {
      action: "",
      scoreType: "",
      dailyLimit: 0,
      shared: false,
    },
    comments: {
      action: "",
      scoreType: "",
      dailyLimit: 0,
      shared: false,
    },
  });
  const [isLoading, setIsLoading] = useState(true);
  const [configStatus, setConfigStatus] = useState();
  const [btnStatus, setBtnStatus] = useState("");
  const [twitterUsername, setTwitterUsername] = useState("TwitterDev");
  const [userNameLocked, setUserNameLocked] = useState(false);
  const [userNameChecked, setUserNameChecked] = useState(false);
  const [userNamePass, setUserNamePass] = useState(false);

  const { data: projectData, loading } = useQuery(GET_PROJECT_DATA, {
    skip: !projectId,
    variables: { id: projectId },
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://us-central1-huddl-in.cloudfunctions.net/getTwitterConfig",
          {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ xpProjectId: projectId }),
          }
        );

        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }

        const data = await response.json();

        if (data.status === "does not exist") {
          return;
        }

        setConfigStatus(data);
      } catch (error) {
        console.error(error);
        // Handle error appropriately (e.g., throw, return default value, etc.)
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, []);

  const setStateHandler = (key, field, value) => {
    setTwitterBotObj((prevState) => {
      if (key in prevState) {
        const updatedObj = { ...prevState };
        updatedObj[key][field] = value;
        return updatedObj;
      }
      return prevState;
    });
  };

  const filterSharedFields = (obj) => {
    const updatedObj = { ...obj };
    for (const key in updatedObj) {
      if (updatedObj[key].shared === false) {
        delete updatedObj[key];
      }
    }
    return updatedObj;
  };

  const validateFields = (obj) => {
    for (const key in obj) {
      const field = obj[key];
      if (
        !field.action ||
        !field.action.name ||
        field.action.name.trim() === "" ||
        !field.scoreType ||
        field.scoreType.trim() === "" ||
        (key === "dailyLimit" && isNaN(field))
      ) {
        throw new Error(`Invalid value found for field: ${key}`);
      }
    }
  };

  const restructureObj = (obj) => {
    const result = {};
    for (const key in obj) {
      const field = obj[key];
      if (typeof field === "object" && field !== null) {
        const { shared, action, ...rest } = field;
        if (action && action.name) {
          rest.action = action.name;
        }
        result[key] = rest;
      } else {
        result[key] = field;
      }
    }
    return result;
  };

  const fetchTwitterUserData = async () => {
    const endpoint = `https://api.twitter.com/2/users/by/username/${twitterUsername}`;
    setUserNameChecked(true);
    try {
      // const response = await fetch(endpoint, {
      //   headers: {
      //     Authorization:
      //       "Bearer AAAAAAAAAAAAAAAAAAAAAFktTgEAAAAAnxf%2FLRDou2ANtJC854h2BxI5R6c%3DVnjmj4lSCHrfyH2fKz80hoMMSK6AEUpZBi8Sli6l6f7NE5rziG",
      //     "Content-Type": "application/json",
      //   },
      // });

      // if (!response.ok) {
      //   setUserNamePass(false);
      //   throw new Error("Failed to fetch Twitter user data");
      // }

      // const data = await response.json();

      // Process the fetched user data

      try {
        const usernameLookup = await client.users.findUserByUsername(
          //The Twitter username (handle) of the user.
          "TwitterDev"
        );
        console.log(usernameLookup);
      } catch (error) {
        console.log(error);
      }
      return "";
      // console.log(data);
      // return data;
    } catch (error) {
      // Handle any errors that occurred during fetching
      console.error(error);
      setUserNamePass(false);
      throw new Error("Failed to fetch Twitter user data");
    }
  };

  const handleSave = async () => {
    if (btnStatus === "loading") {
      return;
    }
    setBtnStatus("loading");
    let title = "";
    let status = "error";
    try {
      const onlyShared = filterSharedFields(twitterBotObj);
      validateFields(onlyShared);
      const actionMap = restructureObj(onlyShared);

      let twitterId = await fetchTwitterUserData();

      return;

      const requestData = {
        configData: {
          xpProjectId: projectId,
          twitterId,
          actionMap,
          version: "1.0.0",
        },
      };

      const toSign =
        "0x" + CryptoJS.SHA256(JSON.stringify(requestData)).toString();

      const signature = await web3.eth.personal.sign(
        toSign,
        window.ethereum.selectedAddress
      );

      let requestBody = {
        configData: {
          xpProjectId: projectId,
          twitterId,
          actionMap,
          version: "1.0.0",
        },
        verification: {
          signature,
          message: toSign,
          address: accounts[0],
        },
      };

      const configResponse = await fetch(
        "https://us-central1-huddl-in.cloudfunctions.net/getTwitterConfig",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      );

      const response = await configResponse.json();

      title = "Configuration Updated";
      status = "success";
    } catch (error) {
      title = error.message;
    } finally {
      setBtnStatus("");
      toast({
        title,
        // description: "We've created your account for you.",
        position: "top",
        status,
        variant: "subtle",
        duration: 2000,
        isClosable: true,
      });
    }
  };

  if (isLoading || loading) {
    return <LoadComponent />;
  } else if (!configStatus) {
    return (
      <PageWrapper width="800px">
        <Flex justifyContent="center" alignItems="center" flex="1">
          <CustomCard>
            <Flex
              maxW="600px"
              flexDirection="column"
              alignItems="center"
              justifyContent="space-evenly"
            >
              <Icon as={FaTwitter} color="white" fontSize={60} mb="30px" />
              <Text color="white" fontWeight={600} fontSize="20px" mb="10px">
                Connect Twitter
              </Text>
              <Text color="#a7a7a7" fontSize="16px" fontWeight={500} mb="40px">
                Monitor and reward your followers for interacting with your
                account
              </Text>
              <Button
                variant="purple"
                fontSize="18px"
                fontWeight={600}
                borderRadius={45}
                onClick={() => setConfigStatus(true)}
              >
                Connect
              </Button>
            </Flex>
          </CustomCard>
        </Flex>
      </PageWrapper>
    );
  } else {
    return (
      <PageWrapper width="800px">
        {/* {configActiveStatus > 0 && ( */}
        <TwitterBotStatus
          proposalCount={1}
          voteCount={1}
          configActiveStatus={1}
        />
        {/* )} */}
        <Flex
          mb="20px"
          mt="20px"
          width="100%"
          justifyContent="center"
          alignItems="center"
        >
          <Text color="white" fontWeight={600} fontSize="18px">
            Configuration
          </Text>
        </Flex>
        <CustomCard>
          <Flex
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
            mb="5px"
          >
            <Heading
              color={userNameLocked ? "#aaa" : "white"}
              fontWeight={600}
              fontSize="18px"
              mb="10px"
            >
              Twitter Username
            </Heading>
            <Locks state={userNameLocked} setState={setUserNameLocked} />
          </Flex>
          <Flex
            flexDirection="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Flex
              flexDirection="row"
              position="relative"
              justifyContent="center"
              alignItems="center"
              bg="#292929"
              border="2px solid #383838"
              // borderColor={!royaltyAddressPass ? "red" : "#505050"}
              borderRadius="10px"
              width="70%"
              p={2}
              pt={0}
              pb={0}
            >
              <Input
                borderRadius="10px"
                textColor={userNameLocked ? "#aaa" : "white"}
                maxLength="250"
                width="100%"
                max={100}
                p={0}
                bg="#292929"
                pr={2}
                value={twitterUsername}
                onChange={(e) => setTwitterUsername(e.target.value)}
              />
              {userNamePass && userNameChecked ? (
                <GoCheck color="green" size="18px" />
              ) : !userNamePass && userNameChecked ? (
                <GoX color="red" size="18px" />
              ) : (
                <Flex boxSize="16px" />
              )}
              {userNameLocked && (
                <Flex
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                />
              )}
            </Flex>
            <Flex />
          </Flex>
        </CustomCard>

        {TWITTER_CONFIG_ARRAY.map((item) => (
          <TwitterCard
            key={item.name}
            item={item}
            actionArray={projectData?.projects[0]?.actions}
            scoreArray={projectData?.projects[0]?.scoreTypes}
            twitterBotObj={twitterBotObj}
            setStateHandler={setStateHandler}
          />
        ))}
        <Button
          mt="10px"
          onClick={handleSave}
          variant="purple"
          alignSelf="center"
          // disabled={contract.length === 0}
        >
          {btnStatus === "loading" ? (
            <Spinner size="xs" color="gray.500" />
          ) : (
            "Save"
          )}
        </Button>
      </PageWrapper>
    );
  }
}
